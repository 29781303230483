import { JsonFormControls } from "./json-form-controls";

  
  export class JsonFormData {
    controls: JsonFormControls[];
  }


  export class JsonFormPacienteSalesForce {
    Salesforce_Id: string
    name: string
    generales: GeneralesSalesForce
    domicilio: DomicilioSalesForce
    informacion: InformacionSalesForce
  }

  export interface GeneralesSalesForce {
    nombre:string 
    apellido:string 
    edad: number
    sexo:string 
    fecha_nacimiento:string 
    correo:string 
    estado_civil:string 
    seguro: boolean 
    nombre_seguro:string 
    seguro_antiguedad:string 
    telefono: string
  }

  export interface DomicilioSalesForce {
    calle: string 
    colonia: string 
    municipio: string 
    numero_exterior: string 
    numero_interior: string 
    codigo_postal: string 
    estado: string 
  }

  export interface InformacionSalesForce {
    origen_paciente?: string 
    paciente_recomienda?: string 
    metodo_contacto?: string 
    nombre_contacto_emergencia: string 
    numero_contacto_emergencia: string 
  }