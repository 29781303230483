import { Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, FormArray } from '@angular/forms';
import { CredentialsService } from 'src/app/core/services/credentials.service';
import { Cita } from 'src/app/pages/calendar/models/calendar';
import { FormatsService } from 'src/app/shared/services/formats.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { WebcamImage } from 'ngx-webcam';
import * as _ from 'lodash';
import { Subject, Subscription, delay, interval } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { AlertsService } from 'src/app/shared/services/alerts.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-historia-clinica-varices',
  templateUrl: './historia-clinica-varices.component.html',
  styleUrls: ['./historia-clinica-varices.component.scss']
})
export class HistoriaClinicaVaricesComponent implements OnInit, OnDestroy {

  @ViewChild('modalSvg') modalSvg: TemplateRef<any>;

  @Input()
  _formatoData: any
  
  
  _controls: any[] = []
  _formato: any = {}
  record: any;

  _Cita: Cita;
  _label: string

  _itsReady: boolean

  _formControl: FormGroup;

  _svg = ''

  isDesktopDevice!: boolean
  //_pictures: any[] = []

  $interval!: Subscription;
  _createInterval = true
  _formVarices: FormGroup = this.formBuilder.group({})

  _interval: number = 0;


  _Images: any[] = []
  _LaboratorioFiles: any[] = []

  _ngbModalRef: NgbModalRef;

  _assetsSvg = ['assets/images/VaricesIMG/Varices1B.svg','assets/images/VaricesIMG/Varices2B.svg', 'assets/images/VaricesIMG/Varices3B.svg','assets/images/VaricesIMG/Varices4B.svg']

  _svgEditors: any[] = []
  _svgfilesPng: any[] = []
  _historia_Clinica_Varices: any[] = []
  _historia_clinica_pdf: any[] = []

  //#region NOTAS DE EVOLUCION
  _notas_evolucion: any[] = []
  _formNotasEvolucion: FormGroup = this.formBuilder.group({})
  _log_notas_evolucion: any[] = []
  _selected_nota_evolucion: any
  _img_nota_evolucion_historia_clinica_varices: any[] = []

  _citas_notas_evolucion: any[] = []
  _cita_selected: string | null

  //#endregion

  _userData: any;
  _firma: any[] = []
  eventSubjectClerFiles: Subject<boolean> = new Subject<boolean>();

  constructor (
    private formBuilder: FormBuilder,
    private formatService: FormatsService,
    private credentials: CredentialsService,
    private deviceService: DeviceDetectorService,
    private toastr: ToastrService,
    private alert: AlertsService,
    private ngModal: NgbModal
  ) {

    const _dataUser = this.credentials._getdataUser()

    if(_dataUser) {
      this._userData = _dataUser
    }

    this.record = this.credentials.userInfoData.data.records[0]
    const cita = this.credentials.getCitaJson()
    if(cita) {
      this._Cita = cita
    }
    this.epicFunction();
  }

  //#region FORMULARIO DINAMICO

  get f() {
    return this._formNotasEvolucion.controls
  }

  get notas(): FormArray {
    return this._formNotasEvolucion.get('notas') as FormArray
  }

  //#endregion

  /**
   * 
   */
  private epicFunction() {
    this.isDesktopDevice = this.deviceService.isDesktop()
  }

  /**
   * 
   */
  ngOnInit(): void {

    if(this.$interval) {
      this.$interval.unsubscribe()
      console.log('ON DESTROY!!!! INIT');      
    }

    if(this._formatoData) {
      this._formato = this._formatoData
      this._label = this._formato.label;
      this._controls = this._formato.form.controls;         
      
      if(this._label == 'Nota_Evolucion_Historia_Clinica_Varices'){
          this._createInterval = false

          this._formNotasEvolucion = this.formBuilder.group({
            notas: this.formBuilder.array([])
          })
          this._loadNotasEvolucion()
         // this._addControl()
      }

      this.initForm()
      this.loadImages() 
    }
  }

  /**
   * 
   */
  private _addControl() {
    this.notas.push(this.newControlBloqueo('nota_evolucion_1', []))
  }

  /**
   * 
   * @param dia 
   * @param _horas 
   * @returns 
   */
  public newControlBloqueo(nota_evolucion_1: string, Objects:any[]): FormGroup {
    return this.formBuilder.group({
      nota_evolucion_1,
      Objects: new FormArray(Objects)
    })
  }

  /**
   * 
   */
  ngOnDestroy(): void {
    if(this.$interval) {
      console.log('ON DESTROY !!!°°°');
      
      this.$interval.unsubscribe()
    }
  }

  /**
   * Cargamos las notas de evolucion
   */
  private _loadNotasEvolucion () {
    this.formatService.GetNotasEvolucion(this.record.Id).subscribe({
      next:(resp: any) => {
        if(resp.codigo === 200) {
          try {
            const Nota_Evolucion_Varices = resp.data
            console.log({Nota_Evolucion_Varices});            
            this._citas_notas_evolucion = Nota_Evolucion_Varices

          }catch(err) {
            console.error({err});            
          } 
        }
      },
      error(err) {
        console.error({err});
        
      },
    })
  }

  /**
   * 
   * @param _nota_evolucion 
   */
  _setNotaEvolucion(_nota_evolucion: any) {      
    //console.log({_nota_evolucion});
    
    this._selected_nota_evolucion = _nota_evolucion
  }

  _set_log_notasEvolucion(_citas_notas_evolucion: any) {
    this._log_notas_evolucion =  _citas_notas_evolucion.archivos.Nota_Evolucion_Varices
    this._selected_nota_evolucion = null

    this._cita_selected = _citas_notas_evolucion.Cita_Id
  }

  /**
   * 
   */
  private initForm () {
    
    _.map(this._controls, (a) => {
      if(a.name != 'historia_clinica_varices_frame_1' )
        _.map(a.Objects, (b) => {

          _.map(b.Objects, (c) => {

            _.map(c.Objects, (d) => {

              _.map(d.Objects, (e) => {
                this._formVarices.addControl(
                  e.name, this.formBuilder.control(e.value)
                )
              })

              this._formVarices.addControl(
                d.name, this.formBuilder.control(d.value)
              )
            })

            this._formVarices.addControl(
              c.name, this.formBuilder.control(c.value) 
            )
          })

          this._formVarices.addControl(
            b.name, this.formBuilder.control(b.value)
          )
        })      
    })
    
    this._itsReady = true        
  
   
    if(this._createInterval) {
      this.createInterval()
    }
  }

  /**
   * 
   */
  private loadImages(): void {
    this._Images = []
    this._LaboratorioFiles = []
    this._img_nota_evolucion_historia_clinica_varices = []
    this._historia_Clinica_Varices = []
    this._historia_clinica_pdf = []

    this.formatService._getListImages(this._Cita.Cita_Id, this.record.Name).subscribe({
      next:(response) =>  {
          if(response.codigo === 200) {
            const data = response.data
            if(data.formato && data.formato.archivos) {

              const archivos = data.formato.archivos  

              this._Images = archivos.Paciente ? archivos.Paciente : [];         
              this._LaboratorioFiles = archivos.Laboratorio ? archivos.Laboratorio : []  
              
              const _files = archivos.Historia_Clinica_Varices ? archivos.Historia_Clinica_Varices : []
              this._img_nota_evolucion_historia_clinica_varices = archivos.nota_evolucion_historia_clinica_varices //ar.data.formato.archivos.nota_evolucion_historia_clinica_varices
             
              _.map(_files, (a) => {
                const _filename = String(a.filename).split('.')
                if(_filename.length === 2 && _filename[1] != 'pdf') {
                  this._historia_Clinica_Varices.push(a)
                }else if (_filename.length === 2 && _filename[1] == 'pdf') {
                  this._historia_clinica_pdf.push(a)
                }                               
              })
              
            }
          }
        },
    })

    this.formatService._getImageProfile(this._userData.usuario).subscribe({
      next:(response: any) => {
          if(response.codigo === 200) {
            
            try {
              const archvios = response.data.formato.archivos
              if(archvios) {
                this._firma = archvios.Firma
              }
            }catch(error) {

            }
            
            
          }
      }
    })
  }

  /**
   * 
   */
  private createInterval() {
    const myObservable = interval(1000);
    this.$interval = myObservable.pipe(
      delay(500),

    ).subscribe((integer) => {
      if(integer === 20 ) { 
        this.$interval.unsubscribe();        
        this._newSaveFormat()            
      }           

      this._interval = integer            
    })
  }

  /**
   * 
   */
  _newSaveFormat(): void {

    let _formValues = this._formVarices.value
    let _body_: any[] = []

    _.map(_formValues, (a, i) => {
        _body_.push({
          name: i,
          value: a
        })      
    })    

    let _bodyForm = {
      Cita_Id: this._Cita.Cita_Id,
      label: this._formato.label,
      new_data: _body_
    }

  //  this._formatoValues = _bodyForm   
  this._saveFormatValues(_bodyForm, true)
  }

  /**
   * 
   * @param _jsonObject 
   * @param createInterval 
   */
  private _saveFormatValues (_jsonObject: any, createInterval: boolean = false): void  {
    this.formatService._postFormato(_jsonObject).then((resp) => {

      this.toastr.info('Se ha guardado la información','Guardada', {
        timeOut: 3200
      }).onHidden.subscribe(() => {

        if(createInterval) {     
          this.createInterval()
        }else{
          console.log('NO SE CREA INTERVALOOOOOO');          
        }

      })
    }).catch((error) => {
      console.error({error});
      
    })

  }

  /**
   * 
   * @param event 
   */
  onEventAddedFiles(event: any) {
    console.log({_EVENT: event});   
  }

  /**
   * 
   * @param webcamImage 
   */
  _onPictureTaken(webcamImage: WebcamImage) {
   // this._pictures.push(webcamImage.imageAsDataUrl)
  }

  _onEvenEmmiter(event: boolean) { }

  /**
   * 
   * @param event 
   */
  _onEventEmmiterImg(event: any) {

    this._svgfilesPng.push(event)   


  }

  /**
   * 
   * @param _control 
   */
  _saveSvgToPng(_control: any): void { 
    this.alert._showLoader('Guardando por favor espere...')   
    
    this.formatService.UploadFiles(this._Cita.Cita_Id, this.record.Name, this._label,null, null, this._svgfilesPng).then((resp) => {
      console.log({resp});      
      this.alert._hideLoader()
      this.loadImages()

      this._svgEditors  = []

    }).catch((error) => {
      console.error({error});
      this.alert._hideLoader()      
    })
    
  }

  /**
   * 
   * @param event 
   */
  _onUploadFiles(_files: any[], _control: any) {  
    this.alert._showLoader('Subiendo, por favor espere')
    console.log({_files});
    
    //this.formatService._uploadImage({ cita_id: this._Cita.Cita_Id, paciente_id: this.record.Name,files: })
    this.formatService.UploadFiles(this._Cita.Cita_Id, this.record.Name,this._label, _files).then((resp) => {
      this.alert._hideLoader()
      this.toastr.success('Exito al subir los archivos','Exito!!',{
        timeOut: 3200
      })
      this.eventSubjectClerFiles.next(true)
      this.loadImages()
    }).catch((error) => {
      this.alert._hideLoader()
      this.toastr.error('Algo salio mal, al tratar de subir los archivos','Error!!', {
        timeOut: 3200
      })
    })
  }

  /**
   * 
   * @param event 
   */
  _onSaveItImages(webcamImage: any [],  _control: any): void {
    
    if(webcamImage && webcamImage.length) {
        this.alert._showLoader('Guardando, espere...')

        console.log({webcamImage: webcamImage});        
        
        this.formatService.UploadFiles(this._Cita.Cita_Id,this.record.Name,this._label,null, webcamImage).then((resp) => {
          this.alert._hideLoader()
          this.toastr.success('Exito al subir las imagenes','Exito!!',{
            timeOut: 3200
          })
          this.loadImages()
        }).catch((error) => {
          console.error({error});
          
          this.alert._hideLoader()
          this.toastr.error('Algo salio mal, al tratar de subir las imagenes','Error!!', {
            timeOut: 3200
          })
        })
        
    }
  }


  _openModalSvg(): void {
    
    this._ngbModalRef = this.ngModal.open(this.modalSvg, {    
      size:'xl',
      animation:true,
      backdrop:'static',
      fullscreen:'lg'
    })
  }

  /**
   * 
   * @param _index 
   */
  _onSetsvg(_assets: string, _index: number) : void {

    const _find = _.find(this._svgEditors, { id: _index })

    if(_find) {
      this.toastr.info('Ya existe, este formato','',{
        timeOut: 3200
      })
      return
    }

    this._svgEditors.push({
      id: _index,
      path: _assets,
      _showComments:false,
      comentario: null
    })    

    this._ngbModalRef.close()
  }

  /**
   * 
   * @param control
   */
  _AddedNote(control: any) {
    //console.log({control});   
    this._notas_evolucion.push(control)        
  }

  /**
   * 
   * @param _index 
   */
  _deleteNota(_index: number) {
    this._notas_evolucion.splice(_index, 1)
  }

  _onSaveNotas(): void {
    console.log({_notas_EVOLUCIN___: this._notas_evolucion});
  }

  _openPDF(filePdf: any) {
    console.log({filePdf});

    setTimeout(() => {
      window.open(filePdf.url, '_blank', 'width=1000, height=800');
    }, 100);
  }

  /**
   * 
   * @param img 
   */
  _onDeleteImg(img: any) {
    Swal.fire({
      title:'Por favor confirme esta accion',
      text:'¿Desea eliminar esta imagen '+ img.filename + ' ?',
      showCancelButton:true,
      icon:'question',
      confirmButtonText:'Sí, eliminar',
      cancelButtonText:'No, Cancelar'
    }).then((result) => {
      if(result.value) {
        this.alert._showLoader('Por favor espere...')

        this.formatService._DeleteImg(this._Cita.Cita_Id,this.record.Name,this._label,img.filename).then((resp) => {          
          console.log({resp});
          this.alert._hideLoader()          
          this.toastr.success('Se ha eliminado correctamente, la imagen','Exito!!', {
            timeOut: 3200
          })
          this.loadImages()

        }).catch((error) => {
          console.error({error});
          this.alert._hideLoader()
          this.toastr.error('No se pudo eliminar, la imagen','Error!!', {
            timeOut: 3200
          })
        })

      }
    })
  }


  _oneventsignatureImg(event: any) {
    if(event != null) {
      this.alert._showLoader('Subiendo firma, espere...')
      console.log({event});
     // this.formatService._uploadImage({cita_id: this._Cita.Cita_Id, paciente_id: this.record.Name,  } )
      
    }
    
  }

  _onEventSaveItFormNotasEvolucion(event: boolean) {

    if(event) {
      this._notas_evolucion = []
      this._loadNotasEvolucion()
    }

  }

}
