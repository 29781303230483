import { Component, HostListener, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { CandidatosService } from '../../calendar/services/candidatos.service';
import { CandidatoData } from '../../calendar/models/candidatos';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';

const _SET_INDEX_ = '_set_index'
const _CURRENT_PAGe = '_currentPage'

@Component({
  selector: 'app-candidatos',
  templateUrl: './candidatos.component.html',
  styleUrls: ['./candidatos.component.scss']
})
export class CandidatosComponent implements OnInit, OnDestroy {

  @ViewChild('modalCandidato') modalCandidato!: TemplateRef <any>;
  @ViewChild('successContent') successContent: TemplateRef<any>;
  @ViewChild('modalCita') modalCita: TemplateRef<any>

  _listadoCandidatos: CandidatoData[]

  _candidato: CandidatoData;

  _ngbModalRef: NgbModalRef
  _ngbModalSuccess: NgbModalRef
  _ngModalCitaRef: NgbModalRef


  $_subscriber: Subscription;  
  _spinner = '_spinner_'
  _responseData: any;

  searchText: string 

  _set_index = 0

  totalCount:   number = 0
  currentPage:  number = 1
  pageSize:     number = 100
  totalPages:   number = 1

  page = 1

  isLoading: boolean = false
  
  constructor (
    private candiatos: CandidatosService,
    private modal: NgbModal,
    private spinner: NgxSpinnerService
  ) {

    let _setIndex = sessionStorage.getItem(_SET_INDEX_)

    if(_setIndex) {
      let _CURRENT_PAGe_ = sessionStorage.getItem(_CURRENT_PAGe)

      this._set_index = Number(_setIndex)
      this.currentPage = _CURRENT_PAGe_ ? Number(_CURRENT_PAGe_) : 1
    }

    this.loadCandidatos()
  }

  @HostListener('window:scroll',['$event'])
  onWindowScroll(event:any){    
    if(window.innerHeight+window.scrollY>=document.body.offsetHeight&&!this.isLoading){
      console.log(event);
      this.page++
      this._set_index = (this.page * this.pageSize)
      sessionStorage.setItem(_SET_INDEX_,`${this._set_index}`)    
      this.loadCandidatos()  
    }
  }

  ngOnInit(): void {
    this. $_subscriber = this.candiatos.CurrentLoadCandidatos.subscribe({
      next:(value) => {
        console.log({value____: value});
        
        if(value) {
          this.loadCandidatos()
        }
      },
      error(err) {
        console.error({err});        
      },
    })

  }

  ngOnDestroy(): void {
    if(this.$_subscriber) {
      this.$_subscriber.unsubscribe()
    }
  }


  /**
   * 
   */
  private  loadCandidatos() {
    this.isLoading = true
    this.spinner.show(this._spinner)
    this._listadoCandidatos = []
    this.candiatos.ObtenerListadoCanidatos('true',this._set_index).subscribe({
      next:(response) =>  {      
        this.spinner.hide(this._spinner)  
        if(response.codigo === 200) {
          this._listadoCandidatos = response.data.candidatos
          this.totalCount = response.data.total
          this.totalPages = Math.ceil(this.totalCount / this.pageSize)
          this.isLoading = false
        }
      },
      error:(err) =>{
        this.spinner.hide(this._spinner)  
          console.error({err});          
      },
    })
  }

  /**
   * 
   * @param content 
   * @param candidato 
   */
  openModal(content:TemplateRef<any>, candidato: CandidatoData) {

    this._candidato = candidato
    this._ngbModalRef = this.modal.open(content, {
      size:'xl',
      animation:true,
      backdrop:'static',
      fullscreen:'lg'
    })
  }

  /**
   * 
   * @param event 
   */
  onClickEvent(event: boolean) {
    console.log({event});
    
    if(event) {
      this.modal.dismissAll()
    }
  }

  /**
   * 
   * @param event 
   */
  _onEventEmmiterReaload(event:boolean): void {

    if(event) {
      this.modal.dismissAll()
      this.loadCandidatos()
    }

  }


  /**
   * 
   * @param item 
   */
  _onEdit(candidato: CandidatoData) {

  //  console.log({item});
    this._candidato = candidato  

    this._ngbModalRef = this.modal.open(this.modalCandidato, {
      size:'xl',
      animation:true,
      backdrop:'static',
      fullscreen:'lg'
    })  

  }

  /**
   * 
   * @param event 
   */
  _onEventCloseModal(event: boolean) {
    if(event) {
      this._ngbModalRef.close()
      this.loadCandidatos()
    }
  }

  _onEventConfirmCandidate(event: any) {

    this.loadCandidatos()

    this._ngbModalRef.close()
    this._responseData = event
    this._openModalSuccess()

  }


  private _openModalSuccess() {

    this._ngbModalSuccess = this.modal.open(this.successContent, {
      animation: true,
      size:'lg',     
      backdrop:'static',     
      fullscreen:'lg'
    })
  }

  
  _onSetPatient() {
    this._ngbModalSuccess.close()
    
    this._ngModalCitaRef = this.modal.open(this.modalCita, {
      animation: true,
      size:'xl',     
      backdrop:'static',     
      fullscreen:'lg'
    })
  }

  /**
   *
   *
   * @param {*} event
   * @memberof CandidatosComponent
   */
/*   _onPageChange(page: number) {
    const startItem = (page - 1) * this.pageSize;
    sessionStorage.setItem(_CURRENT_PAGe, `${page}`)
    const endItem = page * this.pageSize;
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    this._set_index = endItem
    sessionStorage.setItem(_SET_INDEX_,`${this._set_index}`)
    console.log({ startItem, endItem });
    this.loadCandidatos()    
  } */

}
