<div *ngIf="_showForm">
    <form [formGroup]="_formNotasEvolucion">
        <div class="row">
            <div *ngFor="let _item of item.Objects"
                [ngClass]="_item.type === 'canvas' ? 'col-12 mt-2': 'col-lg-4 col-xxl-3 mt-2' ">
                <div *ngIf="_item.type != 'canvas' ">
                    <label class="control-label">{{_item.label}}</label>
                    <div *ngIf="_item.type === 'text' || _item.type === 'number'">
                        <input [type]="_item.type" [name]="_item.name" [required]="_item.required" [value]="_item.value"
                            [formControlName]="_item.name" [readonly]="_item.readonly" class="form-control">
                    </div>
                    <div *ngIf="_item.type === 'date' ">
                        <input class="form-control flatpickr-input" [id]="_item.name" [type]="_item.type" mwlFlatpickr
                            [altInput]="true" [formControlName]="_item.name" [value]="_item.value"
                            [convertModelValue]="true">
                    </div>
                </div>
            </div>
        </div>
    </form>
    <div class="mt-4">
        <fieldset class="mmt-4">
            <div class="card card-body">
                <app-custom-upload-files 
                [showUpload]="false" 
                [_maxFiles]="10" 
                [_showGallery]="true"
                [_acceptedFileTypes]="['image/jpeg','image/png','image/jpg','application/pdf']"
                [eventSubjectClerFiles]="eventSubjectClerFiles.asObservable()"
                (_filesPondsEvent)="_onUploadFiles($event)">
                </app-custom-upload-files>
            </div>
        </fieldset>
        <div class="mt-4" *ngIf="isDesktopDevice">
            <app-ng-web-cam [showBtnSaveIt]="false" (eventSaveImages)="_onSaveItImages($event)"></app-ng-web-cam>
        </div>
    </div>

    <div class="d-flex mt-4" >
        <div class="card card-body">
            <div class="row gp-2 ">
                <div class="col-xxl-4 col-lg-6" *ngFor="let item of _svgEditors"> 
                    <app-svg-editor
                    [_path]="item.path"
                    [_maxHeigh]="70"
                    [_svgItem]="item"
                    (eventEmmiterSaveImg)="_onEvenEmmiter($event)"
                    (eventEmmiterImage)="_onEventEmmiterImg($event)"
                    ></app-svg-editor>
                </div>
            </div>   
        </div>
    </div>
</div>

<div class="d-flex justify-content-center mt-2">
    <button type="button" class="btn btn-success w-50 text-uppercase fw-bold " (click)="_onsave()">Guardar nota de Evolucion</button>
</div>