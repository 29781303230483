<ng-container *ngIf="_showEditor">
    <div style="height: 70vh">
        <pintura-editor
        #editorRef
        [options]="editorOptions"
        [src]="_path"
        [imageCropAspectRatio]="cropAspectRatio"
        (load)="handleLoad($event)"
        (process)="handleProcess($event)"
        ></pintura-editor>
    </div>
    <div class="mt-4">
        <div class="hstack gap-2">
            <button class="btn btn-icon" (click)="_onEventDeleted(_path)">
                <i class="mdi mdi-trash-can-outline align-middle me-1 fs-3 text-danger"></i>
            </button>
            <button class="btn btn-sm btn-primary" (click)="_addComent()">Agregar comentario</button>
        </div>
    </div>

    <fieldset class="mt-4" *ngIf="_svgItem._showComments">
        <textarea 
        class="form-control" 
        [rows]="4"
        [ngModelOptions]="{standalone: true}"
        [(ngModel)]="_svgItem.comentario"></textarea>

        <button class="btn btn-danger btn-sm mt-4" (click)="_deleteComent()">Quitar comentario </button>

    </fieldset>
</ng-container>
<div [style]="'height: '+_maxHeigh+'vh;'" *ngIf="result">
    <p><img  [src]="result" alt="" class="img-fluid"   /></p>
    <div class="d-flex justify-content-center " *ngIf="_svgItem._showComments">      
        <textarea 
        class="form-control" 
        [rows]="4"
        [ngModelOptions]="{standalone: true}"
        readonly
        disabled
        [(ngModel)]="_svgItem.comentario"></textarea>
    </div>
</div>

<!-- <ng-template *ngIf="result">
  
    <div class="d-flex justify-content-center ">
        <button type="button" class="btn btn-primary w-100" (click)="_onClickEditor()">Volver a dibujar</button>
    </div>
</ng-template> -->

<!--  -->