import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AlertsService } from '@app/shared/services/alerts.service';
import { VoiceRecognitionService } from '@app/shared/services/voice-recognition.service';
import * as _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { Subscription, interval } from 'rxjs';
import { CredentialsService } from 'src/app/core/services/credentials.service';
import { Cita } from 'src/app/pages/calendar/models/calendar';
import { FormatsService } from 'src/app/shared/services/formats.service';

@Component({
  selector: 'app-descipcion-quirurgica',
  templateUrl: './descipcion-quirurgica.component.html',
  styleUrls: ['./descipcion-quirurgica.component.scss']
})
export class DescipcionQuirurgicaComponent implements OnInit {

  @Input()
  id: string;

  @Input()
  _formatoData : any


  _controls: any[] = []
  _formato: any = {}

  _label: string
  _itsReady: boolean

  record: any;

   
  $interval!: Subscription;
  public formDescQuirurgica: FormGroup = this.formBuilder.group({})

  _Cita: Cita;
  _BODYFORM: any;

  firma_enfermera_intrumentista: string | null
  firma_enfermera_circulante: string | null
  firma_ayudante: string | null
  firma_anestesiologo: string | null
  firma_cirujano: string| null

  constructor(
    private formatService: FormatsService,
    private date: DatePipe,
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    private credentials: CredentialsService,
    public VoiceS: VoiceRecognitionService,
    private alert: AlertsService
  ) {
   // this.VoiceS.init();  
    this.record = this.credentials.userInfoData.data.records[0]
    const cita = this.credentials.getCitaJson()
    if(cita) {
      this._Cita = cita
    }
  }

  ngOnInit(): void {

    if(this._formatoData) {
      this._formato = this._formatoData

      this._label = this._formato.label;
      this._controls = this._formato.form.controls;

      this.loadImages()

      this._itsReady = true
      this.initForm();
    }
  }

  get f() {
    return this.formDescQuirurgica.controls;
  }

  ngOnDestroy(): void {
    if(this.$interval) {
      this.$interval.unsubscribe()
    }
    this._savePrevDestroy()
  }

  
  private _savePrevDestroy(): void {
    let _formValues = this.formDescQuirurgica.value
    let _body_: any[] = []

    _.map(_formValues, (a, i) => {
        _body_.push({
          name: i,
          value: a
        })      
    })    

    let _bodyForm = {
      Cita_Id: this._Cita.Cita_Id,
      label: this._formato.label,
      new_data: _body_
    }
    this._saveFormatValues(_bodyForm, false)
  }

  /**
   * 
   */
  private initForm() {
    _.map(this._controls, (a) => {      
      _.map(a.Objects, (b) => {
        if(b.type != 'canvas') {
          this.formDescQuirurgica.addControl(
            b.name, this.formBuilder.control(b.value)
          )
        }else {
          console.log({_CANVAS:b});          
        }
      })   
    })
    
   this.createInterval()
   
  }

  private loadImages() {
    this.formatService._getListImages(this._Cita.Cita_Id, this.record.Name).subscribe({
      next:(response) => {
        if(response.codigo === 200) {
          const data = response.data

          
          if(data.formato && data.formato.archivos) {
            const archivos = data.formato.archivos  
            this.firma_enfermera_intrumentista = archivos.firma_enfermera_intrumentista ? archivos.firma_enfermera_intrumentista[0].url : null
            this.firma_enfermera_circulante = archivos.firma_enfermera_circulante ? archivos.firma_enfermera_circulante[0].url : null
            this.firma_ayudante = archivos.firma_ayudante ? archivos.firma_ayudante[0].url : null
            this.firma_anestesiologo = archivos.firma_anestesiologo ? archivos.firma_anestesiologo[0].url : null
            this.firma_cirujano = archivos.firma_cirujano ? archivos.firma_cirujano[0].url : null

          }
        
        }

      }
    })
  }

  /**
   *
   *
   * @private
   * @memberof DescipcionQuirurgicaComponent
   */
  private createInterval () {
    const myObservable= interval(1000);
    this.$interval = myObservable.subscribe((integer) => {
      if(integer === 20 ) { 
        this.$interval.unsubscribe();      
        this._newSaveFormat()          
      }           
    })
  }

    /**
   * 
   */
    _newSaveFormat(): void {

      let _formValues = this.formDescQuirurgica.value
      let _body_: any[] = []
  
      _.map(_formValues, (a, i) => {
          _body_.push({
            name: i,
            value: a
          })      
      })    
  
      let _bodyForm = {
        Cita_Id: this._Cita.Cita_Id,
        label: this._formato.label,
        new_data: _body_
      }
  
    //  this._formatoValues = _bodyForm   
      this._saveFormatValues(_bodyForm, true)
    }
  
    /**
     * 
     * @param _jsonObject 
     * @param createInterval 
     */
    _saveFormatValues(_jsonObject: any, createInterval: boolean = false): void {
  
      this.formatService._postFormato(_jsonObject).then((resp) => {
  
        this.toastr.info('Se ha guardado la información','Guardada', {
          timeOut: 3200
        }).onHidden.subscribe(() => {
          if(createInterval) {
                      
            this.createInterval()
          }
        })
      }).catch((error) => {
        console.error({error});
        
      })
  
  
    }

  private _onSaveIt () {

    let _formato = this._formato;

    _.map(_formato.form.controls, (a) => {
      _.map(a.Objects, (b) => {
        if(b.type != 'canvas') {
          b.value = this.f[b.name].value
        }else {
          console.log({_CANVAS:b});          
        }
      })   
    })

    let _bodyForm = {
      Cita_Id: this._Cita.Cita_Id,
      label: this._formato.label,
      form: _formato.form
    }

    console.log({_BODYFORM: _bodyForm });
    this._BODYFORM = _bodyForm

    this.formatService._postFormato(_bodyForm).then((resp) => {
      this.toastr.info('Se ha guardado la información','Guardada', {
        timeOut: 3200
      })
      this.$interval.unsubscribe()
       setTimeout(() => {
        //  this.createInterval()     
        }, 5000);

    }).catch((error) => {
      console.error({error});      
    })

  }

  _getValue(name: string) {

    switch (name) {
      case 'name':
        return this.record.Nombre_completo__c       
      case 'age':
        return this.record.P05_Edad__c;
      case 'sex':
        return this.record.P05_Sexo__c;
      case 'n_expediente':
        return this.record.Name;
      case 'birth_date':
        return this.record.P05_Fecha_de_Nacimiento__c
      case 'fecha':
        return this.record.CreatedDate //this.date.transform(, 'yyyy-MM-dd')
      case 'hora':           
        return this.date.transform(this.record.CreatedDate,'hh:mm a')
      
      default:
        return 'N/D'
        break;
    }
  }

  /**
   *
   *
   * @param {string} text
   * @param {*} control
   * @memberof DescipcionQuirurgicaComponent
   */
  _onEventEmmiterValue(text: string, control: any) {
    console.log({_TEXT________: text, control});
    this.f[control.name].patchValue(text)       
  }

  _onSaveSing (event: any, main_label: string) {

    if(event) {
      this.alert._showLoader('Guardando, espere...')

      this.formatService.UploadFirmas(this._Cita.Cita_Id, this.record.Name, main_label,event)
      .then((resp) => {  
        this.alert._hideLoader()
        this.loadImages()
        this.toastr.success('Firma, cargada exitosamente','Exito!!',{
          timeOut: 3200
        })
      }).catch((error) => {
        console.error({_ERROR_LOAD_FIRMA: error });
        
        this.alert._hideLoader()
        this.toastr.error('Error al cargar la firma','Error!!',{
          timeOut: 3200
        })
      })
    }

  }

}
