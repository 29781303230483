import { Injectable } from '@angular/core';
import { dataInfo } from '@app/layouts/models/user-data';
import { Cita } from '@app/pages/calendar/models/calendar';
import { InfoUserData } from '@app/pages/models/info-user-data';

const USER_DETAIL   = 'data_user'
const DATA_USER_KEY = 'user_info_data'

const CITA_ID = '_cita_id'

@Injectable({
  providedIn: 'root'
})
export class CredentialsService {

  private _credentials: dataInfo | null = null;
  private _user_info_data: InfoUserData | null = null;

  constructor() { 
    const savedCredentials =  localStorage.getItem(USER_DETAIL)     
    
    if (savedCredentials) {
      this._credentials = JSON.parse(savedCredentials);
    }

    const _user_info =  localStorage.getItem(DATA_USER_KEY)

    if(_user_info) {
      this._user_info_data = JSON.parse(_user_info)
    }

  }   
  
  /**
   * 
   */
  get credentials(): dataInfo | null {
    return this._credentials
  }

  /**
   * 
   */
  get userInfoData(): any | null {
    return this._user_info_data
  }

  /**
   * 
   * @param dataInfo 
   */
  _setCredential(dataInfo?: dataInfo) {
    this._credentials = dataInfo || null;
    if(dataInfo) {
       localStorage.setItem(USER_DETAIL, JSON.stringify(dataInfo))
    }else {
       localStorage.removeItem(USER_DETAIL)
    }  
  }

  /**
   * 
   * @returns 
   */
  _getdataUser() {
    
    let data =  localStorage.getItem(USER_DETAIL)

    if(data) {
      return JSON.parse(data)
    }
    return null

  }
  

  /**
   * 
   * @param userinfoData 
   */
  saveUserinfo(userinfoData?: any) {
    this._user_info_data = userinfoData || null;

    if(userinfoData) {
       localStorage.setItem(DATA_USER_KEY, JSON.stringify(userinfoData))
    }else {
       localStorage.removeItem(DATA_USER_KEY)
    }
  }

  /**
   * Guardamos la CITA CLICK
   * @param cita 
   */
  saveCitaJson(cita:any) {
     localStorage.setItem(CITA_ID,JSON.stringify(cita))
  }


  getCitaJson(){
    let _cita =  localStorage.getItem(CITA_ID);

    if(_cita) {
      return JSON.parse(_cita) as Cita;
    }
    return null
  }

  
}
