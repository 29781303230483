import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { PinturaEditorComponent } from '@pqina/angular-pintura';
//import { PinturaEditorOptions, createDefaultImageReader, createDefaultImageWriter, createDefaultShapePreprocessor } from '@pqina/pintura';

import {
  LocaleCore,
  LocaleCrop,
  LocaleFinetune,
  LocaleFilter,
  LocaleAnnotate,
  LocaleMarkupEditor,
} from '@pqina/pintura/locale/en_GB';

import {
  PinturaEditorOptions,


  // editor
  createDefaultImageReader,
  createDefaultImageWriter,
  createDefaultShapePreprocessor,

  plugin_finetune_defaults,
  
  plugin_filter_defaults,

  markup_editor_defaults,
  setPlugins,
  plugin_crop,
  plugin_finetune,
  plugin_filter,
  plugin_annotate,


} from '@pqina/pintura';

setPlugins(plugin_crop, plugin_finetune, plugin_filter, plugin_annotate);

@Component({
  selector: 'app-svg-editor',
  templateUrl: './svg-editor.component.html',
  styleUrls: ['./svg-editor.component.scss']
})
export class SvgEditorComponent {

  @ViewChild('editorRef') editorRef?: PinturaEditorComponent<any> = undefined;

  @Input()
  _path: string

  @Input()
  _maxHeigh: number = 50

  @Input()
  _svgItem: any

  @Output()
  eventEmmiterSaveImg: EventEmitter<boolean> = new EventEmitter<boolean>()

  @Output()
  eventEmmiterImage: EventEmitter<any> = new EventEmitter<any>()

  @Output()
  evnetEmmiterDeleteSVG: EventEmitter<string> = new EventEmitter<string> ()

  editorOptions = {
    utils: ['annotate'],
    imageReader: createDefaultImageReader(),
    imageWriter: createDefaultImageWriter({
      targetSize: {
        width: 512,
        height: 512,
        fit: 'contain',
      },
    }),
    shapePreprocessor: createDefaultShapePreprocessor(),
    ...plugin_finetune_defaults,
    ...plugin_filter_defaults,
    ...markup_editor_defaults,
    locale: {
      ...LocaleCore,
      ...LocaleCrop,
      ...LocaleFinetune,
      ...LocaleFilter,
      ...LocaleAnnotate,
      ...LocaleMarkupEditor,
    },
  } as  PinturaEditorOptions;

 // src: string = this._path;
  result?: string = undefined;
  cropAspectRatio = 1;
  _showEditor:boolean = true

  constructor (private sanitizer: DomSanitizer) {}

  /**
   * 
   * @param $event 
   */
  handleLoad($event: any): void {
/*     console.log('load', $event);

    console.log('component ref', this.editorRef);

    console.log('editor instance ref', this.editorRef?.editor);

    console.log(
      'inline editor image state',
      this.editorRef?.editor?.imageState
    ); */
  }

  /**
   * 
   * @param $event 
   */
  handleProcess($event: any) {
    console.log('process', $event);
    this._showEditor = false
    const objectURL = URL.createObjectURL($event.dest);
    
    this.result = this.sanitizer.bypassSecurityTrustResourceUrl(
      objectURL
    ) as string;   
    
    let _svgItems = {
      _imgSVG:$event.dest,
      comment: this._svgItem.comentario
    }
    this.eventEmmiterImage.next(_svgItems)
  }

  /**
   * 
   */
  _onClickEditor() {
    this._showEditor = true
  }

  /**
   * 
   * @param _path 
   */
  _onEventDeleted(_path: string) {
      this.evnetEmmiterDeleteSVG.emit(_path)    
  }


  _addComent(): void {
    this._svgItem._showComments = true
  }

  _deleteComent() {
    this._svgItem._showComments = false
    this._svgItem.comentario = null
  }
  

}
