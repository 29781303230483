import { DatePipe } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { VoiceRecognitionService } from '@app/shared/services/voice-recognition.service';
import * as _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { Subscription, interval } from 'rxjs';
import { CredentialsService } from 'src/app/core/services/credentials.service';
import { TokenStorageService } from 'src/app/core/services/token-storage.service';
import { Cita } from 'src/app/pages/calendar/models/calendar';
import { Images } from 'src/app/shared/models/list-images';
import { FormatsService } from 'src/app/shared/services/formats.service';

@Component({
  selector: 'app-nota-preoperatoria',
  templateUrl: './nota-preoperatoria.component.html',
  styleUrls: ['./nota-preoperatoria.component.scss']
})
export class NotaPreoperatoriaComponent implements OnInit, OnDestroy {

  @Input()
  id: string;

  @Input()
  _formatoData: any

  _controls: any[] = []
  _formato: any = {}

  _label: string
  _itsReady: boolean

  record: any;

  $interval!: Subscription;
  public formNotaPreoperatoria: FormGroup = this.formBuilder.group({})

  _Cita: Cita;

  _BODYFORM: any;

  _Firma: Images[] = []

  currentUser: any

  constructor(
    private formatoService: FormatsService,
    private date: DatePipe,
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    private credentials: CredentialsService,
    private tokenService: TokenStorageService,
    public VoiceS: VoiceRecognitionService
  ) {

   // this.VoiceS.init();
    this.record = this.credentials.userInfoData.data.records[0]

    const cita = this.credentials.getCitaJson()

    if (cita) {
      this._Cita = cita
    }

    this.currentUser = this.tokenService.getUser()

  }

  ngOnInit(): void {

    if (this._formatoData) {
      this._formato = this._formatoData

      this._controls = this._formato.form.controls;
      this._itsReady = true;
      this.initForm();
      this._loadImages()
    }

    /*    if(this.id) {
   
         this.formatoService._getFormat(this.id, this._Cita.Cita_Id).subscribe({
           next:(response) => {
             if(response.codigo == 200){
   
               this._formato = response.data.formato;
   
               if(this._formato) {
                 //this._label = response.data.formato.label;
                 this._controls = this._formato.form.controls;              
                 this._itsReady = true;  
                 this.initForm();
                 this._loadImages()
               }
             }
           },
           error:(error) => {
   
           }
         })
       } */

  }

  get f() {
    return this.formNotaPreoperatoria.controls;
  }

  ngOnDestroy(): void {
    if (this.$interval) {
      console.log('ON DESTROYY');
      this.$interval.unsubscribe()
    }

    this._savePrevDestroy()

  }

  private _savePrevDestroy(): void {
    let _formValues = this.formNotaPreoperatoria.value
    let _body_: any[] = []

    _.map(_formValues, (a, i) => {
      _body_.push({
        name: i,
        value: a
      })
    })

    let _bodyForm = {
      Cita_Id: this._Cita.Cita_Id,
      label: this._formato.label,
      new_data: _body_
    }
    this._saveFormatValues(_bodyForm, false)
  }

  /***
 * Cargamos las imagenes
 */
  private _loadImages() {

    /**
     * Cargamos la firma del doctor
     */
    this.formatoService._getImageProfile(this.currentUser.username).subscribe({
      next: (response) => {
        if (response.codigo == 200) {
          this._Firma = response.data.formato.archivos.Firma;
        }

      }
    })

  }

  /**
   * 
   */
  private initForm() {

    _.map(this._controls, (a) => {
      _.map(a.Objects, (b) => {
        this.formNotaPreoperatoria.addControl(
          b.name, this.formBuilder.control(b.value)
        )
      })
    })

    //console.log({formNotaPreoperatoria: this.formNotaPreoperatoria.controls});
    this.createInterval()


  }

  private createInterval() {

    const myObservable = interval(1000);
    this.$interval = myObservable.subscribe((integer) => {
      if (integer === 20) {
        this.$interval.unsubscribe();
        console.log('GO SAVE IT!!!!');
        //this.show = !this.show;       
        //this._onSaveIt()     
        this._newSaveFormat()

      }
      // this._interval = integer

    })
  }

  private _newSaveFormat(): void {
    let _formValues = this.formNotaPreoperatoria.value
    let _body_: any[] = []

    _.map(_formValues, (a, i) => {
      _body_.push({
        name: i,
        value: a
      })
    })

    let _bodyForm = {
      Cita_Id: this._Cita.Cita_Id,
      label: this._formato.label,
      new_data: _body_
    }

    this._saveFormatValues(_bodyForm, true)

  }

  /**
   * 
   * @param _jsonObject 
   * @param createInterval 
   */
  _saveFormatValues(_jsonObject: any, createInterval: boolean = false): void {

    this.formatoService._postFormato(_jsonObject).then((resp) => {

      this.toastr.info('Se ha guardado la información', 'Guardada', {
        timeOut: 3200
      }).onHidden.subscribe(() => {
        if (createInterval) {

          this.createInterval()
        }
      })
    }).catch((error) => {
      console.error({ error });

    })


  }

  private _onSaveIt() {

    let _formato = this._formato;

    _.map(_formato.form.controls, (a) => {

      if (a.type == 'frame' || a.type == 'title') {

        _.map(a.Objects, (b) => {
          b.value = this.f[b.name].value
        })
      }
    })

    let _bodyForm = {
      Cita_Id: this._Cita.Cita_Id,
      label: this._formato.label,
      form: _formato.form
    }

    this._BODYFORM = _bodyForm

    this.formatoService._postFormato(_bodyForm).then((resp) => {
      this.toastr.info('Se ha guardado la información', 'Guardada', {
        timeOut: 3200
      })

      this.$interval.unsubscribe()
      setTimeout(() => {
        //  this.createInterval()     
      }, 5000);

    }).catch((error) => {
      console.error({ error });

    })

  }

  _getValue(name: string) {

    switch (name) {
      case 'name':
        return this.record.Nombre_completo__c
      case 'age':
        return this.record.P05_Edad__c;
      case 'sex':
        return this.record.P05_Sexo__c;
      case 'n_expediente':
        return this.record.Name;
      case 'birth_date':
        return this.record.P05_Fecha_de_Nacimiento__c
      case 'fecha':
        return this.record.CreatedDate //this.date.transform(, 'yyyy-MM-dd')
      case 'hora':
        return this.date.transform(this.record.CreatedDate, 'hh:mm a')

      default:
        return 'N/D'
        break;
    }
  }

  /**
   *
   *
   * @param {string} text
   * @param {*} control
   * @memberof NotaPreoperatoriaComponent
   */
  _onEventEmmiterValue(text: string, control: any) {
    console.log({ _TEXT________: text, control });
    this.f[control.name].patchValue(text)

  }

}
