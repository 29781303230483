import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { OrigenPaciente } from '../../models/origen-paciente';
import { CalendarService } from 'src/app/pages/calendar/services/calendar.service';
import { ToastrService } from 'ngx-toastr';
import { Recurso } from 'src/app/pages/models/recursos';
import { CreateCandidate } from '../../models/create-candidate';
import { PatientsService } from 'src/app/pages/calendar/services/patients.service';
import { empleadoResponsable } from 'src/app/pages/models/lista-empleados-responsable';
import { NgxSpinnerService } from 'ngx-spinner';
import { CalificacionCandidato, CalificacionVarices, CandidatoData, CandidatoEstatus } from 'src/app/pages/calendar/models/candidatos';
import { CandidatosService } from 'src/app/pages/calendar/services/candidatos.service';
import { AlertsService } from '../../services/alerts.service';
import * as _ from 'lodash';
import { CredentialsService } from 'src/app/core/services/credentials.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-create-candidate',
  templateUrl: './create-candidate.component.html',
  styleUrls: ['./create-candidate.component.scss']
})
export class CreateCandidateComponent implements OnInit {

  @ViewChild('editmodalShow') editmodalShow!: TemplateRef<any>;
  @ViewChild('modalShow') modalShow !: TemplateRef<any>;

  _candidato: CreateCandidate = new CreateCandidate(); 
  
  @Input()
  editable: boolean = false

  @Input()
  _cita_id: string | null

  @Input()
  _dataCandidato: CandidatoData | null  = null;


  @Output()
  eventClickCancel: EventEmitter<boolean> = new EventEmitter();  

  @Output()
  eventCloseModal: EventEmitter<any> = new EventEmitter<any>()


  eventSubjecttGetCalificacion: Subject<boolean> = new Subject<boolean>();


  _recursos: Recurso[] = []
  _origenPaciente: OrigenPaciente[] = []
  _especialidad: any[] = []

  _estadosCandidato: any[] = []
  _municipalitie= ['Otros']
  _callCenter: any[] = []  
  
  _seguroMedico: any[]  = []
  _objecion: any[] = []
  _metodo_contacto: any[] = []
  _paiese: any[] = []
  _estados: any[] = []

  _responsable_venta: any[] = []

  _listaResponsables: empleadoResponsable[] = []
  _estadoCandidato: CandidatoEstatus[] = []


  _spinner = '_spinner_'
  _loader: boolean

    
  submitted = false;
  newEventDate: any;
  _loadingPostalCode: boolean = false

  _FormCandidato: FormGroup;
  _centro_medico_select: any;

  _show_datos_ubicacion_: boolean  = true

  _candidato_id: string| null = null
  _colonias: any[] = []

  _calificacionCandidato: CalificacionCandidato = new CalificacionCandidato()
  _calificacionVarices: CalificacionVarices = new CalificacionVarices()

  constructor (
    private builder: FormBuilder,
    private calendar: CalendarService,
    private toastr: ToastrService,
    private patients: PatientsService,
    private spinner: NgxSpinnerService,
    private candidatoService: CandidatosService,
    private alert: AlertsService,
    private credentials: CredentialsService
    ) {

      if(this.credentials.credentials?.roles.Callcenter) {
        this._show_datos_ubicacion_ = false
      }

    }

  ngOnInit(): void {
    this._loadCatalogs()

    this.initForm()    

    if(this._dataCandidato) {   

      this._candidato_id = this._dataCandidato.Candidato_Id
      
      let _jsoData: any = {}

      //if(_data.relaciones) {
        _jsoData = {
          ...this._dataCandidato.Datos_Candidato.relaciones,
          ...this._dataCandidato.Datos_Candidato.direccion,
          ...this._dataCandidato.Datos_Candidato.generales,
          ...this._dataCandidato.Datos_Candidato.informacion
        }            
        
      this._centro_medico_select = this._dataCandidato.Datos_Candidato.relaciones.centro_medico
      
      if(this._dataCandidato.Datos_Candidato.informacion.especialidad === 'Proctología' || this._dataCandidato.Datos_Candidato.informacion.especialidad === 'Coloproctología') {
          this._getCandidaoResultado(this._candidato_id)   
      } 

      if(this._dataCandidato.Datos_Candidato.direccion && this._dataCandidato.Datos_Candidato.direccion.codigo_postal) {
        this.loadDataByPostalCode(this._dataCandidato.Datos_Candidato.direccion.codigo_postal)
      }

      if(
        this._dataCandidato.Datos_Candidato.informacion.especialidad === 'Varices'||
        this._dataCandidato.Datos_Candidato.informacion.especialidad === 'Várices'||
        this._dataCandidato.Datos_Candidato.informacion.especialidad === 'Varices avanzadas'
      ) {
        this._getCalificacionVarices(this._candidato_id)
      }   
      
      this._FormCandidato.patchValue(_jsoData)
    }
  }

  get f() {
    return this._FormCandidato.controls
  }

  private _loadCatalogs () {

     /**
     * Obtener los metodos de Contacto
     */
     this.calendar._getMetodosContacto().subscribe({
      next: (response: any) => {
        if(response.codigo == 200) {
          this._metodo_contacto = response.data[0].metodo_contacto;         
          
        }
      }
    })

     /**
     * Obtener los recursos
     */
     this.calendar.GetRecursoPacientes('true').subscribe({
      next: (response: any) => {
        if(response.codigo == 200) {
          this._recursos = response.data;
        }
      },error:(error) => {
        console.error({error});
        
      }
    })

     /**
     * Obtener el origen de los pacientes
     */
     this.calendar._getOrigenPaciente().subscribe({
      next:(response) => {
        if(response.codigo == 200) {
          this._origenPaciente = response.data[0].origen_del_paciente;
        }        
      },
      error: (error) => {
        console.error({error});        
      }
    })

     /**
     * Obtener las especialidades
     */
     this.calendar._getEspecialidades('candidato').subscribe({
      next: (response) => {
        if(response.codigo == 200 ) {
          this._especialidad = response.data //[0].especialidades        
          
        }
      },
      error:(error) => {
        console.error({error});     
      }
    }) 

    /**
     * Cargar Estados
     */

    this.calendar._getEstados().subscribe({
      next: (resp) => {
        if(resp.codigo == 200) {
          this._estados = resp.data[0].estados          
        }
      }
    })

    /**
     * Cargamos los Paises
     */

    this.calendar._getPais().subscribe({
      next: (response: any) => {
        if(response.codigo == 200) {
          this._paiese = response.data[0].pais
          
          
        }
      }
    })

    this.patients._getListaEmpleadosResponsable().subscribe({
      next:(response) => {
        if(response.codigo == 200) {
          this._listaResponsables = response.data;
          //this._paciente_recomienda = response.data
        }
      },error:(error) => {
        console.error({error});
        
      }
    })

    this.candidatoService.ObtenerListadoEstatus().subscribe({
      next:(resp) =>  {
          if(resp.codigo === 200) {
            this._estadosCandidato = resp.data
          }
      },
    })

    //this.calendar.getEsta
    

  }

  /**
   * 
   */
  private initForm() {
    

    this._FormCandidato = this.builder.group({

      //Generales
      correo:[''],
      telefono:['',[Validators.required]],
      comentarios:[''],
      //estatus:[''],
      responsable_marketing:[''],
      responsable_call_center:[''],

      //Direccion
      pais:[null],
      estado:[null],
      municipio:[null],
      municipio_otro:[null],
      codigo_postal:[null, this._show_datos_ubicacion_ ? Validators.required : null ],
      calle:[''],
      colonia:[''],
      colonia_otro:[''],
      num_exterior:[],
      num_interior:[],

      //relacion
      centro_medico:[null, [Validators.required]],
      nombre: ['', [Validators.required]],
      apellidos:['',[Validators.required]],
      ultima_actividad_reporte:[''],

      estatus:[null, [Validators.required]],

      //informacion
      origen_paciente:[null,[Validators.required]],
      especialidad:[null,[Validators.required]],            
      metodo_contacto:[null,[Validators.required]],
     // especilidad:[null, [Validators.required]]

      
    })
  }

  /**
   * 
   */
  _onSubmitForm() {

    if(this._FormCandidato.valid) {

      this._loader = true
      this.spinner.show(this._spinner)     

      const { telefono, correo, comentarios, pais, estado, municipio, codigo_postal, calle, colonia, num_exterior, num_interior, nombre, apellidos, ultima_actividad_reporte, origen_paciente, especialidad, metodo_contacto, estatus, responsable_marketing,
        responsable_call_center  } = this._FormCandidato.value

      this._candidato = {
        generales: {
          telefono: String(telefono), 
          correo, 
          comentarios, 
          estatus, 
          responsable_marketing, 
          responsable_call_center
        },
        direccion: { 
          pais, 
          estado, 
          municipio, 
          codigo_postal: String(codigo_postal), 
          calle, 
          colonia: colonia === 'OTRO' ? this.f['colonia_otro'].value : colonia , 
          num_exterior: String(num_exterior), 
          num_interior: String(num_interior) 
        },
        relaciones: {
          centro_medico: {
            Id: this._centro_medico_select.Id,
            Name: this._centro_medico_select.Name
          },
          nombre,
          apellidos,
          ultima_actividad_reporte

        },
        informacion: {
          origen_paciente, 
          especialidad,
          metodo_contacto, 
        }
      }      

      
      if(this._dataCandidato && this._dataCandidato.Candidato_Id) {
        this._candidato.Candidato_Id = this._dataCandidato.Candidato_Id

        this.candidatoService._updateCandidato(this._candidato).subscribe({
          next:(resp: any) => {
            this._loader = false;
            this.spinner.hide(this._spinner)
            this.submitted = false

            this.toastr.success(`Se han actualizado correctamente los datos del candidato`,'Exito al actualizar',{
              //tapToDismiss: false,
              enableHtml:true,
              timeOut: 15000            
            }) 

            this.eventCloseModal.next(resp.data)
          },
          error:(err) => {

            this._loader = false
            this.spinner.hide(this._spinner)
            this.submitted = false
            this.toastr.error('Falló la actualización de los datos del candidato.','Error ocurrido', {
              timeOut: 3200
            })

          }
        })      
        
        return console.log('Editedd');
                
      }

      this.calendar._CretaeCandidate(this._candidato).then((response: any) => {   

        this._loader = false;
        this.spinner.hide(this._spinner)
        this.submitted = false
        this._FormCandidato.reset()
        this._FormCandidato.clearValidators()
        this._FormCandidato.markAsUntouched()


        
 /*        this.toastr.success(`El registro se ha relizado correctamente`,'Exito al crear',{
          //tapToDismiss: false,
          enableHtml:true,
          timeOut: 15000            
        })    */ 

        this.candidatoService._setCurrentLoaderCandidatos(true)
        
        this.eventCloseModal.next(response.data)

      }).catch((error) => {
        this._loader = false
        this.spinner.hide(this._spinner)
        console.error({__ERRORR_: error});
        this.toastr.error('Hubo un error al intentar guardar el registro del paciente nuevo, por favor intente de nuevo','Error al guardar',{
          timeOut: 5000
        })       

      })         

    }else {

      let _errors = this._FormCandidato.controls    
      let totalErrors = 0;

      let _msg = `Por favor revise los campos en Rojo <br> <ul> `

      Object.keys(_errors).forEach(key => {
        let _controls = this._FormCandidato.get(key)?.errors
        if( _controls && _controls != null) {
          const controlErrors : ValidationErrors = _controls;
          totalErrors ++;
          Object.keys(controlErrors).forEach(keyError => {         
            _msg += `<li> ${key} - ${keyError} </li>`  
           });
          }
        })      
        _msg+= `</ul>`;

      this.toastr.error(`${_msg}`,'Campos faltantes',{
        timeOut: 15000,
        enableHtml: true,
        positionClass:'toast-bottom-center'
      })

      this.submitted = true
      this._FormCandidato.markAllAsTouched()
      this._FormCandidato.markAsDirty()
    }

  }

  /**
   * 
   */
  _onChangeEspecialidad() {
    if(this.f['especialidad'].value === 'Proctología' || this.f['especialidad'].value === 'Coloproctología') {
      if(this._candidato_id) {
        this._getCandidaoResultado(this._candidato_id)
      }  
    }
    if(this.f['especialidad'].value === 'Varices' || this.f['especialidad'].value === 'Várices' || this.f['especialidad'].value === 'Varices avanzadas' ) {
      if(this._candidato_id) {
        this._getCalificacionVarices(this._candidato_id)
      }
    }
  }

  /**
   * 
   * @param _candidato_id 
   */
  private _getCandidaoResultado(_candidato_id: string) {
    this.alert._showLoader('Consultando calificacion, paciente')
    this.candidatoService._consultarCalifPaciente(_candidato_id).subscribe({
      next:(value: any) => {           

        if(value && value.codigo === 200) {              
          this.alert._hideLoader()
          value.data.candidato_id = this._candidato_id
          this._calificacionCandidato = value.data;              
        }            
      },
      error:(err) => {
        this.alert._hideLoader()
        console.error({err});            
      },
    })
  }

  /**
   * 
   * @param _cadidato_id 
   */
  private _getCalificacionVarices(_cadidato_id: string) {
    this.alert._showLoader('Consultando calificacion, paciente')
    this.candidatoService._consultarCalificacionVarices(_cadidato_id).subscribe({
      next:(value: any) => {           

        if(value && value.codigo === 200) {              
          this.alert._hideLoader()
          value.data.candidato_id = this._candidato_id
          this._calificacionVarices = value.data;              
        }            
      },
      error:(err) => {
        this.alert._hideLoader()
        console.error({err});            
      },
    })
  }

  /**
   * 
   * @param centro_medico 
   */
  _onChangeEvent(centro_medico: any) :void {
    this._centro_medico_select = null
    if(centro_medico) {
      this._centro_medico_select = centro_medico
    }
  }

  onCancel() {
    //console.log('aaaaaaaa');
    
    this.eventCloseModal.next(true)
  }

  onKeyPress(event: any) {
    /* let _b = (this.form['codigo_postal'].value).length */
    if(event === 13 && this.f['codigo_postal'].valid ){

      this.alert._showLoader('Espere...')
      this.loadDataByPostalCode(this.f['codigo_postal'].value)
    }    
  }

  /**
   * 
   * @param value 
   */
  _onChangePostalCode(value: string) {    
    const _length = value.length;
    if(_length === 5) {
  

      this.alert._showLoader('Cargando, espere...')    
      this.loadDataByPostalCode(value)
         
      this._loadingPostalCode = true

      return ''
    } 
    this._loadingPostalCode = false
    return console.log('d');       
  }

  _onModelChange() {
    const val = this.f['especialidad'].value    
  }

  private loadDataByPostalCode (value: string) {
    this.patients.getDireccion(value).then((resp: any) => {
        
      if(resp.codigo === 200) {
        const data = resp.data;    

        let _municipios: string [] = []

        let _colonias: any[] = []

        _.map(data, (a,i: number) => {

          if (i === 0) {
            _colonias.push({
              colonia: 'OTRO', //"": "Valle San Francisco",
              estado: a.estado, //"": "Sonora",
              ciudad: a.ciudad, //"": "Hermosillo",
              municipio: a.municipio, //"": 
            })
          }
          _colonias.push(a)
        })
        
        this._colonias = [..._colonias]

        _.map(data, (a, i: number) => {
          if(i === 0) {
            _.map(data, (a, i : number) => {
              if(i === 0) {
                this.f['estado'].patchValue(a.estado)
                this.f['pais'].patchValue('México')
                this.f['municipio'].patchValue(a.municipio)
                _municipios.push(a.municipio)
              }
            })
            //_municipios.push(a.municipio)
          }
        })

        this._municipalitie =[..._municipios]

        console.log({_municipios});
        
        
      }

      this.alert._hideLoader()
      

      
    }).catch((error) => {
      this.alert._hideLoader()
      console.error({error});      
      this.toastr.error('Error al obtener los datos, del codigo postal','Error',{
        timeOut: 3200
      }) 
    })
  }

}
