import { PostCalendarData } from './../models/post-calendar-data';
import { Injectable } from '@angular/core';

import { HttpClient} from '@angular/common/http';
import { BehaviorSubject, Observable, firstValueFrom } from 'rxjs';
import { CalendarObjects } from '../models/calendar';
import { FilterCalendar } from '../../models/filter-calendar';
import { Filters } from '../../models/filters';
import { Recursos } from '../../models/recursos';
import { Servicios } from '../../models/servicios';
import { Doctores } from '../../models/doctores';
import {  Anestesiologos } from '../models/anestesiologo';
import { EnfermeriaGeneral } from '../models/enfermeria-general';
import { EnfermeriaQuirurgica } from '../models/enfermeria-quirurgica';
import { ResponseSearchResult } from '../../models/response-search-result';
import { OrigenPaciente } from 'src/app/shared/models/origen-paciente';
import { CreatePatients } from 'src/app/shared/models/create-patients';
import { CalendarResponse } from '../models/calendar-response';
import { CreateCita } from 'src/app/shared/models/create-cita';
import { DoctoresWs } from '../models/doctores-ws';
import { EmpresaOdoo } from '../models/empresa-odoo';
import { environment } from 'src/environments/environment';
import { CreateCandidate } from 'src/app/shared/models/create-candidate';
import { CitasDoctor } from 'src/app/store/citas.model';

@Injectable({
  providedIn: 'root'
})
export class CalendarService {

  CurrentCalendarSubject: BehaviorSubject<CalendarObjects> = new BehaviorSubject({} as CalendarObjects);
  public readonly CurrentCalendar: Observable<CalendarObjects> = this.CurrentCalendarSubject.asObservable();

  CurrentLoadCalendarSubject: BehaviorSubject<any> = new BehaviorSubject({} as  any)
  public readonly CurrentLoadCalendar: Observable<any> = this.CurrentLoadCalendarSubject.asObservable();
  
  CurrentLoadCitasSubsecuenteSubjects: BehaviorSubject<boolean> = new BehaviorSubject(false)
  public readonly CurrentCitaSubsecuente: Observable<boolean> = this.CurrentLoadCitasSubsecuenteSubjects.asObservable();

  CurrentLoadRecetasSubject: BehaviorSubject<boolean> = new BehaviorSubject(false)
  public readonly CurrentLoadReceta: Observable<boolean> = this.CurrentLoadRecetasSubject.asObservable()
  

  private readonly apiUrl: string = environment.apiUrl;    
  //private readonly apiUrl: string = //env.environment.apiUrl //= environment.production ? environment.apiUrl : env.environment.apiUrl

  params = {
    inicio: '',
    final: '',
    doctor: ''
  }
  

  constructor(private http: HttpClient) { }

  /**
   * Pacientes
   * @returns 
   */
  _getPacientes() {
    return this.http.get(`${this.apiUrl}pacientes`)
  }

  /**
   * Obtiene los datos del calendario
   * @param inicio 
   * @param final 
   * @returns 
   */
  _getCalendarReport(filter?: Filters): Observable<CalendarObjects> {       
    if(!filter) {
      
      filter = this.params
    }
    return this.http.get<CalendarObjects>(`${this.apiUrl}calendario/reporte_calendario_encargada_revision?inicio=${filter.inicio}&final=${ filter.final}&doctor=${filter.doctor}`)
    /* .pipe(
      (map((response: CalendarObjects) => {
        console.log({'en el PIPE': response});        
        return response
      }))) */
  }

 
  /**
   * 
   * @param cita_id 
   * @returns 
   */
  _getDetallesCita(cita_id: string) {
    return this.http.get(`${this.apiUrl}calendario/detalles?cita_id=${cita_id}`)
  }

  /**
   * 
   * @param filters 
   */
  _getCalendarFetch(filters: Filters = this.params): Observable<CalendarObjects> {

    //let _httpParams = new HttpParams({ fromObject : filters})

    return this.http.get<CalendarObjects>(`${this.apiUrl}?calendario/reporte_calendario_encargada_revision?inicio=${filters.inicio}&final=${ filters.final}&doctor=${filters.doctor}`)
  }

  /**
   * Devuelve los datos del Calendario Filtrado
   * @param _filters 
   * @returns 
   */
  _getFilterCalendar(_filters:FilterCalendar): Observable<CalendarObjects> {
    return this.http.post<CalendarObjects>(`${this.apiUrl}calendario/reporte_calendario_encargada_revision`,_filters);
  }

  /**
   * Obtiene los recursos o bien true - Todos los recursos
   * @param recurso 
   * @returns 
   */
  _getRecursos(recurso:string): Observable<Recursos>{
    return this.http.get<Recursos>(`${this.apiUrl}calendario/recurso?recurso=${recurso}`)
  }

  
  /**
   * 
   * @returns 
   */
  GetRecursoPacientes(recurso:string) { 
    return this.http.get(`${this.apiUrl}calendario/recurso?recurso=${recurso}`)
  }

  /**
   * 
   * @param servicio 
   */
  _getServicio(servicio: string): Observable<Servicios> {  
    return this.http.get<Servicios>(`${this.apiUrl}calendario/servicio?servicio=${servicio}`)
  }

  /**
   * 
   * @param doctor 
   */
  _getDoctores(doctor: string): Observable<Doctores> {
    return this.http.get<Doctores>(`${this.apiUrl}calendario/doctor?doctor=${doctor}`)
  }

  /**
   * 
   * @param id_doctor 
   * @returns 
   */
  _getDoctorByid(id_doctor: string): Observable<DoctoresWs> {
    return this.http.get<DoctoresWs>(`${this.apiUrl}calendario/doctor?doctor=${id_doctor}`)
  }

  /**
   * Retorna los anestesiologos
   * @param anestesio 
   * @returns 
   */
  _getAntestesilogo(anestesio: string): Observable<Anestesiologos>{
    return this.http.get<Anestesiologos>(`${this.apiUrl}calendario/anestesio?anestesio=${anestesio}`)
  }

  /**
   * 
   * @param enf_general 
   * @returns 
   */
  _getEnfermeroGral(enf_general: string): Observable<EnfermeriaGeneral> {
    return this.http.get<EnfermeriaGeneral>(`${this.apiUrl}calendario/enf_general?enf_general=${enf_general}`)
  }

  /**
   * 
   * @param enf_qx 
   * @returns 
   */
  _getEnfermeroQuirurgico(enf_qx: string):Observable<EnfermeriaQuirurgica>{
    return this.http.get<EnfermeriaQuirurgica>(`${this.apiUrl}calendario/enf_qx?enf_qx=${enf_qx}`)
  }

  //#region Catalogos

  /**
   * 
   * @returns 
   */
  _getOrigenPaciente(): Observable<OrigenPaciente> {
    return this.http.get<OrigenPaciente>(`${this.apiUrl}calendario/origenPaciente`)
  }

  /**
   *   {{server}}/api/
   * @returns 
   */
  _getEspecialidades(tipo: string): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}calendario/especialidad?tipo=${tipo}`)
  }

  /**
   *  
   */
  _getSeguroMedico():Observable<any[]> {
    return this.http.get<any[]>(`${this.apiUrl}calendario/seguro_medicos_mayores`)
  } 

  /**
   * @returns 
   */
  _getObjeciones(): Observable<any[]> {
    return this.http.get<any[]>(`${this.apiUrl}calendario/objeciones`)
  }

  /**
   * 
   */
  _getMetodosContacto(): Observable<any[]> {
    return this.http.get<any[]>(`${this.apiUrl}calendario/metodo_contacto`)
  }

  /**
   * 
   */
  _getPais(): Observable<any[]> {
    return this.http.get<any[]>(`${this.apiUrl}calendario/pais`)
  }

  /**
   * 
   * @returns 
   */
  _getEstados(): Observable<any> {    
    return this.http.get<any>(`${this.apiUrl}calendario/estados`)
  }


  /**
   * Obtiene los datoa para la plancha
   * @param _filters 
   */
  _getPlancha(_filters:FilterCalendar):Observable<CalendarResponse>{ 
    return this.http.get<CalendarResponse>(`${this.apiUrl}calendario/get_plancha?inicio=${_filters.inicio}&final=${_filters.final}&doctor=${_filters.doctor}` ) 
  }
  
  /**
   * Listado de empresas Odoo
   * @returns 
   */
  _getOdooEmpresa(): Observable<EmpresaOdoo> {
    return this.http.get<EmpresaOdoo>(`${this.apiUrl}calendario/empresa_odoo`)
  }

  //#endregion


 /**
  * Asigna los valores al claendario/CITA
  * @param body 
  * @returns 
  */
  _setValuesCalendar(body: PostCalendarData){
    return this.http.post(`${this.apiUrl}calendario/cambiar_calendario`,body)
  }

  /**
   * Guardamos en un Observer la data del Calendario
   * @param calendar 
   */
  _setCelendar(calendar: CalendarObjects) {
    this.CurrentCalendarSubject.next(calendar);
  }

  /**
   * 
   * @param value 
   */
  _setValueLoadCitasSubsecuentes(value: boolean) {
    this.CurrentLoadCitasSubsecuenteSubjects.next(value)
  }

  /**
   * 
   * @param value 
   */
  _setCurrentLoadReceta(value: boolean) {
    this.CurrentLoadRecetasSubject.next(value)
  }
  
  //#region Estilos Calendar

  /**
   * 
   * @param status 
   */
  _getColorCalendar(status: string) {

    switch (status) {
      case 'Tratamiento':
          return 'bg-danger-subtle' //'#2FED9C';
      case 'Revisión':
          return 'bg-success-subtle' //'#2160E7';
      case 'Bloqueo' :
          return 'bg-primary-subtle' //'#919696'
      case 'Consulta':
          return   'bg-info-subtle' //'#0F7D27'
      case 'Cirugía':
          return 'bg-dark-subtle' //'#4AECE9'
      case 'Primera Cita':
          return 'bg-warning-subtle' //'#1ECC0D'
      default: 
        return 'bg-dark-subtle'
    }

  }


  //#endregion

  //#region Catalogo CITAS

  /**
   * Retorna los tipos de eventos para la CITA
   * @returns 
   */
  _getTipoEvento(paciente_id: string):Observable<any[]> {
      return this.http.get<any[]>(`${this.apiUrl}calendario/tipo_evento?paciente_id=${paciente_id}`)
  }

  /**
   * Retorna los motivo de cancelacion de una CITA
   * @returns 
   */
  _getMotivoCancelacion(): Observable<any[]> {
    return this.http.get<any[]>(`${this.apiUrl}calendario/motivo_cancelacion`)
  }

  /**
   * Devuelve los estatus utilizados en el alta de la Cita
   * @returns 
   */
  _getCitaEstatus():Observable<any[]> {
    return this.http.get<any[]>(`${this.apiUrl}calendario/estatus`) 
  }

  /**
   * 
   * @param id 
   */
  _getCitasDoctor(id: string, fecha: string) {
    return this.http.get<CitasDoctor>(`${this.apiUrl}calendario/ver_citas_disponibles?doctor=${id}&fecha=${fecha}`)
  }

  /**
   * 
   * @returns 
   */
  _getSeguroMedicoMayores(): Observable<any[]> {
    return this.http.get<any[]>(`${this.apiUrl}calendario/seguro_medicos_mayores`)
  }

  //#region CREAR CITA CALENDARIO
  
  /**
   * 
   * @param citaCalendario 
   * @returns 
   */
  _CrearCitaCalendario(citaCalendario: CreateCita) {
    return this.http.post(`${this.apiUrl}calendario/crearCalendario`,citaCalendario)
  }

  /**
   * 
   * @param citaCalendario 
   * @returns 
   */
  _UpdateCitaCalendario(citaCalendario: CreateCita) {
    return this.http.post(`${this.apiUrl}calendario/editar_datos_calendario_salesforce`,citaCalendario)
  }

  /**
   * 
   * @param tipo 
   */
  _ConsultarTipoProcedimiento(tipo: string, doctor_id: string) {
    return this.http.get(`${this.apiUrl}calendario/consultar_tipo_procedimiento?tipo=${tipo}&doctor_id=${doctor_id}`)
  }


  /**
   * 
   * @param citaCalendario 
   */
  async _CreateCitaPromie(citaCalendario: CreateCita) {
    return await firstValueFrom(this.http.post(`${this.apiUrl}calendario/crearCalendario`, citaCalendario))
  }

  /**
   * 
   * @param paciente 
   * @returns 
   */
  _GetCitasSubsecuentes(paciente: string) {
    return firstValueFrom(this.http.get(`${this.apiUrl}calendario/ver_citas_subsecuentes?paciente=${paciente}`))
  }


  /**
   * 
   * @param paciente 
   * @returns 
   */
  _GetCitasPaciente(paciente:string) {
    return firstValueFrom(
      this.http.get(`${this.apiUrl}calendario/buscar_citas_por_paciente?paciente=${paciente}`)
    )
  }

  

  //#endregion

  //#endregion

  /**
   *  Buscador
   * @param text 
   */
  _serchByText(text: string): Observable<ResponseSearchResult> {

    return this.http.get<ResponseSearchResult>(`${this.apiUrl}calendario/search?search=${text}`)

  }


   /**
   * Creamos un nuevo paciente 
   * @param _paciente 
   */
   async _CreatePatient(_paciente: CreatePatients) {

    return await  firstValueFrom(this.http.post(`${this.apiUrl}calendario/crearPaciente`,_paciente) ).then((resp) => {
      return resp
    })
  }

  _CretaeCandidate(_candidato: CreateCandidate) {
    return firstValueFrom(
      this.http.post(`${this.apiUrl}calendario/crearCandidato`,_candidato)
    )
  }

  /**
   * 
   * @param _body 
   */
  _setCurrentLoader(_body: any) {
    this.CurrentLoadCalendarSubject.next(_body)
  }

  /**
   * https://testhistoriaclinica.iml.com.mx/
   * @param _obj 
   * @returns 
   */
  _CrearBloqueoHorario(_obj: Object) {
    return firstValueFrom(
      this.http.post(`${this.apiUrl}calendario/agregar_bloqueo_horario_doctor`,_obj)
    )    
  }

  _ConsultarBloqueoHorario(doctor: string) {

    return firstValueFrom(
      this.http.get(`${this.apiUrl}calendario/consultar_bloqueo_horarios_doctor?doctor=${doctor}`)
    )

  }

  //#endregion

}
