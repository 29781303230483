<div *ngFor="let toast of toastService.toasts">
    <ngb-toast [autohide]="true" (hidden)="show = false " [autohide]="autohide" (mouseenter)="autohide = false"
        (mouseleave)="autohide = true" [delay]="15000" (hidden)="toastService.remove(toast)"
        class="bg-container bg-success text-center text-white  top-0 end-0 p-2" style="z-index: 1200;">
        <ng-template ngbToastHeader>
            <img src="assets/images/logo-sm.png" alt="" class="me-2" height="18">
            <span class="fw-semibold me-auto"> Clinica IML </span>
            <!--   <small>06 mins ago</small> -->
        </ng-template>
        <div class="d-flex justify-content-center" (click)="_goToForm(toast)">
            <span class="text-uppercase text-wrap "> <span class="fw-bold"> {{toast.data.pacient}}.</span>  Ha llegado a su cita </span>           
        </div>
    </ngb-toast>
</div>