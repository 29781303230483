import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, firstValueFrom, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

import { ListaEmpleadosResponsable } from '../../models/lista-empleados-responsable';

interface _oldClinicalHistory {
  cita_id: string
  paciente_id : string 
  nombre_paciente: string
  apellidos_paciente: string
  historia_clinica_antigua: boolean 
  files: any[]
}

@Injectable({
  providedIn: 'root'
})
export class PatientsService {

  //private readonly apiUrl: string = environment.production ? environment.apiUrl : env.environment.apiUrl
  private readonly apiUrl: string = environment.apiUrl;

  currentCitaSubject: BehaviorSubject<any> = new BehaviorSubject<any>({} as any)
  public readonly currentCita: Observable<any> = this.currentCitaSubject.asObservable()


  constructor(private http: HttpClient) { }

  _getPatientInfo(patiendId: string, set_index: number = 0) {
    return this.http.get(`${this.apiUrl}pacientes/?paciente=${patiendId}&set_index=${set_index}`)
  }

  /**
   * Empleado Responsable
   * @returns 
   */
  _getListaEmpleadosResponsable(): Observable<ListaEmpleadosResponsable> {
    return this.http.get<ListaEmpleadosResponsable>(`${this.apiUrl}pacientes/lista_empleados_responsables`)
  }

  /**
   * 
   * @param paciente_id 
   */
  _getPacienteDetalle(paciente_id: string) {
    return this.http.get(`${this.apiUrl}pacientes/detalles?paciente_id=${paciente_id}`)
  }

  /**
   * GET
   * 
   * @param codigo_postal 
   */
  getDireccion(codigo_postal: string) {
    return firstValueFrom(this.http.get(`${this.apiUrl}pacientes/obtener_direcciones?codigo_postal=${codigo_postal}`))
  }

  /**
   * 
   * @param paciente_id 
   * @returns 
   */
  getDataPacientesSalesForce(paciente_id: string) {
    return firstValueFrom(this.http.get(`${this.apiUrl}pacientes/datos_paciente_salesforce?paciente_id=${paciente_id}`))
  }

  /**
   * 
   * @param datos 
   * @returns 
   */
  PostEditarDatosSalesForce(datos: any) {
    return firstValueFrom(
      this.http.post(`${this.apiUrl}pacientes/editar_datos_paciente_salesforce`, datos)
    )
  }

  _setCitaActual(cita: any): void {
    this.currentCitaSubject.next(cita)
  }

  //#region HISTORIA CLINICA ANTIGUA

  async _uploadOldHistoryClinic(_data: _oldClinicalHistory) {

    const _files = _data.files;
    let _total = 0

    if (_files && _files.length) {
      for (let index = 0; index < _files.length; index++) {
        const element = _files[index];      
        const file = element.file

        console.log({file});
        

       // return console.log('a');
        

        const formData: FormData = new FormData();

        formData.append('cita_id', `${_data.cita_id}`);
        formData.append('paciente_id', `${_data.paciente_id}`);
        formData.append('historia_clinica_antigua', `${_data.historia_clinica_antigua}`)
        formData.append('apellidos_paciente', `${_data.apellidos_paciente}`)
        formData.append('nombre_paciente', `${_data.nombre_paciente}`)


        formData.append('file', file);

        let headers = new HttpHeaders();
        headers.append('Content-Type', 'multipart/form-data');
        headers.append('Accept', 'application/json');

        await firstValueFrom(
          this.http.post(`${this.apiUrl}formatos/guardar_historia_clinica_antigua`, formData)
        ).then((resp) => {
          console.log({ _resp: resp });
          _total++
        }).catch((error) => {
          console.error({ _ERRORR__: error });
        })

      }

      if(_total == _files.length) {
        return true
      }else {
        return false
      } 

    }

    return false

  }

  //#endregion
}
