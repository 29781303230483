<div class="mt-4">
    <app-upload-files 
    [showUpload]="_showUploadfiles" 
    [isMobile]="isMobile"
    [eventSubjectClerFiles]="eventSubjectClerFiles.asObservable()"
    (eventEmmitFilesAdded)="onEventAddedFiles($event)">
    </app-upload-files>
</div>

<fieldset class="mt-4">
    <form [formGroup]="_formOldClinicalHistory" class="needs-validation createfile-form" autocomplete="off" novalidate>
        <div class="row g-2 m-4">
            <div class="col-12 ">
                <h4 class="fs-12 text-primary bold text-uppercase text-center"> Registre los datos, si cuenta con ellos
                </h4>
            </div>
        </div>

        <div class="row row-cols-xxl-4 row-cols-lg-3 row-cols-md-2 row-cols-1">
            <div class="col">
                <div class="form-group">
                    <label class="form-label">Nombre(s) </label>
                    <input type="text" name="nombre_paciente" formControlName="nombre_paciente" class="form-control">
                </div>
            </div>

            <div class="col">
                <div class="form-group">
                    <label class="form-label">Apellidos </label>
                    <input type="text" name="apellidos_paciente" formControlName="apellidos_paciente"
                        class="form-control">
                </div>
            </div>
            <div class="col">
                <div class="form-group">
                    <label class="form-label">Numero de cita</label>
                    <input type="text" formControlName="cita_id" class="form-control">
                </div>
            </div>
            <div class="col">
                <div class="form-group">
                    <label class="form-label">Numero de paciente</label>
                    <input type="text" formControlName="paciente_id" class="form-control">
                </div>
            </div>
        </div>
        <div class="d-flex justify-content-center m-2 p-2">
            <button type="button" (click)="_onsaveIt()" class="btn btn-primary w-90">Guardar</button>
        </div>
    </form>
</fieldset>