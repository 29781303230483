import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import * as _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { Subscription, delay, interval } from 'rxjs';
import { CredentialsService } from 'src/app/core/services/credentials.service';
import { Cita } from 'src/app/pages/calendar/models/calendar';
import { Images } from 'src/app/shared/models/list-images';
import { FormatsService } from 'src/app/shared/services/formats.service';

import flatpickr from 'flatpickr';
import { Spanish } from 'flatpickr/dist/l10n/es.js';
import { JsonFormPacienteSalesForce } from 'src/app/shared/models/json-form-data';
import { PatientsService } from 'src/app/pages/calendar/services/patients.service';
import { AlertsService } from 'src/app/shared/services/alerts.service';
import { OrigenPaciente } from 'src/app/shared/models/origen-paciente';
import { CalendarService } from 'src/app/pages/calendar/services/calendar.service';

@Component({
  selector: 'app-recomendaciones-paciente',
  templateUrl: './recomendaciones-paciente.component.html',
  styleUrls: ['./recomendaciones-paciente.component.scss']
})
export class RecomendacionesPacienteComponent implements OnInit, OnDestroy {

  @Input()
  id: string

  @Input()
  _formatoData: any

  @Output()
  _eventLoadRecord: EventEmitter<boolean> = new EventEmitter<boolean>()
  
  
  _controls: any[] = []
  _formato: any = {}
  record: any;

  _Cita: Cita;
  _label: string

  _itsReady: boolean

  _formato_ine_frente: Images   | null
  _formato_ine_reverso: Images  | null

  _formControl: FormGroup = this.formBuilder.group({})
  $interval!: Subscription;
  _interval: number = 0;

  _origenPaciente: OrigenPaciente[] = []

  _segurosMedicos: any[] = []
  _seguroMedico: string = ''

  constructor (
    private formBuilder: FormBuilder,
    private formatService: FormatsService,
    private credentials: CredentialsService,
    private toastr: ToastrService,
    private patients: PatientsService,
    private alert: AlertsService,
    private calendar: CalendarService
  ) {
    flatpickr.localize(Spanish)

    this.record = this.credentials.userInfoData.data.records[0]
    const cita = this.credentials.getCitaJson()
    if(cita) {
      this._Cita = cita
      this.loadImages()
    }
  }

  ngOnInit(): void {

    if(this._formatoData) {
      this._formato = this._formatoData

      this.lodPacientes()
      this.loadSegurosMedicos()

      if(this._formato.form){
        this._label = this._formato.label;
        this._controls = this._formato.form.controls;
        this._itsReady = true     
        this.initForm();
      }
    }    
  }

  ngOnDestroy(): void {
    if(this.$interval) {
      this.$interval.unsubscribe()
    }
    this._savePrevDestroy()
  }

  get form() {
    return this._formControl.controls
  }

  private lodPacientes () {
    this.patients.getDataPacientesSalesForce(this.record.Id).then((resp) => {
      
      console.log({resp});
      
    }).catch((error) => {
      console.error({error});      
    })
    
    this.calendar._getOrigenPaciente().subscribe({
      next:(response) => {
        if(response.codigo == 200) {
          this._origenPaciente = response.data[0].origen_del_paciente;
          
          
        }        
      },
      error: (error) => {
        console.error({error});
        
      }
    })
  }


  /**
   * 
   */
  private loadSegurosMedicos () {
    this.calendar._getSeguroMedicoMayores().subscribe({
      next:(response: any) => {
        if(response.codigo === 200) {
          console.log({response});
          this._segurosMedicos = response.data[0].seguro_gastos_medicos_mayores     

          console.log({_segurosMedicos_: this._segurosMedicos});
          
        }
        
      },
      error(err) {
        console.error({err});
        
      },
    })
  }


  /**
   * 
   */
  private loadImages () {
    this._formato_ine_frente = null
    this._formato_ine_reverso = null
    this.formatService._getListImages(this._Cita.Cita_Id,this.record.Name).subscribe({
      next:(value: any) => {
          if(value.codigo === 200) {
            
            const archivos = value.data.formato.archivos

            if(archivos) {
              this._formato_ine_frente = archivos.formato_ine_frente ? archivos.formato_ine_frente[0] : null
              this._formato_ine_reverso = archivos.formato_ine_reverso ? archivos.formato_ine_reverso[0]: null
              
            }
            
          }
      },
      error:(err) => {
          console.error({err});
          
      },
    })
  }

  /**
   * 
   */
  private _savePrevDestroy(): void {
    let _formValues = this._formControl.value
    let _body_: any[] = []

    _.map(_formValues, (a, i) => {
        _body_.push({
          name: i,
          value: a
        })      
    })    

    let _bodyForm = {
      Cita_Id: this._Cita.Cita_Id,
      label: this._formato.label,
      new_data: _body_
    }
    this._saveFormatValues(_bodyForm, false)
  }

  /**
   * 
   */
  private initForm () {

    _.map(this._controls, (a) => {
      _.map(a.Objects, (b) => {

        if(b.type === 'radio') {
          this._formControl.addControl(a.name, this.formBuilder.control(a.value))
        }

        let _value = b.value

        if(b.name === 'fecha_datos_paciente_recomendaciones' ) {
          _value = new Date()
        }

        if(b.name === 'name_datos_paciente_recomendaciones' ) {
          _value = this.record.P00_Nombre__c
        }

        if(b.name === 'birth_date_datos_paciente_recomendaciones' ) {
          _value = new Date(this.record.Fecha_nacimiento_calendario__c)
        }

        if(b.name === 'apellido_datos_paciente_recomendaciones' ) {
          _value = this.record.P05_Apellidos__c
        }

        if(b.name === 'edad_datos_paciente_recomendaciones' ) {
          _value = this.record.P05_Edad__c
        }

        if(b.name === 'sex_datos_paciente_recomendaciones') {
          _value = this.record.P05_Sexo__c
        }     

        if(b.name === 'email_datos_paciente_recomendaciones') {
          _value = this.record.P05_Correo__c
        }

        if(b.name === 'estado_civil_datos_paciente_recomendaciones' ) {
          _value = this.record.P05_Estado_civil__c
        }


        if(b.name === 'calle_datos_paciente_recomendaciones' ){
          _value = this.record.Calle__c
        }

        if(b.name === 'Colonia_datos_paciente_recomendaciones' ) {
          _value = this.record.Colonia__c
        }

        if(b.name === 'municipio_datos_paciente_recomendaciones' ) {
          _value = this.record.Municipio__c
        }

        if(b.name === 'numero_ext_datos_paciente_recomendaciones' ) {
          _value = this.record.Numero_Exterior__c
        }

        if(b.name === 'numero_int_datos_paciente_recomendaciones' ) {
          _value = this.record.Numero_Interior__c
        }

        if(b.name === 'codigo_postal_datos_paciente_recomendaciones') {
          _value = this.record.C_digo_postal__c
        }

        if(b.name === 'estado_datos_paciente_recomendaciones' ) {
          _value = this.record.Estado__c
        }

        this._formControl.addControl(b.name, this.formBuilder.control(_value))
      })      
    })

    console.log({controls__: this._formControl.value});
    
    
    this.createInterval()

  }

  /**
   * 
   */
  private createInterval () {
    const myObservable = interval(1000);
    this.$interval = myObservable.pipe(
      delay(500),

    ).subscribe((integer) => {
      if(integer === 20 ) { 
        this.$interval.unsubscribe();        
        this._newSaveFormat()            
      }           

      this._interval = integer            
    })
  }

  /**
   * 
   */
  private _newSaveFormat() {
    
    let _formValues = this._formControl.value
    let _body_: any[] = []

    _.map(_formValues, (a, i) => {
        _body_.push({
          name: i,
          value: a
        })      
    })    

    let _bodyForm = {
      Cita_Id: this._Cita.Cita_Id,
      label: this._formato.label,
      new_data: _body_
    }

    console.log({_bodyForm});
    this._saveFormatValues(_bodyForm, true)    

  }

  /**
   * 
   * @param _jsonObject 
   * @param createInterval 
   */
  private _saveFormatValues (_jsonObject: any, createInterval: boolean = false): void  {
    this.formatService._postFormato(_jsonObject).then((resp) => {

      this.toastr.info('Se ha guardado la información, correctamente','Guardada', {
        timeOut: 3200
      }).onHidden.subscribe(() => {
        this.$interval.unsubscribe()

        if(createInterval) {     
          this.createInterval()
        }

      })
    }).catch((error) => {
      console.error({error});
      
    })

  }

  /**
   * 
   * @param event 
   * @param name 
   */
  _onCheckChange(event:any, name: string) {
    let val = event.target.checked
    this._formControl.controls[name].patchValue(val)   
  }


  _onSendData() {
    let data: JsonFormPacienteSalesForce = new JsonFormPacienteSalesForce();
    
    data = {
      paciente_id: this.record.Id,
      name: this.form['name_datos_paciente_recomendaciones'].value,
      generales:{
        nombre: this.form['name_datos_paciente_recomendaciones'].value,
        apellido: this.form['apellido_datos_paciente_recomendaciones'].value,
        edad: this.form['edad_datos_paciente_recomendaciones'].value,
        sexo: this.form['sex_datos_paciente_recomendaciones'].value,
        fecha_nacimiento: this.form['birth_date_datos_paciente_recomendaciones'].value,
        correo: this.form['email_datos_paciente_recomendaciones'].value,
        estado_civil: this.form['estado_civil_datos_paciente_recomendaciones'].value,
        seguro: this.form['seguro_medico_datos_paciente_recomendaciones'].value === 'si_seguro_medico_datos_paciente_recomendaciones' ? true : false ,
        nombre_seguro: this.form['cual_seguro_medico_datos_paciente_recomendaciones'].value,
        seguro_antiguedad: this.form['antiguedad_seguro_medico_datos_paciente_recomendaciones'].value
      },
      domicilio:{
        calle: this.form['calle_datos_paciente_recomendaciones'].value,
        codigo_postal:this.form['codigo_postal_datos_paciente_recomendaciones'].value,
        colonia:this.form['Colonia_datos_paciente_recomendaciones'].value,
        estado: this.form['estado_datos_paciente_recomendaciones'].value,
        municipio: this.form['municipio_datos_paciente_recomendaciones'].value,
        numero_exterior: this.form['numero_ext_datos_paciente_recomendaciones'].value,
        numero_interior: String(this.form['numero_int_datos_paciente_recomendaciones'].value)
      },
      informacion:{        
        nombre_contacto_emergencia: this.form['nombre_contacto_emergencia__datos_paciente_recomendaciones'].value,
        numero_contacto_emergencia: this.form['numero_contacto_emergencia__datos_paciente_recomendaciones'].value,
        paciente_recomienda: this.form['nombre_paciente_donde_enteraste_datos_paciente_recomendaciones'].value
      }
    }
    
    
    this.alert._showLoader('Enviando datos a SalesForce')

    this.patients.PostEditarDatosSalesForce(data).then((resp) => {
      this.alert._hideLoader()

      console.log(resp);      
      this.toastr.success('Se han enviado, los datos correctamente','Exito!!',{
        timeOut: 3200
      })

      this.patients._getPatientInfo(this._Cita.Paciente_Id).subscribe({
        next:(resp: any) =>  {

          if(resp.codigo === 200) {
            this.credentials.saveUserinfo(resp);
            this._eventLoadRecord.emit(true)
          }

          console.log({resp});            
        },
        error(err) {
          console.error({err});
          
        },
      }) 

    }).catch((error) => {
      console.error({error});
      this.alert._hideLoader()

      this.toastr.error('Ocurrio un error al tratar de guardar, los datos en salesForce','Error!!',{
        timeOut: 3200
      })
    })

  }

}
