import { Component, OnInit, EventEmitter, Output, Inject, Input, ViewChild, TemplateRef, OnDestroy } from '@angular/core';
//Logout
import { AuthenticationService } from '../../core/services/auth.service';
import { Router } from '@angular/router';
// Language
import { CookieService } from 'ngx-cookie-service';
import { LanguageService } from '../../core/services/language.service';
import { TranslateService } from '@ngx-translate/core';

import { dataInfo } from '../models/user-data';
import { CredentialsService } from '@app/core/services/credentials.service';
import { DOCUMENT } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { RedirectService } from '@app/services/redirect.service';
import { NgbModal, NgbModalRef, NgbOffcanvas, NgbOffcanvasRef } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { SessionPageService } from '@app/pages/services/session-page.service';
import * as _ from 'lodash';
import { CalendarService } from '@app/pages/calendar/services/calendar.service';
import { DataResponseResultSearch } from '@app/pages/models/response-search-result';
import { ProfileService } from '@app/pages/services/profile.service';
import { Subscription } from 'rxjs';
import { CandidatoData } from '@app/pages/calendar/models/candidatos';
import { CandidatosService } from '@app/pages/calendar/services/candidatos.service';
import { AlertsService } from '@app/shared/services/alerts.service';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss']
})
export class TopbarComponent implements OnInit, OnDestroy {
  
  @ViewChild('canvasSearch') canvasSearch: TemplateRef<any>;
  @ViewChild('modalPatient') modalPatient !: TemplateRef<any>
  @ViewChild('modalCreatePatient') modalCreatePatient!: TemplateRef<any>

  @ViewChild('modalConvertPatient') modalConvertPatient!: TemplateRef<any>;

  element: any;
  mode: string | undefined;
  @Output() mobileMenuButtonClicked = new EventEmitter();

  flagvalue: any;
  valueset: any;
  countryName: any;
  cookieValue: any;
  userData: dataInfo | null;
  total = 0;
  cart_length: any = 0;  

  _formSearch: FormGroup;
  _minLength: number = 3 
  _canvasRef_ : NgbOffcanvasRef;

  _searchResult: any[] = []

  _resultSearch: DataResponseResultSearch[]

  _ngModal: NgbModalRef;
  _ngbModalOpen: NgbModalRef;

  _ngbModalPatient: NgbModalRef


  _optionForm: number;
  _enfermero : boolean;
  _profileImg: string | null
  $_ProfileSubject: Subscription;

  _candidato: CandidatoData

  _respData: any;


  constructor(
    @Inject(DOCUMENT) private document: any, 
    public languageService: LanguageService,
    public _cookiesService: CookieService, 
    public translate: TranslateService, 
    private authService: AuthenticationService,
    private credentials: CredentialsService,
    private redirect: RedirectService,
    private fb: FormBuilder,
    private router: Router,
    private toastr: ToastrService,
    private session: SessionPageService,
    private offCanvasService: NgbOffcanvas, 
    private calendar: CalendarService,
    private modalService: NgbModal,
    private _profileService: ProfileService,
    private candidato: CandidatosService,
    private alert: AlertsService
    
   ) {  
 

  }

  ngOnInit(): void {    
    
    this._enfermero = false;
    this._profileImg = null
    
    this.$_ProfileSubject = this._profileService.currentProfile.subscribe((respImg) => {      
      
      if(respImg && respImg.url) {
        this._profileImg = respImg.url;
      }else {
        const _profile = this.credentials._getdataUser()
        if(_profile) {
          if(_profile.archivos && _profile.archivos.Perfil) {
            let _perfil = _profile.archivos.Perfil[0]
  
            this._profileImg = _perfil.url          
          }  
        }else {
          console.log('AAAAAAAAA');
          
          
          //this.logout()
          
        }
                  
      }    
    })     

    let _role = this.credentials.credentials?.roles.Enfermera_General;
    if(!_role) {
      _role = this.credentials.credentials?.roles.Enfermera_Quirurgica;
    }
    
    if(_role) {
      this._enfermero = _role
    }
    

    this._formSearch = this.fb.group({
      searchText: [null, [Validators.required, Validators.minLength(this._minLength) ]]
    })

    this.userData = this.credentials.credentials;    
       
    this.element = document.documentElement;

    // Cookies wise Language set
    this.cookieValue = this._cookiesService.get('lang');     
  }

  ngOnDestroy(): void {
    if(this.$_ProfileSubject) {
      this.$_ProfileSubject.unsubscribe();
    }
  }

  get form () {
    return this._formSearch.controls;
  }

  /**
   * Toggle the menu bar when having mobile screen
   */
  toggleMobileMenu(event: any) {
    document.querySelector('.hamburger-icon')?.classList.toggle('open')
    event.preventDefault();
    this.mobileMenuButtonClicked.emit();
  }

  /***
   * Language Value Set
   */
  setLanguage(text: string, lang: string, flag: string) {
    this.countryName = text;
    this.flagvalue = flag;
    this.cookieValue = lang;
    this.languageService.setLanguage(lang);
  }

  /**
   * Logout the user
   */
  logout() {
    this.authService.logout();
    this.router.navigate(['/auth/login']);
  }

  windowScroll() {
    if (document.body.scrollTop > 100 || document.documentElement.scrollTop > 100) {
      (document.getElementById("back-to-top") as HTMLElement).style.display = "block";
      document.getElementById('page-topbar')?.classList.add('topbar-shadow')
    } else {
      (document.getElementById("back-to-top") as HTMLElement).style.display = "none";
      document.getElementById('page-topbar')?.classList.remove('topbar-shadow')

    }
  }

  /**
   * 
   * @param form 
   */
  _onSerach() {

    if(this._formSearch.valid) {

      this.session._saveResultSearch(this._searchResult)

     // if(this._searchResult.length == 0) {

        let _searchText = this._formSearch.value.searchText;       

        this.calendar._serchByText(_searchText).subscribe({
          next:(response) => {            
            if(response.codigo === 200) {
              this._resultSearch = response.data;
              this.session._saveSearchResponse(this._resultSearch)
              this.closeBtn()
              this.redirect.to('/search-results',{
                queryParams: { searchText: this.form['searchText'].value }
              })
            }           
            
          },error:(error) => {
            console.error({error});
            
          }
        })
    }
  }

  /**
   * 
   * @param item 
   */
  _onClickSearch(item: any) {

    this._searchResult = [item]

    
    this.session._saveResultSearch(this._searchResult)


    this.redirect.to('/search-results',{
      queryParams: { searchText: this.form['searchText'].value }
    })
  }

  _onEventSearch() {
    let searchOptions = document.getElementById("search-close-options") as HTMLAreaElement;
    let dropdown = document.getElementById("search-dropdown") as HTMLAreaElement;
    let input: any, filter: any, ul: any, li: any, a: any | undefined, i: any, txtValue: any;
    input = document.getElementById("search-text") as HTMLAreaElement;
    filter = input.value.toUpperCase();

    let _value = this.form['searchText'].value;
   // _value.tolo
    
    if(_value &&( _value.length >= this._minLength)) {
      dropdown.classList.add("show");
      searchOptions.classList.remove("d-none");

      let _filters = this.session._returnJSONSession()

      if(_filters) {
        this._searchResult = _filters.filter((item: any) => 
        item.Cita_Id.toLocaleUpperCase().includes(_value.toLocaleUpperCase()) ||
        item.Recurso.toLocaleUpperCase().includes(_value.toLocaleUpperCase())  ||
        item.Tipo_Cita.toLocaleUpperCase().includes(_value.toLocaleUpperCase()) ||
        item.N_Expediente.toLocaleUpperCase().includes(_value.toLocaleUpperCase()) ||
        item.Doctor.toLocaleUpperCase().includes(_value.toLocaleUpperCase()) ||
        item.Paciente_Nombre_Completo.toLocaleUpperCase().includes(_value.toLocaleUpperCase()))   
      }              
      
    }     
  }

  closeBtn() {
    let searchOptions = document.getElementById("search-close-options") as HTMLAreaElement;
    let dropdown = document.getElementById("search-dropdown") as HTMLAreaElement;
    let searchInputReponsive = document.getElementById("search-text") as HTMLInputElement;
    dropdown.classList.remove("show");
    searchOptions.classList.add("d-none");
    searchInputReponsive.value = "";
  }


  _onSetCanvas() {
    this._canvasRef_ = this.offCanvasService.open(this.canvasSearch, {
      position:'top',
    })

  }


  oncloseCanvas() {
    this._canvasRef_.close()
  }

  /**
   * 
   */
  openModalNewPatient(option: number) {
    this._optionForm = option
    
    this._ngModal  = this.modalService.open(this.modalPatient,{
      size:'xl',
      animation:true,
      backdrop:'static',
      fullscreen:'lg'
    })

  }

  /**
   * 
   */
  closeEventModal() {
    this._ngModal.close()
  }


  onClickEvent(event: boolean) {
        if(event) {
          this._ngModal.close()
        }
  }

  /**
   * 
   */
  onClickEventCalendar(): void {
    if (this.credentials.credentials?.roles.Administrador || this.credentials.credentials?.roles.Administracion || this.credentials.credentials?.roles.Doctor) {
      this.router.navigateByUrl('/')
    }else {
     // routerLink=""
     this.router.navigateByUrl('/calendario/calendario')
    }
  }


  _canCreate(): boolean {    

    if(this.credentials.credentials?.roles.Administrador || this.credentials.credentials?.roles.Doctor  )
    {
      return true
    }


    return false
  }

  _canViewCalendar(): boolean {
    if(this.credentials.credentials?.roles.Administrador || this.credentials.credentials?.roles.Doctor || this.credentials.credentials?.roles.Asistencia || this.credentials.credentials?.roles.Callcenter) {
      return true
    }

    return false
  }

  _canViewAnestesiologo(): boolean {
    if(this.credentials.credentials?.roles.Administrador || this.credentials.credentials?.roles.Anestesiologo) {
      return true
    }

    return false
  }

  _canViewPaciente() : boolean {

    if(this.credentials.credentials?.roles.Administrador || this.credentials.credentials?.roles.Doctor ) {
      return true
    }

    return false
  }

  _canViewCandidatos(): boolean {
    
    if(this.credentials.credentials?.roles.Administrador || this.credentials.credentials?.roles.Doctor || this.credentials.credentials?.roles.Administracion || this.credentials.credentials?.roles.Callcenter) {
      return true
    }

    return false
  }

  _canViewLab(): boolean {

    //if()
    if(this.credentials.credentials?.roles.Administrador) {
      return true
    }

    return false
  }

  /**
   * 
   */
  _canCreatePatient(): boolean {

    if(this.credentials.credentials?.roles.Administrador) {
      return true
    }

    return false
  }

  /**
   * 
   * @param event 
   */
  _onEventCloseModal(event: any) {
    console.log({event});

    this._respData = event
    
    //if(event) {
      this._ngModal.close()
      this._openModalPatient()
   // }
  }

  /**
   * 
   */
  private _openModalPatient() {

    this._ngbModalOpen = this.modalService.open(this.modalCreatePatient, {
      size:'xl',
      animation:true,
      backdrop:'static',
      fullscreen:'lg'
    })
    

  }

  _onSetPatient() {
    
    this._ngbModalOpen.close()
    this.alert._showLoader('Espere...')
    
    this.candidato.ObtenerListadoCanidatos(this._respData.Candidato_Id,0).subscribe({
      next:(resp) => {
        if(resp && resp.codigo === 200) {
          this._candidato = resp.data.candidatos[0]
          this.alert._hideLoader()
        
          setTimeout(() => {
            this._ngbModalPatient = this.modalService.open(this.modalConvertPatient,{
              size:'xl',
              animation:true,
              backdrop:'static',
              fullscreen:'lg'
            })

          }, 300);
        
   
        }
 
      },
      error:(err)=> {
        console.error({err});
        this.toastr.error('Fallo al consultar los detalles del candidato, por favor intente de nuevo','Error', {
          timeOut: 3200,
          progressBar: true
        })
        this.alert._hideLoader()
      }
    })


  }

  _onEventClickCancel(event: boolean) {
    if(event) {

    }
  }

}
