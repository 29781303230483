<div class="mt-4">
    <!-- <blockquote class="blockquote fs-14 text-wrap"> {{_text_value}}</blockquote> -->
    <textarea [name]="name" [required]="required" [readonly]="readonly" [rows]="rows" 
    class="custom-text-area form-control text-uppercase fw-bold"
        [(ngModel)]="_text_value" [value]="_text_value">
    </textarea>
    <div class="d-flex justify-content-end">
        <button *ngIf="!itsRecording" class="btn-light rounded-pill shadow-lg btn btn-icon btn-lg p-2"
            (click)="startListening()">
            <i class='mdi mdi-microphone-off fs-22'></i>
        </button>
        <button *ngIf="itsRecording" class="btn-danger rounded-pill shadow-lg btn btn-icon btn-lg p-2"
            (click)="stopListening()">
            <i class='mdi mdi-microphone-outline fs-22'></i>
        </button>
    </div>
</div>