<header id="page-topbar" data-scroll-header (window:scroll)="windowScroll()">
    <div class="layout-width">
        <div class="navbar-header">
            <div class="d-flex">
                <!-- LOGO -->
                <div class="navbar-brand-box horizontal-logo">
                    <a href="javascript:void(0);" class="logo logo-dark">
                        <span class="logo-sm">
                            <img src="assets/images/logo-sm.png" alt="" height="22">
                        </span>
                        <span class="logo-lg">
                            <img src="assets/images/IML.png" alt="" height="17">
                        </span>
                    </a>
                </div>
                <button type="button" class="btn btn-sm px-3 fs-16 header-item vertical-menu-btn topnav-hamburger"
                    id="topnav-hamburger-icon" (click)="toggleMobileMenu($event)">
                    <span class="hamburger-icon">
                        <span></span>
                        <span></span>
                        <span></span>
                    </span>
                </button>                
                <div class="d-none d-md-block">
                    <div class="position-relative pt-4"> 
                        <h4 class="text-muted"> {{userData?.nombre }} </h4>
                    </div>                
                </div>                    
            </div>  

            <!-- App Search-->
            <form [formGroup]="_formSearch" (ngSubmit)="_onSerach()"  class="app-search d-none d-xl-block " *ngIf="!_enfermero" >
                <div class="position-relative"> 
                    <div class="">
                        <input 
                        id="search-text"
                        class="form-control me-auto"
                        formControlName="searchText" 
                        type="text" 
                        (keyup)="_onEventSearch()"
                        placeholder="Ingrese un texto para buscar">
                            <span class="mdi mdi-magnify search-widget-icon"></span>
                            <span class="mdi mdi-close-circle search-widget-icon search-widget-icon-close d-none"
                                id="search-close-options" (click)="closeBtn()"></span>
                    </div>
                </div>
                <div class="dropdown-menu dropdown-menu-xl" id="search-dropdown">
                    <ngx-simplebar style="max-height: 320px;">    
                        <ng-container *ngIf="_searchResult && _searchResult.length; else emptyResults">

                            <div class="dropdown-header">
                                <h6 class="text-overflow text-muted mb-0 text-uppercase">Resultados de busqueda</h6>
                            </div>
                            <div class="notification-list">
                                <a *ngFor="let item of _searchResult" 
                                href="javascript:void(0);" 
                                (click)="_onClickSearch(item)"
                                class="dropdown-item notify-item py-2"  >                                  
                                    <div class="flex-grow-1">
                                        <h6 class="m-0">{{item.Paciente_Nombre_Completo}} </h6>
                                        <span class="fs-11 mb-0 text-muted">Cita {{item.Doctor}} </span>
                                        <br>
                                        <span class="fs-11 mb-0 text-muted">No. Expediente {{item.N_Expediente}} </span>
                                    </div>
                                </a>
                            </div>
                        </ng-container>                

                        <ng-template #emptyResults>

                        </ng-template>
                        
                    </ngx-simplebar>

                    <div class="text-center pt-3 pb-1">
                        <button type="submit" class="btn btn-primary btn-sm">Ver todos los resultado <i
                                class="ri-arrow-right-line ms-1"></i></button>
                    </div>
                </div>
            </form>
        
            <div class="d-flex align-items-center" >  
                
                <!-- Buscar SM -->
                <div class="d-block d-sm-none d-md-block d-xl-none" *ngIf="!_enfermero" >

                    <button type="button" (click)="_onSetCanvas()" class="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle">
                        <i class="bx bx-search-alt-2  fs-22"></i>
                    </button>  

                   <!--  <ng-template [ngTemplateOutlet]="btnPlus" ></ng-template> -->
                    

                </div>

                <div class="ms-1 header-item d-none d-sm-flex" *ngIf="!_enfermero"> 
                    
                    <ng-container >
                        <ng-template [ngTemplateOutlet]="btnPlus" ></ng-template>
                    </ng-container>
                    

                    <button type="button" class="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle light-dark-mode">
                        <a class="nav-link" routerLink="/pacientes"> 
                            <i class='bx bxs-user-detail fs-22' ></i>    
                        </a>                        
                    </button>

                    <button type="button"   class="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle light-dark-mode" *ngIf="_canViewCalendar()">
                        <a class="nav-link" href="javascript:void(0);" (click)="onClickEventCalendar()">
                            <i class='bx bx-calendar fs-22' ></i>
                        </a>                    
                    </button>

                </div>               

                <div *ngIf="!_enfermero" class="dropdown topbar-head-dropdown ms-1 header-item" ngbDropdown>
                    <button type="button" class="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
                        data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" ngbDropdownToggle>
                        <i class='bx bx-category-alt fs-22'></i>
                    </button>
                    <div class="dropdown-menu dropdown-menu-lg p-0 dropdown-menu-end" ngbDropdownMenu>
                        <div class="p-3 border-top-0 border-start-0 border-end-0 border-dashed border">
                            <div class="row align-items-center">
                                <div class="col">
                                    <h6 class="m-0 fw-semibold fs-15 bold text-uppercase"> Atajos </h6>
                                </div>
                            
                            </div>
                        </div>

                        <div class="p-2"  >
                            <div class="row g-0" >                                
                                <div class="d-block d-sm-none d-md-none d-xl-none" *ngIf="_canCreate()">
                                    <div class="row g-0">
                                        <div class="col">
                                            <a class="dropdown-icon-item" href="javascript:void(0);" (click)="openModalNewPatient(1)">
                                                <i class='bx bx-user-plus fs-22' ></i>
                                                <span class="bold text-uppercase">Crear un paciente</span>
                                            </a>
                                        </div>
                               
                                        <div class="col">
                                            <a class="dropdown-icon-item" href="javascript:void(0);" (click)="openModalNewPatient(2)">
                                                <i class='bx bx-calendar-alt  fs-22' ></i>
                                                <span class="bold text-uppercase">Crear cita</span>
                                            </a>
                                        </div>                                        
                                    </div>                                
                                </div>       
                                
                                <div class="col-6">
                                    <a class="dropdown-icon-item" href="javascript:void(0);" (click)="openModalNewPatient(3)">
                                        <i class='bx bx-user-plus fs-22' ></i>
                                        <span class="bold text-uppercase">Crear un candidato</span>
                                    </a>
                                </div>
                                
                                <div class="col-6" *ngIf="_canViewCandidatos()">
                                    <a class="dropdown-icon-item" routerLink="/candidatos">
                                        <i class='bx bxs-user-detail  fs-22' ></i>
                                        <span class="bold text-uppercase">Candidatos</span>
                                    </a>
                                </div>

                                     
                                <div class="col-6" *ngIf="_canViewCandidatos()">
                                    <a class="dropdown-icon-item" routerLink="/listado-pacientes">
                                        <i class='bx bxs-user-detail  fs-22' ></i>
                                        <span class="bold text-uppercase">Listado de pacientes</span>
                                    </a>
                                </div>

                                <div class="col-6" *ngIf="_canViewPaciente()">
                                    <a class="dropdown-icon-item" routerLink="/pacientes">
                                        <i class='bx bxs-user-detail  fs-22' ></i>
                                        <span class="bold text-uppercase">Pacientes</span>
                                    </a>
                                </div>

                                <div class="col-6"  *ngIf="_canViewCalendar()" >
                                    <a class="dropdown-icon-item" href="javascript:void(0);" (click)="onClickEventCalendar()">
                                        <i class='bx bx-calendar fs-22' ></i>
                                        <span class="bold text-uppercase">calendario</span>
                                    </a>
                                </div>    

                                <div class="col-6" *ngIf="_canViewLab()">
                                    <a class="dropdown-icon-item" routerLink="/pharmacy">
                                        <i class='ri-hospital-line fs-22' ></i>
                                        <span class="bold text-uppercase">Laboratorio</span>
                                    </a>
                                </div>   

                                <div class="col-6">
                                    <a class="dropdown-icon-item" routerLink="/old-clinical-history">
                                        <i class='ri-upload-cloud-2-line  fs-22' ></i>
                                        <span class="bold text-uppercase">H.C Antiguas</span>
                                    </a>
                                </div>    
                                
                                <div class="col-6" *ngIf="_canViewAnestesiologo()">
                                    <a class="dropdown-icon-item" routerLink="/index-anestesiologo">
                                        <i class='ri-edit-box-fill fs-22' ></i>
                                        <span class="bold text-uppercase">Anestesiologo</span>
                                    </a>
                                </div>  
                                
                            </div>                           
                        </div>
                    </div>
                </div>

                <!-- Menu User -->
                <div class="dropdown ms-sm-3 header-item topbar-user" ngbDropdown>
                    <button type="button" class="btn" id="page-header-user-dropdown" data-bs-toggle="dropdown"
                        aria-haspopup="true" aria-expanded="false" ngbDropdownToggle>
                        <span class="d-flex align-items-center">
                            <img class="rounded-circle header-profile-user" [src]="_profileImg!= null ? _profileImg : 'assets/images/users/user-dummy-img.jpg'"
                                alt="Header Avatar">
                            <span class="text-start ms-xl-2">
                                <span
                                    class="d-none d-xl-inline-block ms-1 fw-medium user-name-text"> {{userData?.usuario}} </span>
                                <span class="d-none d-xl-block ms-1 fs-12 text-muted user-name-sub-text">
                                    {{userData?.puesto}} </span>
                            </span>
                        </span>
                    </button>
                    <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
                        <!-- item-->
                        <h6 class="dropdown-header">{{userData?.nombre}} </h6>          
                        <a class="dropdown-item" routerLink="/profile"><i
                            class="mdi mdi-account-circle text-muted fs-16 align-middle me-1"></i> <span
                            class="align-middle">Perfil</span></a>

                        <a class="dropdown-item" href="javascript:void(0);" (click)="logout()">
                        <span class="align-middle" data-key="t-logout">Cerrar sesion</span></a>
                    </div>
                </div>

            </div>
        </div>
    </div>
</header>

<ng-template #canvasSearch let-offcanvas>
    <app-canvas-search-sm (eventCloseCanvas)="oncloseCanvas()" ></app-canvas-search-sm>
</ng-template>

<ng-template #modalPatient let-modal> 

    <div class="modal-header p-3 bg-primary-subtle">
        <h5 class="modal-title" id="modal-title"> {{ _optionForm == 1 ? 'Crear nuevo Paciente' : _optionForm == 2 ? 'Crear nueva Cita' : _optionForm == 3 ? 'Nuevo Candidato' : 'Listado de Canidatos'}}  </h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-hidden="true"
            (click)="closeEventModal()"></button>
    </div>  

    <div class="modal-body p-4">
        <ng-container *ngIf="_optionForm == 1" >
            <app-create-patient (eventClickCancel)="onClickEvent($event)"></app-create-patient>
        </ng-container>

        <ng-container *ngIf="_optionForm == 2">
            <app-create-citas (eventClickCancel)="onClickEvent($event)"></app-create-citas>
        </ng-container>

        <ng-container *ngIf="_optionForm == 3">
            <app-create-candidate 
            (eventCloseModal)="_onEventCloseModal($event)"
            (eventClickCancel)="onClickEvent($event)"></app-create-candidate>
        </ng-container>

        <ng-container *ngIf="_optionForm === 4">
            <app-lista-candidatos ></app-lista-candidatos>
        </ng-container>        
    </div>

</ng-template>


<ng-template #btnPlus>
    <div class="dropdown opbar-head-dropdown ms-1 header-item" ngbDropdown>
        <button type="button" class="btn btn-icon btn-topbar btn-ghost-success rounded-circle light-dark-mode"  data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" ngbDropdownToggle>     
            <i class=" bx bx-plus  fs-22"></i>                   
        </button>     
        <div class="dropdown-menu dropdown-menu-lg p-0 dropdown-menu-end" ngbDropdownMenu>
            <div class="p-3 border-top-0 border-start-0 border-end-0 border-dashed border">
                <div class="row align-items-center">
                    <div class="col">
                        <h6 class="m-0 fw-semibold fs-15 bold text-uppercase"> Opciones </h6>
                    </div>
                
                </div>
            </div>
            <a class="dropdown-item" href="javascript:void(0);"routerLink="/candidatos" >
                <i class="bx bx-calendar-alt  text-muted fs-22 align-middle me-1"></i> <span class="align-middle">Candidatos</span>
            </a>

            <a class="dropdown-item" href="javascript:void(0);"routerLink="/listado-pacientes" >
                <i class="bx bx-calendar-alt  text-muted fs-22 align-middle me-1"></i> <span class="align-middle">Listado de pacientes</span>
            </a>

            <a class="dropdown-item" href="javascript:void(0);" (click)="openModalNewPatient(3)">
                <i class="bx  bx bxs-user-circle  text-muted fs-22 align-middle me-1"></i> <span class="align-middle">Crear Candidato</span>
            </a>

            <a class="dropdown-item" href="javascript:void(0);" (click)="openModalNewPatient(1)" *ngIf="_canCreatePatient()">
                <i class="bx bx-user-plus  text-muted fs-22 align-middle me-1"></i> <span class="align-middle">Crear paciente</span>
            </a>      

            <a class="dropdown-item" href="javascript:void(0);" (click)="openModalNewPatient(2)">
                <i class="bx bx-calendar-alt  text-muted fs-22 align-middle me-1"></i> <span class="align-middle">Crear Cita</span>
            </a>
        
            
            <!-- 

                   <a class="dropdown-icon-item" href="javascript:void(0);" (click)="openModalNewPatient()">
                        <i class='bx bxs-user-detail  fs-22' ></i>
                        <span class="bold text-uppercase">Crear paciente</span>
                    </a>
             -->

        </div>
    </div>
</ng-template>

<ng-template #modalCreatePatient let-modal>
    <div class="modal-content">
        <div class="modal-header">
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="btn-close" (click)="modal.close('Cross click')">  </button>
        </div>
        <div class="modal-body text-center">

          <!-- <lord-icon src="https://cdn.lordicon.com/kvuyljqb.json" trigger="loop" colors="primary:#0ab39c,secondary:#405189" style="width:120px;height:120px"></lord-icon> -->
          <div class="mt-4 pt-2 fs-15">       
              <h4>El registro del candidato se ha realizado correctamente</h4>
              <h5 class="text-muted mx-4 mb-0">¿Desea proceder a convertir a paciente ?</h5>
          </div>
          <!--  -->
          <div class="d-flex gap-2 justify-content-center mt-4 mb-2">
              <button type="button" class="swal2-cancel btn btn-primary w-xs mb-1" aria-label="" style="display: inline-block;" (click)="modal.close('Close click')">Cerrar</button>
              <button type="button" class="swal2-cancel btn btn-success w-xs mb-1" aria-label="" style="display: inline-block;" (click)="_onSetPatient()"> Convertir paciente</button>
          </div>
        </div>
      </div>
</ng-template>

<ng-template #modalConvertPatient let-modalConvertPatient>
    <div class="modal-header p-3 bg-primary-subtle">
        <h5 class="modal-title" id="modal-title"> 'Crear nuevo Paciente' </h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-hidden="true" (click)="modalConvertPatient.close()"></button>
    </div>  
    <div class="modal-body p-4">
        <app-create-patient
        [editable]="true"
        [_candidato]="_candidato"
        (eventClickCancel)="_onEventClickCancel($event)"
        ></app-create-patient>
    </div>
</ng-template>


