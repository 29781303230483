import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, firstValueFrom, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

import { ListaEmpleadosResponsable } from '../../models/lista-empleados-responsable';
import { JsonFormPacienteSalesForce } from 'src/app/shared/models/json-form-data';

@Injectable({
  providedIn: 'root'
})
export class PatientsService {

  //private readonly apiUrl: string = environment.production ? environment.apiUrl : env.environment.apiUrl
  private readonly apiUrl: string = environment.apiUrl;    

  currentCitaSubject: BehaviorSubject<any> = new BehaviorSubject<any>({} as any)
  public readonly currentCita:Observable<any> = this.currentCitaSubject.asObservable()
  

  constructor(private http: HttpClient) { }
  
  _getPatientInfo(patiendId: string, set_index: number = 0 ) {    
    return this.http.get(`${this.apiUrl}pacientes/?paciente=${patiendId}&set_index=${set_index}`)
  }

  /**
   * Empleado Responsable
   * @returns 
   */
  _getListaEmpleadosResponsable():Observable<ListaEmpleadosResponsable> {
    return this.http.get<ListaEmpleadosResponsable>(`${this.apiUrl}pacientes/lista_empleados_responsables`)
  }

  /**
   * 
   * @param paciente_id 
   */
  _getPacienteDetalle(paciente_id: string) {
    return this.http.get(`${this.apiUrl}pacientes/detalles?paciente_id=${paciente_id}`)
  }

  /**
   * GET
   * 
   * @param codigo_postal 
   */
  getDireccion(codigo_postal:string) {  
    return firstValueFrom(this.http.get(`${this.apiUrl}pacientes/obtener_direcciones?codigo_postal=${codigo_postal}`))
  } 

  /**
   * 
   * @param paciente_id 
   * @returns 
   */
  getDataPacientesSalesForce(paciente_id: string) {
    return firstValueFrom(this.http.get(`${this.apiUrl}pacientes/datos_paciente_salesforce?paciente_id=${paciente_id}`))
  }
  
  /**
   * 
   * @param datos 
   * @returns 
   */
  PostEditarDatosSalesForce(datos:any) {
    return firstValueFrom(
      this.http.post(`${this.apiUrl}pacientes/editar_datos_paciente_salesforce`,datos)
    )
  }
  


  _setCitaActual(cita:any): void {
    this.currentCitaSubject.next(cita)
  }

}
