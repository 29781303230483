import { LogsResponseWs } from "@app/layouts/models/user-data"

export class DeletePaciente {
    id_cita: string
    patient: string
}

export interface PacientesEspera {
    codigo: number
    data: PacientesEsperaData
    mensaje: string
}

export interface PacientesEsperaData {
    detail: string  
    info: PacientesEsperaInfo
}

export interface PacientesEsperaInfo {
    nombre: string
    usuario: string
    Websockets: {
        paciente_espera: PacientesEsperaWebSocket
    }

}

export interface PacientesEsperaWebSocket {
    cliente: string
    Logs: LogsResponseWs[]
}
