import { DataResponseResultSearch } from '@app/pages/models/response-search-result';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CalendarService } from '@app/pages/calendar/services/calendar.service';
import { SessionPageService } from '@app/pages/services/session-page.service';
import { RedirectService } from '@app/services/redirect.service';

@Component({
  selector: 'app-canvas-search-sm',
  templateUrl: './canvas-search-sm.component.html',
  styleUrls: ['./canvas-search-sm.component.scss']
})
export class CanvasSearchSMComponent implements OnInit {

  @Output()
    eventCloseCanvas : EventEmitter<any>  = new EventEmitter()
  _formSearch: FormGroup;
  _searchResult: any[] = []

  _resultSearch: DataResponseResultSearch[]

  _minLength:number = 3





  constructor(
    private fb: FormBuilder,
    private session: SessionPageService,
    private calendar: CalendarService,
    private redirect: RedirectService
    ) {}

    ngOnInit(): void {
      this._formSearch = this.fb.group({
        searchText: [null, [Validators.required, Validators.minLength(this._minLength) ]]
      })
    }


    get form () {
      return this._formSearch.controls;
    }
  

  /**
   * 
   * @param form 
   */
    _onSerach() {

      if(this._formSearch.valid) {

        this.eventCloseCanvas.emit()

  
        this.session._saveResultSearch(this._searchResult)
  
        if(this._searchResult.length == 0) {
  
          let _searchText = this._formSearch.value.searchText;       
  
          this.calendar._serchByText(_searchText).subscribe({
            next:(response) => {
              
              if(response.codigo === 200) {
  
                this._resultSearch = response.data;
  
                this.session._saveSearchResponse(this._resultSearch)
  
                this.redirect.to('/search-results',{
                  queryParams: { searchText: this.form['searchText'].value }
                })
  
              }
              
              
            },error:(error) => {
              console.error({error});
              
            }
          })
  
  
  
  
        }else {
          
  
  
     
  
        }
  
  
      }
  
  
    }

  /**
   * 
   * @param item 
   */
    _onClickSearch(item: any) {

      this._searchResult = [item]
      this.eventCloseCanvas.emit()
      
      this.session._saveResultSearch(this._searchResult)
      this.redirect.to('/search-results',{
        queryParams: { searchText: this.form['searchText'].value }
      })
    }


    _onEventSearch() {
      let searchOptions = document.getElementById("search-close-options") as HTMLAreaElement;
      let dropdown = document.getElementById("search-dropdown") as HTMLAreaElement;
      let input: any, filter: any, ul: any, li: any, a: any | undefined, i: any, txtValue: any;
      input = document.getElementById("search-text") as HTMLAreaElement;
      filter = input.value.toUpperCase();
  
      let _value = this.form['searchText'].value;
     // _value.tolo
      
      if(_value &&( _value.length >= this._minLength)) {
        dropdown.classList.add("show");
        searchOptions.classList.remove("d-none");
  
        let _filters = this.session._returnJSONSession()
  
        if(_filters) {
          this._searchResult = _filters.filter((item: any) => 
          item.Cita_Id.toLocaleUpperCase().includes(_value.toLocaleUpperCase()) ||
          item.Recurso.toLocaleUpperCase().includes(_value.toLocaleUpperCase())  ||
          item.Tipo_Cita.toLocaleUpperCase().includes(_value.toLocaleUpperCase()) ||
          item.N_Expediente.toLocaleUpperCase().includes(_value.toLocaleUpperCase()) ||
          item.Doctor.toLocaleUpperCase().includes(_value.toLocaleUpperCase()) ||
          item.Paciente_Nombre_Completo.toLocaleUpperCase().includes(_value.toLocaleUpperCase()))   
        }              
        
        
      }  
    }

    closeBtn() {
      let searchOptions = document.getElementById("search-close-options") as HTMLAreaElement;
      let dropdown = document.getElementById("search-dropdown") as HTMLAreaElement;
      let searchInputReponsive = document.getElementById("search-text") as HTMLInputElement;
      dropdown.classList.remove("show");
      searchOptions.classList.add("d-none");
      searchInputReponsive.value = "";
    }

}
