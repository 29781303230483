import { PinturaEditorComponent } from '@pqina/angular-pintura';
import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { VoiceRecognitionService } from '@app/shared/services/voice-recognition.service';
import * as _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { Subject, Subscription, interval } from 'rxjs';
import { CredentialsService } from 'src/app/core/services/credentials.service';
import { Cita } from 'src/app/pages/calendar/models/calendar';
import { FormatsService } from 'src/app/shared/services/formats.service';
import { createDefaultImageReader, createDefaultImageWriter, createDefaultShapePreprocessor, markup_editor_defaults, PinturaEditorOptions, plugin_filter_defaults, plugin_finetune_defaults } from '@pqina/pintura';
import { LocaleAnnotate, LocaleCore, LocaleCrop, LocaleFilter, LocaleFinetune, LocaleMarkupEditor } from '@pqina/pintura/locale/en_GB';
import { DomSanitizer } from '@angular/platform-browser';
import { AlertsService } from '@app/shared/services/alerts.service';
import { TokenStorageService } from '@app/core/services/token-storage.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-anestesia',
  templateUrl: './anestesia.component.html',
  styleUrls: ['./anestesia.component.scss']
})
export class AnestesiaComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild('editorRef') editorRef?: PinturaEditorComponent<any> = undefined;

  @ViewChild('svgObject')
  svgObject!: ElementRef;


  @Input()
  id: string

  @Input()
  _formatoData: any

  _controls: any[] = []
  _formato: any = {}

  _label: string
  _itsReady: boolean

  $interval!: Subscription;
  public formAnestesia: FormGroup = this.formBuilder.group({})

  _Cita: Cita;

  _BODYFORM: any;

  _ngbModalRef: NgbModalRef;
  _assetsSvg = 'assets/images/svg/AnestesioAFinal.svg'

  editorOptions = {
    utils: ['annotate'],
    
    imageReader: createDefaultImageReader({
      orientImage: true,
    }),
    imageFrame: {
      frameStyle:'nine',
      
    },
    imageWriter: createDefaultImageWriter({
      targetSize: {
        fit:'cover'
      },
      mimeType:'image/webp',
      quality: 1,
      orientImage:true,
      format:'file',
    }),
    layoutDirectionPreference: 'vertical',
    enableZoomControls: true,
    enableTapToAddText:false,
    zoomLevel: 0.75,
    enableToolbar: true,
    layoutVerticalToolbarPreference:'top',
    layoutHorizontalUtilsPreference:'top',
    layoutVerticalControlGroupsPreference:'top',
    layoutVerticalControlTabsPreference:'top',
    enableViewTool: true,
    shapePreprocessor: createDefaultShapePreprocessor(),
    
    ...plugin_finetune_defaults,
    ...plugin_filter_defaults,
    ...markup_editor_defaults,
    locale: {
      ...LocaleCore,
      ...LocaleCrop,
      ...LocaleFinetune,
      ...LocaleFilter,
      ...LocaleAnnotate,
      ...LocaleMarkupEditor,
    },
  } as PinturaEditorOptions;

  result?: string | undefined | null = undefined
  cropAspectRatio = 0;
  _showEditor: boolean

  eventSubjectClerFiles: Subject<boolean> = new Subject<boolean>();
  _Paciente: any;

  _showCustomFiles: boolean = true

  _Images: any
  _LaboratorioFiles: any[] = []

  main_label: string

  _svgfilesPng: any[] = []
  currentUser: any

  constructor(
    private formatService: FormatsService,
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    private credentials: CredentialsService,
    public VoiceS: VoiceRecognitionService,
    private alert: AlertsService,
    private sanitizer: DomSanitizer,
    private tokenService: TokenStorageService
  ) {

    //this.VoiceS.init();
    this._Paciente = this.credentials.userInfoData.data?.records[0]
    this.currentUser = this.tokenService.getUser()

    const cita = this.credentials.getCitaJson()

    if (cita) {
      this._Cita = cita
    }
  }

  ngOnInit(): void {

    if (this._formatoData) {
      this._formato = this._formatoData
      //this._controls = this._formato.form.controls;
      //console.log({_controls: this._controls});   
      //this.initForm();
      this.main_label = this._formato.label
      console.log({ ___formato___: this._formato });


      this._loadImages(this._Cita.Cita_Id)
    }

    /*     if(this.id) {
    
          this.formatService._getFormat(this.id, this._Cita.Cita_Id).subscribe({
            next:(response) => {
    
              if(response.codigo == 200) {
                this._formato = response.data.formato;
                if(this._formato.form) {
                  this._controls = this._formato.form.controls;
                  console.log({_controls: this._controls});   
                  this.initForm();
                }
              }
              
    
            },
            error:(error) => {
    
            }
          })
    
        } */
  }

  ngAfterViewInit(): void {
    /*     this.svgObject.nativeElement.addEventListener('load', () => {
          const svgDoc = this.svgObject.nativeElement.contentDocument;
          const paths = svgDoc.querySelectorAll('path');
    
          paths.forEach((path: any) => {
            path.addEventListener('click', (event: any) => {
              this.onCountryClick(event.target);
            });
          });
        }); */
  }

  handleLoad($event: any): void {
    console.log('load', $event);

    console.log('component ref', this.editorRef);

    console.log('editor instance ref', this.editorRef?.editor);

    console.log(
      'inline editor image state',
      this.editorRef?.editor?.imageState
    );
  }

  handleProcess(imageWriterResult: any) {
    console.log('process', imageWriterResult);
    this._showEditor = false
    const objectURL = URL.createObjectURL(imageWriterResult.dest);

    this.result = this.sanitizer.bypassSecurityTrustResourceUrl(
      objectURL
    ) as string;

    console.log({ objectURL, result___: this.result, dest: imageWriterResult.dest });

    this._svgfilesPng = []
    //this.eventEmmiterSaveImg.next(true)
    let _svgItems = {
      _imgSVG: imageWriterResult.dest,
      comment: ''
    }
    this._svgfilesPng.push(_svgItems)

    // this.eventEmmiterImage.next(_svgItems)
  }

  onCountryClick(target: any): void {
    console.log('Country clicked:', target.id);
    // Handle the click event, e.g., display data for the clicked country
  }

  ngOnDestroy(): void {
    if (this.$interval) {
      this.$interval.unsubscribe()
    }
    this._savePrevDestroy()
  }

  get f() {
    return this.formAnestesia.controls;
  }


  private _loadImages(Cita_Id: string) {
    this._Images = null
    this.formatService._getListImages(Cita_Id, this._Paciente.Name).subscribe({
      next: (response) => {
        if (response.codigo == 200) {
          console.log({ response });

          const data = response.data

          if (data.formato && data.formato.archivos) {
            const archivos = data.formato.archivos
            this._Images = archivos
            this._LaboratorioFiles = archivos.Laboratorio ? archivos.Laboratorio : []
            console.log({ archivos, _Images: this._Images });
          }
        }
      }
    })
  }


  private _savePrevDestroy(): void {
    let _formValues = this.formAnestesia.value
    let _body_: any[] = []

    _.map(_formValues, (a, i) => {
      _body_.push({
        name: i,
        value: a
      })
    })

    let _bodyForm = {
      Cita_Id: this._Cita.Cita_Id,
      label: this._formato.label,
      new_data: _body_
    }
    this._saveFormatValues(_bodyForm, false)
  }

  private initForm() {

    _.map(this._controls, (a) => {

      if (a.type === 'subtitle') {
        // console.log({_SUBTITLE: a});

        this.formAnestesia.addControl(
          a.name, this.formBuilder.control(a.value)
        )

      }

      _.map(a.Objects, (b) => {

        if (b.type == 'title') {
          _.map(b.Objects, (c) => {
            if (c.type == 'label') {
              _.map(c.Objects, (d) => {
                if (d.type == 'label') {
                  _.map(d.Objects, (e) => {
                    this.formAnestesia.addControl(
                      e.name, this.formBuilder.control(e.value)
                    )
                  })

                } else {
                  this.formAnestesia.addControl(
                    d.name, this.formBuilder.control(d.value)
                  )
                }
              })

            } else {
              console.log({ NOTLABEL: c });

            }
          })
        } else {
          _.map(b.Objects, (c) => {
            this.formAnestesia.addControl(
              c.name, this.formBuilder.control(c.value)
            )
          })

        }

        this.formAnestesia.addControl(
          b.name, this.formBuilder.control(b.value)
        )

      })

    })

    console.log({ formAnestesia: this.formAnestesia });


    this.createInterval()

  }

  /**
   * 
   */
  private createInterval() {
    const myObservable = interval(1000);
    this.$interval = myObservable.subscribe((integer) => {
      if (integer === 20) {
        this.$interval.unsubscribe();
        console.log('GO SAVE IT!!!!');
        //this.show = !this.show;       
        // this._onSaveIt()     
        this._newSaveFormat()

      }
      // this._interval = integer

    })
  }

  /**
   * 
   */
  _newSaveFormat(): void {

    let _formValues = this.formAnestesia.value
    let _body_: any[] = []

    _.map(_formValues, (a, i) => {
      _body_.push({
        name: i,
        value: a
      })
    })

    let _bodyForm = {
      Cita_Id: this._Cita.Cita_Id,
      label: this._formato.label,
      new_data: _body_
    }

    //  this._formatoValues = _bodyForm   
    this._saveFormatValues(_bodyForm, true)
  }

  _saveFormatValues(_jsonObject: any, createInterval: boolean = false): void {

    this.formatService._postFormato(_jsonObject).then((resp) => {

      this.toastr.info('Se ha guardado la información', 'Guardada', {
        timeOut: 3200
      }).onHidden.subscribe(() => {
        if (createInterval) {

          this.createInterval()
        }
      })
    }).catch((error) => {
      console.error({ error });

    })


  }

  private _onSaveIt() {

    let _formato = this._formato;

    _.map(_formato.form.controls, (a) => {

      _.map(a.Objects, (b) => {
        if (b.type == 'title') {
          _.map(b.Objects, (c) => {
            if (c.type == 'label') {
              _.map(c.Objects, (d) => {
                if (d.type == 'label') {
                  _.map(d.Objects, (e) => {
                    e.value = this.f[e.name].value
                  })
                } else {
                  d.value = this.f[d.name].value
                }
              })
            }
          })
        }
      })
    })

    let _bodyForm = {
      Cita_Id: this._Cita.Cita_Id,
      label: this._formato.label,
      form: _formato.form
    }
    this._BODYFORM = _bodyForm

    this.formatService._postFormato(_bodyForm).then((resp) => {
      this.toastr.info('Se ha guardado la información', 'Guardada', {
        timeOut: 3200
      })
      this.$interval.unsubscribe()
      setTimeout(() => {
        //this.createInterval()     
      }, 5000);

    }).catch((error) => {
      console.error({ error });

    })

  }

  /**
   * 
   */
  _onCheckChange(event: any, name: string) {
    let val = event.target.checked
    this.formAnestesia.controls[name].patchValue(val)
  }

  /**
   *
   *
   * @param {any[]} files
   * @param {*} obj
   * @memberof AnestesiaComponent
   */
  _onPondsEvent(files: any[], obj: any) {
    this.alert._showLoader('Subiendo, por favor espere')

    this.formatService.UploadFiles(this._Cita.Cita_Id, this._Paciente.Name, this.main_label, files).then((resp) => {
      this._loadImages(this._Cita.Cita_Id)
      this.alert._hideLoader()
      this.toastr.success('Exito al subir las imagenes', 'Exito!!', {
        timeOut: 3200
      })

      this.eventSubjectClerFiles.next(true)

      setTimeout(() => {
        this._showCustomFiles = true
      }, 300);

    }).catch((error) => {
      console.error({ error });
      this.alert._hideLoader()
      this.toastr.error('Algo salio mal, al tratar de subir las imagenes', 'Error!!', {
        timeOut: 3200
      })
    })
  }

  /**
   *
   *
   * @param {any[]} image
   * @param {*} obj
   * @memberof AnestesiaComponent
   */
  _onSaveItImages(image: any[], obj: any) {

    if (image && image.length) {
      this.alert._showLoader('Guardando imagenes, espere...')
      /* 
         this.formatService.UploadFromCamera(this._Cita.Cita_Id,this._Paciente.Name, webcamImage, this._itemLabel.label, this._itemLabel.name)
      */
      this.formatService.UploadFiles(this._Cita.Cita_Id, this._Paciente.Name, this.main_label, null, image).then((resp) => {
        this._loadImages(this._Cita.Cita_Id)
        this.alert._hideLoader()
        this.toastr.success('Exito al subir las imagenes', 'Exito!!', {
          timeOut: 3200
        })
      }).catch((error) => {
        console.error({ error });
        this.alert._hideLoader()
        this.toastr.error('Algo salio mal, al tratar de subir las imagenes', 'Error!!', {
          timeOut: 3200
        })
      })
    }

  }

  _onEventDeleteModel(event: any, laboratorio: string = 'false') {
    Swal.fire(({
      title: 'Por favor confirme esta accion',
      text: '¿Desea eliminar este elemento?',
      showCancelButton: true,
      icon: 'question',
      cancelButtonText: 'No, cancelar',
      confirmButtonText: 'Si, continuar',

    })).then((result) => {
      if (result.value) {
       // console.log({ ___event___: event, _Cita___: this._Cita, _Paciente: this._Paciente, __ITEM__: item });
        this.alert._showLoader('Borrando, por favor espere...')

        this.formatService._PostdeleteImage({ user: null, filename: event.filename, cita_id: this._Cita.Cita_Id, label: null, laboratorio: laboratorio, paciente_id: this._Cita.N_Expediente, main_label: this.main_label }).then((resp) => {
          console.log({ resp });
          this.alert._hideLoader()
          this._loadImages(this._Cita.Cita_Id)

        }).catch((error) => {
          console.error({ error });
          this.alert._hideLoader()
          this.toastr.error('Ocurrio un error al tratar de borrar este elemento', 'Error', {
            timeOut: 3200,
            progressBar: true
          })
        })

      }
    })
  }

  /* 
  * @param text 
  * @param control 
  */
  _onEventEmmiterValue(text: string, control: any) {
    console.log({ _TEXT________: text, control });
    this.f[control.name].patchValue(text)
  }

  /**
   *
   *
   * @memberof AnestesiaComponent
   */
  _onSaveFile() {
    this.alert._showLoader('Guardando por favor espere...')
    this.formatService.UploadFiles(this._Cita.Cita_Id, this._Paciente.Name, this.main_label, null, null, this._svgfilesPng).then((resp) => {
      console.log({ resp });
      this.alert._hideLoader()
      this._loadImages(this._Cita.Cita_Id)
      this._svgfilesPng = []
      this.result = null 

    }).catch((error) => {
      console.error({ error });
      this.alert._hideLoader()
    })

  }

}
