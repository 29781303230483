import { Component, HostListener, TemplateRef, ViewChild } from '@angular/core';
import { PatientsService } from '../../calendar/services/patients.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { Patients } from '@app/shared/models/Patients';

const _SET_INDEX_ = '_pacientes_set_index_'
const _CURRENT_PAGe = '_pacientes_current_page'

@Component({
  selector: 'app-listado-pacientes',
  templateUrl: './listado-pacientes.component.html',
  styleUrls: ['./listado-pacientes.component.scss']
})
export class ListadoPacientesComponent {

  @ViewChild('modalPatient') modalPatient!: TemplateRef<any>;
  @ViewChild('modalDetailPatient') modalDetailPatient!: TemplateRef<any>

  _listadoPacientes: Patients[] = []

  _ngModal: NgbModalRef;

  _ngbModal: NgbModalRef

  _paciente_id: string
  _spinner = '_spinner_'

  searchText: string
  _patientSelected: Patients;

  _set_index = 0

  totalCount:   number = 0
  currentPage:  number = 1
  pageSize:     number = 100
  totalPages:   number = 1

  
  page = 1

  isLoading: boolean = false

  constructor(
    private patient: PatientsService,    
    private spinner: NgxSpinnerService,
    private modalService: NgbModal
  ) {

    let _setIndex = sessionStorage.getItem(_SET_INDEX_)

    if(_setIndex) {
      let _CURRENT_PAGe_ = sessionStorage.getItem(_CURRENT_PAGe)

      this._set_index = Number(_setIndex)
      this.currentPage = _CURRENT_PAGe_ ? Number(_CURRENT_PAGe_) : 1
    }


    this.getPacientes()
  }

  @HostListener('window:scroll',['$event'])
  onWindowScroll(event:any){    
    if(window.innerHeight+window.scrollY>=document.body.offsetHeight&&!this.isLoading){ 
      console.log(event);
      this.page ++
      this._set_index = (this.page * this.pageSize)
      sessionStorage.setItem(_SET_INDEX_,`${this._set_index}`)
      this.getPacientes()
    }
  }


  private getPacientes() {
    this.isLoading = true
    this.spinner.show(this._spinner)
    this.patient._getPatientInfo('true', this._set_index).subscribe({
      next: (resp: any) => {
        this.spinner.hide(this._spinner)
        if (resp && resp.codigo === 200) {
          this._listadoPacientes = resp.data.records
          this.totalCount = resp.data.totalSize

          this.totalPages = Math.ceil(this.totalCount / this.pageSize)
          console.log({ _PACIENTE___: this._listadoPacientes });
          this.isLoading = false

        }
      }, error: (error) => {
        console.error({ error });

        this.spinner.hide(this._spinner)
      }
    })
  }


  /**
   * 
   * @param _paciente 
   */
  _onEdit(_paciente: any) {

    this._paciente_id = _paciente.Name;

    this._ngModal = this.modalService.open(this.modalPatient, {
      size: 'xl',
      animation: true,
      backdrop: 'static',
      fullscreen: 'lg'
    })

  }

  closeEventModal() {
    this._ngModal.close()
  }

  onClickEvent(event: boolean) {
    if (event) {
      this._ngModal.close()
    }
  }


  _onEventReload(event: boolean) {
    if (event) {
      this.closeEventModal()
      this.getPacientes()
    }
  }
  
  _openModalPatient(_paciente: Patients) {

    this._patientSelected = _paciente
    
    this._ngbModal = this.modalService.open(this.modalDetailPatient, {
      size: 'xl',
      animation: true,
      backdrop: 'static',
      fullscreen: 'lg'
    })

  }

/*   _onPageChange(page: number) {
    sessionStorage.setItem(_CURRENT_PAGe, `${page}`)

   // const startItem = (page - 1) * this.pageSize;
  
    const endItem = page * this.pageSize;
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    this._set_index = endItem
    sessionStorage.setItem(_SET_INDEX_,`${this._set_index}`)
    
    this.getPacientes()    
  } */

}
