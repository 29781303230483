<app-breadcrumbs title="Listado de pacientes"></app-breadcrumbs>

<div class="mt-4 d-none d-lg-block">
    <div class="card card-body">
        <div class="row row-cols-1 mt-3">
            <div class="mb-3">
                <label class="form-label">Buscar por nombre/paciente </label>
                <input type="text" class="form-control" [(ngModel)]="searchText">
            </div>
        </div>
       <!--  <ngx-simplebar class="sidebar-menu-scroll" style="max-height: calc(100vh - 30px)"> -->
        <div class="table-responsive">
            <table class="table table-hover table-sm table-nowrap">
                <thead class="text-center bg-light text-uppercase" >
                    <th scope="col">#</th>
                    <th scope="col">Paciente</th>
                    <th scope="col">Nombre completo</th>    
                    <th scope="col">Teléfono</th>
                    <th scope="col">Correo</th>
                    <th scope="col">Direccion</th>
                    <th scope="col"></th>                    
                </thead>
                <tbody >
                    <tr *ngFor="let _paciente of _listadoPacientes | buscadorPacientes: searchText ; let i = index;">
                        <th scope="row"> {{i + 1}} </th>
                        <td> {{_paciente.Name}} </td>
                        <td> {{_paciente.Nombre_completo__c}} </td>
                
                        <td> {{_paciente.P05_Telefono__c | mask:'(000) 000 0000' }} </td>
                        <td> {{_paciente.P05_Correo__c}} </td>  
                        <td> <span class="text-wrap"> {{_paciente.DireccionCompleta__c}}  </span>  </td>       
                        <td> 
                            <div class="hstack gap-3">                                
                                <button class="btn btn-secondary btn-sm" (click)="_onEdit(_paciente)">Editar paciente</button>

                                <button class="btn btn-icon" (click)="_openModalPatient(_paciente)" data-bs-toggle="tooltip" data-bs-placement="top" title="Ver detalles candidato"> 
                                    <i class="mdi mdi-clipboard-account  align-middle me-1 fw-bold fs-2"></i> 
                                </button>
                            </div>
                        </td>                                         
                    </tr>    
                </tbody>
            </table>
            <ng-template [ngTemplateOutlet]="filterPage"></ng-template>
        </div>
        <!-- </ngx-simplebar> -->
    </div>
</div>

<div class="mt-4 d-block d-lg-none">
    <div class="row row-cols-1 mt-3">
        <div class="mb-3">
            <label class="form-label">Buscar por nombre</label>
            <input type="text" class="form-control" [(ngModel)]="searchText">
        </div>
    </div>
    <div class="row row-cols-xxl-5 row-cols-lg-3 row-cols-md-2 row-cols-1">
        <div class="col" *ngFor="let _paciente of _listadoPacientes | buscadorPacientes: searchText; let i = index ">
            <div class="card mt-2 shadow-lg p-3 rounded">
                <div class="card-body">
                    <div class="d-md-flex align-items-center">   
                        <div class="ms-lg-3 my-3 my-lg-0">
                            <h5 class="fs-16 mb-2"> {{_paciente.Name}}</h5>
                            <p class="fw-bold text-primarymb-0">{{_paciente.Nombre_completo__c}} </p>
                                               
                        </div>   
                    </div>
                    <div class="d-flex gap-4 mt-0 text-muted mx-auto fs-12">
                        <p class="text-wrap fw-bold">Teléfono</p>
                        <div class="d-flex mb-0 justify-content-center">
                            <span>{{_paciente.P05_Telefono__c | mask:'(000) 000 0000' }}</span>                            
                        </div>                      
                    </div>
                    <div class="d-flex gap-4 mt-0 text-muted mx-auto fs-12">
                        <p class="text-wrap  fw-bold"> Correo </p>
                        <div class="d-flex mb-0 justify-content-center">
                            <span> {{_paciente.P05_Correo__c}} </span>                            
                        </div>                      
                    </div>
                    <div class="d-flex gap-4 mt-0 text-muted mx-auto fs-12">
                        <p class="text-wrap  fw-bold"> Direccion </p>
                        <div class="d-flex mb-0 justify-content-center">
                            <span> {{_paciente.DireccionCompleta__c}}</span>                            
                        </div>                      
                    </div>                 
                    <div class="d-flex mt-4 justify-content-center ">
                        <div class="hstack gap-3">
                            <button class="btn btn-primary btn-sm text-uppercase" (click)="_onEdit(_paciente)">Editar paciente</button>
                        </div>
                    </div>
                </div>            
            </div>
        </div>
    </div>
    <ng-template [ngTemplateOutlet]="filterPage"></ng-template>
</div>

<ng-template #filterPage>
    <div class="m-2 p-1">
        <div class="row justify-content-md-between align-items-md-center"
            *ngIf="_listadoPacientes && _listadoPacientes.length">
            <div class="col-sm-12 col-md-5">
                <div class="dataTables_info mb-2" id="tickets-table_info" role="status" aria-live="polite">
                    Pagina {{currentPage}} de {{totalPages}} <small class="fw-bold">
                        {{totalCount}} registros </small>
                </div>
            </div>
            <!-- Pagination -->
           <!--  <div class="col-sm-12 col-md-5">
                <div class="text-md-right float-md-end gridjs-pagination">
                    <ngb-pagination [collectionSize]="totalCount" [(page)]="currentPage" [maxSize]="4"
                        [boundaryLinks]="true" [pageSize]="pageSize" (pageChange)="_onPageChange($event)">
                    </ngb-pagination>
                </div>
            </div> -->
            <!-- End Pagination -->
        </div>
    </div>
</ng-template>

<ng-template #modalPatient let-modal>
    <div class="modal-header p-3 bg-primary-subtle">
        <h5 class="modal-title" id="modal-title">Editar datos de paciente </h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-hidden="true"
            (click)="closeEventModal()">
        </button>
    </div> 

    <div class="modal-body p-4">
        <app-create-patient 
        (eventClickCancel)="onClickEvent($event)" 
        (eventReloadCandidates)="_onEventReload($event)"
        [_paciente_id]="_paciente_id" 
        [editable]="true">
    </app-create-patient>
    </div>
</ng-template>

<ng-template #modalDetailPatient let-modal>
    <div class="modal-header p-3 bg-primary-subtle">
        <h5 class="modal-title" id="modal-title">Informacion de paciente </h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-hidden="true"
            (click)="_ngbModal.close()">
        </button>
    </div> 
    <div class="modal-body p-4">
        <app-modal-details-patient [_patient]="_patientSelected" ></app-modal-details-patient>
    </div>
</ng-template>

<ngx-spinner [name]="_spinner" type="ball-scale-multiple" [fullScreen]="false"  ></ngx-spinner>

