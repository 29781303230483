import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

import flatpickr from 'flatpickr';
import { Spanish } from 'flatpickr/dist/l10n/es.js';
import * as _ from 'lodash';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { timeout } from 'rxjs';
import { CredentialsService } from 'src/app/core/services/credentials.service';
import { CalificacionCandidato, CalificacionVarices, CandidatoData } from 'src/app/pages/calendar/models/candidatos';
import { DataEmpresaOdoo } from 'src/app/pages/calendar/models/empresa-odoo';
import { CalendarService } from 'src/app/pages/calendar/services/calendar.service';
import { CandidatosService } from 'src/app/pages/calendar/services/candidatos.service';
import { PatientsService } from 'src/app/pages/calendar/services/patients.service';
import { empleadoResponsable } from 'src/app/pages/models/lista-empleados-responsable';
import { Recurso } from 'src/app/pages/models/recursos';
import { CreatePatients } from 'src/app/shared/models/create-patients';
import { OrigenPaciente } from 'src/app/shared/models/origen-paciente';
import { AlertsService } from 'src/app/shared/services/alerts.service';

const _SET_INDEX_ = '_set_index_'


@Component({
  selector: 'app-create-patient',
  templateUrl: './create-patient.component.html',
  styleUrls: ['./create-patient.component.scss']
})
export class CreatePatientComponent implements OnInit {

  @Input()
  editable: boolean = false
  @Input()
  _paciente_id: string | null
  @Input()
  _candidato!: CandidatoData;
  @Input()
  _reloadCanidates: boolean = false

  @Output()
  eventClickCancel: EventEmitter<boolean> = new EventEmitter();
  @Output()
  eventReloadCandidates: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  eventConfirmCandidate: EventEmitter<any> = new EventEmitter<any>()


  _calificacionCandidato: CalificacionCandidato = new CalificacionCandidato()
  _calificacionVarices: CalificacionVarices = new CalificacionVarices()


  _sex = ['Mujer', 'Hombre', 'Pendiente']
  _candidatoCirugia = ['SI', 'NO', 'Pendiente']
  _estadoCivil = ['--None--', 'Casado', 'Divorciado', 'Soltero', 'Union libre', 'Viudo']
  _actividadPaciente = ['--None--', 'Paciente Creado', 'Cita Agendada', 'Cita Confirmada', 'Cita Exitosa', 'Cirugía Exitosa']
  _antiguedad = ['--None--', 'SI', 'NO']
  _recursos: Recurso[] = []

  _origenPaciente: OrigenPaciente[] = []
  _especialidad: any[] = []

  _sgmm: any[] = []


  _state: any[] = []
  _municipalitie: any[] = []

  _callCenter: any[] = []

  _seguroMedico: any[] = []
  _objecion: any[] = []
  _metodo_contacto: any[] = []
  _paiese: any[] = []
  _estados: any[] = []

  _colonias: any[] = []

  _responsable_venta: any[] = []
  _paciente_recomienda: any[] = []

  _empresasOdoo: DataEmpresaOdoo[]


  _formPaciente: FormGroup;
  _centro_medico_select: any;

  _Paciente: CreatePatients = new CreatePatients();
  _loader: boolean;

  _recursoSelected: Recurso;

  _listaResponsables: empleadoResponsable[] = []

  spinnerUpdate = 'spinnerUpdate'

  _listadoCandidatos: CandidatoData[]
  _candidatoSelect: CandidatoData;
  _loaderCandidatos: boolean

  _show_datos_ubicacion_: boolean = true

  _id_paciente: string
  _Candidato_Id: string

  _ngModalRef: NgbModalRef;
  _ngModalCitaRef: NgbModalRef;

  _paciente_cita_id: string | null  //= 'a0Bcc000000ApAvEAK'


  _set_index = 0

  _istReady: boolean = false


  constructor(
    private calendar: CalendarService,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private alert: AlertsService,
    private patients: PatientsService,
    private spinner: NgxSpinnerService,
    private candiatos: CandidatosService,
    private credentials: CredentialsService,
    private modal: NgbModal
  ) {
    flatpickr.localize(Spanish)
    if (this.credentials.credentials?.roles.Callcenter) {
      this._show_datos_ubicacion_ = false
    }
  }

  ngOnInit(): void {
    this._istReady = false
    this.initForm()

    this._loadCandidatos()

    /**
     * Obtener los recursos
     */
    this.calendar.GetRecursoPacientes('true').subscribe({
      next: (response: any) => {
        if (response.codigo == 200) {
          this._recursos = response.data;
        }
      }, error: (error) => {
        console.error({ error });

      }
    })

    /**
     * Obtener el origen de los pacientes
     */
    this.calendar._getOrigenPaciente().subscribe({
      next: (response) => {
        if (response.codigo == 200) {
          this._origenPaciente = response.data[0].origen_del_paciente;


        }
      },
      error: (error) => {
        console.error({ error });

      }
    })

    /**
     * Obtener las especialidades
     */
    this.calendar._getEspecialidades('paciente').subscribe({
      next: (response) => {
        if (response.codigo == 200) {
          this._especialidad = response.data //[0].especialidades        

        }
      },
      error: (error) => {

      }
    })

    /**
     * Obtener los seguros medicos
     */
    this.calendar._getSeguroMedico().subscribe({
      next: (response: any) => {
        if (response.codigo == 200) {
          this._seguroMedico = response.data[0].seguro_gastos_medicos_mayores;


        }
      }, error: (error: any) => {


      }
    })


    /**
     * Obtener las  objeciones
     */

    this.calendar._getObjeciones().subscribe({
      next: (response: any) => {
        if (response.codigo == 200) {
          this._objecion = response.data[0].objeciones;
        }
      },
      error: (response: any) => {

      }
    })

    /**
     * Obtener los metodos de Contacto
     */
    this.calendar._getMetodosContacto().subscribe({
      next: (response: any) => {
        if (response.codigo == 200) {
          this._metodo_contacto = response.data[0].metodo_contacto;


        }
      }
    })

    /**
     * Obtener los paises
     */
    this.calendar._getPais().subscribe({
      next: (response: any) => {
        if (response.codigo == 200) {
          this._paiese = response.data[0].pais


        }
      }
    })

    /**
     * Obtener los Estados
     */
    this.calendar._getEstados().subscribe({
      next: (response: any) => {
        if (response.codigo == 200) {
          this._estados = response.data[0].estados


        }
      }
    })

    /**
     * 
     */
    this.patients._getListaEmpleadosResponsable().subscribe({
      next: (response) => {
        if (response.codigo == 200) {
          this._listaResponsables = response.data;
          //this._paciente_recomienda = response.data
        }
      }, error: (error) => {
        console.error({ error });

      }
    })

    this.patients._getPatientInfo('true').pipe(timeout(60000)).subscribe({
      next: (response: any) => {
        if (response.codigo == 200) {

          this._paciente_recomienda = response.data.records;

          // console.log({paitents: response});
        }
      },
      error: (error) => {
        console.error({ error });
      }
    })

    /**
     * 
     */
    this.calendar._getSeguroMedico().subscribe({
      next: (response: any) => {
        if (response.codigo == 200) {
          this._sgmm = response.data[0].seguro_gastos_medicos_mayores
        }
      },
      error(err) {
        console.error({ err });

      },
    })

    /**
     * 
     */
    this.calendar._getOdooEmpresa().subscribe({
      next: (response) => {
        if (response.codigo == 200) {

          this._empresasOdoo = response.data

        }
      },
      error(err) {
        console.error({ err });

      },
    })

  }

  get form() {
    return this._formPaciente.controls;
  }

  /**
   * 
   */
  private _loadCandidatos(): void {

    this._loaderCandidatos = true

    this.candiatos.ObtenerListadoCanidatos('true', this._set_index).subscribe({
      next: (response) => {
        this._loaderCandidatos = false
        if (response.codigo === 200) {
          this._listadoCandidatos = response.data.candidatos
        }
      },
      error: (err) => {
        this._loaderCandidatos = false
        console.error({ err });
      },
    })
  }

  /**
   * 
   */
  private initForm() {
    this._formPaciente = this.fb.group({

      fecha_nacimiento: [null, [Validators.required]],
      edad: [null],
      sexo: [null, [Validators.required]],
      correo: ['', [Validators.email]],
      telefono: [null, [Validators.required]],
      estado_civil: [null],
      sgmm: [null],
      compañia_ssgm: [''],

      //correo:[null],
      pais: [null],
      estado: [null],
      municipio: [null],
      municipio_otro: [null],
      codigo_postal: [null, [Validators.minLength(5)]],
      calle: [null],
      colonia: [null],
      colonia_otro: [null],
      num_exterior: [null],
      num_interior: [null],

      candidato: [null],
      odoo_api: [null, [Validators.required]],
      centro_medico: [null, [Validators.required]],
      nombre: [null, [Validators.required]],
      apellidos: [null, [Validators.required]],
      estado_paciente: [null],
      ultima_actividad_reporte: [null],
      referencia_numero_paciente: [null],
      mes_ultima_cirugia: [null],
      mes_penultima_cirugia: [null],
      mes_antepenultima_cirugia: [null],
      responsable_call_center: [null],
      candidato_cirugia: [null, [Validators.required]],
      objecion: [null],
      cumple_hoy: [null],
      seguro_antiguedad: [null],
      responsable_marketing: [null],
      responsable_venta: [null],

      origen_paciente: [null, [Validators.required]],
      paciente_recomienda: [null],
      origen_paciente_historico: [null],
      motivo_consulta: [null],
      especialidad: [null, [Validators.required]],
      antiguedad_mayor_2: [null],
      metodo_contacto: [null, [Validators.required]],
      nombre_contacto_emergencias: [null],
      telefono_contacto_emergencias: [null],
      comentarioinformation: [null],

      razon_social: [null],
      correo_facturacion: [null],
      rfc: [null],
      //motivo_consulta:[null],
      misma_direccion_paciente: [null],
      numero_tarjeta: [null],
      direccion_facturacion: [null],
      numero_poliza: [null],
      deducible: [null],
      coaseguro: [null],
      // comentario:[null],

      campaña: [null],
      nombre_conjunto_anuncios: [null],
      palabra_clave: [null],
      costo_resultado: [null],
      tasa_conversion: [null],
      id_anuncio: [null],
      id_cuenta: [null]
    })

    this.patchValue()
  }

  private async patchValue() {

    if (this.editable && this._paciente_id != null) {
      this.spinner.show(this.spinnerUpdate)
      this.patients._getPacienteDetalle(this._paciente_id).subscribe({
        next: (response: any) => {
          this.spinner.hide(this.spinnerUpdate)
          this._istReady = true
          if (response.codigo == 200) {

            if (response.data && response.data.length) {
              const error = response.data[0]
              this.toastr.error(`${error.error}`, `${error.details}`)

              return this.eventClickCancel.emit(true)
            }

            const _data = response.data.Datos_Paciente
            this._id_paciente = response.data.Salesforce_Id;

            const _candidato_id = response.data.Candidato_Id
            this._Candidato_Id = _candidato_id

            let _jsoData: any = {}

            //if(_data.relaciones) {
            _jsoData = {
              ..._data.relaciones,
              ..._data.direccion,
              ..._data.generales,
              ..._data.informacion
            }

            this._recursoSelected = _data.relaciones.centro_medico

            if (_data.informacion.especialidad === 'Proctología' || _data.informacion.especialidad === 'Coloproctología') {
              this._getCandidaoResultado(_candidato_id)
            }

            if (
              _data.informacion.especialidad === 'Varices' ||
              _data.informacion.especialidad === 'Várices' ||
              _data.informacion.especialidad === 'Varices avanzadas'
            ) {
              this._getCalificacionVarices(_candidato_id)
            }

            if (_data.direccion && _data.direccion.codigo_postal) {
              this.getDataByPostalCode(_data.direccion.codigo_postal)
            }

            this._formPaciente.patchValue(_jsoData)

            this._formPaciente.patchValue({
              centro_medico: _data.relaciones.centro_medico.Id,
              odoo_api: _data.relaciones.odoo_api.id_empresa ? Number(_data.relaciones.odoo_api.id_empresa) : null
            })
          }
    

        },
        error: (err) => {
          console.error({ ERROR: err });
          this.spinner.hide(this.spinnerUpdate)
        },
      })
    } else if (this._candidato) {


      this.spinner.show(this.spinnerUpdate)
      await this._setValue()

      this._istReady = true
      this.spinner.hide(this.spinnerUpdate)
      console.log({ _formPaciente: this._formPaciente });


    }
  }
  

  private async _setValue() {
    const _id_candidato = this._candidato.Candidato_Id
    this._Candidato_Id = _id_candidato

    let _jsoData: any = {}
    _jsoData = {
      ...this._candidato.Datos_Candidato.relaciones,
      ...this._candidato.Datos_Candidato.direccion,
      ...this._candidato.Datos_Candidato.generales,
      ...this._candidato.Datos_Candidato.informacion
    }

    this._formPaciente.patchValue(_jsoData)

    this._recursoSelected = this._candidato.Datos_Candidato.relaciones.centro_medico

    if (this._candidato.Datos_Candidato.informacion.especialidad === 'Proctología' || this._candidato.Datos_Candidato.informacion.especialidad === 'Coloproctología') {
      await this._getCandidaoResultado(_id_candidato)
    }

    if (
      this._candidato.Datos_Candidato.informacion.especialidad === 'Varices' ||
      this._candidato.Datos_Candidato.informacion.especialidad === 'Várices' ||
      this._candidato.Datos_Candidato.informacion.especialidad === 'Varices avanzadas'
    ) {
      await this._getCalificacionVarices(_id_candidato)
    }

    if (this._candidato.Datos_Candidato.direccion && this._candidato.Datos_Candidato.direccion.codigo_postal) {
      await this.getDataByPostalCode(this._candidato.Datos_Candidato.direccion.codigo_postal)
    }

    this._formPaciente.patchValue({
      centro_medico: this._candidato.Datos_Candidato.relaciones.centro_medico.Id
    })

  }

  /**
   * 
   * @param _candidato_id 
   */
  private async _getCandidaoResultado(_candidato_id: string) {
    this.alert._showLoader('Consultando calificacion, paciente')

    await this.candiatos._consultarCalifPaciente(_candidato_id).subscribe({
      next: (value: any) => {
        if (value && value.codigo === 200) {
          this.alert._hideLoader()
          value.data.candidato_id = this._id_paciente
          this._calificacionCandidato = value.data;
        }
      },
      error: (err) => {
        this.alert._hideLoader()
        console.error({ err });
      },
    })
  }

  private async _getCalificacionVarices(_cadidato_id: string) {
    this.alert._showLoader('Consultando calificacion, paciente')
    await this.candiatos._consultarCalificacionVarices(_cadidato_id).subscribe({
      next: (value: any) => {

        if (value && value.codigo === 200) {
          this.alert._hideLoader()
          value.data.candidato_id = _cadidato_id
          this._calificacionVarices = value.data;
        }
      },
      error: (err) => {
        this.alert._hideLoader()
        console.error({ err });
      },
    })
  }

  /**
   * 
   * @param vali 
   * @param value 
   */
  onSubmit() {

    if (this._formPaciente.valid) {
      this.spinner.show(this.spinnerUpdate)

      this._loader = true

      this._Paciente.generales = {
        fecha_nacimiento: moment(this.form['fecha_nacimiento'].value).format('yyyy-MM-DD'),  // new Date().toISOString(),
        sexo: this.form['sexo'].value, // _.result(_.find(this._sex, (a: any) => {return a.id ==  } ), 'description'),

        compañia_ssgm: this.form['compañia_ssgm'].value,
        correo: this.form['correo'].value,
        edad: this.form['edad'].value,
        estado_civil: this.form['estado_civil'].value,
        sgmm: this.form['sgmm'].value,
        telefono: this.form['telefono'].value
      }

      this._Paciente.relaciones = {
        nombre: this.form['nombre'].value,
        apellidos: this.form['apellidos'].value,
        candidato_cirugia: this.form['candidato_cirugia'].value,
        centro_medico: {
          Id: this._recursoSelected.Id,
          Name: this._recursoSelected.Name
        },

        odoo_api: {
          id_empresa: `${this.form['odoo_api'].value}`
        },


        candidato: this.form['candidato'].value,

        cumple_hoy: this.form['cumple_hoy'].value,
        estado: this.form['estado'].value,
        mes_antepenultima_cirugia: this.form['mes_antepenultima_cirugia'].value,
        mes_penultima_cirugia: this.form['mes_penultima_cirugia'].value,
        mes_ultima_cirugia: this.form['mes_ultima_cirugia'].value,
        objecion: this.form['objecion'].value,
        referencia_numero_paciente: this.form['referencia_numero_paciente'].value,
        responsable_call_center: this.form['responsable_call_center'].value,
        responsable_marketing: this.form['responsable_marketing'].value,
        responsable_venta: this.form['responsable_venta'].value,
        seguro_antiguedad: this.form['seguro_antiguedad'].value,
        ultima_actividad_reporte: this.form['ultima_actividad_reporte'].value
      }

      this._Paciente.informacion = {
        especialidad: this.form['especialidad'].value,
        metodo_contacto: this.form['metodo_contacto'].value,
        origen_paciente: this.form['origen_paciente'].value,
        antiguedad_mayor_2: this.form['antiguedad_mayor_2'].value,
        comentario: this.form['comentarioinformation'].value,
        motivo_consulta: this.form['motivo_consulta'].value,
        nombre_contacto_emergencias: this.form['nombre_contacto_emergencias'].value,
        origen_paciente_historico: this.form['origen_paciente_historico'].value,
        paciente_recomienda: this.form['paciente_recomienda'].value,
        telefono_contacto_emergencias: this.form['telefono_contacto_emergencias'].value
      }

      this._Paciente.direccion = {
        pais: this.form['pais'].value,
        estado: this.form['estado'].value,
        municipio: this.form['municipio'].value,
        codigo_postal: this.form['codigo_postal'].value,
        calle: this.form['calle'].value,
        colonia: this.form['colonia'].value === 'OTRO' ? this.form['colonia_otro'].value : this.form['colonia'].value,
        num_exterior: this.form['num_exterior'].value,
        num_interior: this.form['num_interior'].value,
      }
      /* 
            this._Paciente.facturacion = {
              razon_social: this.form['razon_social'].value,
              correo_facturacion: this.form['correo_facturacion'].value,
              rfc: this.form['rfc'].value,
              misma_direccion_paciente: this.form['misma_direccion_paciente'].value,
              numero_tarjeta: this.form['numero_tarjeta'].value,
              direccion_facturacion: this.form['direccion_facturacion'].value,
              numero_poliza: this.form['numero_poliza'].value,
              deducible: this.form['deducible'].value,
              coaseguro: this.form['coaseguro'].value,
              //comentario
              //motivo_consulta
            } */

      /*     this._Paciente.marketing = {
            
            campaña: this.form['campaña'].value,
            nombre_conjunto_anuncios: this.form['nombre_conjunto_anuncios'].value,
            palabra_clave: this.form['palabra_clave'].value,
            costo_resultado: this.form['costo_resultado'].value,
            tasa_conversion: this.form['tasa_conversion'].value,
            id_anuncio: this.form['id_anuncio'].value,
            id_cuenta: this.form['id_cuenta'].value,
    
          } */


      if (this.editable && this._paciente_id != null) {

        delete this._Paciente.name

        /**
         * // TODO
         * integrar servicio para actualizacion de paciente
         * Actualizamos al Paciente
         */

        //  this._Paciente.paciente_id = 
        this._Paciente.Salesforce_Id = this._id_paciente
        this._Paciente.name = this.form['nombre'].value


        this.UpdatePaciente()

      } else if (this._candidato) {
        this._Paciente.Candidato_Id = this._candidato.Candidato_Id;
        this.CreatePaciente()
      } else {
        this.CreatePaciente()
      }

    } else {

      let _errors = this._formPaciente.controls
      let totalErrors = 0;

      let _msg = `Por favor revise los campos en Rojo <br> <ul> `

      Object.keys(_errors).forEach(key => {
        let _controls = this._formPaciente.get(key)?.errors
        if (_controls && _controls != null) {
          const controlErrors: ValidationErrors = _controls;
          totalErrors++;
          Object.keys(controlErrors).forEach(keyError => {
            _msg += `<li> ${key} - ${keyError} </li>`
          });
        }
      })
      _msg += `</ul>`;

      this.toastr.error(`${_msg}`, 'Campos faltantes', {
        timeOut: 15000,
        enableHtml: true,
        positionClass: 'toast-bottom-center'
      })

      this._formPaciente.markAllAsTouched()
      this._formPaciente.markAsDirty()
    }

  }

  /**
   * 
   */
  private UpdatePaciente() {

    this.patients.PostEditarDatosSalesForce(this._Paciente).then((resp) => {
      this.alert._hideLoader()

      this.toastr.success('Se han enviado, los datos correctamente', 'Exito!!', {
        timeOut: 3200
      })

      this.spinner.hide(this.spinnerUpdate)

      this.eventReloadCandidates.emit(true)

    }).catch((error) => {
      console.error({ error });
      this.alert._hideLoader()

      this.toastr.error('Ocurrio un error al tratar de guardar, los datos en salesForce', 'Error!!', {
        timeOut: 3200
      })
    })
  }

  /**
   * 
   */
  private CreatePaciente(): void {
    this.calendar._CreatePatient(this._Paciente).then((response: any) => {

      //let _id_paciente: string = '' 

      if (response.codigo == 200) {
        // _id_paciente = response.data.Paciente_Id
        //this._paciente_cita_id =  response.data.Salesforce_Id        
      }

      this.spinner.hide(this.spinnerUpdate)

      this.eventConfirmCandidate.next(response.data)

      /*  
       this._loader = false;      
 
       this.toastr.success(`El registro se ha relizado correctamente. <br> <b> ID_PACIENTE:  ${_id_paciente} </b>`,'Exito al crear',{
         tapToDismiss: true,
         enableHtml:true,
         timeOut: 15000            
       })          
 
 
     
       this._loadCandidatos()
 
       if(this._reloadCanidates) {
         this.eventReloadCandidates.emit(true)
       }
 
       this._formPaciente.reset();
       this._formPaciente.clearValidators()
       this._formPaciente.markAsUntouched() */

      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;



    }).catch((error: any) => {
      this.spinner.hide(this.spinnerUpdate)
      console.error({ error });
      this._loader = false
      this.toastr.error('Hubo un error al intentar guardar el registro del paciente nuevo, por favor intente de nuevo', 'Error al guardar', {
        timeOut: 5000
      })

    })
  }

  /**
   * 
   * @param event 
   */
  onBlur(event: any) {
    console.log({ event });

  }

  /**
   * 
   */
  _onChangeSelected(_recurso: Recurso) {
    this._recursoSelected = _recurso

  }

  /**
   * 
   * @param event 
   */
  onCahnge(event: any) {

    setTimeout(() => {
      let _birthday = this.form['fecha_nacimiento'].value;
      let years_ = moment().diff(_birthday, 'years', false);
      this.form['edad'].setValue(years_)
      this._formPaciente.patchValue({
        edad: Number(years_)
      })
    }, 300);

  }

  onCancel() {
    this.eventClickCancel.emit(true)
  }

  /**
   * 
   */
  _onChangeCandidato(): void {
    if (!(this._candidatoSelect != null)) {

      this._formPaciente.reset();
      this._formPaciente.clearValidators()
      this._formPaciente.markAsUntouched()

    } else {
      this._candidato = this._candidatoSelect

      this.patchValue()
    }

  }

  /**
   * 
   * @param event 
   */
  onKeyPress(event: any) {
    /* let _b = (this.form['codigo_postal'].value).length */
    if (event === 13 && this.form['codigo_postal'].valid) {

      this.alert._showLoader('Espere...')
      this.getDataByPostalCode(this.form['codigo_postal'].value)
    }
  }

  /**
   * 
   */
  private async getDataByPostalCode(value: string) {
    await this.patients.getDireccion(value).then((resp: any) => {
      if (resp.codigo === 200) {
        const data = resp.data;

        let _colonias: any[] = []

        _.map(data, (a, i: number) => {

          if (i === 0) {
            _colonias.push({
              colonia: 'OTRO', //"": "Valle San Francisco",
              estado: a.estado, //"": "Sonora",
              ciudad: a.ciudad, //"": "Hermosillo",
              municipio: a.municipio, //"": 
            })
          }
          _colonias.push(a)
        })

        this._colonias = [..._colonias]

        let _municipios: string[] = ['Otro']
        _.map(data, (a, i: number) => {
          if (i === 0) {
            this.form['pais'].patchValue('México')
            this.form['estado'].patchValue(a.estado)
            this.form['municipio'].patchValue(a.municipio)
            _municipios.push(a.municipio)
          }
        })
        this._municipalitie = [..._municipios]
      }
      this.alert._hideLoader()
    }).catch((error) => {
      this.alert._hideLoader()
      console.error({ error });
      this.toastr.error('Error al obtener los datos, del codigo postal', 'Error', {
        timeOut: 3200
      })
    })
  }

  /**
   * 
   * @param value 
   */
  _onChangePostalCode(value: string) {
    const _length = value.length;

    if (_length === 5) {
      this.alert._showLoader('Cargando, espere...')
      this.getDataByPostalCode(value)
    }
  }

  onClickEvent(event: boolean) {
    if (event) {
      this._ngModalCitaRef.close()
    }
  }

  /**
   *
   *
   * @param {number} _set_index
   * @memberof CreatePatientComponent
   */
  fetchMore(_set_index: number) {
    _set_index += 100
    this._set_index = _set_index
    this._loadCandidatos()

  }
}
