import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, EventEmitter, HostListener, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { FullCalendarComponent } from '@fullcalendar/angular';
import { EventInput, CalendarOptions, EventApi, DatesSetArg, EventClickArg } from '@fullcalendar/core';
import { NgbOffcanvasRef, NgbOffcanvas, NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';

import * as _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription } from 'rxjs';

import { CalendarObjects, Cita, CitasObjects } from '@app/pages/calendar/models/calendar';
import { DetallesCita } from '@app/pages/calendar/models/detalles-cita';
import { CalendarService } from '@app/pages/calendar/services/calendar.service';
import { Doctores } from '@app/pages/models/doctores';
import { FilterCalendar } from '@app/pages/models/filter-calendar';
import { Filters } from '@app/pages/models/filters';
import { Servicios, Servicio } from '@app/pages/models/servicios';
import { LocalSessionService } from '@app/pages/services/local-session.service';
import { SessionPageService } from '@app/pages/services/session-page.service';
import { RedirectService } from '@app/services/redirect.service';
import { SelectedFIlters } from '../../models/selected-filters';

import listPlugin from '@fullcalendar/list';
import timeGridPlugin from '@fullcalendar/timegrid';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import esLocale from '@fullcalendar/core/locales/es';

import { Citas } from '@app/store/citas.model'
import * as moment from 'moment';
import { CredentialsService } from '@app/core/services/credentials.service';

//#endregion Valores JSON_LOCAL
const CALENDAR_DATES = '_json_calendar_dates'
const CALENDAR_VIEW = '_calendar_view'
const _FORM_FILTER = '_form_filter_calendar'
const _FILTER_CALENDAR_ = '_filter_calendar_result'
//#endregion

//#region Catalogos
const DOCTORES_ = '_cat_doctores'
const RECURSOS_ = '_cat_reursos'
const SERVICIOS_ = '_cat_servicios'
//#endregion


@Component({
  selector: 'app-full-calendar-dashboard',
  templateUrl: './full-calendar-dashboard.component.html',
  styleUrls: ['./full-calendar-dashboard.component.scss']
})
export class FullCalendarDashboardComponent implements OnInit {

  @Output()
  eventShowCoutDown: EventEmitter<boolean> = new EventEmitter<boolean>()

  @Output()
  eventShowCitasDia: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild('canvasDetail') canvasDetail: TemplateRef<any>;
  @ViewChild('fullcalendar', { static: false }) fullcalendar: FullCalendarComponent
  @ViewChild('modalFilters') modalFilters!: TemplateRef<any>;

  responseData: CalendarObjects;

  _doctores: Doctores[] = []
  _servicios: Servicios[] = []
  _citas: Cita[] = []
  _groupedCitas: any[] = []

  public innerWidth: number;
  calendarEvents: EventInput[] = []

  _canvasRef_: NgbOffcanvasRef;

  calendarOptions: CalendarOptions

  //#region Propiedades Calendario

  currentEvents: EventApi[] = [];
  _title: string;
  calendarVisible: boolean = false;

  _detallesCita: DetallesCita;

  _startDate: string = ''
  _endData: string = ''
  _doctor: string = ''

  _filters: Filters = new Filters()

  _itsReady: boolean;
  _loaderError: boolean;
  _isDoctor: boolean;
  _showLoader: boolean;

  _selectedDoctors: SelectedFIlters[] = []
  _selectedServicios: SelectedFIlters[] = []
  _selectedRecursos: SelectedFIlters[] = []

  //#endregion

  _recursoId: string = 'all'
  _doctorId: string = 'true'
  _servicioId: string = 'true'

  _isAdmin: boolean
  _calendarFilters: FilterCalendar = new FilterCalendar()

  calendarObservable$: Subscription;//Observable<CalendarObjects>;
  calendarObservableSusbscription$: Subscription;

  _initialViewCalendar: string = 'dayGridWeek'

  _filterSelected: any[] = []

  _filters_calendar: boolean = false
  _filter_calendar_json: any | null
  _loadCalendarCitas: boolean = true
  public _citasXstore: Observable<Citas>;
  _citasStorage: any[] = []

  _bloqueoResponse: any[] = []
  _bloqueo_especifico: any[] = []
  _bloqueo_recurrente: any[] = []

  resultDays: any[] = []

  _dias_ = [
    {
      id: 1,
      desc: 'Lunes'
    },

    {
      id: 2,
      desc: 'Martes'
    },

    {
      id: 3,
      desc: 'Miercoles'
    },

    {
      id: 4,
      desc: 'Jueves'
    },

    {
      id: 5,
      desc: 'Viernes'
    },

    {
      id: 6,
      desc: 'Sabado'
    },
    {
      id: 0,
      desc: 'Domingo'
    },

  ]

  _ngbModalRef: NgbModalRef;

  _dateRange: DatesSetArg;



  @HostListener('window:resize', [])
  onResize(): void {
    this.checkView()
  }

  constructor(
    private offCanvasService: NgbOffcanvas,
    private calendarS: CalendarService,
    private redirect: RedirectService,
    private toastr: ToastrService,
    private date: DatePipe,
    private credentialService: CredentialsService,
    private changeRef: ChangeDetectorRef,
    private session: SessionPageService,

    private _local: LocalSessionService,
    private ngModal: NgbModal
  ) { }

  ngOnInit(): void {

    this.calendarObservable$ = this.calendarS.CurrentLoadCalendar.subscribe({
      next: (value) => {

        if (value && value.loadCitas) {
          this.calendarS._setCurrentLoader({ loadCitas: false })
          this.loadCalendarData(this._filters_calendar)
        }
      },
      complete() {
        console.log('COMPLETEEE');

      },
      error(err) {
        console.error({ err });

      },

    })

    if (this._local.getJsonValues(_FORM_FILTER)) {
      this._filterSelected = this._local.getJsonValues(_FORM_FILTER)
    }


    if (this._local.getJsonValues(_FILTER_CALENDAR_)) {
      // console.log('FILTERRRRRRR');      
      this._filter_calendar_json = this._local.getJsonValues(_FILTER_CALENDAR_)
    }

    this.CheckLocalVal()
    this.GetCatalogs()
    this.checkView()


    this.initCalendar()

    this._isAdmin = this.credentialService.credentials?.roles.Administrador ? this.credentialService.credentials.roles.Administrador : false;

    this._filters.doctor = ''
    if (this.credentialService.credentials?.roles.Doctor) {
      this._isDoctor = this.credentialService.credentials.roles.Doctor;
      this._doctor = this.credentialService.credentials.id_salesforce;
    }

    setTimeout(() => {
      this.calendarVisible = true
    }, 300);
  }

  ngOnDestroy(): void {
    if (this.calendarObservableSusbscription$) {
      this.calendarObservableSusbscription$.unsubscribe();
    }

    if (this.calendarObservable$) {

      this.calendarObservable$.unsubscribe()
    }
  }

  ngAfterContentChecked(): void {
    this.changeRef.detectChanges()
  }

  get isMobile(): boolean {
    return this.innerWidth <= 768;
  }

  private checkView(): void {
    this.innerWidth = window.innerWidth;
  }

  private CheckLocalVal() {

    let _calendar_view = this._local.getJsonValues(CALENDAR_VIEW)

    if (_calendar_view) {
      const _viewCalendar = _calendar_view
      this._initialViewCalendar = _viewCalendar.type
    }

  }

  /**
   * 
   */
  private GetCatalogs() {
    this.getRecursos()
    this.getDoctors();
    this.getServicios()
  }

  /**
   * inicio del Calendario
   */
  private initCalendar() {

    this.calendarOptions = {
      plugins: [
        interactionPlugin,
        dayGridPlugin,
        timeGridPlugin,
        listPlugin,
      ],
      headerToolbar: {
        left: 'dayGridMonth,dayGridWeek,listWeek',
        center: 'title',
        right: 'prev,next'
      },

      initialView: this._initialViewCalendar,
      themeSystem: "bootstrap",
      locale: esLocale,
      eventClick: (arg) => this.eventClick(arg),
      datesSet: this.eventDataSet.bind(this),

    }

  }

  /**
   * Obtiene el listado de Doctores
   */
  private getDoctors() {
    this._selectedDoctors = []

    if (this._local.getJsonValues(DOCTORES_)) {
      const _DOCTORES = this._local.getJsonValues(DOCTORES_)
      _.map(_DOCTORES, (a) => {
        if (a.tipo_empleado == 'Doctor') {
          this._selectedDoctors.push({
            Id: a.id_salesforce,
            Name: a.name,
            checked: true
          })
        }
      })

    } else {
      this.calendarS._getDoctores(this._doctorId).subscribe({
        next: (response) => {
          if (response.codigo == 200) {
            let _response = response.data as any;
            let _doctores = _response.info;

            this._local.savedJsonValues(DOCTORES_, _doctores)

            //this._doctor = _response.info.salesforce as Doctor;   
            _.map(_response.info, (a) => {
              if (a.tipo_empleado == 'Doctor') {
                this._selectedDoctors.push({
                  Id: a.id_salesforce,
                  Name: a.name,
                  checked: true
                })
              }
            })

          }
        }, error: (error) => {
          this.toastr.error('Ocurri un error al obtener el listado de doctores', 'Error en listado de doctores', {
            timeOut: 3200,
            positionClass: 'toast-top-left'
          })
        }
      })
    }


  }

  /**
   * Obtiene el listado de Recursos
   */
  private getRecursos() {
    this._selectedRecursos = []

    if (this._local.getJsonValues(RECURSOS_)) {
      const _recurso = this._local.getJsonValues(RECURSOS_)
      _.map(_recurso, (a) => {
        this._selectedRecursos.push({
          Id: a.Id,
          Name: a.Name,
          checked: true
        })
      })

    } else {
      this.calendarS._getRecursos(this._recursoId).subscribe({
        next: (response) => {
          if (response.codigo == 200) {
            let _recurso = response.data
            this._local.savedJsonValues(RECURSOS_, _recurso)

            _.map(response.data, (a) => {
              this._selectedRecursos.push({
                Id: a.Id,
                Name: a.Name,
                checked: true
              })
            })
          }
        },
        error: (error) => {
          console.error({ error });

        }
      })
    }


  }

  /**
   * Obtiene el listado de Servicios
   */
  private getServicios() {
    this._selectedServicios = []

    if (this._local.getJsonValues(SERVICIOS_)) {
      const _servicios = this._local.getJsonValues(SERVICIOS_)
      _.map(_servicios, (a: Servicio) => {
        this._selectedServicios.push({
          Id: a.Id,
          Name: a.Name,
          checked: true
        })
      })

    } else {
      this.calendarS._getServicio(this._servicioId).subscribe({
        next: (response) => {
          if (response.codigo == 200) {
            this._local.savedJsonValues(SERVICIOS_, response.data)
            _.map(response.data, (a: Servicio) => {

              this._selectedServicios.push({
                Id: a.Id,
                Name: a.Name,
                checked: true
              })
            })
          }
        }
      })
    }
  }

  /**
   * 
   * @param date 
   */
  eventDataSet(date: DatesSetArg) {
    this._dateRange = date

    this._local.savedJsonValues(CALENDAR_VIEW, date.view)
    this._local.savedJsonValues(CALENDAR_DATES, date)

    if (this._isDoctor) {
      this._filters.doctor = this._doctor;
    }

    this._filters.inicio = this.date.transform(date.start, 'yyyy-MM-dd')
    this._filters.final = this.date.transform(date.end, 'yyyy-MM-dd')
    this._filters_calendar = false

    let start = moment(this._filters.inicio);
    let end = moment(this._filters.final);

    this.resultDays = [];
    for (var current = start; current <= end; current.add(1, 'd')) {
      this.resultDays.push(current.format("YYYY-MM-DD"))
    }

    this.loadCalendarData(false)
  }

  /**
   * 
   */
  private loadCalendarData(isLoaderFilter: boolean) {

    this._showLoader = true;
    this._doctores = []
    this._servicios = []

    this._bloqueo_especifico = []
    this._bloqueo_recurrente = []


    this.loadBloqueos()

    if (!isLoaderFilter) {
      this.calendarS._getCalendarReport(this._filters).subscribe({
        next: (response: any) => {
          this._showLoader = false;
          if (response.codigo == 200) {

            this.responseData = response.data as CalendarObjects;

            if (this.responseData.bloqueo_horario) {
              this._bloqueo_especifico = this.responseData.bloqueo_horario.bloqueo_especifico
              this._bloqueo_recurrente = this.responseData.bloqueo_horario.bloqueo_recurrente
            }

            if (this.responseData.Citas) {
              this._onSetData()
            }

          }
        },
        error: (err: any) => {
          console.error({ err });
          this._loaderError = true;
          this._showLoader = false;
          this.toastr.error('Ocurrio un error al obtener los datos del calendario', 'Error', {
            timeOut: 3200
          })
        },
      })

    } else {

      this.calendarS._getFilterCalendar(this._calendarFilters).subscribe({
        next: (response: any) => {

          if (response.codigo == 200) {
            this._showLoader = false;
            this.responseData = response.data as CalendarObjects;

            if (this.responseData.bloqueo_horario) {
              this._bloqueo_especifico = this.responseData.bloqueo_horario.bloqueo_especifico
              this._bloqueo_recurrente = this.responseData.bloqueo_horario.bloqueo_recurrente
            }

            if (this.responseData.Citas) {
              this._onSetData()
            } else {
              this.loadEventsCalendar()
            }
          }
        }, error: (err: any) => {
          console.error({ err });
          this._showLoader = false;
          this._loaderError = true;
          this.toastr.error('Ocurrio un error al obtener los datos del calendario', 'Error', {
            timeOut: 3200
          })
        }
      })
    }
  }

  /**
   * Cargamos los bloqueso de Horario
   */
  private loadBloqueos(): void {
    let _doctor = this._filters.doctor != '' ? this._filters.doctor : 'true'

    this._bloqueoResponse = []
    this.calendarS._ConsultarBloqueoHorario(_doctor).then((resp: any) => {
      if (resp.codigo === 200) {
        this._bloqueoResponse = resp.data
      }
    }).catch((error) => {
      console.error({ error });
    })
  }

  /**
   * Guardamos las citas
   */
  private _onSetData() {

    if (this.responseData) {

      let _citasObjects = this.responseData.Citas
      this._citas = []
      /**
       * Si las Citas Vienen en un Array
       */
      if (_citasObjects.length) {
        this._citas = [...this.responseData.Citas]
      } else {

        /**
         *Si las Citas vienen en Objetos
         *Today y Tomorrow
        */
        let _citasObjects = this.responseData.Citas as CitasObjects;

        let _today: Cita[] = []
        let _tomorrow: Cita[] = []

        /**
        * Recorremos las Citas de Hoy
        */
        if (_citasObjects.Today && _citasObjects.Today.length) {
          _today = _citasObjects.Today
        }

        /**
        * Recorremos las Citas de Mañana
        */
        if (_citasObjects.Tomorrow && _citasObjects.Tomorrow.length) {
          _tomorrow = _citasObjects.Tomorrow;
        }

        /**
        * Juntamos los dos arreglos
        */
        this._citas = [..._today, ..._tomorrow]
      }

      let _citas = this._citas;

      _.map(_citas, (a) => {
        let _doctor = _.find(this.responseData.Doctores, { Id: a.Empleado_Id })
        let _servicio = _.find(this.responseData.Servicios, { Id: a.Servicio_Id })

        a.Doctor = _doctor?.Name;
        a.Servicio = _servicio?.Name
        a.Selected = false
        a.expanded = false
      })

      //this.store.dispatch(new AddCitas(this._citas))
      this.session._saveJSONtoSession(_citas)
      this.eventShowCoutDown.next(true)


      this.GroupedCitas()
      this.loadEventsCalendar()
    }

  }

  /**
   * Agrupamos las Citas
   */
  private GroupedCitas() {

    this._groupedCitas = _(this._citas).groupBy('Recurso_Id').map((items, recursoId) => ({
      recursoId,
      Name: items[0].Recurso,
      value: items.length,
      data: items
    })).value();




  }

  /**
   * Cargamos los eventos del Calendario
   */
  private loadEventsCalendar() {

    let events: EventInput[] = []

    _.map(this._citas, (a) => {

      let _color = this.calendarS._getColorCalendar(a.Tipo_Cita)

      events.push({
        id: a.Cita_Id,
        title: a.Tipo_Cita === 'Bloqueo' ? `[${a.Procedimiento}] ${moment(a.Fecha_Inicio).format('HH:mm a') } a ${moment(a.Fecha_Final).format('HH:mm a')} - ${a.Paciente_Nombre_Completo}` : `[${a.Tipo_Cita}] - ${a.Estatus} - ${a.Paciente_Nombre_Completo}` ,
        date: new Date(a.Fecha_Inicio),
        end: new Date(a.Fecha_Final),
        allDay: false,
        className: `${_color } ${_color === 'bg-light' ? 'text-dark': '' } text-wrap `   
      })
    })

    /**
     * 
     */
    if (this._bloqueo_especifico && this._bloqueo_especifico.length || this._bloqueo_recurrente && this._bloqueo_recurrente.length) {

      /**
       * 
       */
      _.map(this.resultDays, (a) => {
        let _day = moment(a).day()
        let _find = _.find(this._dias_, { id: _day })?.desc;
        let _findBloqueo = _.find(this._bloqueo_recurrente, { dia: _find })

        console.log({ ____findBloqueo___: _findBloqueo });


        if (_findBloqueo) {
          _.map(_findBloqueo.horas, (b) => {
            let _date_ = new Date(moment(`${a} ${b.inicio}`).toString())
            let _end_ = new Date(moment(`${a} ${b.fin}`).toString())

            let _color = 'bg-primary-subtle'
            events.push({
              id: a,
              title: `BLOQUEO-A de ${b.inicio} a  ${b.fin}`,
              date: _date_,
              end: _end_,
              allDay: false,
              color: _color,
              className: `${_color} text-white text-wrap`
            })
          })
        }
      })

      /**
       * 
       */
      _.map(this._bloqueo_especifico, (a) => {

        _.map(a.horas, (b) => {
          let _date_ = new Date(moment(`${a.fecha} ${b.inicio}`).toString())
          let _end_ = new Date(moment(`${a.fecha} ${b.fin}`).toString())

          let _color = 'bg-primary-subtle'
          events.push({
            id: a,
            title: `BLOQUEO-B de ${b.inicio} a ${b.fin}`,
            date: _date_,
            end: _end_,
            allDay: false,
            color: _color,
            className: `${_color} text-white tex-wrap`
          })
        })
      })

    }

    if (this._isAdmin) {
      _.map(this._bloqueoResponse, (a) => {

        if (a.bloqueo_horario.bloqueo_recurrente) {
          _.map(this.resultDays, (c) => {
            let _day = moment(c).day()
            let _find = _.find(this._dias_, { id: _day })?.desc;
            let _findBloqueo = _.find(a.bloqueo_horario.bloqueo_recurrente, { dia: _find })


            if (_findBloqueo) {

              _.map(_findBloqueo.horas, (b) => {
                let _date_ = new Date(moment(`${c} ${b.inicio}`).toString())
                let _end_ = new Date(moment(`${c} ${b.fin}`).toString())

                let _color = 'bg-light'
                events.push({
                  id: c,
                  title: `${a.nombre} de ${b.inicio} - ${b.fin}`,
                  date: _date_,
                  end: _end_,
                  allDay: false,
                  color: _color,
                  className: `${_color} text-dark text-wrap`
                })
              })
            }
          })
        }

        if (a.bloqueo_horario.bloqueo_especifico) {

          let _doctor = a

          _.map(a.bloqueo_horario.bloqueo_especifico, (a) => {

            /**
             * 
             */
            _.map(a.horas, (b) => {
              let _date_ = new Date(moment(`${a.fecha} ${b.inicio}`).toString())
              let _end_ = new Date(moment(`${a.fecha} ${b.fin}`).toString())

              let _color = 'bg-light'
              events.push({
                id: a,
                title: `${_doctor.nombre} ${b.inicio} - ${b.fin}`,
                date: _date_,
                end: _end_,
                allDay: false,
                color: _color,
                className: `${_color} text-dark text-wrap`
              })

            })

          })
        }
      })
    }

    this.calendarOptions = { ...this.calendarOptions, events }
  }

  /**
   * 
   * @param info 
   */
  eventClick(info: EventClickArg) {

    this._detallesCita = new DetallesCita()

    let publicId = info.event._def.publicId

    const _cita = _.find(this._citas, { Cita_Id: publicId })

    if (_cita) {

      let _v = this.offCanvasService.hasOpenOffcanvas()

      const doctor = _.find(this.responseData.Doctores, { Id: _cita.Empleado_Id })
      const servicio = _.find(this.responseData.Servicios, { Id: _cita.Servicio_Id })

      this._detallesCita = {
        cita: _cita,
        doctor,
        servicio
      }

      if (_v) {

        this._canvasRef_.close() //= this.offCanvasService.dismiss()
        //this.offCanvasService.open(this.canvasDetail)
        this._openCanvas()

      } else {
        //this.offCanvasService.dismiss()
        this._openCanvas()

      }
    }
  }

  /**
   * 
   */
  private _openCanvas() {
    this._canvasRef_ = this.offCanvasService.open(this.canvasDetail, {
      position: 'end',
      backdrop: true,
      scroll: false
    })
  }

  /**
   * 
   * @param event 
   */
  onEventCanvasEmmitter(event: string) {

    // this._detallesCita = new DetallesCita;

    this._canvasRef_.close()

    if (event == 'redirect') {
      //this.offCanvasService.dismiss();
      this.redirect.to('/pacientes_formatos')
    } else {
      //this.offCanvasService.dismiss();
    }

  }

  /**
   * 
   * @param recurso 
   * @returns 
   */
  recursoName(recurso: string) {
    let _find = _.find(this._selectedRecursos, { Id: recurso })


    if (_find) {
      return _find.Name
    }

    return 'N/D'
  }

  /**
   * 
   * @param citas_ 
   */
  OnEmmitCitas(citas_: any[]) {

    let events: EventInput[] = []
    _.map(citas_, (a) => {

      let _color = this.calendarS._getColorCalendar(a.Tipo_Cita)

      events.push({
        id: a.Cita_Id,
        title: `${a.Tipo_Cita} - ${a.Estatus}`,
        date: new Date(a.Fecha_Inicio),
        end: new Date(a.Fecha_Final),
        allDay: false,

        color: _color,
        className: `${_color} text-white`,
      })

    })





    this.calendarOptions = { ...this.calendarOptions, events }


  }



  //#region Solo Administradores
  SetEventEmmitFilters(calendarFilter: FilterCalendar) {

    //calendarFilter.inicio = this._filters.inicio;
    //calendarFilter.final  = this._filters.final  

    this._calendarFilters = calendarFilter;
    this._filters_calendar = true
    this.loadCalendarData(true)

  }
  //#endregion


  /**
   * 
   * @param eventFilter 
   */
  OnEventFilter(calendarFilter: FilterCalendar) {
    console.log({ EVENT_FILTER: calendarFilter });

    calendarFilter.inicio = this._filters.inicio;
    calendarFilter.final = this._filters.final

    this._calendarFilters = calendarFilter;

    this._filters_calendar = true

    let _filter_calendar: any

    _filter_calendar = {
      _filters_calendar: this._filters_calendar,
      _calendarFilters: this._calendarFilters
    }

    this._local.savedJsonValues(_FILTER_CALENDAR_, _filter_calendar)


    this.loadCalendarData(true)

  }

  _openModal() {

    this._ngbModalRef = this.ngModal.open(this.modalFilters, {
      animation: true,
      size: 'lg',
      centered: true,
      backdrop: 'static',
      fullscreen: 'lg',
      keyboard: false,
    })

  }

}
