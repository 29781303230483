<div class="d-flex profile-wrapper">
    <div class="row row-cols-xl-2 row-cols-1 ">
        <div class="col">
            <div class="card">
                <div class="card-body">
                    <h5 class="card-title mb-3"></h5>
                    <div class="table-responsive">
                        <table class="table table-borderless mb-0">
                            <tbody>
                                <tr>
                                    <th class="ps-0" scope="row">Nombre completo </th>
                                    <td class="text-muted">{{_patient.Nombre_completo__c}} </td>
                                </tr>
                                <tr>
                                    <th class="ps-0" scope="row">Fecha de nacimiento</th>
                                    <td class="text-muted">{{_patient.Fecha_nacimiento_calendario__c}} </td>
                                </tr>
                                <tr>
                                    <th class="ps-0" scope="row">Telefono</th>
                                    <td class="text-muted">{{_patient.P05_Telefono__c | mask:'(000) 000 0000'}} </td>
                                </tr>
                                <tr>
                                    <th class="ps-0" scope="row">Sexo </th>
                                    <td class="text-muted">{{_patient.P05_Sexo__c}} </td>
                                </tr>
                                <tr>
                                    <th class="ps-0" scope="row">Centro Medico Relacionado </th>
                                    <td class="text-primary"> {{_patient.Centros_Medicos__c}} </td>
                                </tr>
                                <tr>
                                    <th class="ps-0" scope="row">¿Candidato a cirugía? </th>
                                    <td class="text-muted"> {{_patient.Candidato_a_cirug_a__c}} </td>
                                </tr>
                                <tr>
                                    <th class="ps-0" scope="row">Empresa Oddo </th>
                                    <td class="text-muted"> {{_patient.ID_Oddo__c}} </td>
                                </tr>
                                <tr>
                                    <th class="ps-0" scope="row">Origen del Paciente </th>
                                    <td class="text-muted"> {{_patient.Origen_del_Paciente__c}} </td>
                                </tr>
                                <tr>
                                    <th class="ps-0" scope="row">Especialidad </th>
                                    <td class="text-primary"> {{_patient.Especialidad__c}} </td>
                                </tr>
                                <tr>
                                    <th class="ps-0" scope="row">Método de contacto</th>
                                    <td class="text-muted"> {{_patient.Metodo_de_contacto_del__c}} </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div><!-- end card body -->
            </div><!-- end card -->
        </div>
        <div class="col">
            <div class="card">
                <div class="card-body">
                    <h5 class="card-title mb-3">General</h5>
                    <div class="table-responsive">
                        <table class="table table-borderless mb-0">
                            <tbody>
                                <tr>
                                    <th class="ps-0" scope="row">Correo </th>
                                    <td class="text-muted">{{_patient.P05_Correo__c}}</td>
                                </tr>
                                <tr>
                                    <th class="ps-0" scope="row">Edad </th>
                                    <td class="text-muted">{{_patient.P05_Edad__c}}</td>
                                </tr>
                                <tr>
                                    <th class="ps-0" scope="row">Estado civil</th>
                                    <td class="text-muted">{{_patient.P05_Estado_civil__c}}</td>
                                </tr>
                                <tr>
                                    <th class="ps-0" scope="row">Cuenta con SGMM </th>
                                    <td class="text-muted">{{_patient.Cuenta_con_Seguro_Gastos_M_dicos_Mayores__c !=
                                        null ?
                                        _patient.Cuenta_con_Seguro_Gastos_M_dicos_Mayores__c ? 'SI' : 'NO' :'' }}</td>
                                </tr>
                                <tr>
                                    <th class="ps-0" scope="row">Compañía de SGMM </th>
                                    <td class="text-muted">{{_patient.Compa_a_de_SGMM__c}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div><!-- end card body -->
            </div><!-- end card -->
        </div>
        <div class="col">
            <div class="card">
                <div class="card-body">
                    <h5 class="card-title mb-3">Dirección (Obligatorio capturar País, Estado, Municipio, Calle y CP)
                    </h5>
                    <div class="table-responsive">
                        <table class="table table-borderless mb-0">
                            <tbody>
                                <tr>
                                    <th class="ps-0" scope="row">Código postal</th>
                                    <td class="text-muted">{{_patient.C_digo_postal__c}} </td>
                                </tr>
                                <tr>
                                    <th class="ps-0" scope="row">Estado</th>
                                    <td class="text-muted">{{_patient.Estado__c}} </td>
                                </tr>
                                <tr>
                                    <th class="ps-0" scope="row">Municipio</th>
                                    <td class="text-muted">{{_patient.Municipio__c}} </td>
                                </tr>
                                <tr>
                                    <th class="ps-0" scope="row">Colonia</th>
                                    <td class="text-muted">{{_patient.Colonia__c}} </td>
                                </tr>
                                <tr>
                                    <th class="ps-0" scope="row">Calle</th>
                                    <td class="text-muted">{{_patient.Calle__c}} </td>
                                </tr>
                                <tr>
                                    <th class="ps-0" scope="row">Numero Exterior</th>
                                    <td class="text-muted">{{_patient.Numero_Exterior__c}} </td>
                                </tr>
                                <tr>
                                    <th class="ps-0" scope="row">Numero Interior</th>
                                    <td class="text-muted">{{_patient.Numero_Interior__c}} </td>
                                </tr>

                            </tbody>
                        </table>
                    </div>
                </div><!-- end card body -->
            </div><!-- end card -->
        </div>
        <div class="col">
            <div class="card">
                <div class="card-body">
                    <h5 class="card-title mb-3">Relaciones</h5>
                    <div class="table-responsive">
                        <table class="table table-borderless mb-0">
                            <tbody>
                                <tr>
                                    <th class="ps-0" scope="row">Responsable Call Center </th>
                                    <td class="text-muted">
                                        <a href="javascript:void(0);" class=""> {{_patient.Empleado__c}} </a>    
                                    </td>
                                </tr>
                                <tr>
                                    <th class="ps-0" scope="row">Objeción</th>
                                    <td class="text-muted">{{_patient.Objeci_n__c}}</td>
                                </tr>
                                <tr>
                                    <th class="ps-0" scope="row">Seguro antiguedad</th>
                                    <td class="text-muted">{{_patient.Seguro_Antiguedad__c}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div><!-- end card body -->
            </div><!-- end card -->
        </div>
        <div class="col">
            <div class="card">
                <div class="card-body">
                    <h5 class="card-title mb-3">Information</h5>
                    <div class="table-responsive">
                        <table class="table table-borderless mb-0">
                            <tbody>
                                <tr>
                                    <th class="ps-0" scope="row">Paciente que lo recomienda </th>
                                    <td class="text-muted">{{_patient.Paciente_que_lo_recomienda__c}} </td>
                                </tr>        
                                <tr>
                                    <th class="ps-0" scope="row">Motivo de consulta </th>
                                    <td class="text-muted">{{_patient.P05_Motivo_de_consulta__c}} </td>
                                </tr>        
                                <tr>
                                    <th class="ps-0" scope="row">Contacto Emergencias Nombre </th>
                                    <td class="text-muted">{{_patient.P05_Contacto_Emergencias_Nombre__c}} </td>
                                </tr>      
                                <tr>
                                    <th class="ps-0" scope="row">Contacto Emergencias Teléfono </th>
                                    <td class="text-muted">{{_patient.P05_Contacto_Emergencias_Tel_fono__c}} </td>
                                </tr>     
                                <tr>
                                    <th class="ps-0" scope="row">Comentario</th>
                                    <td class="text-muted">{{_patient.P05_Comentario__c}} </td>
                                </tr>            
                            </tbody>
                        </table>
                    </div>
                </div><!-- end card body -->
            </div><!-- end card -->
        </div>
    </div>

</div>