import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { CalificacionCandidato, CalificacionVarices, CandidatoEstatus, CandidatosResponse } from '../models/candidatos';
import { environment } from 'src/environments/environment';
import { CreateCandidate } from 'src/app/shared/models/create-candidate';

@Injectable({
  providedIn: 'root'
})
export class CandidatosService {

  private readonly apiUrl: string = environment.apiUrl;  

  currentLoadCandidatosSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public readonly CurrentLoadCandidatos: Observable<any> =  this.currentLoadCandidatosSubject.asObservable()

  constructor(
    private http: HttpClient
  ) { }

  /**
   * 
   * @param candidato 
   * @returns 
   */
  ObtenerListadoCanidatos(candidato: string): Observable<CandidatosResponse> {    
    return this.http.get<CandidatosResponse>(`${this.apiUrl}candidato/?candidato=${candidato}`)    
  }
  
  /**
   * 
   * @param candidato_obj 
   * @returns 
   */
  _updateCandidato(candidato_obj: CreateCandidate) {
    return this.http.post(`${this.apiUrl}candidato/editar_datos_candidato_salesforce`, candidato_obj)
  }

  /**
   * {{server}}/api/
   */
  ObtenerListadoEstatus(): Observable<CandidatoEstatus> {
    return this.http.get<CandidatoEstatus>(`${this.apiUrl}candidato/estatus`)
  }

  /**
   * 
   * @param candidato_id -- a0Acc000000CXbJEAW
   */
  _consultarCalifPaciente(candidato_id: string) {
    return this.http.get(`${this.apiUrl}pacientes/consultar_clasificacion_paciente?candidato_id=${candidato_id}`)
  }

  /**
   * 
   * @param candidato_id 
   * @returns 
   */
  _consultarCalificacionVarices(candidato_id: string) {    
    return this.http.get(`${this.apiUrl}pacientes/consultar_clasificacion_paciente_varices?candidato_id=${candidato_id}`)
  }
  /**
   * 
   * @param _calificacion 
   * @returns 
   */
  _guardarCalifPaciente(_calificacion: CalificacionCandidato) {
    //{{server}}/
    return this.http.post(`${this.apiUrl}pacientes/guardar_clasificacion_paciente`, _calificacion)
  }
  
  /**
   * 
   * @param _calificacion 
   * @returns 
   */
  _guardarCalificacionVarices(_calificacion: CalificacionVarices) {
    return this.http.post(`${this.apiUrl}pacientes/guardar_clasificacion_paciente_varices`, _calificacion)
   }

   /**
    * 
    * @param event 
    */
   _setCurrentLoaderCandidatos(event: boolean) {
    this.currentLoadCandidatosSubject.next(event)
   }

}
