import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SessionPageService } from '../services/session-page.service';
import { DataResponseResultSearch } from '../models/response-search-result';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CalendarService } from '../calendar/services/calendar.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-search-results',
  templateUrl: './search-results.component.html',
  styleUrls: ['./search-results.component.scss']
})
export class SearchResultsComponent implements OnInit {

  breadCrumbItems!: Array<{}>;
  images: { src: string; thumb: string; caption: string }[] = [];

  _formSearch: FormGroup;

  _textSearch: string | null

  _results: any[] = []

  _resulSerach: any[] = []
  _minLength: number = 3

  _resultSearch: any[] = []

  _dataResponseResultSearch: DataResponseResultSearch

  spinner_name: string = '_spinner_name'

  constructor(
    private route: ActivatedRoute,
    private session: SessionPageService,
    private calendar: CalendarService,
    private fb: FormBuilder,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit(): void {

    this._formSearch = this.fb.group({
      searchText: [null, [Validators.required, Validators.minLength(this._minLength)]]
    })

    this.breadCrumbItems = [
      { label: 'Pagina' },
      { label: 'Resultados de busqueda ', active: true }
    ];

    this.route.queryParams.subscribe({
      next: (response) => {
        if (response['searchText']) {
          this._textSearch = response['searchText']
          this._formSearch.patchValue({
            searchText: this._textSearch
          })
        }
      }
    })
    this.initTableSearch()
  }

  private initTableSearch() {
    this._results = this.session._returnResultSearch();
    this._dataResponseResultSearch = this.session._getSearchResponse();

    this._resulSerach = []

    if (this._dataResponseResultSearch) {

      if (this._dataResponseResultSearch.citas) {
        this._resulSerach.push({
          label: 'CITAS',
          data: this._dataResponseResultSearch.citas
        })
      }

      if (this._dataResponseResultSearch.pacientes) {
        this._resulSerach.push({
          label: 'PACIENTES',
          data: this._dataResponseResultSearch.pacientes
        })
      }
    }

  }

  _onSerach() {

    if (this._formSearch.valid) {

      // this.session._saveResultSearch(this._searchResult)

      // console.log('Message HOLA');

      this.spinner.show(this.spinner_name)
      let _searchText = this._formSearch.value.searchText;

      this.calendar._serchByText(_searchText).subscribe({

        next: (response) => {
          this.spinner.hide(this.spinner_name)
          if (response.codigo === 200) {

            // this._resultSearch = []  // response.data;

            this.session._saveSearchResponse(response.data)

            this.initTableSearch()

            /*      this.redirect.to('/search-results',{
                   queryParams: { searchText: this.form['searchText'].value }
                 }) */

          }


        }, error: (error) => {
          this.spinner.hide(this.spinner_name)
          console.error({ error });

        }
      })


    }

  }



}
