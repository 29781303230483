
import { Component, ElementRef, HostListener, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormatsService } from 'src/app/shared/services/formats.service';

import { SessionPageService } from '../../services/session-page.service';

import { Location } from '@angular/common';

import { environment } from 'src/environments/environment';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CredentialsService } from 'src/app/core/services/credentials.service';
import { DetallesCita } from '../../calendar/models/detalles-cita';
import { NgbOffcanvas, NgbOffcanvasRef } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import { CalendarService } from '../../calendar/services/calendar.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { PatientsService } from '../../calendar/services/patients.service';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/core/services/auth.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})

/**
 * Ecommerce Component
 */
export class DashboardComponent implements OnInit, OnDestroy {

  @ViewChild('canvasDetail') canvasDetail: TemplateRef<any>;

  @ViewChild('div_card_left') div_card_left: ElementRef;
  @ViewChild('row_left') row_left: ElementRef;
  @ViewChild('row_rigth') row_rigth: ElementRef;
  @ViewChild('card_body') card_body: ElementRef;


  _canvasRef_: NgbOffcanvasRef;

  // _show_logs: boolean;

  _show_history: boolean;
  _pacientesFormatos: any[] = []

  public Collapsed = true;
  public secondColleaps = false;

  _activeId: number | null = null
  _viewId: string;
  public readonly publicKey: string = 'BE1yv8t8-uuPR3QW_Wgzdzb3VSaB2TlEW-rY1aZoX1z2O0hHkAXs4Oq4hk84sZpFLvpkddXRFefZmiUuC1lWe-Q';

  // bread crumb items
  breadCrumbItems!: Array<{}>;

  // Current Date
  currentDate: any;


  _formTest: FormGroup;

  _previewImage: any[] = []

  public innerWidth: number;

  _nombre_completo: string

  _detallesCita: DetallesCita;

  _clientes_espera: any[] = []


  record: any
  _cita: any;

  _jsonCitas: any[] = []

  _spinner = 'spinner'

  @HostListener('window:resize', [])
  onResize(): void {
    this.checkView()
  }

  constructor(
    private sessionPage: SessionPageService,
    private formatService: FormatsService,
    private location: Location,
    private formBuilder: FormBuilder,
    private credentials: CredentialsService,
    private offCanvasService: NgbOffcanvas,
    private calendarService: CalendarService,
    private spinner: NgxSpinnerService,
    private patientService: PatientsService,
    private router: Router,
    private authService: AuthenticationService
  ) {

    const _record = this.credentials.userInfoData

    if (_record && _record.data) {
      this.record = _record.data.records[0]
    }

    this._cita = this.credentials.getCitaJson()
    const _dataUser = this.credentials._getdataUser();

    if (_dataUser) {
      /*    const _Websockets = _dataUser.Websockets;
   
         if(_Websockets && _Websockets.paciente_espera){
           this._clientes_espera = _Websockets.paciente_espera.Logs;
         } */
    }

    const _jsonCitas = this.sessionPage._returnJSONSession()
    if (_jsonCitas) {
      this._jsonCitas = _jsonCitas
    }
  }

  ngOnInit(): void {

    if (this._cita && this.record) {
      this._nombre_completo = this._cita.Paciente_Nombre_Completo

      this.breadCrumbItems = [
        { label: this.record.Name, type: 'paciente' },
        { label: this._cita.Cita_Id, type: 'cita' }
      ];
    }
    this.loadClientesEspera()
    this.checkView()
    this.initForm()

    this._pacientesFormatos = this.sessionPage.pacientesFormatos;
  }

  get isMobile(): boolean {
    return this.innerWidth <= 768;
  }

  private checkView(): void {
    this.innerWidth = window.innerWidth;
  }

  private initForm() {
    this._formTest = this.formBuilder.group({
      images: []
    })
  }

  /**
   * 
   */
  private loadClientesEspera() {

    this.authService._getUserDetail().then((resp) => {
      // console.log({_RESPO: resp});

      if (resp.codigo == 200) {
        this._clientes_espera = resp.data.info.Websockets ? resp.data.info.Websockets.paciente_espera.Logs : []

      }

    })



  }

  /**
   * 
   */
  SwpSusbribeNotification(): any {
    /* this.swPush.requestSubscription({
      serverPublicKey: this.publicKey
    }).then(sub => {
      const token = JSON.parse(JSON.stringify(sub))
      console.log({sub});
      
    }) */
  }

  ngOnDestroy(): void {
    // this.$interval.unsubscribe()
  }


  _onloadHistory(event: any) {
    //console.log('Holaaa');    
    // this._show_history = true;

    let _cita_id = event.data.cita_id

    let _find = _.find(this._jsonCitas, { Cita_Id: _cita_id })

    if (_find) {
      this.credentials.saveCitaJson(_find)
      this._getPatientDetalle(_find.Paciente_Id)

    } else {

      this.spinner.show(this._spinner)

      this.calendarService._getDetallesCita(event.data.cita_id).subscribe({
        next: (response: any) => {

          if (response.codigo == 200) {
            const _data = response.data[0];
            if (_data && _data.error) {
              console.log('AAAAAAAAAA');
            } else {
              console.log('BBBBBBBBBBBB');
            }
            this.spinner.hide(this._spinner)
          }

        },
        error: (error: any) => {
          console.error({ error });

          this.spinner.hide(this._spinner)

        }
      })

    }
    console.log({ event, _jsonCitas: this._jsonCitas, _find });
  }

  /**
   * 
   * @param paciente_id 
   */
  _getPatientDetalle(paciente_id: string) {
    this.spinner.show(this._spinner)
    this.patientService._getPatientInfo(paciente_id).subscribe({
      next: (response: any) => {
        // 
        this.spinner.hide(this._spinner)
        this.credentials.saveUserinfo(response);
        // this.router.
        window.location.reload()
      },
      error: (error: any) => {
        this.spinner.hide(this._spinner)
        console.error({ error });
      }
    })


  }

  onCollapsed(event: any) {
    console.log({ event });
  }

  /**
   * 
   * @param item 
   */
  _onLoadItems(item: any) {
    this.formatService._getFormat(item.id).subscribe(resp => {
      console.log({ resp });

    })
    console.log({ item });

  }

  onSettingsButtonClicked() {
    document.body.classList.toggle('right-bar-enabled');
    const rightBar = document.getElementById('theme-settings-offcanvas');
    if (rightBar != null) {
      rightBar.classList.toggle('show');
      rightBar.setAttribute('style', "visibility: visible;");
    }
  }

  _onSetView(item: any) {
    if (item && item.id) {
      this._viewId = item.id
      console.log({ _viewId: this._viewId });
    } else {
      this._viewId = item
    }


  }

  onSetIdFormato(Id: any) {
    this._viewId = Id
  }

  onBack() {
    this.location.back();
  }

  /**
   * 
   * @param item 
   */
  _onEventEmmiterClick(item: any) {
    if (item.type == 'cita') {
      this._detallesCita = new DetallesCita()

      console.log({ _ITEM: item, CITA: this._cita });
      this._detallesCita = {
        cita: this._cita
      }

      this._openCanvas()

    } else {

    }

  }

  private _openCanvas() {
    this._canvasRef_ = this.offCanvasService.open(this.canvasDetail, {
      position: 'end',
      backdrop: true,
      scroll: false
    })
  }

  /**
   * 
   * @param event 
   */
  onEventCanvasEmmitter(event: string) {
    this._canvasRef_.close()
  }


}
