import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import * as _ from 'lodash';
import * as moment from 'moment';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ToastrService } from 'ngx-toastr';
import { Subject, Subscription, interval } from 'rxjs';
import { CredentialsService } from 'src/app/core/services/credentials.service';
import { TokenStorageService } from 'src/app/core/services/token-storage.service';
import { Cita } from 'src/app/pages/calendar/models/calendar';
import { Images } from 'src/app/shared/models/list-images';
import { AlertsService } from 'src/app/shared/services/alerts.service';
import { FormatsService } from 'src/app/shared/services/formats.service';

@Component({
  selector: 'app-nota-evolucion',
  templateUrl: './nota-evolucion.component.html',
  styleUrls: ['./nota-evolucion.component.scss']
})
export class NotaEvolucionComponent implements OnInit {

  @Input()
  id: string

  @Input()
  _formatoData: any

  _Cita: Cita
  //$interval!: Subscription;
  //_BODYFORM: any;
  _Firma: Images[] = []
  currentUser: any

  // _formNotasEvolucion: FormGroup;

  public formNotaEvolucion: FormGroup = this.formBuilder.group({})

  record: any;
  control: string
  isDesktopDevice!: boolean

  _notasEvolucion: any[] = []
  _log_notas_evolucion: any[] = []
  _cita_selected: string | null
  _selected_nota_evolucion: any
  _img_nota_evolucion_historia_clinica: any[] = []

  _Images: any[] = []

  _controls: any[] = []
  _formato: any = {}

  _label: string
  _itsReady: boolean
  _label_: string = 'nota_evolucion_frame'

  _notas_evolucion: any[] = []

  firma_medico_nota_evolucion_historia_clinica: string | null = null

  constructor(
    private formatService: FormatsService,
    private credentials: CredentialsService,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private tokenService: TokenStorageService,
    private deviceService: DeviceDetectorService,
    private alert: AlertsService
  ) {

    const _cita = this.credentials.getCitaJson()
    if (_cita) {
      this._Cita = _cita
    }

    this.record = this.credentials.userInfoData.data.records[0]
    this.currentUser = this.tokenService.getUser()

    this.epicFunction();
  }

  ngOnInit(): void {

    if (this._formatoData) {
      this._formato = this._formatoData
      if (this._formato.form) {
        this._label = this._formato.label;
        this._controls = this._formato.form.controls;
        this.control = this._controls[0].name

        this._itsReady = true;
        this.initForm()
        this.loadNotasEvolucion()
        this._loadImage()

      }
    }
  }

  /**
   *
   *
   * @private
   * @memberof NotaEvolucionComponent
   */
  private epicFunction() {
    this.isDesktopDevice = this.deviceService.isDesktop()
  }





  /**
   * 
   */
  private loadNotasEvolucion() {
    this.formatService._getNotasEvolucion(this.record.Id).subscribe({
      next: (response: any) => {
        if (response.codigo === 200) {

          if (response.data && response.data.length) {
            const error = response.data[0]
            if (error.error && error.details) {
            } else {
              const data = response.data
              this._notasEvolucion = [...data];
            }
          }
        }
      },
      error: (error) => {
        console.error({ error });
      }
    })
  }

  /**
   * 
   */
  private _loadImage() {
    /**
   * Cargamos la firma del doctor
   */
    this.formatService._getImageProfile(this.currentUser.username).subscribe({
      next: (response) => {
        if (response.codigo == 200) {
          this._Firma = response.data.formato.archivos.Firma;
        }
      }
    })

    this._Images = []
    // this._LaboratorioFiles = []
    this._img_nota_evolucion_historia_clinica = []
    this.formatService._getListImages(this._Cita.Cita_Id, this.record.Name).subscribe({
      next: (response) => {
        if (response.codigo === 200) {
          const data = response.data
          if (data.formato && data.formato.archivos) {
            const archivos = data.formato.archivos
            this._Images = archivos.Paciente ? archivos.Paciente : [];
            this._img_nota_evolucion_historia_clinica = archivos.nota_evolucion_frame

            this.firma_medico_nota_evolucion_historia_clinica = archivos.firma_medico_nota_evolucion_historia_clinica ? archivos.firma_medico_nota_evolucion_historia_clinica[0].url :  null
          }
        }
      }
    })
  }

  /**
   * 
   */
  private initForm() {
    _.map(this._controls, (a) => {
      _.map(a.Objects, (b) => {
        this.formNotaEvolucion.addControl(
          b.name, this.formBuilder.control(b.value)
        )
      })
    })
  }

  _set_log_notasEvolucion(item: any) {
    this._log_notas_evolucion = item.archivos.Nota_Evolucion
    this._selected_nota_evolucion = null
    this._cita_selected = item.Cita_Id
  }

  _setNotaEvolucion(_nota_evolucion: any) {
    this._selected_nota_evolucion = _nota_evolucion
  }


  /**
   *
   *
   * @param {*} control
   * @memberof NotaEvolucionComponent
   */
  _AddedNote(control: any) {
    //console.log({control});   
    this._notas_evolucion.push(control)
  }


  /**
   *
   *
   * @param {number} _index
   * @memberof NotaEvolucionComponent
   */
  _deleteNota(_index: number) {
    this._notas_evolucion.splice(_index, 1)
  }

  /**
   *
   *
   * @param {boolean} event
   * @memberof NotaEvolucionComponent
   */
  _onEventSaveItFormNotasEvolucion(event: boolean) {

    if(event) {
      this._notasEvolucion = []
      this.loadNotasEvolucion()
      this._loadImage()
    }

  }

  /**
   *
   *
   * @param {*} event
   * @param {string} main_label
   * @memberof NotaEvolucionComponent
   */
  _onSaveSing(event: any, main_label: string) {

    if(event) {
      this.alert._showLoader('Guardando, espere...')

      this.formatService.UploadFirmas(this._Cita.Cita_Id, this.record.Name, main_label, event)
      .then((resp) => {  
        this.alert._hideLoader()
        this._loadImage()
        this.toastr.success('Firma, cargada exitosamente','Exito!!',{
          timeOut: 3200
        })
      }).catch((error) => {
        console.error({_ERROR_LOAD_FIRMA: error });
        
        this.alert._hideLoader()
        this.toastr.error('Error al cargar la firma','Error!!',{
          timeOut: 3200
        })
      })
    }

  }
}
