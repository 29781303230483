import { AfterContentChecked, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';

import { FilePondOptions } from 'filepond';
import * as _ from 'lodash';
import { Lightbox } from 'ngx-lightbox';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-custom-upload-files',
  templateUrl: './custom-upload-files.component.html',
  styleUrls: ['./custom-upload-files.component.scss']
})
export class CustomUploadFilesComponent implements OnInit, AfterContentChecked, OnDestroy {  

  @ViewChild('myPond') myPond: any;

  @Input()
  showUpload: boolean = true;
  @Input()
  _acceptedFileTypes!: string[] 
  @Input()
  _allowMultiple: boolean = true

  @Input()
  eventSubjectClerFiles: Observable<boolean>;


  @Output()
  _filesPondsEvent: EventEmitter<any[]> = new EventEmitter<any[]>();
  
  @Input()
  _maxFiles: number

  @Input()
  _showGallery!: boolean

  acceptedFileTypes: string[] = ['application/pdf']
  maxFiles: number = 1

  pondOptions: FilePondOptions = {    
    className:'dropzone_sec',    
    chunkUploads:true
  };

  pondFiles: FilePondOptions["files"] = [];
  _pondItemFiles: any[] = []

  _urls: any[] = []
  Base64images: { id:  string, src: string; thumb: string; caption: string }[] = [];
  _showImgs: boolean = true

  $_subscriber: Subscription;



  constructor (
    private lightbox: Lightbox,
    private changeRef: ChangeDetectorRef
  ) {}
  
  ngOnInit(): void {   

    this.$_subscriber = this.eventSubjectClerFiles.subscribe({
      next:(resp) => {
        if(resp) {
          console.log({resp});          
          this._onDelete()          
        }
      }
    })

    if(this._acceptedFileTypes) {
      this.pondOptions = {
        labelIdle: 'Selecciona o arrastra y suelta los archivos a cargar',
        acceptedFileTypes: this._acceptedFileTypes,
        allowMultiple: this._allowMultiple,
        maxFiles:this._maxFiles
      }      
    }else{
      this.pondOptions = {
        labelIdle: "Suelte aquí o seleccione el archivo a subir",
        allowMultiple: this._allowMultiple,
        acceptedFileTypes: this._acceptedFileTypes,
        maxFiles: this.maxFiles
      }
     
    }
    
     
  }

  ngOnDestroy(): void {
    if(this.$_subscriber) {
      this.$_subscriber.unsubscribe()
    }
  }

  ngAfterContentChecked(): void {
    this.changeRef.detectChanges()
  }

  pondHandleInit() { }

  pondHandleAddFile(event: any) { 

    //console.log({_EVENT___: event});

    if(!event.error) {
      const file = event.file.file
      const _event = event.file     

      var reader = new FileReader();
      reader.readAsDataURL(file)

      reader.onload = (event) => { 
        const url = event.target ? event.target.result: null     
        if(url) {      
          // TODO
          /**
           * Borrar el IndexOF del Base64 de las imagenes
           */
          this.Base64images.push({
            id: _event.id,
            src: String(url),
            caption: file.name,
            thumb: file.name            
          })      

          this._urls.push({
            id: _event.id,
            filename: file.name,
            src: url,
            type: _event.fileType,
            _showComentario: false,
            comentario: null,
            pondFile: _event
          })        

        }
      }

      if(!this.showUpload) {
        this._filesPondsEvent.emit(this._urls)
      }

      this.pondFiles?.push(event)    
    }    
  } 

  /**
   * 
   * @param event 
   */
  pondRemoveFile(event: any) {
    if(this.pondFiles) {      
      const _id = event.file.id;    
      _.remove(this.pondFiles, (a:any) => {return a.file.id  === _id })   
      _.remove(this._urls, (a:any) => { return a.id === _id} )
      _.remove(this.Base64images, (a: any ) => { return a.id === _id }  )

      if(!this.showUpload) {
        this._filesPondsEvent.emit(this._urls)
      }
    }
  }

  /**
   * 
   * @param event 
   */
  pondHandleActivateFile(event: any) {}

  /**
   * 
   * @param inde 
   */
  _deleteImg(inde: number) { }

  /**
   * 
   * @param index 
   */
  openBase64(index: number) {
    this.lightbox.open(this.Base64images, index, { });
  }

  _onUpload(): void {
    if(this.pondFiles){
      this._filesPondsEvent.emit(this._urls)
    }      
  }

  /**
   * 
   * @param data 
   * @param index 
   * @returns 
   */
  _deleteComentario(data: any, index: number) {
    data._showComentario = false
    data.comentario = null   
    
    this._urls[index] = data
    //this.changeRef.detectChanges()
    return     
  }

  _onDelete() {     
    _.map(this.pondFiles, (a: any) => {
      a.pond.removeFiles()       
    })
  } 

}
