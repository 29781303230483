<div class="row mt-4" *ngIf="!_reloadCanidates && !editable && !_paciente_id">
    <div class="col-12">
        <div class="mb-3">
            <label class="form-label">Buscar Candidato</label>
            <ng-select [items]="_listadoCandidatos" bindLabel="Name" [virtualScroll]="true"
                [(ngModel)]="_candidatoSelect" [loading]="_loaderCandidatos" (scrollToEnd)="fetchMore(_set_index)"
                [ngModelOptions]="{standalone: true}" (ngModelChange)="_onChangeCandidato()">
            </ng-select>
        </div>

    </div>
</div>
<!-- (ngSubmit)="onSubmit()" -->

<ng-container *ngIf="_istReady; else _loadTemplate">
    <form [formGroup]="_formPaciente" class="needs-validation createfile-form" autocomplete="off" novalidate>

        <!-- Campos requeridos -->
        <fieldset class="mt-4">
            <legend class="fs-16 bg-warning text-center text-white">Campos requeridos </legend>
            <div class="row g-2 mt-4">

                <div class="col-xl-3 col-lg-6 col-md-6">
                    <label class="form-label">Nombre(s)</label>
                    <input id="name" type="text" formControlName="nombre"
                        placeholder="Nombre o nombres del paciente/candidato"
                        [ngClass]="{ 'is-invalid': form['nombre'].invalid && form['nombre'].errors && form['nombre'].touched}"
                        class="form-control">
                    <div *ngIf="form['nombre'].invalid && form['nombre'].errors && form['nombre'].touched"
                        class="invalid-feedback">
                        <div *ngIf="form['nombre'].errors['required']">
                            Este campo es requerido
                        </div>
                    </div>
                </div>

                <div class="col-xl-3 col-lg-6 col-md-6">
                    <label class="form-label">Apellido(s)</label>
                    <input type="text" id="apellidos" formControlName="apellidos"
                        placeholder="Apelldo paterno apellido materno"
                        [ngClass]="{ 'is-invalid': form['apellidos'].invalid && form['apellidos'].errors && form['apellidos'].touched}"
                        class="form-control">
                    <div *ngIf="form['apellidos'].invalid && form['apellidos'].errors && form['apellidos'].touched"
                        class="invalid-feedback">
                        <div *ngIf="form['apellidos'].errors['required']">
                            Este campo es requerido
                        </div>
                    </div>
                </div>

                <div class="col-xl-3 col-lg-6 col-md-6">
                    <div class="form-group">
                        <label class="form-lable">Fecha de nacimiento</label>
                        <input type="text" class="form-control flatpickr-input" mwlFlatpickr #fecha_nacimiento
                            [altInput]="true" formControlName="fecha_nacimiento"
                            placeholder="seleccione la fecha de nacimiento"
                            [ngClass]="{ 'is-invalid': form['fecha_nacimiento'].invalid && form['fecha_nacimiento'].errors && form['fecha_nacimiento'].touched}"
                            [convertModelValue]="true" (blur)="onBlur($event)" (change)="onCahnge($event)"
                            dateFormat="Y-m-d">
                        <div *ngIf="form['fecha_nacimiento'].invalid && form['fecha_nacimiento'].errors && form['fecha_nacimiento'].touched"
                            class="invalid-feedback">
                            <div *ngIf="form['fecha_nacimiento'].errors['required']">
                                Este campo es requerido
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-xl-3 col-lg-6 col-md-6">
                    <label class="form-label">Telefono</label>
                    <input type="text" mask="(000) 000 0000" formControlName="telefono"
                        [ngClass]="{ 'is-invalid': form['telefono'].invalid && form['telefono'].errors && form['telefono'].touched}"
                        [allowNegativeNumbers]="false" class="form-control">
                    <div *ngIf="form['telefono'].invalid && form['telefono'].errors && form['telefono'].touched"
                        class="invalid-feedback">
                        <div *ngIf="form['telefono'].errors['required']">
                            Este campo es requerido
                        </div>
                    </div>
                </div>

            </div>
            <div class="row  g-2 mt-4">
                <div class="col-xl-4 col-lg-4 col-md-6 ">
                    <label class="form-label">Sexo</label>
                    <ng-select [items]="_sex" bindLabel="description" bindValue="id" formControlName="sexo"
                        placeholder="Sexo del Candiato"
                        [ngClass]="{ 'is-invalid': form['sexo'].invalid && form['sexo'].errors && form['sexo'].touched}"></ng-select>
                    <div *ngIf="form['sexo'].invalid && form['sexo'].errors && form['sexo'].touched"
                        class="invalid-feedback">
                        <div *ngIf="form['sexo'].errors['required']">
                            Este campo es requerido
                        </div>
                    </div>
                </div>

                <div class="col-xl-4 col-lg-4 col-md-6">
                    <label class="form-label">Centro Medico Relacionado</label>
                    <ng-select [items]="_recursos" bindLabel="Name" bindValue="Id"
                        placeholder="seleccione el centro medico" formControlName="centro_medico"
                        (change)="_onChangeSelected($event)"
                        [ngClass]="{ 'is-invalid': form['centro_medico'].invalid && form['centro_medico'].errors && form['centro_medico'].touched}">
                    </ng-select>
                    <div *ngIf="form['centro_medico'].invalid && form['centro_medico'].errors && form['centro_medico'].touched"
                        class="invalid-feedback">
                        <div *ngIf="form['centro_medico'].errors['required']">
                            Este campo es requerido
                        </div>
                    </div>
                </div>

                <div class="col-xl-4 col-lg-4 col-md-6">
                    <label class="form-label">¿Candidato a cirugía?</label>
                    <ng-select [items]="_candidatoCirugia" bindLabel="description" formControlName="candidato_cirugia"
                        [ngClass]="{ 'is-invalid': form['candidato_cirugia'].invalid && form['candidato_cirugia'].errors && form['candidato_cirugia'].touched}"
                        bindValue="id">
                    </ng-select>
                    <div *ngIf="form['candidato_cirugia'].invalid && form['candidato_cirugia'].errors && form['candidato_cirugia'].touched"
                        class="invalid-feedback">
                        <div *ngIf="form['candidato_cirugia'].errors['required']">
                            Este campo es requerido
                        </div>
                    </div>
                </div>

                <div class="col-xl-4 col-lg-4 col-md-6">
                    <label class="form-label">Empresa Oddo</label>
                    <ng-select [items]="_empresasOdoo" bindLabel="empresa" bindValue="id" formControlName="odoo_api"
                        [ngClass]="{ 'is-invalid': form['odoo_api'].invalid && form['odoo_api'].errors && form['odoo_api'].touched}"></ng-select>
                    <div *ngIf="form['odoo_api'].invalid && form['odoo_api'].errors && form['odoo_api'].touched"
                        class="invalid-feedback">
                        <div *ngIf="form['odoo_api'].errors['required']">
                            Este campo es requerido
                        </div>
                    </div>
                </div>

            </div>
            <div class="row g-2 mt-4 ">

                <div class="col-xl-4 col-lg-4 col-md-6">
                    <label class="form-label">Origen del Paciente</label>
                    <ng-select [items]="_origenPaciente" bindLabel="description" bindValue="id"
                        [ngClass]="{ 'is-invalid': form['origen_paciente'].invalid && form['origen_paciente'].errors && form['origen_paciente'].touched}"
                        formControlName="origen_paciente">
                    </ng-select>
                    <div *ngIf="form['origen_paciente'].invalid && form['origen_paciente'].errors && form['origen_paciente'].touched"
                        class="invalid-feedback">
                        <div *ngIf="form['origen_paciente'].errors['required']">
                            Este campo es requerido
                        </div>
                    </div>
                </div>

                <div class="col-xl-4 col-lg-4 col-md-6">
                    <label class="form-label">Especialidad</label>
                    <ng-select [items]="_especialidad"
                        [ngClass]="{ 'is-invalid': form['especialidad'].invalid && form['especialidad'].errors && form['especialidad'].touched}"
                        formControlName="especialidad">
                    </ng-select>
                    <div *ngIf="form['especialidad'].invalid && form['especialidad'].errors && form['especialidad'].touched"
                        class="invalid-feedback">
                        <div *ngIf="form['especialidad'].errors['required']">
                            Este campo es requerido
                        </div>
                    </div>
                </div>

                <div class="col-xl-4 col-lg-4 col-md-6">
                    <label class="form-label">Método de contacto</label>
                    <ng-select [items]="_metodo_contacto" bindLabel="description" bindValue="id"
                        [ngClass]="{ 'is-invalid': form['metodo_contacto'].invalid && form['metodo_contacto'].errors && form['metodo_contacto'].touched}"
                        formControlName="metodo_contacto">
                    </ng-select>
                    <div *ngIf="form['metodo_contacto'].invalid && form['metodo_contacto'].errors && form['metodo_contacto'].touched"
                        class="invalid-feedback">
                        <div *ngIf="form['metodo_contacto'].errors['required']">
                            Este campo es requerido
                        </div>
                    </div>
                </div>

            </div>
        </fieldset>

        <!-- General -->
        <fieldset class="mt-4" *ngIf="_show_datos_ubicacion_">
            <legend class="text-muted fs-16 bg-light text-center">General </legend>
            <div class="row g-2 mt-4">
                <div class="col-xl-3 col-lg-4 col-md-6">
                    <label class="form-label">Correo</label>
                    <input type="email" formControlName="correo"
                        [ngClass]="{ 'is-invalid': form['correo'].invalid && form['correo'].errors && form['correo'].touched}"
                        class="form-control">
                    <div *ngIf="form['correo'].invalid && form['correo'].errors && form['correo'].touched"
                        class="invalid-feedback">
                        <div *ngIf="form['correo'].errors['email']">
                            Este campo dede ser un correo valido
                        </div>
                    </div>
                </div>

                <div class="col-xl-2 col-lg-3 col-md-4">
                    <label class="form-label">Edad</label>
                    <input type="text" class="form-control" formControlName="edad" readonly [disabled]="true">
                </div>

                <div class="col-xl-3 col-lg-4 col-md-6">
                    <label class="form-label">Estado civil</label>
                    <ng-select [items]="_estadoCivil" bindLabel="description" bindValue="id"
                        formControlName="estado_civil">
                    </ng-select>
                </div>



            </div>
            <div class="row g-2 mt-4">
                <div class="col-xl-3 col-lg-4 col-md-6">
                    <label class="form-check-label">Seleccione si aplica con SGMM</label> &nbsp;&nbsp;
                    <input class="form-check-input ml-4 mr-4" type="checkbox" formControlName="sgmm">
                </div>

                <div class="col-xl-4 col-lg-4 col-md-6">
                    <label class="control-label">Compañía de SGMM</label>
                    <ng-select [items]="_sgmm" bindLabel="id" bindValue="description" formControlName="compañia_ssgm">
                    </ng-select>
                </div>
            </div>
        </fieldset>

        <!-- Dirección (Obligatorio capturar País, Estado, Municipio, Calle y CP)  -->
        <fieldset class="mt-4" *ngIf="_show_datos_ubicacion_">
            <legend class="text-muted fs-16 bg-light text-center">Dirección (Obligatorio capturar País, Estado,
                Municipio,
                Calle y CP)</legend>

            <div class="row g-2 mt-4">

                <div class="col-xl-3 col-lg-4 col-md-6">
                    <label class="control-label">Código postal</label>
                    <input type="text" mask="00000" class="form-control" formControlName="codigo_postal"
                        (ngModelChange)="_onChangePostalCode($event)"
                        [ngClass]="{ 'is-invalid': form['codigo_postal'].invalid && form['codigo_postal'].errors && form['codigo_postal'].touched}"
                        (keypress)="onKeyPress($event.keyCode)" [allowNegativeNumbers]="false">
                    <div *ngIf="form['codigo_postal'].invalid && form['codigo_postal'].errors && form['codigo_postal'].touched"
                        class="invalid-feedback">
                        <div *ngIf="form['codigo_postal'].errors['minlength']">
                            El valor para este campo no es valido
                        </div>
                    </div>
                </div>


                <div class="col-xl-3 col-lg-4 col-md-6">
                    <label class="control-label">País</label>
                    <ng-select [items]="_paiese" bindLabel="description" bindValue="id"
                        formControlName="pais"></ng-select>
                </div>
                <div class="col-xl-3 col-lg-4 col-md-6">
                    <label class="control-label">Estado</label>
                    <ng-select [items]="_estados" bindLabel="description" bindValue="id"
                        formControlName="estado"></ng-select>
                </div>
                <div class="col-xl-3 col-lg-4 col-md-6">
                    <label class="control-label">Municipio</label>
                    <ng-select [items]="_municipalitie" formControlName="municipio"></ng-select>
                    <div class="mb-3" *ngIf="form['municipio'].value === 'Otro' ">
                        <!-- 
                        // TODO 
                        colocar el FormcontrolName para Otro
                        -->
                        <label class="control-label">Municipio (otros)</label>
                        <input type="text" class="form-control">
                    </div>
                </div>

            </div>

            <div class="row g-2 mt-4">
                <div class="col-xl-4 col-lg-4 col-md-6">
                    <div class="form-group ">
                        <label class="control-label">Colonia</label>
                        <ng-select [items]="_colonias" formControlName="colonia" bindLabel="colonia"
                            bindValue="colonia"></ng-select>
                    </div>

                    <div *ngIf="form['colonia'].value === 'OTRO' ">
                        <div class="form-group">
                            <label class="control-label">Nombre de colonia</label>
                            <input type="text" class="form-control" formControlName="colonia_otro">
                        </div>
                    </div>


                </div>
                <div class="col-xl-4 col-lg-4 col-md-6">
                    <label class="control-label">Calle</label>
                    <input type="text" class="form-control" formControlName="calle">
                </div>
            </div>

            <div class="row g-2 mt-4">
                <div class="col-xl-3 col-lg-4 col-md-6">
                    <label class="control-label">Numero Exterior</label>
                    <input type="text" class="form-control" formControlName="num_exterior">
                </div>
                <div class="col-xl-3 col-lg-4 col-md-6">
                    <label class="control-label">Numero Interior</label>
                    <input type="text" class="form-control" formControlName="num_interior">
                </div>
            </div>
        </fieldset>

        <!-- Relaciones -->
        <fieldset class="mt-4">
            <legend class="text-muted fs-16 bg-light text-center"> Relaciones </legend>
            <!--  <div class="row g-2 mt-4"> -->
            <!--    <div class="col-xl-4 col-lg-4 col-md-6">
                    <label class="control-label">Estado (Dirección paciente)</label>
                    <ng-select
                    [items]="_estados"
                    bindLabel="description"
                    bindValue="id"
                    formControlName="estado_paciente"
                    ></ng-select>
                </div> -->
            <!--      <div class="col-xl-3 col-lg-4 col-md-6">
                    <label class="control-label">Ultima Actividad Reporte Embudo</label>
                    <ng-select
                    [items]="_actividadPaciente"
                    bindLabel="description"
                    bindValue="id"
                    formControlName="ultima_actividad_reporte"
                    ></ng-select>
                </div> -->
            <!--    <div class="col-xl-3 col-lg-4 col-md-6">
                    <label class="control-label">Referencia numero de paciente</label>
                    <input 
                    type="text" 
                    class="form-control" 
                    formControlName="referencia_numero_paciente">
                </div> -->
            <!--     <div class="col-xl-2 col-lg-4 col-md-6">
                    <label class="control-label">Mes de última cirugía</label>
                    <input 
                    type="text" 
                    class="form-control" 
                    formControlName="mes_ultima_cirugia">
                </div> -->
            <!--  </div> -->

            <div class="row g-2 mt-4">

                <!--             <div class="col-xl-3 col-lg-4 col-md-6">
                    <label class="control-label">Mes de penúltima cirugía</label>
                    <input 
                    type="text" 
                    class="form-control" 
                    formControlName="mes_penultima_cirugia">
                </div> -->

                <!--     <div class="col-xl-3 col-lg-4 col-md-6">
                    <label class="control-label">Mes de antepenúltima cirugía</label>
                    <input 
                    type="text" 
                    class="form-control" 
                    formControlName="mes_antepenultima_cirugia">
                </div> -->

                <div class="col-xl-6 col-lg-4 col-md-6">
                    <label class="control-label">Responsable Call Center</label>
                    <ng-select [items]="_listaResponsables" bindLabel="Nombre" bindValue="Id"
                        formControlName="responsable_call_center"></ng-select>
                </div>

                <div class="col-xl-4 col-lg-4 col-md-6" *ngIf="_show_datos_ubicacion_">
                    <label class="control-label">Objeción</label>
                    <ng-select [items]="_objecion" bindLabel="description" bindValue="id"
                        formControlName="objecion"></ng-select>
                </div>
                <!-- 
                <div class="col-xl-3 col-lg-3 col-md-4 mt-4 g-2">
                    <label class="form-check-label">Cumple hoy</label> &nbsp;&nbsp;
                    <input class="form-check-input ml-4 mr-4" type="checkbox" formControlName="cumple_hoy">               
                </div>    -->

                <div class="col-xl-3 col-lg-4 col-md-6" *ngIf="_show_datos_ubicacion_">
                    <label class="control-label">Seguro antiguedad</label>
                    <input type="text" class="form-control" formControlName="seguro_antiguedad">
                </div>
            </div>

            <div class="row g-2 mt-4">

                <div class="col-xl-4 col-lg-4 col-md-6">
                    <label class="control-label">Responsable Redes Sociales</label>
                    <ng-select [items]="_listaResponsables" bindLabel="Nombre" bindValue="Id"
                        formControlName="responsable_marketing"></ng-select>
                </div>

                <div class="col-xl-4 col-lg-4 col-md-6" *ngIf="_show_datos_ubicacion_">
                    <label class="control-label">Responsable de Venta</label>
                    <ng-select [items]="_listaResponsables" bindLabel="Nombre" bindValue="Id"
                        formControlName="responsable_venta"></ng-select>
                </div>
            </div>

        </fieldset>

        <!-- Information -->
        <fieldset class="mt-4" *ngIf="_show_datos_ubicacion_">
            <legend class="text-muted fs-16 bg-light text-center"> Information </legend>
            <div class="row g-2 mt-4">
                <div class="col-xl-4 col-lg-4 col-md-6">
                    <div class="form-group">
                        <label class="control-label"> Paciente que lo recomienda</label>
                        <ng-select [items]="_paciente_recomienda" bindLabel="Nombre_completo__c" bindValue="Id"
                            formControlName="paciente_recomienda"></ng-select>
                    </div>

                </div>
                <!--   <div class="col-xl-3 col-lg-4 col-md-6">
                    <label class="control-label"> Origen del paciente Historico</label>
                    <input type="text" class="form-control" formControlName="origen_paciente_historico">
                </div>   -->
                <div class="col-xl-5 col-lg-4 col-md-6">
                    <label class="control-label">Motivo de consulta</label>
                    <textarea class="form-control" [rows]="5" formControlName="motivo_consulta"></textarea>
                </div>
            </div>
            <div class="row g-2 mt-4">
                <!--        <div class="col-xl-3 col-lg-4 col-md-6">
                    <label class="control-label">Antigüedad mayor a 2 años</label>
                    <ng-select
                    [items]="_antiguedad"
                    bindLabel="description"
                    bindValue="id"
                    formControlName="antiguedad_mayor_2"
                    ></ng-select>  
                </div> -->

                <div class="col-xl-3 col-lg-4 col-md-6">
                    <label class="control-label">Contacto Emergencias Nombre</label>
                    <input type="text" class="form-control" formControlName="nombre_contacto_emergencias">
                </div>

                <div class="col-xl-3 col-lg-4 col-md-6">
                    <label class="control-label">Contacto Emergencias Teléfono</label>
                    <input type="text" class="form-control" type="text" mask="(000) 000 0000"
                        [allowNegativeNumbers]="false" formControlName="telefono_contacto_emergencias">
                </div>

                <div class="col-xl-3 col-lg-4 col-md-6">
                    <label class="control-label">Comentario</label>
                    <textarea class="form-control" [rows]="5" formControlName="comentarioinformation"></textarea>
                </div>



            </div>
        </fieldset>

        <!--     <fieldset class="mt-4">
            <legend class="text-muted fs-16 bg-light text-center"> Facturación </legend>
    
            <div class="row g-2 mt-4">
    
                <div class="col-xl-4 col-lg-4 col-md-6">
                    <label class="control-label">Razón Social</label>
                    <input type="text" class="form-control" formControlName="razon_social">
                </div>
    
                <div class="col-xl-4 col-lg-4 col-md-6">
                    <label class="control-label">Correo de Facturación</label>
                    <input type="text" 
                    [ngClass]="{ 'is-invalid': form['correo_facturacion'].invalid && form['correo_facturacion'].errors && form['correo_facturacion'].touched}"
                    class="form-control" 
                    formControlName="correo_facturacion">
                    <div *ngIf="form['correo_facturacion'].invalid && form['correo_facturacion'].errors && form['correo_facturacion'].touched" class="invalid-feedback">
                        <div *ngIf="form['correo_facturacion'].errors['email']" >
                            Este campo dede ser un correo valido
                        </div>
                    </div> 
                </div>
    
                <div class="col-xl-3 col-lg-4 col-md-6">
                    <label class="control-label">RFC</label>
                    <input type="text" class="form-control" formControlName="rfc">
                </div>
    
            </div>
            
            <div class="row g-2 mt-4">
    
                <div class="col-xl-3 col-lg-3 col-md-4 mt-4 g-2">
                    <label class="form-check-label">Misma dirección del paciente</label> &nbsp;&nbsp;
                    <input
                    class="form-check-input ml-4 mr-4" 
                    type="checkbox" 
                    formControlName="misma_direccion_paciente">               
                </div>   
    
                <div class="col-xl-3 col-lg-4 col-md-6">
                    <label class="control-label">Numero de Tarjeta</label>
                    <input type="text" class="form-control" mask="0000" [allowNegativeNumbers]="false" placeholder="Ultimo 4 digitos" formControlName="numero_tarjeta">
                </div>
    
                <div class="col-xl-5 col-lg-4 col-md-6">
                    <label class="control-label">Dirección de Facturación</label>
                    <textarea
                    class="form-control"
                    [rows]="5"
                    formControlName="direccion_facturacion"
                    ></textarea>
                </div>
    
            </div>
    
            <div class="row g-2 mt-4">
                <div class="col-xl-3 col-lg-4 col-md-6">
                    <label class="control-label">No. Póliza</label>
                    <input type="text" class="form-control" formControlName="numero_poliza">
                </div>
                <div class="col-xl-3 col-lg-4 col-md-6">
                    <label class="control-label">Deducible</label>
                    <input type="text" class="form-control" formControlName="deducible">
                </div>
                <div class="col-xl-3 col-lg-4 col-md-6">
                    <label class="control-label">Coaseguro</label>
                    <input type="text" class="form-control" formControlName="coaseguro">
                </div>
            </div>
    
        </fieldset> -->

        <!--     <fieldset class="mt-4">
            <legend class="text-muted fs-16 bg-light text-center"> System Information </legend>
            <div class="row g2- mt-4">
                <div class="col-xl-6 col-lg-4 col-md-6">
                    <div class="form-group">
                        <label class="control-label">Campaña</label>
                        <textarea
                        class="form-control"
                        [rows]="5"
                        formControlName="campaña"
                        ></textarea>
                    </div>
                    <div class="form-group">
                        <label class="control-label">Nombre del conjunto de anuncios</label>
                        <textarea
                        class="form-control"
                        [rows]="5"
                        formControlName="nombre_conjunto_anuncios"
                        ></textarea>
                    </div>
                    <div class="form-group">
                        <label class="control-label">Palabra clave</label>
                        <textarea
                        class="form-control"
                        [rows]="5"
                        formControlName="palabra_clave"
                        ></textarea>
                    </div>
                </div>
                <div class="col-xl-6 col-lg-4 col-md-6">
                    <div class="form-group">
                        <label class="control-label">Costo por resultado</label>
                        <input type="text" class="form-control" formControlName="costo_resultado">
                    </div>
                    <div class="form-group">
                        <label class="control-label">Tasa de conversión</label>
                        <input type="text" class="form-control" formControlName="tasa_conversion">
                    </div>
                    <div class="form-group">
                        <label class="control-label">ID del anuncio</label>
                        <textarea
                        class="form-control"
                        [rows]="5"
                        formControlName="id_anuncio"
                        ></textarea>
                    </div>
                    <div class="form-group">
                        <label class="control-label">ID Cuenta</label>
                        <textarea
                        class="form-control"
                        [rows]="5"
                        formControlName="id_cuenta"
                        ></textarea>
                    </div>
                  
                </div>
            </div>
    
        </fieldset> -->

        <fieldset *ngIf="_candidato && _candidato.Candidato_Id || (editable && _paciente_id != null) ">

            <div
                *ngIf="form['especialidad'].value === 'Proctología' || form['especialidad'].value === 'Coloproctología' ">
                <app-wizard-calificacion [_calificacionCandidato]="_calificacionCandidato"
                    [_candidato_id]="_Candidato_Id"></app-wizard-calificacion>
            </div>

            <div
                *ngIf="form['especialidad'].value === 'Varices' || form['especialidad'].value === 'Várices' || form['especialidad'].value === 'Varices avanzadas' ">
                <app-calificacion-varices [_candidato_id]="_Candidato_Id"
                    [_calificacion]="_calificacionVarices"></app-calificacion-varices>
            </div>

        </fieldset>
        <fieldset class="mt-4">
            <div class="d-flex justify-content-end ">
                <button type="button" class="btn btn-primary m-2 " [disabled]="_loader" (click)="onSubmit()"> {{editable
                    &&
                    _paciente_id ? 'Actualizar': 'Guardar' }} </button>
                <button type="button" class="btn btn-danger  m-2 " (click)="onCancel()"> Cancelar</button>
            </div>
        </fieldset>

    </form>
</ng-container>


<ng-template #_loadTemplate>
    <ngx-simplebar style="height: 20vh;">  
    
    </ngx-simplebar>
</ng-template>



<ngx-spinner [name]="spinnerUpdate" type="ball-scale-multiple" [fullScreen]="false"></ngx-spinner>