import { B } from "@fullcalendar/core/internal-common"
import { CreateCandidate } from "src/app/shared/models/create-candidate"

export class CalificacionCandidato {
    candidato_id: string | null
    comezon:boolean 
    sensacion_bolita:boolean
    dolor: {
        durante_el_dia:boolean
        al_evacuar:boolean
        nivel_dolor: number
    }
    sangrado: boolean 
    prioridad: number

}

export class CalificacionVarices {
    candidato_id: string
    comezon: boolean
    dolor: {
        dolor: boolean | null
        nivel_dolor: number
    }
    ardor: boolean
    sensacion_pesadez: boolean
    piernas_cansadas: boolean
    prioridad: number
}

export interface CandidatosResponse {
    codigo:number
    mensaje: string
    data: {
        candidatos: CandidatoData[]
        total: number
    }
}

export interface CandidatoData {
    Candidato_Id: string
    Datos_Candidato: CreateCandidate
    Name:string
    Paciente_Id:string
    Recurso:string
    Salesforce_Id:string
}


export interface CandidatoEstatus {
    codigo:number
    mensaje: string
    data: any[]
}


export interface Listacandidatosestructurados {    
    codigo: number
    mensaje: string //"": "Operación exitosa",
    data: {
        total: number
        candidatos: CandidatosEstructurados[]
    }
}


export interface CandidatosEstructurados {
    Candidato_Id: string //"": "a0Acc000000ru45EAA",
    Name: string //"": "TEST API 101",
    Recurso: string //"": "a0K5e000001UqlCEAS",
    generales_telefono: string //"": "6621090000",
    generales_correo: string //"": "test.api@gmail.com",
    generales_comentarios: string //"": "1",
    direccion_pais: string //"": "México",
    direccion_estado: string //"": "Sonora",
    direccion_municipio: string //"": "Otros",
    direccion_codigo_postal: string //"": "83000",
    direccion_calle: string //"": "test",
    direccion_num_exterior: string //"": "10.0",
    direccion_num_interior: string //"": "10.0",
    relacion_centro_medico_id: string //"": "a0K5e000001UqlCEAS",
    relacion_centro_medico_nombre: string //"": "IML CENTRO DE COLOPROCTOLOGIA",
    relacion_nombre: string //"": "TEST API 101",
    relacion_apellidos: string //"": "TEST",
    informacion_origen_paciente: string //"": "Convenio Axtel",
    informacion_especialidad: string //"": "Cirugía General",
    informacion_metodo_contacto: string //"": "Teléfono",
    Recurso_Id: string //"": "a0K5e000001UqlCEAS",
    Recurso_Nombre: string //"": "IML CENTRO DE COLOPROCTOLOGIA",
    Salesforce_Id: string //"": "a0Acc000000ru45EAA"
}