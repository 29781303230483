<div class="row ">
    <div class="col-12">
        <div class="form-group m-0">
            <label class="form-label">Agregar medicamento</label>
            <ng-select [items]="_medicamentos" bindLabel="nombre_producto" [(ngModel)]="_medicamento"
                [ngModelOptions]="{standalone: true}" [multiple]="true" [closeOnSelect]="false"></ng-select>
        </div>
        <div class="mt-2">
            <div class="d-flex justify-content-center ">
                <button class="btn btn-primary w-25" type="button" (click)="_addMedicamentos()">Agregar</button>
            </div>
        </div>
    </div>

    <div class="col-12 mt-4">
        <div class="d-flex justify-content-end">
            <button class="btn btn-soft-success waves-effect waves-light w-50" (click)="_AddedMedicamento()"> <i
                    class="ri-add-fill  align-middle me-1 "> </i> Agregar otro medicamento <small>(Que no esta en el
                    listado)</small> </button>
        </div>
    </div>

</div>
<form [formGroup]="_formMedicamentos" (ngSubmit)="_onSaveMedicamentos()" class="mt-4" novalidate>
    <ngx-simplebar style="max-height: calc(100vh - 80px)">
        <div class="card ">
            <div class="card-body">
                <div formArrayName="medicamentos">
                    <div class="row">
                        <div class="mt-2" *ngFor="let _medicamento of medicamentos.controls; let i = index">
                            <div class="d-flex justify-content-end ">
                                <button class="btn btn-link" (click)="onDeleteMedicamento(i)"> <i
                                        class="mdi mdi-trash-can-outline align-middle me-1 fs-4 text-danger"> </i>
                                </button>
                            </div>
                            <div [formGroupName]="i">
                                <div class="card card-body ">
                                    <div class="row">
                                        <!--  -->
                                        <div class="col-xl-4 col-lg-6">
                                            <div class="form-group">
                                                <label class="form-label">Nombre producto </label>
                                                <textarea class="form-control" [rows]="1"
                                                    formControlName="nombre_producto"
                                                    [placeholder]=" _medicamento.value.readOnly ? '': 'ingrese el nombre del producto'  "
                                                    [readonly]="_medicamento.value.readOnly"
                                                    [disabled]="true"> </textarea>
                                            </div>
                                        </div>
                                        <!--  -->
                                        <div class="col-xl-4 col-lg-6">
                                            <div class="form-group">
                                                <label class="form-label">Tipo</label>

                                                <ng-container *ngIf="_medicamento.value.readOnly; else selectTipo">
                                                    <textarea 
                                                    class="form-control" [rows]="1" formControlName="tipo"
                                                        [readonly]="_medicamento.value.readOnly"
                                                        [disabled]="true"></textarea>
                                                </ng-container>

                                                <ng-template #selectTipo>
                                                    <ng-select
                                                    [items]="_selectTipo"
                                                    bindLabel="id"
                                                    bindLabel="description"
                                                    placeholder="seleccione el tipo de medicamento"
                                                    formControlName="tipo"
                                                    [clearable]="false"
                                                    ></ng-select>
                                                </ng-template>
                                            </div>
                                            <div class="form-group mt-4" *ngIf="!_medicamento.value.readOnly">
                                                <label class="form-label">Dosis/presentación</label>
                                                <textarea class="form-control" 
                                                [rows]="2"
                                                formControlName="descripcion_producto"
                                                [placeholder]=" 'ingrese la Dosis/presentación'  "
                                                
                                                [disabled]="true"> </textarea>
                                            </div>
                                        </div>
                                                          
                                        <!--  -->
                                        <div class="col-xl-4 col-lg-6">
                                            <div class="form-group">
                                                <label class="form-label">Aplicación del medicamento</label>
                                                <textarea class="form-control" [rows]="2"
                                                    [placeholder]=" _medicamento.value.readOnly ? '': 'ingrese la descripcion'  "
                                                    formControlName="descripcion_aplicacion"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="mt-4" *ngIf="medicamentos.controls && medicamentos.controls.length">
                        <div class="d-flex justify-content-end">
                            <button type="submit" class="btn text-uppercase"
                                [ngClass]="_receta ? 'btn-success': 'btn-primary' ">{{_receta ? 'Actualizar': 'Guardar'
                                }}</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-footer">
                <div class="row" *ngIf="_doctor">
                    <div class="col-xl-3 col-lg-6">
                        <div class="card card-body">
                            <div class="row d-flex justify-content-center">
                                <p class="text-uppercase small mb-0"> {{_doctor.name}} </p>
                                <p class="text-primary text-uppercase small mb-0">{{_doctor.tipo_empleado}}</p>
                                <!-- <p class="text-primary text-uppercase small mb-0">MËDICO CIRUJANO</p> -->
                                <p class="text-uppercase small mb-0">Tel. {{_doctor.telphone | mask:'(000) 000 0000' }}
                                </p>
                                <p class="text-primary fw-bold mb-0">Correo. {{_doctor.correo}}</p>
                                <p class="text-uppercase small mb-0">CED. PROF.{{_doctor.n_cedula}}</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-3 col-lg-6" *ngIf="_doctor && _doctor.clinica ">
                        <div class="card card-body">
                            <div class="row d-flex mt-4 justify-content-center">
                                <span class="text-primary text-uppercase small mb-0 "> {{_doctor.clinica.name}} </span>
                                <span class="text-uppercase small mb-0"> {{_doctor.clinica.telphone}} </span>
                                <span class="text-uppercase small mb-0"> {{_doctor.clinica.direction}} </span>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-6 col-lg-12">
                        <app-firma-doctor></app-firma-doctor>
                        <!--     <ng-container *ngIf="_Firma && _Firma.length; loadedTemplate">    
                            <div class="card" *ngFor="let item of _Firma" >
                                <div class="card-body">
                                    <div class="d-flex justify-content-center"  >
                                        <figure class="figure mb-0">
                                            <img [src]="item.url" class="figure-img img-fluid" max  alt="" width="100">
                                        </figure>                                        
                                    </div>                                    
                                </div>                                                               
                               </div>
                        </ng-container>
                        <ng-template #loadedTemplate>
                            
                        </ng-template> -->
                    </div>
                </div>
            </div>
        </div>
    </ngx-simplebar>
</form>