import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import * as moment from 'moment';
import { LocalSessionService } from '../../services/local-session.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import * as _ from 'lodash';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, Subscription, map, share, timer } from 'rxjs';
import { CalendarService } from '../../calendar/services/calendar.service';
import { CredentialsService } from '@app/core/services/credentials.service';
import { FilterCalendar } from '../../models/filter-calendar';

const _ANESTESIOLOGO_VAL = '_json_anestesiologo'

interface _json_anestesiologo_items {
  hora: string

}

@Component({
  selector: 'app-mobile-template-anestesiologo',
  templateUrl: './mobile-template-anestesiologo.component.html',
  styleUrls: ['./mobile-template-anestesiologo.component.scss']
})
export class MobileTemplateAnestesiologoComponent implements OnInit, OnDestroy {

  @ViewChild('collapse') collapse !:ElementRef<any>

  @Input()
  basicLineChart: any

  @Input()
  lineWithDataLabelsChart: any

  @Input()
  formControls: any

  @Input()
  eventSubjectSpinner: Observable<boolean>

  @Output()
    _eventSaveEmmitter: EventEmitter<any> = new EventEmitter<any>()

  isCollapsed = true;
  _Objects: any

  _bodyForm: any
  Cita_Id = 'E-151822'

  _filterCalendar: FilterCalendar = new FilterCalendar()

  $subscriber: Subscription;

  _local_anestesiologo: _json_anestesiologo_items //| null
  public formAnestesiologo: FormGroup = this.formBuilder.group({})
  
  private readonly _now = moment()//.utcOffset(0)  

  _time_now = this._now.format('HH:mm')

  _form_name: string

  _spinner_anestesiologo = '_spinner_anestesiologo'
  _grafica_anestesiologo = 'grafica_registro_anestesia'
  //_Objects: any

  time = new Date();
  rxTime = new Date();
  intervalId: any;
  subscription: Subscription;

  _hour_caputred: any

  _list_date: any[] = []

  constructor (
    private _local: LocalSessionService,
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    private calendar: CalendarService,
    private credentials: CredentialsService
  ) {}

  ngOnInit(): void {

    this._Objects = this.formControls[0]

    this.initTimer()
    this.listTimer() 

    //console.log({_hour_caputred: this._hour_caputred});
    if(!this._hour_caputred) {

      const _first = moment().startOf('hour')     
      this._hour_caputred = _first      
    }
                      
    //console.log({dates, _hour_caputred: this._hour_caputred})    

    const _id_salesForce =  this.credentials.credentials?.id_salesforce
   
    if(_id_salesForce) {
      //console.log({_id_salesForce});
      
      this._filterCalendar.doctor = _id_salesForce
      this._filterCalendar.inicio = ''
      this._filterCalendar.final  = ''
      //this._filterCalendar.
    }

    this.loadCitas()

    this.$subscriber = this.eventSubjectSpinner.subscribe({
      next:(resp) => {
        if(resp) {
          this.spinner.hide(this._spinner_anestesiologo)
        }
      },  
      error:(error) => {
        console.error({_ERROR: error});
        
      }
    })

    let _hora_anesteiolgo = this._local.getJsonValues(_ANESTESIOLOGO_VAL)

    if(_hora_anesteiolgo) {
      this._local_anestesiologo = _hora_anesteiolgo
      this._time_now = _hora_anesteiolgo.hora      
    }else {

      this._local_anestesiologo = {
        hora: this._time_now
      }
      this._local.savedJsonValues(_ANESTESIOLOGO_VAL,this._local_anestesiologo)
    }

    this.initForm(this._Objects)
  }

  ngOnDestroy(): void {
    if(this.subscription) {
      this.subscription.unsubscribe()
    }
  }

  get f() {
    return this.formAnestesiologo.controls;
  }

  /**
   * 
   */
  private listTimer() {
    let startDate = moment().startOf('h');

    let dates = [];
    let hourDivider = 2;
    let t = hourDivider * 24;

    while(t--) {      
      startDate.add(15, 'minute');
      let _added_minutes = moment(startDate)//.format('HH:mm')
      //console.log({_START_DATA: startDate,  });

      if(_added_minutes >= this._now ) {
        //console.log('AAAAAAAAAAA');
        dates.push(startDate.format('H:mm'));
        
      }else{
     //   console.log({_added_minutes});
          this._hour_caputred = _added_minutes
      } 
    }   
    this._list_date = dates
  }

  /**
   * 
   * @param Objects 
   */
  private initForm (Objects: any) {   

    //_.map(Objects)

    _.map(Objects.Objects ,(a) => {    
      
      /**
       * 
       */
      _.map(a.Objects, (b) => {       
        this.formAnestesiologo.addControl(
          b.name, this.formBuilder.control(b.value)
        )        
      })    

      /**
       * 
       */
      this.formAnestesiologo.addControl(
        a.name, this.formBuilder.control(a.value)
      )
      
    })
    console.log({ANESTESIOLOGO: this.formAnestesiologo});  
  }

  /**
   * 
   */
  private initTimer() {
    // Using Basic Interval
    /*     this.intervalId = setInterval(() => {
      this.time = new Date();
    }, 1000); */

    // Using RxJS Timer
    this.subscription = timer(0, 1000)
      .pipe(
        map(() => new Date()),
        share()
      )
      .subscribe({
        next:(resp) => {
          this.rxTime = resp

          let _time = moment(resp).format('H:mm')
          let _time_1 = this._list_date[0]

         //console.log({_time, _time_1 });

          if(_time == _time_1) {
           // console.log('Holaaa');
            this._hour_caputred = resp // _time_1

            //this._hour_caputred.split()
            this.listTimer()
            
          }else{ 
            //console.log({_TIME_: this.rxTime});
            
          }

       
          

          //console.log({rxTime: this.time});
          

        }
      })
  }

  /**
   * Cargamos las Citas
   */
  private loadCitas() {
   // console.log({FILTER: this._filterCalendar});
    
    this.calendar._getFilterCalendar(this._filterCalendar).subscribe({
      next:(response) => {
       // console.log({_RESPONSE_: response});
        
      },
      error:(error)=> {
    //    console.error({_ERROR: error});
        
      }
    })

  }

  /**
   * 
   */
  _onSubmit() {

    if(this.formAnestesiologo.valid) {
      let _values = this.formAnestesiologo.value;

      console.log({_VALUES: _values});
      

    }else {
      console.log('NOT VALID');
      
    }

    //console.log({_form_name: this._form_name});
    
    //this.spinner.show(this._spinner_anestesiologo)

   /*  _.map(this.formControls, (a) => {
      
      if(a.name == this._form_name) {
        _.map(a.Objects, (b) => {          
          let _hora: any[] = []
          _.map(b.Objects, (c, i)=> {  
            let _value_form  = this.f[b.Objects[0].name].value             
            if(c.label == 'HORA' && _value_form != '') {
              //c.
              _hora.push({
                fecha_hora: moment(`${this._now.format('YYYY-MM-DD')} ${this._time_now}`).format('YYYY-MM-DD HH:mm'),
                value: _value_form
              })
           
             c.value = _hora
            }        
          })          
        })      
      }      
    })   


    let _bodyForm = {
      Cita_Id: 'E-151822', //this._Cita.Cita_Id,
      label: 'Registro_Anestesia', //this.formControls.label,
      form: this.formControls //_formato.form
    }
    
    //this._bodyForm = _bodyForm
    
    this._eventSaveEmmitter.next(_bodyForm) */

    
    

  }

  /**
   * 
   * @param collapse 
   * @param Objects 
   */
  _collapsed(collapse: any, Objects: any) {   
    //console.log({Objects});
    this._Objects = Objects

    this._form_name = Objects.name

    this.initForm(Objects)
    collapse.toggle()    
  }


  /**
   * 
   * @param item 
   */
  async _setObjects(item: any) {
    console.log({_ITEMS_: item});
    
    this._Objects = item
    await this.removeControls()
    this.initForm(this._Objects);

  }

  private async removeControls (): Promise<void> {
    while(Object.keys(this.f).length){
      const toRemove = Object.keys(this.formAnestesiologo.controls)[0];
      this.formAnestesiologo.removeControl(toRemove)
    }    
    console.log({ANESTESIOLOGO_CLEAR_FORM: this.formAnestesiologo});

  }

}
