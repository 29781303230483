import { PatientsService } from '@app/pages/calendar/services/patients.service';
import { Component, HostListener, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AlertsService } from '@app/shared/services/alerts.service';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';


interface _oldClinicalHistory {
  cita_id: string
  paciente_id: string
  nombre_paciente: string
  apellidos_paciente: string
  historia_clinica_antigua: boolean
  files: any[]
}

@Component({
  selector: 'app-old-history-clinical',
  templateUrl: './old-history-clinical.component.html',
  styleUrls: ['./old-history-clinical.component.scss']
})
export class OldHistoryClinicalComponent implements OnInit {


  public innerWidth: number;
  @HostListener('window:resize', [])
  onResize(): void {
    this.checkView()
  }

  _showUploadfiles: boolean = true
  eventSubjectClerFiles: Subject<boolean> = new Subject<boolean>();

  _formOldClinicalHistory: FormGroup;

  _files: any[] = []

  constructor(
    private fb: FormBuilder,
    private alert: AlertsService,
    private patient: PatientsService,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.checkView()
    this.initform()
  }

  get isMobile(): boolean {
    return this.innerWidth <= 1024;
  }
  private checkView(): void {
    this.innerWidth = window.innerWidth;
  }

  /**
   * 
   */
  private initform() {
    this._formOldClinicalHistory = this.fb.group({
      nombre_paciente: [null],
      apellidos_paciente: [null],
      cita_id: [null],
      paciente_id: [null]

    })
  }


  /**
   * 
   * @param $event 
   */
  onEventAddedFiles(event: any[]) {
    this._files = [...event]
  }

  /**
   *
   *
   * @memberof OldHistoryClinicalComponent
   */
  _onsaveIt() {
    if (this._files && this._files.length) {

      this.alert._showLoader('Guardando espere...')

      let _clinicaOld: _oldClinicalHistory = {
        cita_id: this._formOldClinicalHistory.value.cita_id,
        apellidos_paciente: this._formOldClinicalHistory.value.apellidos_paciente,
        nombre_paciente: this._formOldClinicalHistory.value.nombre_paciente,
        paciente_id: this._formOldClinicalHistory.value.paciente_id,
        historia_clinica_antigua: true,
        files: this._files
      }

      this.patient._uploadOldHistoryClinic(_clinicaOld).then((resp) => {
        this.eventSubjectClerFiles.next(true)
        this._formOldClinicalHistory.reset()
        this.alert._hideLoader()
        this.toastr.success('Se han registrado los archivos de la historia clínica antigua', `Exito al guardar Cita ${_clinicaOld.cita_id}, Paciente ${_clinicaOld.paciente_id}`, {
          progressBar: true,
          timeOut: 3200
        })

      }).catch((error) => {
        this.alert._hideLoader()
        console.error({ error });
        this.toastr.error('Ocurrió  un error al guardar los registros', 'Error', {
          timeOut: 3200,
          progressBar: true
        })

      })
    }
  }

}
