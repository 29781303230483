<!-- <app-notification></app-notification>  -->
<app-breadcrumbs [title]="_nombre_completo" [breadcrumbItems]="breadCrumbItems"
    (eventClickEmitter)="_onEventEmmiterClick($event)"></app-breadcrumbs>

<div class="chat-wrapper d-lg-flex gap-1 mx-n4 mt-n4 p-1">

    <div class="file-manager-sidebar " id="menusidebar">
        <div class="p-3 d-flex flex-column h-50 ">
            <div class="d-flex flex-wrap gap-2 justify-content-center " *ngIf="_prioridad">
                <span class="text-muted text-uppercase fw-bold"> Paciente tipo
                    <span class="badge fs-4 fw-bold" [ngClass]="getbgBadge(_prioridad)"> {{getLevel(_prioridad)}}
                    </span>
                </span>

            </div>
            <ngx-simplebar class="mt-3 mx-n4 px-4 file-menu-sidebar-scroll">
                <ul class="list-unstyled file-manager-menu">
                    <li>
                        <a data-bs-toggle="collapsePacientes" href="javascript:void(0);" role="button"
                            aria-expanded="true" aria-controls="collapsePacientesId"
                            (click)="collapsePacientes.toggle()" [attr.aria-expanded]="!isCollapsedPacientes">
                            <i class="ri-user-line  align-bottom me-2"></i>
                            <span class="file-list-link">Listado de pacientes</span>
                        </a>
                        <div class="collapse show" id="collapsePacientesId" #collapsePacientes="ngbCollapse"
                            [(ngbCollapse)]="isCollapsedPacientes">
                            <ul class="sub-menu list-unstyled">
                                <li *ngFor="let paciente of _clientes_espera">
                                    <a href="javascript:void(0);" (click)="_onloadHistory(paciente)">
                                        {{paciente.data.pacient}} </a>
                                </li>
                            </ul>
                        </div>
                    </li>

                    <ng-container *ngIf="!_loadFromCita; else _templateCitaMenu">
                        <li>
                            <a data-bs-toggle="collapse" href="javascript:void(0);" role="button" aria-expanded="true"
                                aria-controls="collapseExample" (click)="collapse.toggle()"
                                [attr.aria-expanded]="!isCollapsed">
                                <i class="ri-folder-2-line align-bottom me-2"></i>
                                <span class="file-list-link">Formatos</span>
                            </a>
                            <div class="collapse show" id="collapseExample" #collapse="ngbCollapse"
                                [(ngbCollapse)]="isCollapsed">
                                <ul class="sub-menu list-unstyled">
                                    <li *ngFor="let formato of _pacientesFormatos">
                                        <a href="javascript:void(0);"
                                            (click)="_routerLink(formato)">{{formato.label}}</a>
                                        <ul *ngIf="formato.subItems && formato.subItems.length"
                                            class="sub-menu list-unstyled">
                                            <li *ngFor="let subitem of formato.subItems">
                                                <a href="javascript:void(0);"
                                                    (click)="_routerLink(subitem)">{{subitem.label}} </a>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                        </li>
                    </ng-container>
                    <!--  -->
                    <ng-template #_templateCitaMenu>
                        <li *ngFor="let formato of _pacientesFormatos">
                            <a data-bs-toggle="collapse" href="javascript:void(0);" role="button" aria-expanded="true"
                                aria-controls="collapseExample" (click)="collapse.toggle()"
                                [attr.aria-expanded]="!isCollapsed">
                                <i class="ri-folder-2-line align-bottom me-2"></i>
                                <span class="file-list-link">{{formato.label}} </span>
                            </a>

                            <div class="collapse show" id="collapseExample" #collapse="ngbCollapse"
                                [(ngbCollapse)]="isCollapsed">
                                <ul class="sub-menu list-unstyled">
                                    <div *ngFor="let _formato of formato.subItems; let i = index;">
                                        <li>
                                            <a href="javascript:void(0);" (click)="_routerLink(_formato)"
                                                [ngClass]="{'active': _formato_id === _formato._sub_link }">{{_formato.label}}
                                            </a>
                                            <ul *ngIf="_formato.subItems && _formato.subItems.length"
                                                class="sub-menu list-unstyled">
                                                <li *ngFor="let subitem of _formato.subItems">
                                                    <a href="javascript:void(0);"
                                                        (click)="_routerLink(subitem)">{{subitem.label}} </a>
                                                </li>
                                            </ul>

                                        </li>
                                    </div>

                                </ul>
                            </div>

                        </li>
                    </ng-template>

                </ul>
            </ngx-simplebar>

            <div class="mt-2" *ngIf="_citasSubsecuentes && _citasSubsecuentes.length">
                <ngx-simplebar style="height: 300px;">
                    <h6 class="fs-11 text-muted text-uppercase mb-3">Citas subsecuentes</h6>
                    <div class="d-flex align-items-center">
                        <div class="flex-grow-1 m-1 ">
                            <div class="row gap-2 m-2">
                                <div class="col-12" *ngFor="let item of _citasSubsecuentes">
                                    <div class="d-grid gap-2">
                                        <button type="button"
                                            class="btn btn-primary btn-label waves-effect waves-light rounded-pill"
                                            (click)="_onOpenCita(item)">
                                            <i
                                                class="ri-error-warning-line label-icon align-middle rounded-pill fs-16 me-2"></i>
                                            {{item.Tipo_Cita}}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ngx-simplebar>
            </div>

            <div class="mt-4" *ngIf="_citas_paciente && _citas_paciente.length">
                <ngx-simplebar style="max-height: calc(100vh - 70px)">
                    <h6 class="fs-11 text-muted text-uppercase mb-3">Citas Log</h6>
                    <div class="d-flex align-items-center ">
                        <div class="flex-grow-1 m-1">
                            <div class="row gap-2 m-2">
                                <div class="col-12" *ngFor="let item of _citas_paciente">
                                    <div class="d-grid gap-2">
                                        <button type="button"
                                            class="btn  btn-label waves-effect waves-light rounded-pill"
                                            [ngClass]=" item.Name === _cita.Cita_Id ? 'btn-primary' : 'bg-primary-subtle' "
                                            (click)="_setCita(item)">
                                            <i
                                                class="ri-error-warning-line label-icon align-middle rounded-pill fs-16 me-2"></i>
                                            {{item.Tipo_cita}} - {{item.Name}}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ngx-simplebar>
            </div>
        </div>
    </div>

    <div class="file-manager-content w-100 p-3 py-0 border border-1 ">
        <div class="bg-primary" *ngIf="_formato">
            <h4 class="fs-4 text-white bold text-uppercase text-center"> {{formato.label}} </h4>
        </div>
        <!-- <ngx-simplebar class="mx-n3 pt-4 px-4 file-manager-content-scroll"> -->
        <div id="folder-list" class="mb-2">
            <div class="row justify-content-beetwen g-2 mb-3">
                <div class="col">
                    <div class="d-flex align-items-center mt-2">
                        <div class="flex-shrink-0 me-2 d-block d-lg-none">
                            <!--   <button type="button" class="btn btn-soft-success btn-icon btn-sm fs-16 file-menu-btn">
                                    <i class="ri-menu-2-fill align-bottom"></i>
                                </button> -->

                            <app-right-sidebar (settingsButtonClicked)="onSettingsButtonClicked()"
                                [Formatos]="_pacientesFormatos" [_CitasSubsecuentes]="_citasSubsecuentes"
                                [_citas_paciente]="_citas_paciente" (SetIdFormato)="onSetIdFormato($event)">
                            </app-right-sidebar>

                        </div>
                        <div class="flex-grow-1">
                            <button class="btn btn-secondary w-sm flex-shrink-0" (click)="_onBack()"> <i
                                    class=" ri-arrow-left-s-line  align-bottom me-1"></i> Volver atras </button>
                            <div class="float-end ">

                                <button class="btn btn-primary w-sm flex-shrink-0 m-1" (click)="_openModal()">Cita
                                    subsecuente
                                </button>

                                <button class="btn btn-secondary w-sm flex-shrink-0 m-1" (click)="_openModal2()">Receta
                                    (Medicamento)
                                </button>
                                <button class="btn btn-icon w-sm flex-shrink-0 m-1" *ngIf="_formato">
                                    <i-feather name="save"></i-feather> <!-- {{timer$ | async  }} -->
                                </button>
                                <button class="btn btn-success w-sm flex-shrink-0 m-1" *ngIf="_showBtnPrint"
                                    (click)="_printFormato()">Imprimir formato </button>
                                <!--          -->
                            </div>
                            <!--  <h5 class="fs-16 mb-0" *ngIf="_formato"> {{_label_formato}} </h5> -->
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-2">
                <div class="col">
                    <div class="card card-body">
                        <div class="hstack gap-1">
                            <button type="button" class="btn btn-primary waves-effect"
                                (click)="_openModalBtns('recetas')">Recetas</button>
                            <button type="button" class="btn btn-primary waves-effect"
                                (click)="_openModalBtns('estudios')">Estudios y Laboratorios</button>
                            <button type="button" class="btn btn-primary waves-effect"
                                (click)="_openModalBtns('historial')">Historial</button>
                            <button type="button" class="btn btn-primary waves-effect"
                                (click)="_openModalBtns('proximas')">Proximas </button>
                        </div>


                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">

                    <div class="card" *ngIf="record">
                        <div class="card-body">
                            <div class="row g-2 m-4">
                                <h4 class="fs-14 text-primary bold text-uppercase text-center">Datos Generales </h4>

                                <div class="col-xxl-3 col-xl-4 col-lg-6 col-md-6">
                                    <div class="form-floating">
                                        <input class="form-control" [value]="record.P00_Nombre__c" readonly>
                                        <label class="control-label">Nombre(s)</label>
                                    </div>
                                </div>

                                <div class="col-xxl-3 col-xl-4 col-lg-6 col-md-6">
                                    <div class="form-floating">
                                        <input class="form-control" [value]="record.P05_Apellidos__c" readonly>
                                        <label class="control-label">Apellido (s)</label>
                                    </div>
                                </div>

                                <div class="col-xxl-2 col-xl-4 col-lg-4 col-md-4">
                                    <div class="form-floating">
                                        <input class="form-control" [value]="record.P05_Edad__c" readonly>
                                        <label class="control-label">Edad</label>
                                    </div>
                                </div>

                                <div class="col-xxl-2 col-xl-4 col-lg-4 col-md-4">
                                    <div class="form-floating">
                                        <input class="form-control" [value]="record.P05_Sexo__c" readonly>
                                        <label class="control-label">Sexo</label>
                                    </div>
                                </div>

                                <div class="col-xxl-2 col-xxl-2 col-xl-4 col-lg-4 col-md-4">
                                    <div class="form-floating">
                                        <input class="form-control"
                                            [value]="record.P05_Fecha_de_Nacimiento__c | date: 'd-MM-Y' " readonly>
                                        <label class="control-label">Fecha de Nacimiento</label>
                                    </div>
                                </div>

                            </div>
                            <div class="row g-2 m-4">
                                <div class="col-xxl-2 col-xxl-2 col-xl-4 col-lg-4 col-md-4" *ngIf="_cita">
                                    <div class="form-floating">
                                        <input class="form-control" [value]="_cita.Fecha_Inicio | date: 'd-MM-Y H:m a' "
                                            readonly>
                                        <label class="control-label">Fecha inicio</label>
                                    </div>
                                </div>

                                <div class="col-xxl-2 col-xxl-2 col-xl-4 col-lg-4 col-md-4" *ngIf="_cita">
                                    <div class="form-floating">
                                        <input class="form-control" [value]="_cita.Fecha_Final | date: 'd-MM-Y H:m a' "
                                            readonly>
                                        <label class="control-label">Fecha final</label>
                                    </div>
                                </div>

                                <div class="col-xxl-2 col-xxl-2 col-xl-4 col-lg-4 col-md-4" *ngIf="_cita">
                                    <div class="form-floating">
                                        <input class="form-control" [value]="_cita.Tipo_Cita " readonly>
                                        <label class="control-label">Evento</label>
                                    </div>
                                </div>

                                <div class="col-xxl-2 col-xxl-2 col-xl-4 col-lg-6  col-md-6">
                                    <div class="form-floating">
                                        <input class="form-control" [value]="record.Nombre_del_Recurso__c" readonly>
                                        <label class="control-label">Nombre del recurso</label>
                                    </div>
                                </div>
                                <div class="col-xxl-2 col-xxl-2 col-xl-4 col-lg-6 col-md-6">

                                    <div class="form-floating" *ngIf="_cita">
                                        <input class="form-control" [value]="_cita.Servicio" readonly>
                                        <label class="control-label">Servicio</label>
                                    </div>
                                </div>

                                <div class="col-12">
                                    <fieldset>
                                        <legend class="text-muted fs-14">Comentarios del paciente</legend>
                                        <textarea class="form-control" [innerHTML]="record.P05_Comentario__c" readonly
                                            [rows]="10">
                                           </textarea>
                                    </fieldset>
                                </div>
                                <div class="col-12" *ngIf="_cita_set_">
                                    <fieldset>
                                        <legend class="text-muted fs-14">Comentarios de la cita</legend>
                                        <textarea class="form-control" [innerHTML]="_cita_set_.P05_Comentarios__c"
                                            readonly [rows]="5">
                                           </textarea>
                                    </fieldset>
                                </div>
                            </div>
                        </div>
                    </div>

                    <ng-container *ngIf="_formato && _readyFormat">

                        <app-clinical-history *ngIf="_formato_id == 'historia_clinica' "
                            [_formato]="_formato"></app-clinical-history>

                        <app-nota-ingreso *ngIf="_formato_id == 'nota_ingreso'"
                            [_formato]="_formato"></app-nota-ingreso>

                        <app-nota-evolucion *ngIf="_formato_id == 'nota_evolucion' "
                            [_formatoData]="_formato"></app-nota-evolucion>

                        <app-nota-preoperatoria *ngIf="_formato_id == 'nota_preoperatoria' " [_formatoData]="_formato">
                        </app-nota-preoperatoria>

                        <app-anestesia
                            *ngIf="_formato_id == 'registro_anestesia' || _formato_id == 'valoracion_anestesia' || _formato_id=='anestesia' "
                            [_formatoData]="_formato"></app-anestesia>

                        <app-enfermeria-endoscopia
                            *ngIf="_formato_id == 'pre_estudio' || _formato_id == 'trans_estudio' || _formato_id == 'recuperacion' "
                            [_formatoData]="_formato"></app-enfermeria-endoscopia>


                        <app-nota-egreso *ngIf="_formato_id == 'nota_egreso' "
                            [_formatoData]="_formato"></app-nota-egreso>
                        <app-hoja-indicaciones
                            *ngIf="_formato_id == 'hoja_indicaciones' || _formato_id ==  'formato_ine' "
                            [_formatoData]="_formato"></app-hoja-indicaciones>

                        <app-descipcion-quirurgica *ngIf="_formato_id == 'descripcion_quirurgica' "
                            [_formatoData]="_formato"></app-descipcion-quirurgica>

                        <app-enfermeria-operatorios
                            *ngIf="_formato_id == 'preo_peratorios' || _formato_id == 'trans_operatorio' || _formato_id == 'post_operatorio' "
                            [_formatoData]="_formato"></app-enfermeria-operatorios>

                        <app-hoja-post-operatoria *ngIf="_formato_id == 'hoja_post_operatoria' "
                            [_formatoData]="_formato"></app-hoja-post-operatoria>

                        <app-recomendaciones-paciente *ngIf="_formato_id=='datos_paciente_recomendaciones'"
                            [_formatoData]="_formato"
                            (_eventLoadRecord)="_onEventLoadRecord($event)"></app-recomendaciones-paciente>

                        <app-consentimiento-aplicacion-sedacion
                            *ngIf="_formato_id=='consentimiento_informado_aplicacion_sedacion'"
                            [_formatoData]="_formato"></app-consentimiento-aplicacion-sedacion>
                        <app-consentimiento-procedimiento-diagnostico
                            *ngIf="_formato_id=='consentimiento_informado_procedimiento_diagnostico'"
                            [_formatoData]="_formato"></app-consentimiento-procedimiento-diagnostico>

                        <app-historia-clinica-varices
                            *ngIf="_formato_id =='historia_clinica_varices' || _formato_id == 'nota_evolucion_historia_clinica_varices' || _formato_id == 'reporte_quirurgico_historia_clinica_varices' "
                            [_formatoData]="_formato"></app-historia-clinica-varices>

                        <app-aviso-privacidad *ngIf="_formato_id === 'aviso_privacidad' " [record]="record"
                            [_cita]="_cita" [_formatoData]="_formato"></app-aviso-privacidad>

                    </ng-container>

                    <ng-container *ngIf="_formato_id == 'doctor_ayudante'">
                        <app-doctor-ayudante></app-doctor-ayudante>
                    </ng-container>

                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #modalPatient let-modal>

    <div class="modal-header p-3 bg-primary-subtle">
        <h5 class="modal-title" id="modal-title">Crear Cita </h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-hidden="true"
            (click)="closeEventModal()"></button>
    </div>

    <div class="modal-body p-4">
        <app-create-citas [_dataCita]="_dataCita" [cita_sub_secuente_id]="_cita_sub_secuente_id"
            (eventClickCancel)="onClickEvent($event)"></app-create-citas>
    </div>

</ng-template>

<ng-template #modalCrearCita let-modalCita>
    <div class="modal-header">
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="btn-close"
            (click)="modalCita.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body text-center">
        <app-cita-subsecuente [_closeModal]="true"
            (eventCloseModal)="_onEventCloseModal($event)"></app-cita-subsecuente>
    </div>

</ng-template>

<ng-template #modalCrearReceta let-modalReceta>
    <div class="modal-header">
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="btn-close"
            (click)="modalReceta.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body text-center">
        <app-receta-medicamento [_closeModal]="true"
            (eventCloseModal)="_onEventCloseModal2($event)"></app-receta-medicamento>
    </div>
</ng-template>

<ng-template #modalBtns let-modalbtn>
    <div class="modal-header">
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="btn-close"
            (click)="modalbtn.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body p-4">

        <ng-container *ngIf="_modalOption === 'recetas' ">
            <app-recetas-view [_citaID]="_cita.Cita_Id" [_Paciente]="record" [_recetas]="_recetas"
                (eventLoadRecetas)="_onEventLoadRecetas($event)"></app-recetas-view>
        </ng-container>

        <ng-container *ngIf="_modalOption === 'estudios' ">
            <ng-container *ngIf="_historiaClinica && _historiaClinica.length; else _frame_8">
                <fieldset>
                    <legend class="text-muted fs-12 fw-bold"> Archivos cargados(Historia clinica)</legend>
                    <app-gallery-image [GalleryImage]="_historiaClinica" [base64]="false"></app-gallery-image>
                </fieldset>
            </ng-container>

            <ng-template #_frame_8>
                <div class="container-fluid">
                    <div class="d-flex justify-content-center">
                        <h4 class="text-muted ">No hay estudios cargados</h4>
                    </div>
                    <div class="py-0 m-4 p-2 d-flex justify-content-center">
                        <img src="assets/images/emptty_files.jpg" height="500">
                    </div>
                </div>
            </ng-template>
            <!--             <ng-container *ngIf="_LaboratorioFiles && _LaboratorioFiles.length">
                <fieldset class="mt-4" *ngIf="_LaboratorioFiles && _LaboratorioFiles.length">
                    <legend class="text-muted fs-12 fw-bold"> Archivos cargados(Laboratorio)</legend>
                    <app-gallery-image [GalleryImage]="_LaboratorioFiles" [base64]="false"></app-gallery-image>
                </fieldset>
            </ng-container> -->

        </ng-container>


    </div>
</ng-template>