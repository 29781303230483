import { Subscription, interval } from 'rxjs';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import * as _ from 'lodash';
import { CredentialsService } from 'src/app/core/services/credentials.service';
import { Cita } from 'src/app/pages/calendar/models/calendar';
import { FormatsService } from 'src/app/shared/services/formats.service';
import { ToastrService } from 'ngx-toastr';
import { VoiceRecognitionService } from '@app/shared/services/voice-recognition.service';

@Component({
  selector: 'app-enfermeria-operatorios',
  templateUrl: './enfermeria-operatorios.component.html',
  styleUrls: ['./enfermeria-operatorios.component.scss']
})
export class EnfermeriaOperatoriosComponent implements OnInit, OnDestroy {

  @Input()
  id: string
  
  @Input()
  _formatoData: any
  
  
  _controls: any[] = []
  _formato: any = {}
  record: any;

  $interval!: Subscription;
  public formEnfermeriaOperatorios: FormGroup = this.formBuilder.group({})

  _Cita: Cita;
  _label: string

  _itsReady: boolean

  _formControl: FormGroup;

  constructor (
    private build: FormBuilder,
    private formatService: FormatsService,
    private credentials: CredentialsService,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    public VoiceS: VoiceRecognitionService
  ) {
   // this.VoiceS.init()
    this.record = this.credentials.userInfoData.data.records[0]
    const cita = this.credentials.getCitaJson()
    if(cita) {
      this._Cita = cita
    }
  }

  ngOnInit(): void {

    if(this._formatoData) {
      this._formato = this._formatoData

      this._label = this._formato.label;
      this._controls = this._formato.form.controls;

      this._itsReady = true
      this.initForm();
     /*  if(this.$interval) {
        this.$interval.unsubscribe()
      } */
    }    
  }

  get f () {
    return this.formEnfermeriaOperatorios.controls
  }

  ngOnDestroy(): void {
    if(this.$interval) {
      console.log('ONDESTROY');
      
      this.$interval.unsubscribe()
    }
    this._savePrevDestroy()
  }

  private _savePrevDestroy(): void {
    let _formValues = this.formEnfermeriaOperatorios.value
    let _body_: any[] = []

    _.map(_formValues, (a, i) => {
        _body_.push({
          name: i,
          value: a
        })      
    })    

    let _bodyForm = {
      Cita_Id: this._Cita.Cita_Id,
      label: this._formato.label,
      new_data: _body_
    }
    this._saveFormatValues(_bodyForm, false)
  }

  /**
   * 
   */
  private initForm() {
    // 1337
    _.map(this._controls, (a) => {

      _.map(this._controls, (a) => {
        if(a.type != 'frame') {
          _.map(a.Objects, (b) => {
            if(b.Objects){
            _.map(b.Objects, (c) => {   
            //  console.log({_______CCC_____: c});
              
              if(c.Objects) {

                _.map(c.Objects, (d) => {  

                  if(d.type == 'label') {   
                    _.map(d.Objects, (e) => {
                      this.formEnfermeriaOperatorios.addControl(
                        e.name, this.formBuilder.control(e.value)
                      )
                    })
                  }else if (d.type == 'title' || d.type == 'input' ) {
                    console.log({___________dddddd________: d});

                    _.map(d.Objects, (e) => {
                      this.formEnfermeriaOperatorios.addControl(
                        e.name, this.formBuilder.control(e.value)
                      )

                    })
                    
                    
                  }else {
                    this.formEnfermeriaOperatorios.addControl(
                      d.name, this.formBuilder.control(d.value)
                    )                  
                  }

                })
              }else {
                this.formEnfermeriaOperatorios.addControl(
                  c.name, this.formBuilder.control(c.value)  
                )              
              }
            })
          }else {
            this.formEnfermeriaOperatorios.addControl(
              b.name, this.formBuilder.control(b.value)
            )          
          }
          })
        }else {
          console.log({_________AAAA______: a});
          
        }
      })

    })

    console.log({formEnfermeriaOperatorios: this.formEnfermeriaOperatorios.controls});   
    this.createInterval()
  }

  private createInterval () {
    const myObservable= interval(1000);
    this.$interval = myObservable.subscribe((integer) => {
      if(integer === 20 ) { 
        this.$interval.unsubscribe();      
        //console.log('GO SAVE IT!!!!');        
        //this.show = !this.show;       
        //this._onSaveIt()     
        this._newSaveFormat()
       
      }           
      //this._interval = integer
      
    })
  }

  private _newSaveFormat () {
    let _formValues = this.formEnfermeriaOperatorios.value
    let _body_: any[] = []

    _.map(_formValues, (a, i) => {
      _body_.push({
        name: i,
        value: a
      })      
    })    

    let _bodyForm = {
      Cita_Id: this._Cita.Cita_Id,
      label: this._formato.label,
      new_data: _body_
    }

    //this._formatoValues = _bodyForm
    this._saveFormatValues(_bodyForm, true)
  }

  /**
   * 
   * @param _jsonObject 
   * @param createInterval 
   */
  _saveFormatValues(_jsonObject: any, createInterval: boolean = false): void {

    this.formatService._postFormato(_jsonObject).then((resp) => {

      this.toastr.info('Se ha guardado la información','Guardada', {
        timeOut: 3200
      }).onHidden.subscribe(() => {
        if(createInterval) {           
          console.log('CREATE NEW INTERVAL!!!!!!');
                   
          this.createInterval()
        }
      })
    }).catch((error) => {
      console.error({error});
      
    })


  }


  /**
   *
   *
   * @param {string} text
   * @param {*} control
   * @memberof EnfermeriaOperatoriosComponent
   */
  _onEventEmmiterValue(text: string, control: any) {
    console.log({_TEXT________: text, control});
    this.f[control.name].patchValue(text)       
  }

}
