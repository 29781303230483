import { Component, Input } from '@angular/core';
import { Patients } from '@app/shared/models/Patients';

@Component({
  selector: 'app-modal-details-patient',
  templateUrl: './modal-details-patient.component.html',
  styleUrls: ['./modal-details-patient.component.scss']
})
export class ModalDetailsPatientComponent {

  @Input()
  _patient:Patients

  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    console.log({_patient__: this._patient});
    
  }

}
