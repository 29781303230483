import { Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';

import * as _ from 'lodash';

import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { WebcamImage, WebcamInitError, WebcamUtil } from 'ngx-webcam';
import { Observable, Subject, Subscription, interval, map, of } from 'rxjs';
import { CredentialsService } from '@core/services/credentials.service';
import { TokenStorageService } from '@core/services/token-storage.service';
import { Cita } from '@pages/calendar/models/calendar';
import { CalendarService } from '@pages/calendar/services/calendar.service';
import { Doctor } from '@pages/models/doctores';
import { EliminarReceta, Medicamento, Recetas } from '@pages/models/medicamentos';
import { Recurso } from '@pages/models/recursos';
import { Servicio } from '@pages/models/servicios';
import { RecetasService } from '@pages/services/recetas.service';

import { Images } from '@shared/models/list-images';
import { AlertsService } from '@shared/services/alerts.service';
import { FormatsService } from '@shared/services/formats.service';
import { VoiceRecognitionService } from '@shared/services/voice-recognition.service';
import Swal from 'sweetalert2';


// Range Slider
import { Options } from '@angular-slider/ngx-slider';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-clinical-history',
  templateUrl: './clinical-history.component.html',
  styleUrls: ['./clinical-history.component.scss']
})
export class ClinicalHistoryComponent implements OnInit, OnDestroy {

  @ViewChild('modalReceta') modalReceta: TemplateRef<any>;

  @Input()
  _cita_id: any;
  @Input()
  createInterval_: boolean
  @Input()
  id: string;
  @Input()
  _formato: any;

  options: Options = {
    floor: 0,
    ceil: 5,
    showTicks: true
  };


  public _basic_info: any[] = []

  $interval!: Subscription;

  formato: any = {}

  controls: any[] = []
  _formControls: any[] = []
  _footer: any[] = []

  _itsReady: boolean;

  selectedFiles?: FileList;
  progressInfos: any[] = [];
  message: string[] = [];
  _previweImage: any[] = []
  _previewReady: boolean

  _active: boolean = false;

  _interval: number = 0;

  _BODYFORM: any;

  spinner_frame_7 = 'spinner_frame_7'

  _add_cita_seguimiento: boolean


  public formClinicalHistory: FormGroup = this.formBuilder.group({})

  _previewImage: any[] = []


  //#region WEbCamera
  public showWebcam = false;
  public allowCameraSwitch = false;
  public multipleWebcamsAvailable = false;
  public deviceId: string;

  public videoOptions: MediaTrackConstraints = {
    // width: {ideal: 1024},
    // height: {ideal: 576}
  };
  public errors: WebcamInitError[] = [];

  // latest snapshot
  public webcamImage: WebcamImage | null
  _pictures: any[] = []

  // webcam snapshot trigger
  private trigger: Subject<void> = new Subject<void>();
  // switch to next / previous / specific webcam; true/false: forward/backwards, string: deviceId
  private nextWebcam: Subject<boolean | string> = new Subject<boolean | string>();
  //#endregion

  itsRecording: boolean;

  _Cita: Cita
  _Paciente: any;

  _Images: any
  _Firma: Images[] = []
  currentUser: any

  // bread crumb items
  breadCrumbItems!: Array<{}>;

  _formatoValues: any

  _Servicios: Servicio[] = []
  _tipo_evento: any[] = []
  _Recursos: Recurso[] = []

  // _formCitaNueva: FormGroup;    
  _rows: any[] = []

  _plan_tratamiento: string;


  _createInterval = true

  _LaboratorioFiles: any[] = []

  _itemLabel: any;
  _spinner_img = '_spinner_img'

  _cita_subsecuente: boolean = false
  medicamento: boolean = false
  _indicaciones_medicas: boolean = false

  _citaID: string
  _recetas: Recetas[] = []
  _doctor: Doctor

  $subsciption: Subscription;
  _ngModal: NgbModalRef;
  _receta_: Recetas;

  _showCustomFiles: boolean = true
  eventSubjectClerFiles: Subject<boolean> = new Subject<boolean>();

  _assetsSvg = 'assets/images/svg/circle.svg'

  _svgfilesPng: any[] = []

  _svgEditors: any[] = [{
    id: 0,
    path: this._assetsSvg,
    _showComments: true,
    comentario: null
  }]

  constructor(
    private formatService: FormatsService,
    private toastr: ToastrService,
    public VoiceS: VoiceRecognitionService,
    private credentials: CredentialsService,
    private formBuilder: FormBuilder,
    protected _sanitizer: DomSanitizer,
    private tokenService: TokenStorageService,
    private alert: AlertsService,
    private calendar: CalendarService,
    private spinner: NgxSpinnerService,
    private receta: RecetasService,
    private modalService: NgbModal,
  ) {

    this.VoiceS.init();
    this._Paciente = this.credentials.userInfoData.data?.records[0]
    this.currentUser = this.tokenService.getUser()

    // this._initformCitasNew()


  }

  ngOnInit(): void {

    this.$subsciption = this.calendar.CurrentLoadReceta.subscribe({
      next: (value) => {
        if (value) {
          this._loadRecetas(this._citaID)
        }
      },
      error(err) {
        console.error({ err });
      },
    })

    if (this.createInterval_) {
      this._createInterval = this.createInterval_
    }

    const _cita = this.credentials.getCitaJson()

    if (_cita) {

      this.getDoctorData(_cita)
      this._Cita = _cita
      this._citaID = this._Cita.Cita_Id
      this._loadImages(this._Cita.Cita_Id)
      this._loadRecetas(this._Cita.Cita_Id)

    }

    if (this._cita_id) {
      this._createInterval = false
      this._citaID = this._cita_id
      this._loadImages(this._cita_id)
      this._loadRecetas(this._cita_id)
    }

    this.breadCrumbItems = [
      { label: 'Advance UI' },
      { label: 'Swiper Slider', active: true }
    ];



    this.initWebCam()

    this._itsReady = false;

    if (this._formato) {
      this.controls = this._formato.form.controls
      _.map(this.controls, (a) => {
        if ((!a.hasOwnProperty('Objects'))) {
          if (!(a.type == 'frame')) {
            if (a.name != 'firma') {
              this._basic_info.push(a)
            } else if (a.name == 'firma') {
              this._footer.push(a)
            }
          } else {
            this._formControls.push(a)
          }
        } else {
          this._formControls.push(a)
        }
      })

      this.initForm();

      this._itsReady = true

    }

    this.LoadCatalogs()

  }

  /**
   * 
   */
  private getDoctorData(_cita: Cita) {
    this.calendar._getDoctores(_cita.Empleado_Id).subscribe({
      next: (value: any) => {
        if (value.codigo === 200) {
          console.log({ value });
          const data = value.data.info

          if (data) {

            this._doctor = data.salesforce
            console.log({ _data____: data.salesforce });

          }

          /*   
            if(data) {
              this._doctor = data as Doctor
            } */
          //this._doctor = value.data.salesforce ? value.data.salesforce : null
        }
        console.log({ value });

      },
      error(err) {
        console.error({ err });

      },
    })

  }

  /*   private _initformCitasNew() {
      this._formCitaNueva = this.formBuilder.group({
        citas: this.formBuilder.array([])
      })
    } */



  /**
   * 
   */
  ngOnDestroy(): void {
    if (this.$interval) {
      this.$interval.unsubscribe()
    }
    if (this._createInterval) {
      this._savePrevDestroy()
    }
  }

  /**
   * 
   */
  private _savePrevDestroy(): void {
    let _formValues = this.formClinicalHistory.value
    let _body_: any[] = []

    _.map(_formValues, (a, i) => {
      _body_.push({
        name: i,
        value: a
      })
    })

    let _bodyForm = {
      Cita_Id: this._Cita.Cita_Id,
      label: this._formato.label,
      new_data: _body_
    }

    this._saveFormatValues(_bodyForm, false)
  }

  /***
   * Cargamos las imagenes
   */
  private _loadImages(Cita_Id: string) {
    /**
     * Imagenes
     */
    this._Images = null
    this.formatService._getListImages(Cita_Id, this._Paciente.Name).subscribe({
      next: (response) => {
        if (response.codigo == 200) {
          console.log({ response });

          const data = response.data

          if (data.formato && data.formato.archivos) {
            const archivos = data.formato.archivos
            this._Images = archivos
            this._LaboratorioFiles = archivos.Laboratorio ? archivos.Laboratorio : []
            console.log({ archivos, _Images: this._Images });
          }
        }
      },
      error: (error: any) => {
        console.error({ error });
      }
    })



    /**
     * Cargamos la firma del doctor
     */
    this.formatService._getImageProfile(this.currentUser.username).subscribe({
      next: (response) => {
        if (response.codigo == 200) {
          this._Firma = response.data.formato.archivos.Firma;
        }
      }
    })

  }

  /**
   * 
   * @param CitaID 
   */
  private _loadRecetas(CitaID: string) {
    this.receta.GetRecetaByCita(CitaID).subscribe({
      next: (value: any) => {
        if (value.codigo === 200) {
          const data = value.data
          if (data) {

            if (data && data.length >= 1) {
              this._recetas = data
            }
            console.log({ _receta___: this._recetas });

          }
        }

      },
      error(err) {
        console.error({ err });

      },
    })
  }

  /**
   * Borra el archivo cargado
   * @param item 
   */
  onDeleteImage(item: any, img: any) {
    Swal.fire({
      title: 'Por favor confirme esta accion',
      text: `¿Desea eliminar esta imagen`,
      icon: 'question',
      showCancelButton: true,
      cancelButtonText: 'No,Cancelar',
      confirmButtonText: 'Sí, borrar'
    }).then((resp) => {
      if (resp.value) {

        this.formatService._PostdeleteImage({ user: null, paciente_id: null, cita_id: this._Cita.Cita_Id, filename: item.filename, main_label: this.id, label: img.label }).then((resp) => {
          if (resp.codigo == 200) {
            this.toastr.success('Exito al borrar la imagen seleccioanda', 'Exito al borrar', {
              timeOut: 3200
            })
            this._loadImages(this._Cita.Cita_Id)
          } else {
            this.toastr.warning('Ocurrio un error al tratar de borrar la imagen seleccionada', 'Error ocurrido', {
              timeOut: 3200
            })
          }
          // 

        }).catch((error) => {
          console.error({ error });

        })
      }
    })



  }

  /**
   * 
   * @param url 
   * @returns 
   */
  _sanitizeUrl(url: string) {
    return this._sanitizer.bypassSecurityTrustResourceUrl(url)
  }

  /**
   * 
   */
  private createInterval() {
    const myObservable = interval(1000);
    this.$interval = myObservable.subscribe((integer) => {
      if (integer === 20) {
        this.$interval.unsubscribe();
        this._newSaveFormat()
      }
      this._interval = integer
    })
  }

  /**
   * 
   */
  private initWebCam() {
    WebcamUtil
      .getAvailableVideoInputs()
      .then((mediaDevices: MediaDeviceInfo[]) => {
        this.multipleWebcamsAvailable = mediaDevices && mediaDevices.length > 1;
      });
  }

  /**
   * 
   * @param event 
   */
  fileChange(event: any, item: any) {
    console.log({ item });

    // let message = [];
    // let progressInfos = [];
    let selectedFiles = event.target.files

    //console.log({selectedFiles,_Paciente: this._Paciente.Name });
    // this.aler   

    this.alert._showLoader('Guardando imagenes...')

    this.formatService._uploadImage({
      cita_id: this._Cita.Cita_Id,
      paciente_id: this._Paciente.Name,
      files: selectedFiles,
      main_label: this.id,
      label: item.label
    }).then((resp) => {
      this._loadImages(this._Cita.Cita_Id)
      console.log({ resp });
      this.alert._hideLoader()
    }).catch(error => {
      console.error({ error });
      this.toastr.error('Ocurrio un error al tratar de guardar la(s) imagenes', 'Error', {
        timeOut: 3200
      })
      this.alert._hideLoader()
    })

  }

  /**
   * 
   */
  private initForm() {

    _.map(this._formControls, (a) => {
      _.map(a.Objects, (b) => {

        let value = b.value


        if (b.name === 'dolor_interrogatorio_proctologico') {
          value = [value]
        }

        _.map(b.Objects, (c) => {
          _.map(c.Objects, (d) => {
            this.formClinicalHistory.addControl(
              d.name, this.formBuilder.control(d.value)
            )
          })

          this.formClinicalHistory.addControl(c.name, this.formBuilder.control(c.value))
        })

        this.formClinicalHistory.addControl(
          b.name, this.formBuilder.control(value)
        )
      })

      if (a.left) {
        _.map(a.left.Objects, (c) => {
          this.formClinicalHistory.addControl(
            c.name, this.formBuilder.control(c.value)
          )
        })
      }

      if (a.right) {
        _.map(a.right.Objects, (c) => {
          this.formClinicalHistory.addControl(
            c.name, this.formBuilder.control(c.value)
          )
        })
      }
    })

    if (this._createInterval) {
      this.createInterval()
    }
  }

  /**
   * 
   */
  get f() {
    return this.formClinicalHistory.controls;
  }


  /**
   * 
   */
  _newSaveFormat(): void {

    let _formValues = this.formClinicalHistory.value
    let _body_: any[] = []

    _.map(_formValues, (a, i) => {
      _body_.push({
        name: i,
        value: a
      })
    })

    let _bodyForm = {
      Cita_Id: this._Cita.Cita_Id,
      label: this._formato.label,
      new_data: _body_
    }

    //  this._formatoValues = _bodyForm   
    this._saveFormatValues(_bodyForm, true)
  }

  /**
   * 
   * @param _jsonObject 
   * @param createInterval 
   */
  _saveFormatValues(_jsonObject: any, createInterval: boolean = false): void {

    this.formatService._postFormato(_jsonObject).then((resp) => {

      this.toastr.info('Se ha guardado la información', 'Guardada', {
        timeOut: 3200
      }).onHidden.subscribe(() => {

        if (createInterval) {
          console.log('CREAR INTERVALO!!!!!!!!!!!!!!!!');
          this.createInterval()
        } else {
          console.log('NO SE CREA INTERVALOOOOOO');
        }

      })
    }).catch((error) => {
      console.error({ error });

    })


  }

  /**
   * Guardamos los valores
   * @param frame 
   */

  _onSaveIt(frame?: string) {

    let _formato = this.formato

    _.map(_formato.form.controls, (a) => {
      if (a.type == 'frame' || a.type == 'title') {
        _.map(a.Objects, (b) => {
          b.value = this.f[b.name].value
        })
      }
    })

    let _bodyForm = {
      Cita_Id: this._Cita.Cita_Id,
      label: this.formato.label,
      form: _formato.form
    }

    this._BODYFORM = _bodyForm
  }

  /**
   * 
   * @param name 
   * @param event 
   */
  _onCheckChange(event: any, name: string) {
    let val = event.target.checked
    this.formClinicalHistory.controls[name].patchValue(val)
  }

  _oncheckEstrenimiento(event: any, name: string, Objects: any[]) {
    let val = event.target.checked
    this.formClinicalHistory.controls[name].patchValue(val)

    if (!val) {
      this.formClinicalHistory.controls['estrenimiento_interrogatorio_proctologico'].patchValue('false')
    }
  }

  /**
   * 
   * @returns 
   */
  getImage(): Observable<any> {
    console.log({ AAAAAA: this._previweImage });

    return of(this._previweImage)
  }

  /**
   * 
   * @param control 
   */
  startService(control: string) {
    this.toastr.warning('El micrófono ya está abierto y escuchará todo lo que hable, para detener la grabación pulse de nuevo el botón de micrófono', 'Esuchando.', {
      timeOut: 1500
    })
    //this.recordingStart = false
    this.itsRecording = !this.itsRecording
    //this.VoiceS.start()
    //console.log({control});



    //let b = //.patchValue(this.VoiceS.text) // = this.VoiceS.text as any

    //console.log({b});   
  }

  /**
   * 
   * @param control 
   */
  stopService(control: string) {
    this.formClinicalHistory.controls[control].patchValue(this.VoiceS.text)
    // this.recordingStart = true;
    this.toastr.info('Grabación detenida, puede editar si gusta el escrito.', 'Detenida', {
      timeOut: 3200
    })
    this.itsRecording = !this.itsRecording
    //   this.VoiceS.stop()
  }


  public triggerSnapshot(): void {
    this.trigger.next();
  }

  public toggleWebcam(item: any): void {
    console.log({ item });

    this._itemLabel = item;


    // this._active = !this._active
    this.showWebcam = !this.showWebcam;
  }

  public handleInitError(error: WebcamInitError): void {
    this.errors.push(error);
  }

  public showNextWebcam(directionOrDeviceId: boolean | string): void {
    // true => move forward through devices
    // false => move backwards through devices
    // string => move to device with given deviceId
    this.nextWebcam.next(directionOrDeviceId);
  }

  public handleImage(webcamImage: WebcamImage): void {
    console.info({ webcamImage });

    this.webcamImage = webcamImage;
    this.spinner.show(this._spinner_img)

    this.formatService.UploadFromCamera(this._Cita.Cita_Id, this._Paciente.Name, webcamImage, this._itemLabel.label, this._itemLabel.name).then((resp: any) => {
      this.spinner.hide(this._spinner_img)
      if (resp) {
        this.toastr.success('Imagen tomada con exito!!', 'Imagen capturada', {
          timeOut: 3200
        })
        // if(resp.codigo == 200) {
        this._loadImages(this._Cita.Cita_Id)
      } else {
        this.spinner.hide(this._spinner_img)
        this.toastr.error('Error al guardar la imagen', 'Imagen no guardada', {
          timeOut: 3200
        })
      }

      // }
    }).catch((error) => {
      console.error({ _ERROR: error });

    })

    //this._pictures.push(this.webcamImage)
    //this._previewImage.push({imageSrc: this.})
  }

  public cameraWasSwitched(deviceId: string): void {
    console.log('active device: ' + deviceId);
    this.deviceId = deviceId;
  }

  public get triggerObservable(): Observable<void> {
    return this.trigger.asObservable();
  }

  public get nextWebcamObservable(): Observable<boolean | string> {
    return this.nextWebcam.asObservable();
  }

  /**
   * 
   */
  OnToggleCamera() {
    if (this._active) {

    }
  }

  /**
   * 
   */
  private LoadCatalogs(): void {

    /*    this.calendar._getServicio('true').subscribe({
         next:(response) => {
           if(response.codigo == 200 ){
             this._Servicios = response.data         
          
           }        
   
         },
         error: (error) => {
           console.error({error});
           
           this.toastr.error('Hubo un error al listar los Servicios','Lista Servicios Error', {
             timeOut: 3200
           })
         }
       }) */

    this.calendar._getRecursos('true').subscribe({
      next: (response) => {

        if (response.codigo == 200) {
          this._Recursos = response.data;
        }

      },
      error: (error) => {
        console.error({ error });

        this.toastr.error('Hubo un error al listar los Recursos ', 'Lista recursos Error', {
          timeOut: 3200
        })
      }
    })

    /**
     * 
     */
    this.calendar._getTipoEvento(this._Cita.Paciente_Id).subscribe({
      next: (response: any) => {
        if (response.codigo == 200) {
          this._tipo_evento = response.data[0].tipo_evento;
        }
      },
      error: (error) => {
        console.error({ error });
        this.toastr.error('Hubo un error al listar los tipos de evento', 'Lista Anestesiologos Error', {
          timeOut: 3200
        })
      }
    })
  }

  /**
   * 
   * @param event 
   */
  _onChangeServicio(event: any) {
    //this._servicioSelected = event   
  }

  /**
   * 
   */
  async _onsaveNewCita() {

  }

  /**
   * 
   */
  _addOtherCita(): void {
    this._rows.push(2)
  }


  /**
   * 
   * @param filePdf 
   */
  _openPDF(filePdf: any) {
    console.log({ filePdf });

    setTimeout(() => {
      window.open(filePdf.url, '_blank', 'width=1000, height=800');
    }, 100);

  }

  /**
   * 
   * @param image 
   * @param obj 
   */
  _onSaveItImages(image: any[], obj: any) {
    if (image && image.length) {
      this.alert._showLoader('Guardando imagenes, espere...')
      /* 
         this.formatService.UploadFromCamera(this._Cita.Cita_Id,this._Paciente.Name, webcamImage, this._itemLabel.label, this._itemLabel.name)
      */
      this.formatService.UploadFiles(this._Cita.Cita_Id, this._Paciente.Name, obj.name, null, image).then((resp) => {
        this._loadImages(this._Cita.Cita_Id)
        this.alert._hideLoader()
        this.toastr.success('Exito al subir las imagenes', 'Exito!!', {
          timeOut: 3200
        })
      }).catch((error) => {
        console.error({ error });
        this.alert._hideLoader()
        this.toastr.error('Algo salio mal, al tratar de subir las imagenes', 'Error!!', {
          timeOut: 3200
        })
      })
    }
  }

  /**
   * 
   * @param files 
   * @param obj 
   */
  _onUploadFiles(files: any[], obj: any) {
    this.alert._showLoader('Subiendo, por favor espere')

    this.formatService.UploadFiles(this._Cita.Cita_Id, this._Paciente.Name, obj.name, files).then((resp) => {
      this._loadImages(this._Cita.Cita_Id)
      this.alert._hideLoader()
      this.toastr.success('Exito al subir las imagenes', 'Exito!!', {
        timeOut: 3200
      })

      this.eventSubjectClerFiles.next(true)

      setTimeout(() => {
        this._showCustomFiles = true
      }, 300);

    }).catch((error) => {
      console.error({ error });
      this.alert._hideLoader()
      this.toastr.error('Algo salio mal, al tratar de subir las imagenes', 'Error!!', {
        timeOut: 3200
      })
    })

  }

  /**
   * 
   * @param value 
   */
  _onChangeModel(value: boolean) {
    if (value) {

    }
  }

  /**
   * 
   * @param event 
   */
  _onMedicamentoChange(event: Medicamento) {
    console.log({ event });
  }

  /**
   * 
   * @param text 
   * @param control 
   */
  _onEventEmmiterValue(text: string, control: any) {
    console.log({ _TEXT________: text, control });
    this.f[control.name].patchValue(text)

  }

  /**
   * 
   * @param event 
   */
  _onEventLoadCitas(event: boolean) {

    if (event) {

    }

  }

  /**
   * 
   * @param receta 
   */
  _generatePDF(receta: any) {
    console.log({ receta });

    this.alert._showLoader('Espere...')
    this.receta.ImprimirRecceta(this._citaID, this._Paciente.Name, receta.id_receta).subscribe({
      next: (value: any) => {
        console.log({ value });
        this.alert._hideLoader()

        if (value && value.codigo === 200) {
          setTimeout(() => {
            window.open(value.data.url, '_blank', 'width=1000, height=800');
          }, 100);

        }



      },
      error: (err) => {
        console.error({ err });
        this.alert._hideLoader()
      },
    })

  }

  /**
   * 
   * @param receta 
   */
  _editarReceta(receta: Recetas) {
    this._receta_ = receta
    this._ngModal = this.modalService.open(this.modalReceta, {
      size: 'xl',
      animation: true,
      backdrop: 'static',
      fullscreen: 'lg'
    })
  }

  _onEventloadMedicamento(event: boolean) {
    if (event) {
      this.medicamento = false
      this._loadRecetas(this._citaID)
    }
  }

  _onEventCloseModal2(event: boolean) {
    if (event) {
      this._ngModal.close()
    }
  }


  /**
   * 
   * @param receta 
   */
  _onDelete(receta: Recetas) {
    Swal.fire({
      title: 'Por favor confirme esta accion',
      text: '¿Desea eliminar esta receta ?',
      icon: 'question',
      showCancelButton: true,
      cancelButtonText: 'No, cancelar',
      confirmButtonText: 'Sí, borrar'
    }).then((result) => {
      if (result.value) {
        let _receta: EliminarReceta = new EliminarReceta()

        if (receta && receta.id_receta) {
          _receta = {
            cita_id: this._citaID,
            id_receta: receta.id_receta

          }
          this.receta._eliminarReceta(_receta).then((resp) => {
            this.toastr.info('Se ha eliminado la receta, exitosamente', 'Exito', {
              timeOut: 3200
            })
            this._loadRecetas(this._citaID)
          })
        }


      }
    })
  }

  _onEvenEmmiter(event: boolean) { }

  _onEventEmmiterImg(event: any) {
    this._svgfilesPng.push(event)
  }

  /**
   * 
   * @param obj 
   */
  _saveSvgToPng(obj: any) {
    this.alert._showLoader('Guardando por favor espere...')
    this.formatService.UploadFiles(this._Cita.Cita_Id, this._Paciente.Name, obj.name, null, null, this._svgfilesPng).then((resp) => {
      console.log({ resp });
      this.alert._hideLoader()
      //this._loadImage()l
      this._loadImages(this._Cita.Cita_Id)

      this._svgEditors = []
      this._svgEditors.push({
        id: 0,
        path: this._assetsSvg,
        _showComments: true,
        comentario: null
      })
      this._svgfilesPng = []

    }).catch((error) => {
      console.error({ error });
      this.alert._hideLoader()
    })
  }


  _onEventDeleteModel(event: any, item: any, laboratorio: string = 'false') {
    Swal.fire(({
      title: 'Por favor confirme esta accion',
      text: '¿Desea eliminar este elemento?',
      showCancelButton: true,
      icon: 'question',
      cancelButtonText: 'No, cancelar',
      confirmButtonText: 'Si, continuar',

    })).then((result) => {
      if (result.value) {
        console.log({ ___event___: event, _Cita___: this._Cita, _Paciente: this._Paciente, __ITEM__: item });
        this.alert._showLoader('Borrando, por favor espere...')

        this.formatService._PostdeleteImage({ user: null, filename: event.filename, cita_id: this._Cita.Cita_Id, label: item.name, laboratorio: laboratorio, paciente_id: this._Cita.N_Expediente, main_label: null }).then((resp) => {
          console.log({ resp });
          this.alert._hideLoader()
          this._loadImages(this._Cita.Cita_Id)

        }).catch((error) => {
          console.error({ error });
          this.alert._hideLoader()
          this.toastr.error('Ocurrio un error al tratar de borrar este elemento', 'Error', {
            timeOut: 3200,
            progressBar: true
          })
        })

      }
    })
  }

}
