import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef, NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { CredentialsService } from 'src/app/core/services/credentials.service';
import { EventService } from 'src/app/core/services/event.service';
import { CalendarService } from 'src/app/pages/calendar/services/calendar.service';
import { AlertsService } from '../../services/alerts.service';
import { FormatsService } from '../../services/formats.service';
import { Cita } from 'src/app/pages/calendar/models/calendar';
import { LocalSessionService } from 'src/app/pages/services/local-session.service';
import { SessionPageService } from 'src/app/pages/services/session-page.service';

@Component({
  selector: 'app-right-sidebar',
  templateUrl: './right-sidebar.component.html',
  styleUrls: ['./right-sidebar.component.scss']
})
export class RightSidebarComponent implements OnInit {

  @ViewChild('modalPatient') modalPatient !: TemplateRef<any>

  @Input()
  Formatos: any[] = []
  @Input()
  _CitasSubsecuentes: any[] = []

  @Input()
  _citas_paciente: any[] = []

  layout: string | undefined;
  mode: string | undefined;
  width: string | undefined;
  position: string | undefined;
  topbar: string | undefined;
  size: string | undefined;
  sidebarView: string | undefined;
  sidebar: string | undefined;
  attribute: any;
  sidebarImage: any;
  sidebarVisibility: any;
  preLoader: any;
  grd: any;

  _clientes_espera: any[] = []

  isCollapsed: boolean = true
  isCollapsedPacientes: boolean =  true
  isCollapsedOthers: boolean = true

  @Output() settingsButtonClicked = new EventEmitter();
  @Output() SetIdFormato = new EventEmitter()

  _dataCita: any;
  _ngModal: NgbModalRef;
  _cita_sub_secuente_id: string;
  _citasSubsecuentes: any[] = []

  _jsonCitas: any[] = []

  _cita: any | null = null
  record: any | null =  null

  constructor(
    private offcanvasService: NgbOffcanvas,
    private credentials: CredentialsService,
    private modalService: NgbModal,
    private alert: AlertsService,
    private format: FormatsService,
    private local_: LocalSessionService,
    private sessionPage: SessionPageService,
    ) {
      const _record = this.credentials.userInfoData

      if(_record && _record.data) {
        this.record =  _record.data.records[0]
      }
    }

  ngOnInit(): void {
    this._loadDataCita()

    const _dataUser = this.credentials._getdataUser();

    if(_dataUser) {
      const _Websockets = _dataUser.Websockets;

      if(_Websockets && _Websockets.paciente_espera){
        this._clientes_espera = _Websockets.paciente_espera.Logs;
      }      
    }

    
  }

  topFunction() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }

  openEnd(content: TemplateRef<any>) {
    this.offcanvasService.open(content, { position: 'end' });
  }

  /**
   * 
   * @param item 
   */
  _onSetIdFormato(item: any) {
    this.offcanvasService.dismiss()
    this.SetIdFormato.emit(item)
  }
  
  /**
   * 
   * @param patient 
   */
  _onSetPatient(patient: any) {

  }

  private _loadDataCita() {


    //console.log({record_: this.record});
    
    
    this._cita = this.credentials.getCitaJson()    
    //const _dataUser = this.credentials._getdataUser();  
    
    const _jsonCitas = this.sessionPage._returnJSONSession()
    if(_jsonCitas) {
      this._jsonCitas = _jsonCitas
    }
  }

  _onOpenCita(item: any) {
    console.log({item});
    this._dataCita = []
    
    this._cita_sub_secuente_id = item.cita_sub_secuente_id

    this._dataCita = item.Datos_Cita
    
    this._ngModal  = this.modalService.open(this.modalPatient,{
      size:'xl',
      animation:true,
      backdrop:'static',
      fullscreen:'lg'
    })
  }

  closeEventModal() {
    this._ngModal.close()
  }

  onClickEvent(event: boolean) {
    if(event) {
      this._ngModal.close()
    }
  }


  _setCita(_cita: any) {
    console.log({_cita});   
    
    this.alert._showLoader('Cargando, espere...')
    let _cita_: Cita;

    _cita_ = {
      Cita_Id: _cita.Name,
      Recurso: _cita.Recurso,
      Fecha_Inicio: _cita.Fecha_inicio,
      Fecha_Final: _cita.Fecha_final,
      Paciente_Id: _cita.Paciente_Id,
      Recurso_Id: _cita.Recurso_Id,
      Tipo_Cita: _cita.Tipo_cita,
      Servicio_Id: _cita.Servicio_Id,
      Empleado_Id: _cita.Empleado_Id,
      Procedimiento: '',
      Estatus: _cita.Estatus,
      Candidato_Cirugia: this.record.Candidato_a_cirug_a__c,
      N_Expediente: this.record.Name,
      Paciente_Nombre_Completo: this.record.Nombre_completo__c,
      Servicio: _cita.Servicio
    }

    this.format._getForamtoByCita(_cita.Name).then((resp) => {
      console.log({resp});
    //  return console.log('a');
      
      this.local_.savedJsonValues('_json_formatos_cita',resp)   
      this.credentials.saveCitaJson(_cita_)  
      
      setTimeout(() => {
          window.location.reload()
      }, 300);

    }).catch((error) => {
      console.error({error});
      
    })
    
  }
  

}
