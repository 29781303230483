import { CandidatosEstructurados } from './../../calendar/models/candidatos';
import { Component, HostListener, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { CandidatosService } from '../../calendar/services/candidatos.service';
import { CandidatoData } from '../../calendar/models/candidatos';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';

import * as _ from 'lodash'
import { CookieService } from 'ngx-cookie-service';
import { ToastrService } from 'ngx-toastr';
import { AlertsService } from '@app/shared/services/alerts.service';

const CANDIDATOS_ = '_listado_candidatos_'
const CANDIDATOS_FILTER = 'filter_cadidatos'

const _SET_INDEX_ = '_set_index'
const _CURRENT_PAGe = '_currentPage'

@Component({
  selector: 'app-candidatos',
  templateUrl: './candidatos.component.html',
  styleUrls: ['./candidatos.component.scss']
})
export class CandidatosComponent implements OnInit, OnDestroy {

  @ViewChild('modalCandidato') modalCandidato!: TemplateRef<any>;
  @ViewChild('successContent') successContent: TemplateRef<any>;
  @ViewChild('modalCita') modalCita: TemplateRef<any>

  _listadoCandidatos: CandidatosEstructurados[]

  _candidato: CandidatoData;

  _ngbModalRef: NgbModalRef
  _ngbModalSuccess: NgbModalRef
  _ngModalCitaRef: NgbModalRef


  $_subscriber: Subscription;
  _spinner = '_spinner_'
  _responseData: any;

  searchText: string

  _set_index = 0

  totalCount: number = 0
  currentPage: number = 1
  pageSize: number = 100
  totalPages: number = 1

  page = 1

  isLoading: boolean = false

  _selectFilters: string[] = []

  _filters: string[] = []

  constructor(
    private candiatos: CandidatosService,
    private modal: NgbModal,
    private spinner: NgxSpinnerService,
    private cookieService: CookieService,    
    private alert: AlertsService,
    private toastr: ToastrService
  ) {

    let _setIndex = sessionStorage.getItem(_SET_INDEX_)

    if (_setIndex) {
      let _CURRENT_PAGe_ = sessionStorage.getItem(_CURRENT_PAGe)

      this._set_index = Number(_setIndex)
      this.currentPage = _CURRENT_PAGe_ ? Number(_CURRENT_PAGe_) : 1
    }
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(event: any) {
    if (window.innerHeight + window.scrollY >= document.body.offsetHeight && !this.isLoading) {
      console.log(event);
      this.page++
      this._set_index = (this.page * this.pageSize)
      sessionStorage.setItem(_SET_INDEX_, `${this._set_index}`)
      this.loadCandidatos()
    }
  }

  ngOnInit(): void {
    let _v = this.cookieService.get(CANDIDATOS_FILTER)

    if (_v) {
      let filter = JSON.parse(_v)
      this._filters = filter
    }

    this.$_subscriber = this.candiatos.CurrentLoadCandidatos.subscribe({
      next: (value) => {
        if (value) {
          this.loadCandidatos()
        }
      },
      error(err) {
        console.error({ err });
      },
    })

    let _candidatos_ = sessionStorage.getItem(CANDIDATOS_)

    if (_candidatos_) {
      this._listadoCandidatos = JSON.parse(_candidatos_)
      this._getKeys(this._listadoCandidatos)
    }
    this.loadCandidatos()
  }

  ngOnDestroy(): void {
    if (this.$_subscriber) {
      this.$_subscriber.unsubscribe()
    }
  }

  /**
   *
   *
   * @private
   * @param {CandidatosEstructurados []} _listadoCandidatos
   * @return {*} 
   * @memberof CandidatosComponent
   */
  private _getKeys(_listadoCandidatos: CandidatosEstructurados[]) {
    let Keys: any[] = []

    _.map(_listadoCandidatos, (a) => {
      let _keys = _.keys(a)
      Keys = [..._keys]
    })
    this._selectFilters = [..._.uniq(Keys)]
    return console.log({ KEY___S: _.uniq(Keys) });
  }


  /**
   * 
   */
  private loadCandidatos(_querySearch: any | null = null) {
    this.isLoading = true
    this.spinner.show(this._spinner)
    this.candiatos._GetCandidatosEstructurados('true', this._set_index, _querySearch).subscribe({
      next: (response) => {
        this.spinner.hide(this._spinner)
        if (response.codigo === 200) {
          this._listadoCandidatos = [...response.data.candidatos]
          this.totalCount = response.data.total
          this.totalPages = Math.ceil(this.totalCount / this.pageSize)
          this._getKeys(this._listadoCandidatos)
          sessionStorage.setItem(CANDIDATOS_, JSON.stringify(this._listadoCandidatos))

          this.isLoading = false
        }
      },
      error: (err) => {
        this.spinner.hide(this._spinner)
        console.error({ err });
      },
    })
  }

  /**
   * 
   * @param content 
   * @param candidato 
   */
  openModal(content: TemplateRef<any>, candidato: CandidatosEstructurados) {
    this.alert._showLoader('Por favor espere...')
    this.candiatos.ObtenerListadoCanidatos(candidato.Candidato_Id, 0).subscribe({
      next: (response: any) => {
        this.alert._hideLoader()
        if (response && response.codigo === 200) {
          this._candidato = response.data.candidatos[0]
          this._ngbModalRef = this.modal.open(content, {
            size: 'xl',
            animation: true,
            backdrop: 'static',
            fullscreen: 'lg'
          })
          
          return console.log();
        }

        this.toastr.warning('Ocurrio un error al buscar','!Error¡', {
          timeOut: 3200,
          progressBar: true
        })
      },
      error: (err) => {
        this.alert._hideLoader()
        this.toastr.error('Error al consultar', 'Error!!', {
          timeOut: 3200,
          progressBar: true
        })
        console.error({ err });
      },
    })
  }

  /**
   * 
   * @param event 
   */
  onClickEvent(event: boolean) {
    if (event) {
      this.modal.dismissAll()
    }
  }

  /**
   * 
   * @param event 
   */
  _onEventEmmiterReaload(event: boolean): void {
    if (event) {
      this.modal.dismissAll()
      this.loadCandidatos()
    }
  }


  /**
   * 
   * @param item 
   */
  _onEdit(candidato: CandidatosEstructurados) {
    this.alert._showLoader('Por favor espere...')
    this.candiatos.ObtenerListadoCanidatos(candidato.Candidato_Id, 0).subscribe({
      next: (response: any) => {  
        this.alert._hideLoader()
        if (response && response.codigo === 200) {
          this._candidato = response.data.candidatos[0]

          this._ngbModalRef = this.modal.open(this.modalCandidato, {
            size: 'xl',
            animation: true,
            backdrop: 'static',
            fullscreen: 'lg'
          })
          
          return console.log();
        }

        this.toastr.warning('Ocurrio un error al buscar','!Error¡', {
          timeOut: 3200,
          progressBar: true
        })
      },
      error: (err) => {
        this.alert._hideLoader()
        this.toastr.error('Error al consultar', 'Error!!', {
          timeOut: 3200,
          progressBar: true
        })
        console.error({ err });
      },
    })

 /*    this._candidato = Object.assign(candidato)
    this._ngbModalRef = this.modal.open(this.modalCandidato, {
      size: 'xl',
      animation: true,
      backdrop: 'static',
      fullscreen: 'lg'
    }) */
  }

  /**
   * 
   * @param event 
   */
  _onEventCloseModal(event: boolean) {
    if (event) {
      this._ngbModalRef.close()
      this.loadCandidatos()
    }
  }

  _onEventConfirmCandidate(event: any) {
    this.loadCandidatos()
    this._ngbModalRef.close()
    this._responseData = event
    this._openModalSuccess()
  }

  private _openModalSuccess() {
    this._ngbModalSuccess = this.modal.open(this.successContent, {
      animation: true,
      size: 'lg',
      backdrop: 'static',
      fullscreen: 'lg'
    })
  }

  _onSetPatient() {
    this._ngbModalSuccess.close()
    this._ngModalCitaRef = this.modal.open(this.modalCita, {
      animation: true,
      size: 'xl',
      backdrop: 'static',
      fullscreen: 'lg'
    })
  }

  _onChange(event: any) {
    this.cookieService.set(CANDIDATOS_FILTER, JSON.stringify(event))
  }

  _onSearchWithParams() {
    let _v = this.cookieService.get(CANDIDATOS_FILTER)
    this._set_index = 0
    sessionStorage.setItem(_SET_INDEX_, `${this._set_index}`)

    let _querySearch: Object = {
      searchText: this.searchText,
      params: JSON.parse(_v)
    }
    this.loadCandidatos(_querySearch)
    console.log({ _querySearch });

  }

}
