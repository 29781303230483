<!-- <div  > -->
<!-- <app-basic-info [_basic_info]="_basic_info" *ngIf="_itsReady" ></app-basic-info>   -->
<div *ngIf="formato">
    <form [formGroup]="formClinicalHistory" class="needs-validation createfile-form " autocomplete="off" novalidate>
        <ngb-accordion #acc="ngbAccordion"
            class="accordion custom-accordionwithicon custom-accordion-border  accordion-border-box accordion-primary p-1 m-1"
            [closeOthers]="true" id="_clinica_history">
            <ngb-panel [id]="item.name" *ngFor="let item of _formControls">
                <ng-template ngbPanelTitle>
                    <button class="border-0 bg-transparent accordion-pulse" type="button" data-bs-toggle="collapse">
                        <span class="text-uppercase fs-13 bold">{{item.label}}</span>
                    </button>
                </ng-template>
                <ng-template ngbPanelContent>
                    <div class="row g-2 m-4">

                        <div *ngIf="item.type == 'title'">
                            <!--    <div class="col-12 ">
                                    <h4 class="fs-4 text-primary bold text-uppercase text-center">{{item.label}} </h4>
                                </div> -->
                            <div class="row" *ngFor="let obj of item.Objects">
                                <div class="col-12" *ngIf="obj.type == 'textarea' ">
                                    <div class="mb-4">
                                        <label class="form-label">{{obj.label}}</label>
                                        <app-text-area-voice-recorder [transcript]="f[obj.name].value" [name]="obj.name"
                                            [required]="item.required" ngDefaultControl [formControlName]="obj.name"
                                            [rows]="4" (eventEmmiterValue)="_onEventEmmiterValue($event, obj)">
                                        </app-text-area-voice-recorder>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="item.type == 'frame' ">
                            <!-- historia_clinica_frame_1 -->
                            <div class="col-12">
                                <div class="row mt-4" *ngIf="item.name == 'historia_clinica_frame_1' "
                                    [ngClass]="_createInterval ? '' : 'pe-none' ">
                                    <div *ngFor="let obj of item.Objects"
                                        [ngClass]="obj.name === 'antecedentes_heredo_familiares_observaciones'  ? 'col-12' : obj.Objectes ? 'col-xl-6 col-md-6': 'col-md-6 col-lg-6' ">
                                        <div class="card card-body ">
                                            <div class="card-title fw-bold">{{obj.label}}</div>
                                            <ng-container *ngIf="obj.Objects">
                                                <div class="row ">
                                                    <div class="mt-4" *ngFor="let objs of obj.Objects; let i = index"
                                                        [ngClass]="objs.type == 'checkbox' ? 'col-xl-4 col-lg-6 col-md-6' : 'col-12' ">
                                                        <!--  -->
                                                        <div class="form-check form-check-right mb-3"
                                                            *ngIf="objs.type == 'checkbox'">
                                                            <input class="form-check-input" [type]="objs.type"
                                                                [name]="objs.name" [required]="objs.required"
                                                                [formControlName]="objs.name"
                                                                [checked]="objs.value == 'false' ? false : objs.value "
                                                                (change)="_onCheckChange($event, objs.name)"
                                                                [id]="objs.name + i">
                                                            <label class="form-check-label"
                                                                for="{{objs.name + i}}">{{objs.label}}</label>
                                                        </div>
                                                        <!--  -->
                                                        <div class="col mt-2" *ngIf="objs.type == 'text' ">
                                                            <label class="form-label">{{objs.label}}</label>
                                                            <input [type]="objs.type" [name]="objs.name"
                                                                [required]="objs.required" [readonly]="objs.readonly"
                                                                [required]="objs.required" [formControlName]="objs.name"
                                                                class="form-control">
                                                        </div>

                                                        <div class="col mt-2" *ngIf="objs.type=== 'textarea' ">

                                                            <app-text-area-voice-recorder
                                                                [transcript]="f[objs.name].value" [name]="objs.name"
                                                                [required]="item.required" ngDefaultControl
                                                                [formControlName]="objs.name" [rows]="4"
                                                                (eventEmmiterValue)="_onEventEmmiterValue($event, objs)">
                                                            </app-text-area-voice-recorder>
                                                        </div>

                                                    </div>
                                                </div>
                                            </ng-container>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- historia_clinica_frame_2 -->
                            <div class="col-12">
                                <div class="row mt-4" *ngIf="item.name == 'historia_clinica_frame_2'"
                                    [ngClass]="_createInterval ? '' : 'pe-none' ">
                                    <div class="col-sm-12 col-md-12 col-xxl-12">
                                        <div>
                                            <div class="mr-1 ml-2">
                                                <div class="row d-flex justify-content-between">
                                                    <div class="col-md-auto"
                                                        *ngFor="let obj of item.Objects let i = index">
                                                        <div class="form-check form-check-right mb-3"
                                                            *ngIf="obj.type == 'checkbox' ">
                                                            <input [name]="obj.name" class="form-check-input"
                                                                [type]="obj.type" [required]="obj.required"
                                                                [formControlName]="obj.name"
                                                                [checked]="obj.value == 'false' ? false: obj.value "
                                                                (change)="_onCheckChange($event, obj.name)"
                                                                [id]="obj.name + i">
                                                            <label class="form-check-label" for="{{obj.name + i}}">
                                                                {{obj.label}}
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="mr-1 ml-2">
                                                <div class="col-12" *ngFor="let obj of item.Objects">
                                                    <div class="" *ngIf="obj.type =='textarea' ">
                                                        <label class="form-label"> {{obj.label}} </label>
                                                        <app-text-area-voice-recorder
                                                            [transcript]="formClinicalHistory.controls[obj.name].value"
                                                            [name]="obj.name" [required]="item.required"
                                                            ngDefaultControl [formControlName]="obj.name" [rows]="4"
                                                            (eventEmmiterValue)="_onEventEmmiterValue($event, obj)">
                                                        </app-text-area-voice-recorder>
                                                        <!--        <textarea
                                                class="form-control"
                                                [name]="obj.name"
                                                [value]="obj.value"
                                                [formControlName]="obj.name"
                                                [rows]="5"
                                                >
                                            </textarea>    -->
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- historia_clinica_frame_3 -->
                            <div class="col-12">
                                <div class="row mt-4" *ngIf="item.name == 'historia_clinica_frame_3'"
                                    [ngClass]="_createInterval ? '' : 'pe-none' ">
                                    <div *ngFor="let obj of item.Objects; let i = index"
                                        [ngClass]=" obj.type === 'textarea' ? 'col-12' : obj.Objects ? 'col-xl-6 col-lg-12': 'col-xl-3 col-lg-4' ">

                                        <ng-container *ngIf="obj.Objects; else _inputsTemplate">
                                            <div class="card card-body ">
                                                <div class="card-title fw-bold">{{obj.label}}</div>
                                                <div class="row">
                                                    <div class="mt-2" *ngFor="let objs of obj.Objects"
                                                        [ngClass]="objs.type === 'checkbox' || objs.type === 'radio'  ? 'col-xl-6 col-md-6 col-lg-6' : 'col-12' ">

                                                        <div *ngIf="objs.type === 'checkbox' "
                                                            class="form-check form-check-right">
                                                            <input class="form-check-input" [name]="objs.name"
                                                                [type]="objs.type" [required]="objs.required"
                                                                [formControlName]="objs.name"
                                                                [checked]="objs.value == 'false' ? false: objs.value "
                                                                (change)="_onCheckChange($event, objs.name)"
                                                                [id]="objs.name + i">
                                                            <label class="form-check-label" [for]="objs.name + i">
                                                                {{objs.label}}
                                                            </label>
                                                        </div>

                                                        <div *ngIf="objs.type === 'radio' ">
                                                            <div class="form-check form-check-right form-radio-primary">
                                                                <input class="form-check-input" type="radio"
                                                                    [name]="obj.name" [value]="objs.name"
                                                                    id="{{objs.name}}" [formControlName]="obj.name">
                                                                <label class="form-check-label" for="{{objs.name}}">
                                                                    {{objs.label}}
                                                                </label>
                                                            </div>
                                                        </div>


                                                        <div
                                                            *ngIf="objs.name === 'antecedentes_personales_patologicos_cirugias_text' ">
                                                            <div
                                                                *ngIf="formClinicalHistory.controls['antecedentes_personales_patologicos_cirugias_checkbox'].value === true ">
                                                                <ng-template
                                                                    [ngTemplateOutlet]="textNumberTemplate"></ng-template>
                                                            </div>
                                                        </div>

                                                        <div
                                                            *ngIf="objs.name === 'antecedentes_personales_patologicos_internamientos_previos_checkbox_text' ">
                                                            <ng-template
                                                                [ngTemplateOutlet]="textNumberTemplate"></ng-template>
                                                        </div>

                                                        <div
                                                            *ngIf="objs.name === 'antecedentes_personales_patologicos_alergias_text'">
                                                            <div
                                                                *ngIf="formClinicalHistory.controls['antecedentes_personales_patologicos_alergias_checkbox'].value === true ">
                                                                <ng-template
                                                                    [ngTemplateOutlet]="textNumberTemplate"></ng-template>
                                                            </div>
                                                        </div>

                                                        <div
                                                            *ngIf="objs.name === 'antecedentes_personales_patologicos_internamientos_previos_text' ">
                                                            <div
                                                                *ngIf="formClinicalHistory.controls['antecedentes_personales_patologicos_internamientos_previos_checkbox'].value === true ">
                                                                <ng-template [ngTemplateOutlet]="textNumberTemplate">
                                                                </ng-template>
                                                            </div>
                                                        </div>

                                                        <div
                                                            *ngIf="objs.name === 'antecedentes_personales_patologicos_hepatitis_text' ">
                                                            <div
                                                                *ngIf="formClinicalHistory.controls['antecedentes_personales_patologicos_hepatitis_checkbox'].value === true">
                                                                <ng-template
                                                                    [ngTemplateOutlet]="textNumberTemplate"></ng-template>
                                                            </div>
                                                        </div>

                                                        <ng-template #textNumberTemplate>
                                                            <div class=""
                                                                *ngIf="objs.type === 'text' || objs.type === 'number' ">
                                                                <div class="row p-2">
                                                                    <div class="col-12">
                                                                        <label class="form-label">{{objs.label}}
                                                                        </label>
                                                                        <input class="form-control" [name]="objs.name"
                                                                            [id]="objs.name + i" [value]="objs.value"
                                                                            [required]="objs.required"
                                                                            [formControlName]="objs.name"
                                                                            [readonly]="objs.readonly"
                                                                            [type]="objs.type">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </ng-template>

                                                    </div>
                                                </div>
                                            </div>
                                        </ng-container>

                                        <ng-template #_inputsTemplate>
                                            <div *ngIf="obj.type === 'checkbox' " class="form-check form-check-right">
                                                <input class="form-check-input" [name]="obj.name" [type]="obj.type"
                                                    [required]="obj.required" [formControlName]="obj.name"
                                                    [checked]="obj.value == 'false' ? false: obj.value "
                                                    (change)="_onCheckChange($event, obj.name)" [id]="obj.name + i">
                                                <label class="form-check-label" [for]="obj.name + i">
                                                    {{obj.label}}</label>
                                            </div>
                                            <div *ngIf="obj.type === 'textarea' ">
                                                <label class="form-label"> {{obj.label}} </label>
                                                <app-text-area-voice-recorder [name]="obj.name"
                                                    [transcript]="formClinicalHistory.controls[obj.name].value"
                                                    [required]="item.required" ngDefaultControl
                                                    [formControlName]="obj.name" [rows]="4"
                                                    (eventEmmiterValue)="_onEventEmmiterValue($event, obj)">
                                                </app-text-area-voice-recorder>
                                                <!--      <textarea
                                class="form-control"
                                [name]="obj.name"
                                [value]="obj.value"
                                [required]="obj.required"
                                [formControlName]="obj.name"
                                [rows]="4"
                                >
                                </textarea>  -->
                                            </div>
                                        </ng-template>

                                    </div>
                                </div>
                            </div>
                            <!-- historia_clinica_frame_4 -->
                            <div class="col-12">
                                <div class="row mt-4" *ngIf="item.name == 'historia_clinica_frame_4'"
                                    [ngClass]="_createInterval ? '' : 'pe-none' ">
                                    <!--  -->
                                    <div [class]="item.right_layout ?  'col-md-6 col-xl-6' : 'col-12' ">
                                        <div class="row d-flex justify-content-between">
                                            <div *ngFor="let obj of item.left.Objects let i = index"
                                                [ngClass]="obj.type === 'checkbox' || obj.type === 'numeric' ? 'col-sm-12 col-md-6 col-xl-6': 'col-12' ">
                                                <!--  -->
                                                <div *ngIf="obj.type == 'checkbox' ">
                                                    <div class="row p-2 ">
                                                        <div class="col-6">
                                                            <label class="form-check-label" for="{{obj.name + i}}">
                                                                {{obj.label}}
                                                            </label>
                                                        </div>
                                                        <div class="col-6">
                                                            <div class="form-check form-check-right">
                                                                <input class="form-check-input" [name]="obj.name"
                                                                    [type]="obj.type" [required]="obj.required"
                                                                    [checked]="obj.value == 'false' ? false: obj.value "
                                                                    [formControlName]="obj.name"
                                                                    (change)="_onCheckChange($event, obj.name)"
                                                                    [id]="obj.name + i">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <!--  -->
                                                <div
                                                    *ngIf="obj.type === 'text' || obj.type === 'number' || obj.type === 'numeric' ">
                                                    <div class="row p-2">
                                                        <div class="col-12">
                                                            <label class="form-label">{{obj.label}} </label>
                                                            <input class="form-control" [name]="obj.name"
                                                                [id]="obj.name + i" [value]="obj.value"
                                                                [required]="obj.required" [formControlName]="obj.name"
                                                                [readonly]="obj.readonly" [type]="obj.type">
                                                        </div>
                                                    </div>
                                                </div>
                                                <!--  -->
                                                <div *ngIf="obj.type === 'date' ">
                                                    <input class="form-control flatpickr-input" [id]="obj.name"
                                                        [type]="obj.type" mwlFlatpickr [altInput]="true"
                                                        [formControlName]="obj.name" [value]="obj.value"
                                                        [convertModelValue]="true">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!--  -->
                                    <div [class]="item.left_layout ?  'col-md-12 col-xl-6' : 'col-12' ">
                                        <div class="row d-flex justify-content-between">
                                            <div *ngFor="let obj of item.right.Objects let i = index"
                                                [ngClass]="obj.type === 'checkbox'? 'col-sm-12 col-md-6 col-xl-6': 'col-12' ">
                                                <div *ngIf="obj.type == 'checkbox' ">
                                                    <div class="row">
                                                        <div class="col-6">
                                                            <label class="form-check-label" for="{{obj.name + i}}">
                                                                {{obj.label}}
                                                            </label>
                                                        </div>
                                                        <div class="col-6">
                                                            <div class="form-check form-check-right">
                                                                <input
                                                                    class="form-check-input d-flex justify-content-end"
                                                                    [name]="obj.name" [type]="obj.type"
                                                                    [required]="obj.required"
                                                                    [formControlName]="obj.name"
                                                                    [readonly]="obj.readonly"
                                                                    [checked]="obj.value == 'false' ? false: obj.value "
                                                                    (change)="_onCheckChange($event, obj.name)"
                                                                    [id]="obj.name + i">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div *ngIf="obj.type === 'text' || obj.type === 'number' ">
                                                    <div class="row p-2">
                                                        <div class="col-12">
                                                            <label class="form-label">{{obj.label}} </label>
                                                            <input class="form-control" [name]="obj.name"
                                                                [id]="obj.name + i" [value]="obj.value"
                                                                [required]="obj.required" [formControlName]="obj.name"
                                                                [readonly]="obj.readonly" [type]="obj.type">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!--  -->
                                    <div *ngIf="item.down" class="col-12">
                                        <div class="row d-flex justify-content-between">
                                            <div class="col-sm-12 col-md-12 col-xl-6"
                                                *ngFor="let obj of item.down.Objects let i = index">
                                                <div *ngIf="obj.type == 'text' ">
                                                    <div class="" *ngIf="obj.type == 'text' ">
                                                        <div class="row p-2">
                                                            <div class="col-12">
                                                                <label class="form-label">{{obj.label}} </label>
                                                                <input class="form-control" [name]="obj.name"
                                                                    [id]="obj.name + i" [value]="obj.value"
                                                                    [required]="obj.required" [readonly]="obj.readonly"
                                                                    [formControlName]="obj.name" [type]="obj.type">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div *ngIf="obj.type == 'textarea' ">
                                                    <div class="" *ngIf="obj.type =='textarea' ">
                                                        <label class="form-label"> {{obj.label}} </label>
                                                        <app-text-area-voice-recorder [name]="obj.name"
                                                            [transcript]="formClinicalHistory.controls[obj.name].value"
                                                            [required]="item.required" ngDefaultControl
                                                            [formControlName]="obj.name" [rows]="4"
                                                            (eventEmmiterValue)="_onEventEmmiterValue($event, obj)">
                                                        </app-text-area-voice-recorder>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- historia_clinica_frame_5 -->
                            <div class="col-12">
                                <div class="row mt-4" *ngIf="item.name == 'historia_clinica_frame_5' "
                                    [ngClass]="_createInterval ? '' : 'pe-none' ">
                                    <div class="col-12">
                                        <div class="row d-flex justify-content-between">
                                            <div *ngFor="let obj of item.Objects let i = index;"
                                                [ngClass]="obj.type === 'textarea' ?  'col-12' : 'col-xl-4 col-lg-6' ">

                                                <div *ngIf="obj.type == 'text' ">
                                                    <div class="" *ngIf="obj.type == 'text' ">
                                                        <div class="row p-2">
                                                            <div class="col-12">
                                                                <label class="form-label">{{obj.label}} </label>
                                                                <input class="form-control" [name]="obj.name"
                                                                    [id]="obj.name + i" [value]="obj.value"
                                                                    [required]="obj.required"
                                                                    [formControlName]="obj.name"
                                                                    [readonly]="obj.readonly" [type]="obj.type">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div *ngIf="obj.type == 'textarea' ">
                                                    <div class="" *ngIf="obj.type =='textarea' ">
                                                        <label class="form-label"> {{obj.label}} </label>
                                                        <app-text-area-voice-recorder [name]="obj.name"
                                                            [transcript]="formClinicalHistory.controls[obj.name].value"
                                                            [required]="item.required" ngDefaultControl
                                                            [formControlName]="obj.name" [rows]="4"
                                                            (eventEmmiterValue)="_onEventEmmiterValue($event, obj)">
                                                        </app-text-area-voice-recorder>
                                                        <!--    <textarea
                                                class="form-control"
                                                [name]="obj.name"
                                                [value]="obj.value"
                                                [formControlName]="obj.name"
                                                [rows]="4"
                                                >
                                                </textarea>    -->
                                                    </div>
                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- historia_clinica_frame_6 -->
                            <div class="col-12">
                                <div class="row mt-4" *ngIf="item.name == 'historia_clinica_frame_6' "
                                    [ngClass]="_createInterval ? '' : 'pe-none' ">
                                    <div *ngFor="let obj of item.Objects; let i = index"
                                        [ngClass]=" obj.type === 'textarea' ? 'col-12' : obj.Objects ? 'col-xl-6 col-lg-12': 'col-xl-6 col-lg-12' ">
                                        <ng-container *ngIf="obj.Objects; else _inputsTemplateframe_6">
                                            <div class="card card-body">
                                                <div class="card-title fw-bold">{{obj.label}} </div>
                                                <div class="row">
                                                    <div *ngFor="let objs of obj.Objects; let i = index"
                                                        [ngClass]="objs.type === 'checkbox' || objs.type === 'radio'  ? 'col-xl-6 col-md-6 col-lg-6' : 'col-12' ">
                                                        <!--  -->
                                                        <div *ngIf="objs.type === 'checkbox' "
                                                            class="form-check form-check-right">
                                                            <input class="form-check-input" [name]="objs.name"
                                                                [type]="objs.type" [required]="objs.required"
                                                                [formControlName]="objs.name"
                                                                [checked]="objs.value == 'false' ? false: objs.value "
                                                                (change)="_oncheckEstrenimiento($event, objs.name, obj.Objects)"
                                                                [id]="objs.name + i">
                                                            <label class="form-check-label" [for]="objs.name + i">
                                                                {{objs.label}}
                                                            </label>
                                                        </div>

                                                        <div
                                                            *ngIf="obj.name === 'estrenimiento_interrogatorio_proctologico' ">
                                                            <div
                                                                *ngIf="formClinicalHistory.controls['estrenimiento_interrogatorio_proctologico_checkbox'].value === true ">
                                                                <div *ngIf="objs.type === 'radio' ">
                                                                    <div
                                                                        class="form-check form-check-right form-radio-primary">
                                                                        <input class="form-check-input" type="radio"
                                                                            [name]="obj.name" [value]="objs.name"
                                                                            id="{{objs.name}}"
                                                                            [formControlName]="obj.name">
                                                                        <label class="form-check-label"
                                                                            for="{{objs.name}}">
                                                                            {{objs.label}}
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>


                                                        <!--  -->


                                                        <!--  -->
                                                        <div *ngIf="objs.Objects">
                                                            <div class="card card-body">
                                                                <div class="card-title text-primary"> {{objs.label}}
                                                                </div>
                                                                <div class="row">
                                                                    <div *ngFor="let _obj of objs.Objects"
                                                                        [ngClass]="_obj.type === 'checkbox' || _obj.type === 'radio'  ? 'col-xl-6 col-md-6 col-lg-6' : 'col-12' ">
                                                                        <div *ngIf="_obj.type === 'checkbox' "
                                                                            class="form-check form-check-right">
                                                                            <input class="form-check-input"
                                                                                [name]="_obj.name" [type]="_obj.type"
                                                                                [required]="_obj.required"
                                                                                [formControlName]="_obj.name"
                                                                                [checked]="_obj.value == 'false' ? false: _obj.value "
                                                                                (change)="_onCheckChange($event, _obj.name)"
                                                                                [id]="_obj.name + i">
                                                                            <label class="form-check-label"
                                                                                [for]="_obj.name + i">
                                                                                {{_obj.label}}
                                                                            </label>
                                                                        </div>
                                                                        <div *ngIf="_obj.type === 'radio' ">
                                                                            <div
                                                                                class="form-check form-check-right form-radio-primary">
                                                                                <input class="form-check-input"
                                                                                    type="radio" [name]="objs.name"
                                                                                    [value]="_obj.name"
                                                                                    id="{{_obj.name}}"
                                                                                    [formControlName]="objs.name">
                                                                                <label class="form-check-label"
                                                                                    for="{{_obj.name}}">
                                                                                    {{_obj.label}}
                                                                                </label>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <!--  -->
                                                        <div *ngIf="objs.type === 'textarea' ">
                                                            <label class="form-label"> {{objs.label}}</label>
                                                            <app-text-area-voice-recorder [name]="obj.name"
                                                                [transcript]="formClinicalHistory.controls[obj.name].value"
                                                                [required]="item.required" ngDefaultControl
                                                                [formControlName]="obj.name" [rows]="4"
                                                                (eventEmmiterValue)="_onEventEmmiterValue($event, obj)">
                                                            </app-text-area-voice-recorder>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </ng-container>
                                        <ng-template #_inputsTemplateframe_6>
                                            <ng-container *ngIf="obj.name === 'dolor_interrogatorio_proctologico'">
                                                <div class="card card-body">
                                                    <div class="card-title fw-bold">{{obj.label}}</div>
                                                    <ngx-slider formControlName="dolor_interrogatorio_proctologico"
                                                        [options]="options"></ngx-slider>
                                                </div>
                                            </ng-container>
                                            <div *ngIf="obj.type === 'checkbox' " class="form-check form-check-right">
                                                <input class="form-check-input" [name]="obj.name" [type]="obj.type"
                                                    [required]="obj.required" [formControlName]="obj.name"
                                                    [checked]="obj.value == 'false' ? false: obj.value "
                                                    (change)="_onCheckChange($event, obj.name)" [id]="obj.name + i">
                                                <label class="form-check-label" [for]="obj.name + i">
                                                    {{obj.label}}</label>
                                            </div>
                                            <div *ngIf="obj.type === 'textarea' ">
                                                <label class="form-label"> {{obj.label}} </label>
                                                <app-text-area-voice-recorder [name]="obj.name"
                                                    [transcript]="formClinicalHistory.controls[obj.name].value"
                                                    [required]="item.required" ngDefaultControl
                                                    [formControlName]="obj.name" [rows]="4"
                                                    (eventEmmiterValue)="_onEventEmmiterValue($event, obj)">
                                                </app-text-area-voice-recorder>
                                            </div>
                                        </ng-template>
                                    </div>
                                </div>
                            </div>
                            <!-- historia_clinica_frame_7 -->
                            <div class="col-12">
                                <div class="row mt-4" *ngIf="item.name == 'historia_clinica_frame_7' ">
                                    <div *ngIf="_Images && _Images[item.name] ">
                                        <app-gallery-image [GalleryImage]="_Images[item.name]" [base64]="false"
                                            [_showDeleteBtn]="true"
                                            (eventEmmitDelete)="_onEventDeleteModel($event, item)"></app-gallery-image>
                                    </div>
                                    <div *ngIf="_createInterval">

                                        <div *ngFor="let obj of item.Objects let i = index;">
                                            <ng-container *ngIf="obj.type == 'file'; else otherForms ">
                                                <div class="row">
                                                    <div class="col-12">
                                                        <fieldset *ngIf="_showCustomFiles">
                                                            <div class="card card-body">
                                                                <app-custom-upload-files [showUpload]="true"
                                                                    [_maxFiles]="10" [_showGallery]="true"
                                                                    [_acceptedFileTypes]="['image/jpeg','image/png','image/jpg','application/pdf']"
                                                                    (_filesPondsEvent)="_onUploadFiles($event,item)"
                                                                    [eventSubjectClerFiles]="eventSubjectClerFiles.asObservable()">
                                                                </app-custom-upload-files>
                                                            </div>
                                                        </fieldset>
                                                    </div>
                                                    <div class="col-12">
                                                        <app-ng-web-cam
                                                            (eventSaveImages)="_onSaveItImages($event, item)"></app-ng-web-cam>
                                                    </div>
                                                </div>
                                            </ng-container>
                                            <ng-template #otherForms>
                                                <div class="row">
                                                    <div class="col-xl-4 col-lg-6 col-md-6 col-sm-6">
                                                        <div *ngIf="obj.type == 'textarea' ">
                                                            <label class="form-label"> {{obj.label}} </label>
                                                            <app-text-area-voice-recorder [name]="obj.name"
                                                                [transcript]="formClinicalHistory.controls[obj.name].value"
                                                                [required]="item.required" ngDefaultControl
                                                                [formControlName]="obj.name" [rows]="4"
                                                                (eventEmmiterValue)="_onEventEmmiterValue($event, obj)">
                                                            </app-text-area-voice-recorder>
                                                        </div>
                                                        <div class="" *ngIf="obj.type == 'text' ">
                                                            <div class="row p-2">
                                                                <div class="col-12">
                                                                    <label class="form-label">{{obj.label}} </label>
                                                                    <input class="form-control" [name]="obj.name"
                                                                        [id]="obj.name + i" [value]="obj.value"
                                                                        [required]="obj.required"
                                                                        [readonly]="obj.readonly"
                                                                        [formControlName]="obj.name" [type]="obj.type">
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-template>
                                        </div>

                                        <div class="d-flex mt-4">
                                            <div class="card card-body">
                                                <div class="row gp-2 ">
                                                    <div class="col-xxl-4 col-lg-6" *ngFor="let item of _svgEditors">
                                                        <app-svg-editor [_path]="item.path" [_maxHeigh]="70"
                                                            [_svgItem]="item"
                                                            (eventEmmiterSaveImg)="_onEvenEmmiter($event)"
                                                            (eventEmmiterImage)="_onEventEmmiterImg($event)"></app-svg-editor>
                                                    </div>
                                                </div>

                                                <div class="d-flex justify-content-center mt-4"
                                                    *ngIf="_svgfilesPng && _svgfilesPng.length">
                                                    <button class="btn btn-success w-100"
                                                        (click)="_saveSvgToPng(item)">Guardar</button>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <!-- historia_clinica_frame_8 -->
                            <div class="col-12">
                                <div class="row mt-4" *ngIf="item.name == 'historia_clinica_frame_8' ">
                                    <div *ngIf="_Images && _Images[item.name] ">
                                        <app-gallery-image [GalleryImage]="_Images[item.name]" [base64]="false"
                                            [_showDeleteBtn]="true"
                                            (eventEmmitDelete)="_onEventDeleteModel($event, item)"></app-gallery-image>
                                    </div>
                                    <fieldset class="mt-4" *ngIf="_LaboratorioFiles && _LaboratorioFiles.length">
                                        <legend class="text-muted fs-12 fw-bold"> Archivos cargados(Laboratorio)
                                        </legend>
                                        <app-gallery-image [GalleryImage]="_LaboratorioFiles" [base64]="false"
                                            [_showDeleteBtn]="true"
                                            (eventEmmitDelete)="_onEventDeleteModel($event, item, 'true')"></app-gallery-image>
                                    </fieldset>
                                    <div class="col-12" *ngIf="_createInterval">
                                        <div class="row">
                                            <div class="col-12" *ngFor="let obj of item.Objects let i = index;">
                                                <div *ngIf="obj.type == 'file' ">
                                                    <div class="row">
                                                        <div class="col-12">
                                                            <fieldset>
                                                                <div class="card card-body">
                                                                    <app-custom-upload-files [showUpload]="true"
                                                                        [_maxFiles]="10" [_showGallery]="true"
                                                                        [_acceptedFileTypes]="['image/jpeg','image/png','image/jpg','application/pdf']"
                                                                        (_filesPondsEvent)="_onUploadFiles($event,item)"
                                                                        [eventSubjectClerFiles]="eventSubjectClerFiles.asObservable()">
                                                                    </app-custom-upload-files>
                                                                </div>
                                                            </fieldset>
                                                        </div>
                                                        <div class="col-12">
                                                            <app-ng-web-cam
                                                                (eventSaveImages)="_onSaveItImages($event, item)"></app-ng-web-cam>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div *ngIf="obj.type == 'textarea' ">
                                                    <label class="form-label"> {{obj.label}} </label>
                                                    <app-text-area-voice-recorder [name]="obj.name"
                                                        [transcript]="formClinicalHistory.controls[obj.name].value"
                                                        [required]="item.required" ngDefaultControl
                                                        [formControlName]="obj.name" [rows]="4"
                                                        (eventEmmiterValue)="_onEventEmmiterValue($event, obj)">
                                                    </app-text-area-voice-recorder>
                                                    <!--    <textarea
                                        class="form-control"
                                        [name]="obj.name"
                                        [value]="obj.value"
                                        [formControlName]="obj.name"
                                        [rows]="4"
                                        >
                                        </textarea>    -->
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <!-- historia_clinica_frame_9 -->
                            <div class="col-12">
                                <div class="row mt-4" *ngIf="item.name == 'historia_clinica_frame_9' "
                                    [ngClass]="_createInterval ? '' : 'pe-none' ">
                                    <div *ngFor="let obj of item.Objects let i = index;"
                                        [ngClass]="obj.Objects ? 'col-xl-6 col-md-6': obj.type === 'textarea' ? 'col-12' : 'col-md-6 col-lg-6' ">
                                        <div class="card card-body">
                                            <div class="card-title fw-bold">{{obj.label}}</div>
                                            <div *ngIf="obj.type === 'textarea' ">
                                                <label class="form-label"> {{obj.label}} </label>
                                                <app-text-area-voice-recorder [name]="obj.name"
                                                    [transcript]="formClinicalHistory.controls[obj.name].value"
                                                    [required]="item.required" ngDefaultControl
                                                    [formControlName]="obj.name" [rows]="4"
                                                    (eventEmmiterValue)="_onEventEmmiterValue($event, obj)">
                                                </app-text-area-voice-recorder>
                                                <!--      <textarea
                                    class="form-control"
                                    [name]="obj.name"
                                    [value]="obj.value"
                                    [formControlName]="obj.name"
                                    [rows]="4"
                                    >
                                    </textarea>    -->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- historia_clinica_frame_10 -->
                            <div class="col-12">
                                <div class="row mt-4" *ngIf="item.name == 'historia_clinica_frame_10' "
                                    [ngClass]="_createInterval ? '' : 'pe-none' ">
                                    <!-- CheckBox -->
                                    <fieldset class="mt-4">
                                        <div class="row">
                                            <div class="col-lg-4 col-md-6">
                                                <div class="mt-4 mt-md-0">
                                                    <div class="form-check form-check-right">
                                                        <input class="form-check-input" type="checkbox"
                                                            value="_plan_de_tratamiento" id="_plan_de_tratamiento"
                                                            [(ngModel)]="_plan_de_tratamiento"
                                                            [ngModelOptions]="{standalone: true}">
                                                        <label
                                                            class="form-check-label text-uppercase fw-bold text-primary"
                                                            for="_plan_de_tratamiento">
                                                            Describir plan de tratamiento
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-4 col-md-6">
                                                <div class="mt-4 mt-md-0">
                                                    <div class="form-check form-check-right">
                                                        <input class="form-check-input" type="checkbox"
                                                            value="_cita_subsecuente" id="_cita_subsecuente"
                                                            [(ngModel)]="_cita_subsecuente"
                                                            [ngModelOptions]="{standalone: true}">
                                                        <label
                                                            class="form-check-label text-uppercase fw-bold text-primary"
                                                            for="_cita_subsecuente">
                                                            Cita Subsecuente
                                                        </label>
                                                    </div>
                                                </div>
                                            </div><!--end col-->
                                            <div class="col-lg-4 col-md-6">
                                                <div class="mt-4 mt-md-0">
                                                    <div class="form-check form-check-right">
                                                        <input class="form-check-input" type="checkbox"
                                                            value="medicamento" id="medicamento"
                                                            (ngModelChange)="_onChangeModel($event)"
                                                            [(ngModel)]="medicamento"
                                                            [ngModelOptions]="{standalone: true}">
                                                        <label
                                                            class="form-check-label text-uppercase fw-bold text-primary"
                                                            for="medicamento">
                                                            Medicamento
                                                        </label>
                                                    </div>
                                                </div>
                                            </div><!--end col-->

                                            <!--       <div class="col-lg-4 col-md-6">
                                    <div class="mt-4 mt-md-0">
                                        <div class="form-check form-check-right">
                                            <input class="form-check-input" type="checkbox" value="_indicaciones_medicas"   id="_indicaciones_medicas" [(ngModel)]="_indicaciones_medicas" [ngModelOptions]="{standalone: true}">
                                            <label class="form-check-label text-uppercase fw-bold text-primary" for="_indicaciones_medicas">
                                                Indicaciones Medicas
                                            </label>
                                        </div>
                                    </div>
                                </div> -->
                                        </div>
                                    </fieldset>

                                    <!-- RECETAS -->
                                    <fieldset class="mt-4" *ngIf="_recetas && _recetas.length">
                                        <div class="card card-body">
                                            <div class="card-title fw-bold text-primary">Receta registrada</div>
                                            <div class="mt-4">
                                                <div class="d-none d-lg-block">
                                                    <div class="card card-body" *ngFor="let receta of _recetas">
                                                        <span class="fw-bold"> {{receta.fecha}} </span>
                                                        <div class="d-flex justify-content-end">
                                                            <div class="hstack gap-1">
                                                                <button class="btn btn-primary"
                                                                    (click)="_generatePDF(receta)">Imprimir receta
                                                                </button>
                                                                <button class="btn btn-secondary"
                                                                    (click)="_editarReceta(receta)">Editar receta
                                                                </button>
                                                                <button class="btn btn-danger"
                                                                    (click)="_onDelete(receta)">Borrar receta </button>
                                                            </div>
                                                        </div>
                                                        <div class="table-responsive mt-2">
                                                            <div class="table table-hover table-sm ">
                                                                <table class="table table-hover table-sm ">
                                                                    <thead
                                                                        class="bg-light text-center fw-bold text-uppercase">
                                                                        <th scope="col">Nombre producto</th>
                                                                        <th scope="col">Descripción</th>
                                                                        <th scope="col">Tipo</th>
                                                                        <th scope="col">Descripción aplicación </th>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr
                                                                            *ngFor="let receta of receta.productos_recetados">
                                                                            <td class="text-wrap">
                                                                                {{receta.nombre_producto}}
                                                                            </td>
                                                                            <td class="text-wrap">
                                                                                {{receta.descripcion_producto}} </td>
                                                                            <td>{{receta.tipo}} </td>
                                                                            <td class="text-wrap">
                                                                                {{receta.descripcion_aplicacion}} </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="d-block d-lg-none">
                                                    <div
                                                        class="row row-cols-xxl-3 row-cols-lg-2 row-cols-md-2 row-cols-1">
                                                        <div class="card card-body" *ngFor="let receta of _recetas">
                                                            <span class="fw-bold"> {{receta.fecha}} </span>
                                                            <div class="d-flex justify-content-end">
                                                                <div class="hstack gap-1">
                                                                    <button class="btn btn-primary"
                                                                        (click)="_generatePDF(receta)">Imprimir receta
                                                                    </button>
                                                                    <button class="btn btn-secondary"
                                                                        (click)="_editarReceta(receta)">Editar receta
                                                                    </button>
                                                                    <button class="btn btn-danger"
                                                                        (click)="_onDelete(receta)">Borrar receta
                                                                    </button>
                                                                </div>
                                                            </div>
                                                            <div class="col"
                                                                *ngFor="let receta of receta.productos_recetados">
                                                                <div class="card mt-2 shadow-lg p-3 rounded">
                                                                    <div class="card-body">
                                                                        <div class="card-title">
                                                                            <h5 class="text-primary">
                                                                                {{receta.nombre_producto}}
                                                                            </h5>
                                                                        </div>
                                                                        <div class="d-md-flex align-items-center">
                                                                            <div class="ms-lg-3 my-3 my-lg-0">
                                                                                <div class="m-2">
                                                                                    <h5 class="fs-14 mb-2">Descripción
                                                                                    </h5>
                                                                                    <p class="text-primary mb-0">
                                                                                        {{receta.descripcion_producto}}
                                                                                    </p>
                                                                                </div>

                                                                                <div class="m-2">
                                                                                    <h5 class="fs-14 mb-2">Tipo</h5>
                                                                                    <p class="text-primary mb-0">
                                                                                        {{receta.tipo}}
                                                                                    </p>
                                                                                </div>

                                                                                <div class="m-2">
                                                                                    <h5 class="fs-14 mb-2">Descripción
                                                                                        aplicación</h5>
                                                                                    <p class="text-primary mb-0">
                                                                                        {{receta.descripcion_aplicacion}}
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </fieldset>

                                    <!-- CITA SUBSECUENTE -->
                                    <app-cita-subsecuente *ngIf="_cita_subsecuente"></app-cita-subsecuente>

                                    <!-- MEDICMANETO -->
                                    <fieldset class="mt-4" *ngIf="medicamento">
                                        <app-receta-medicamento [_loadRecetas]="true"
                                            (eventLoadMedicamentos)="_onEventloadMedicamento($event)"></app-receta-medicamento>
                                    </fieldset>

                                    <fieldset class="mt-4" *ngIf="_plan_de_tratamiento">
                                        <app-text-area-voice-recorder></app-text-area-voice-recorder>
                                    </fieldset>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>

            </ngb-panel>
        </ngb-accordion>
    </form>
    <div *ngIf="_footer && _footer.length">
        <ng-container *ngIf="_Firma && _Firma.length; loadedTemplate">
            <div class="col-12 ">
                <h4 class="fs-4 text-primary bold text-uppercase text-center">Firma digital </h4>
            </div>
            <fieldset class="mt-4">
                <div class="card" *ngFor="let item of _Firma">
                    <div class="card-body">
                        <div class="d-flex justify-content-center">
                            <figure class="figure mb-0">
                                <img [src]="item.url" class="figure-img img-fluid" max alt="" width="300">
                            </figure>
                        </div>
                    </div>
                </div>
            </fieldset>
        </ng-container>
        <ng-template #loadedTemplate>
            <app-firma-doctor></app-firma-doctor>
        </ng-template>
    </div>
</div>
<!--</div> -->

<ngx-spinner [name]="_spinner_img" [fullScreen]="false" type="ball-scale-multiple" [size]="'medium'"> </ngx-spinner>

<ng-template #modalReceta let-modalReceta>
    <div class="modal-header">
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="btn-close"
            (click)="modalReceta.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body text-center">
        <app-receta-medicamento [_closeModal]="true" [_receta]="_receta_"
            (eventCloseModal)="_onEventCloseModal2($event)"></app-receta-medicamento>
    </div>
</ng-template>