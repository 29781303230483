<form [formGroup]="_formCita" (ngSubmit)="onSubmit()" class="needs-validation createfile-form" autocomplete="off" novalidate>
    <fieldset>        
        <div class="row g-2 mt-4">
            <!-- doctor -->
            <div class="col-12">
                <div class="form-group">
                    <label class="form-label">Doctor</label>
                    <ng-select
                    [items]="_listaDoctores"
                    bindLabel="name"         
                    [loading]="_loaderDoctor"                         
                    bindValue="id_salesforce"
                    formControlName="doctor"
                    placeholder="Seleccione un doctor"
                    [disabled]="editable"
                    (change)="_onCahnge($event)"
                    [ngClass]="{ 'is-invalid': f['doctor'].invalid && f['doctor'].errors && f['doctor'].touched}"
                    [clearable]="false"></ng-select>
                    <div *ngIf="f['doctor'].invalid && f['doctor'].errors && f['doctor'].touched" class="invalid-feedback">
                        <div *ngIf="f['doctor'].errors['required']" >
                            Este campo es requerido
                        </div>
                    </div> 
                </div>    
                <!--  -->
                <fieldset class="mt-4" *ngIf="_showCalendar" >

                    <div class="row g-2">
                        <!-- Paciente -->
                        <div class="col-12">
                            <label class="control-label">Paciente</label>
                            <ng-select
                            [items]="_Pacientes"
                            bindLabel="Nombre_completo__c"
                            bindValue="Id"
                            [virtualScroll]="true"
                            (scrollToEnd)="fetchMore(_set_index)"
                            [loading]="_loaderPaciente"
                            formControlName="paciente"        
                            [maxlength]="1000"         
                            [ngClass]="{ 'is-invalid': f['paciente'].invalid && f['paciente'].errors && f['paciente'].touched}"       
                            (change)="_onChangePatient()"        
                            [clearable]="false">
                            </ng-select>
                            <div *ngIf="f['paciente'].errors" class="invalid-feedback">
                                <div *ngIf="f['paciente'].errors['required']">Este campo es requerido</div>                                       
                            </div>
                        </div>
                    </div>
                    <!--  -->
                    <div class="row g-2 mt-4">    
                        <div class="col-12">
                            <div class="row">
                                <div class="col-xl-4 col-md-12">    
                                    <div class="mb-3" *ngIf="_calendarCitas && _calendarCitas.horas && _calendarCitas.horas.length">                                        
                                        <ng-container *ngIf="!_calendarCitas.bloqueo_empalme_citas && _calendarCitas.horas else Bloqueo ">
                                            <ngx-simplebar style="max-height: 300px;">
                                                <div class="row m-1 row-cols-xxl2 row-cols-xl-3 row-cols-2" >                                                   
                                                    <div class="col mt-2" *ngFor="let item of _calendarCitas.horas; let i = index;" >
                                                        <div class="flex-grow-1 text-muted overflow-hidden" >                                                                       
                                                            <a href="javascript:void(0)" 
                                                            (click)="_onsetHour(_calendarCitas.fecha, item.Horario, i,0, item.Disponibilidad )" 
                                                            
                                                            class="badge  badge-border fs-16"                                                         
                                                            [ngClass]="{'border border-2 border-dashed ': i === _horaIndex , 
                                                                        'bg-success-subtle text-success border-success'  : item.Disponibilidad === 'Disponible',
                                                                        'bg-danger-subtle  text-danger border-danger'  : item.Disponibilidad === 'Ocupado',
                                                                        'bg-light-subtle border-dashed text-muted pe-none ': item.Disponibilidad === 'Bloqueado' } ">  {{item.Horario}}                                                  
                                                            </a>        
                                                            <div class="d-flex justify-content-start">
                                                                <!-- class="" -->
                                                                <small [ngClass]="item.Disponibilidad === 'Disponible' ? 'text-success' : 
                                                                                  item.Disponibilidad === 'Ocupado'    ? 'text-danger' : 
                                                                                  item.Disponibilidad === 'Bloqueado'  ? 'text-muted' : '' " > {{item.Disponibilidad}}</small>
                                                            </div>                                                
                                                        </div>
                                                    </div>
                                                  </div>  
                                              </ngx-simplebar>
                                        </ng-container>
                                        <ng-template #Bloqueo>
                                            <ngx-simplebar style="max-height: 300px;">
                                                <div class="row m-1 row-cols-xxl2 row-cols-xl-3 row-cols-2" >                                                   
                                                    <div class="col mt-2" *ngFor="let item of _calendarCitas.horas; let i = index;" >
                                                        <div class="flex-grow-1 text-muted overflow-hidden" >                                                                       
                                                            <a href="javascript:void(0)" class="badge bg-success-subtle text-success badge-border fs-16"
                                                            (click)="_onsetHour(_calendarCitas.fecha,item, i, 0, null )" 
                                                            [ngClass]="{'border border-2 border-dashed border-success': i === _horaIndex }">  {{item}}                                                  
                                                            </a>        
                                                            <div class="d-flex justify-content-start">
                                                                <small class="text-success"> Disponible</small>
                                                            </div>                                                
                                                        </div>
                                                    </div>
                                                  </div>  
                                              </ngx-simplebar>
                                        </ng-template>
                                        
                                  <!-- -->
                                    </div>
                                    <!--  -->
                                    <div class="mb-3">
                                        <label class="control-label">Tipo de cita</label>
                                        <ng-select
                                        [items]="_tipo_evento"
                                        bindLabel="name"
                                        bindValue="id"
                                        [loading]="_loadingtipoCita"
                                        formControlName="tipo_evento"
                                        (change)="_onLoadTipoProcedimiento($event)"
                                        [ngClass]="{ 'is-invalid': f['tipo_evento'].invalid && f['tipo_evento'].errors && f['tipo_evento'].touched}"
                                        [clearable]="false">
                                        </ng-select>
                                        <div *ngIf="f['tipo_evento'].errors" class="invalid-feedback">
                                            <div *ngIf="f['tipo_evento'].errors['required']">Este campo es requerido</div>                                       
                                        </div>   
                                    </div>   
                                    <!--  -->
                                    <div class="mb-3" *ngIf="_fechaSelected && _horaSelected">
                                        <div class="card card-body">
                                            <fieldset>
                                                <legend class="text-muted fs-12">Fecha/hora seleccionadas</legend>
                                                <div class="d-flex align-items-center">
                                                    <div class="flex-shrink-0">
                                                        <h4 class="fw-bold text-primary">{{_fechaSelected}}</h4>
                                                    </div>
                                                    <div class="flex-shrink-0 mx-3">
                                                        <h4 class="fw-bold text-primary">{{_horaSelected}}</h4>
                                                    </div>                                                                                                
                                                </div>    
                                            </fieldset>  

                                            <div class="mt-4">
                                                <div class="row row-cols-xl-2 row-cols-md-2 row-cols-1">
                                                    <div class="col">
                                                        <div class="form-group ">
                                                            <label class="control-label">Fecha Final</label>
                                                            <input class="form-control" formControlName="fecha_final" [readonly]="true" [disabled]="true">
                                                        </div>  
                                                    </div>
                                                    <div class="col">
                                                        <div class="form-group ">
                                                            <label class="control-label">Hora Final</label>
                                                            <input 
                                                            type="time"
                                                            class="form-control flatpickr-input" 
                                                            mwlFlatpickr                                                
                                                            [noCalendar]="true" 
                                                            [enableTime]="true" 
                                                            [dateFormat]="'H:i'"
                                                            formControlName="hora_final" >
                                                        </div>
                                                    </div>
                                                </div> 
                                            </div>

                                            <fieldset class="mt-2" *ngIf="_servicioSelected">
                                                <ngb-alert type="warning" [dismissible]="false"  class="alert-dismissible alert-label-icon label-arrow fade show custom-alert">
                                                <i class="ri-alert-line label-icon"></i> Tiempo del evento <strong>{{_servicioSelected.Tiempo_del_Evento}} </strong>
                                              </ngb-alert>
                                            </fieldset>

                                        </div>
                                    </div>

                               

                                </div>   
                                <div class="col-xl-8 col-md-12">
                                    <div class="card card-body">          
                                        <full-calendar #fullCalendar [options]="calendarOptions" [deepChangeDetection]="true"  contentHeight="auto"> </full-calendar>     
                                      <!--   <div class="">
                                            <div class="mx-n3">
                                              
                                              </div>
                                        </div> -->
                                    </div>
                                </div>                      
                            </div>
                        </div>                           
                    </div>
                    <!--  -->
                    <div class="row g-2 mt-4">
                        <div class="col-6">
                            <label class="control-label">Tipo de procedimiento</label>
                            <ng-select
                            [items]="_tipo_procedimiento"
                            bindLabel="Name"
                            bindValue="Id"
                            [clearable]="false"
                            (change)="_onChangeServicio($event)"
                            [ngClass]="{ 'is-invalid': f['servicio'].invalid && f['servicio'].errors && f['servicio'].touched}"
                            formControlName="servicio">    
                            </ng-select>           
                            <div *ngIf="f['servicio'].errors" class="invalid-feedback">
                                <div *ngIf="f['servicio'].errors['required']">Este campo es requerido</div>                                       
                            </div>                     
                        </div>

                        <div class="col-6">
                            <div class="mb-3">
                                <label class="control-label">Estatus</label>
                                <ng-select
                                [items]="_estatus"
                                bindLabel="name"
                                bindValue="id"
                                formControlName="estatus"
                                [clearable]="false"
                                ></ng-select>
                            </div>
                        </div>
                    </div>
                    <!--  -->
                    <div class="row g-2 mt-4">
                        <div class="col-12">
                            <div class="row g-2">
                                <div class="col-6">
                              
                                    <div class="mb-3" *ngIf="form['estatus'].value == 'Cancelada' ">
                                        <label class="control-label">Motivo de cancelacion</label>
                                        <ng-select
                                        [items]="_motivo_cancelacion"
                                        bindLabel="name"
                                        bindValue="id"
                                        formControlName="motivo_cancelacion"
                                        [clearable]="false"></ng-select>
                                    </div>
                                </div>
                          
                            </div>
                        </div>
                    </div>    

                </fieldset>
            </div>
   
        </div>

    </fieldset>
            <!--  -->
    <fieldset *ngIf="_showCalendar" >
        <div class="row event-form">
            <fieldset>
                <legend class="text-muted fs-16 bg-light">Datos Generales</legend>
                <!-- Comentario -->
                <div class="col-12">                     
                    <label class="control-label">Comentario</label>
                    <textarea
                    class="form-control"
                    formControlName="comentarios"
                    [rows]="5"></textarea>
                </div>

              <!--   <div class="col-12">
                    <div class="row g-2 mt-4"> -->
                        <!-- Pixel Google -->
                  <!--       <div class="col-4">
                            <label class="control-label">Pixel Google</label>
                            <input type="text" formControlName="pixel_gooogle" class="form-control">
                        </div> -->
                        <!-- ID Camapaña -->
                <!--         <div class="col-4">
                            <label class="control-label">Id Campaña</label>
                            <input
                            type="text" 
                            formControlName="id_campaña"
                            class="form-control">
                        </div> -->
                        <!-- Pixelk Facebook -->
                   <!--      <div class="col-4">
                            <label class="control-label">Pixel Facebook</label>
                            <input 
                            type="text" 
                            formControlName="pixel_facebook"
                            class="form-control">
                        </div>
                    </div> -->
          <!--           <div class="row g-2 mt-4"> -->
                        <!-- Recordatorio -->
                  <!--       <div class="col-4 ">                            
                            <div class="col-12">
                                <label class="form-check-label">Enviar Recordatorio</label> &nbsp;&nbsp;
                                <input class="form-check-input ml-4 mr-4" name="enviar_recordatorio" formControlName="enviar_recordatorio" type="checkbox">
                            </div>
                        </div> -->
                        <!-- enviar encuesta -->
                  <!--       <div class="col-4">
                            <label class="form-check-label">Enviar Encuesta</label> &nbsp;&nbsp;
                            <input class="form-check-input ml-4 mr-4" name="enviar_encuesta" formControlName="enviar_encuesta" type="checkbox">
                        </div> -->
                        <!-- fecha de envio -->
                 <!--        <div class="col-4">
                            <label class="form-label">Fecha Envio Encuesta</label>
                            <input
                            type="text"
                            class="form-control flatpickr-input"
                            mwlFlatpickr
                            formControlName="fecha_envio_encuesta"
                            #fecha_inicio
                            [altInput]="true"
                            [convertModelValue]="true"
                            dateFormat="Y-m-d">
                        </div> -->
                    <!-- </div> -->
          <!--       </div>  -->

            </fieldset>

            <fieldset class="mt-4">
                <legend class="text-muted fs-16 bg-light">Detalles de la cita</legend>
                <div class="col-12">
                    <div class="row g-2">                        
                        <ng-container *ngIf="_show_alert_recurso">                           
                            <ngb-alert class="border-0" [dismissible]="false" type="warning">
                              <strong>¿Estás seguro de querer cambiar el recurso? </strong> Por favor esperé <b> asegurarse de seleccionar el correcto</b> 
                            </ngb-alert>
                        </ng-container>
                        <!-- recurso -->
                        <div class="col-6">
                            <label class="control-label">Recurso</label>
                            <ng-select
                            [items]="_Recursos"
                            bindLabel="Name"
                            bindValue="Id"
                            [clearable]="false"
                            (ngModelChange)="_onModelChange($event)"
                            [ngClass]="{ 'is-invalid': f['recurso'].invalid && f['recurso'].errors && f['recurso'].touched}"
                            formControlName="recurso">
                            </ng-select>
                            <div *ngIf="f['recurso'].errors" class="invalid-feedback">
                                <div *ngIf="f['recurso'].errors['required']">Este campo es requerido</div>                                       
                            </div>   
                        </div>
                        <!-- Anestesia -->
                        <div class="col-6">
                            <label class="control-label">Anestesista</label>
                            <ng-select
                            [items]="_Anestesitas"
                            bindLabel="name"
                            bindValue="id_salesforce"                            
                            formControlName="anestesista">
                            </ng-select>
                        </div>
                        <!-- Dr ayudante -->
                        <div class="col-6">
                            <label class="control-label">DR. Ayudante</label>
                            <ng-select
                            [items]="_Doctores"
                            bindLabel="name"
                            bindValue="id_salesforce"                            
                            formControlName="ayudante">
                            </ng-select>
                        </div>
                    </div>
                </div>  
            </fieldset>

            <fieldset class="mt-4">
                <legend class="text-muted fs-16 bg-light">Atencion A Paciente</legend>
                <div class="col-12">
                    <div class="row">
                        <!-- Recordatorio -->
                        <div class="col-6">
                            <label class="control-label">Recordatorio</label>
                      <!--       <input
                            type="text"
                            class="form-control flatpickr-input"
                            mwlFlatpickr
                            formControlName="recordatorio"
                            #fecha_inicio
                            [altInput]="true"
                            [convertModelValue]="true"
                            dateFormat="Y-m-d"> -->
                            <input type="text" formControlName="recordatorio" class="form-control">
                        </div>
                        <!-- Responsable -->
                        <div class="col-6">
                            
                            <label class="control-label">Responsable</label>
                            <ng-select
                            [items]="_responsable"
                            bindLabel="Nombre"
                            bindValue="Id"
                            formControlName="responsable"
                            [clearable]="false"
                            ></ng-select>
                        </div>
                    </div>
                </div>
            </fieldset>           
        </div>       
        <fieldset class="mt-4">
            <div class="d-flex justify-content-end ">
                <button type="submit" class="btn  m-2 " [ngClass]="editable ? 'btn-success' : 'btn-primary' " [disabled]="_loader"> {{ editable ? 'Actualizar': 'Guardar' }}   </button>
                <button type="button" class="btn btn-danger  m-2 " (click)="onCancel()"> Cancelar</button>
            </div>
        </fieldset>    
    </fieldset>   
</form>
<ngx-spinner [name]="_spinner_cita" type="ball-scale-multiple" [fullScreen]="false" ></ngx-spinner>
<!-- <full-calendar #fullCalendar [options]="calendarOptions" [deepChangeDetection]="true"  contentHeight="auto"></full-calendar> -->

<!-- <ng-template #modalShow let-modal>
    <div class="modal-header p-3 bg-info-subtle">
        <h5 class="modal-title" id="modal-title">{{_selectedDoctor.name}} - {{ newEventDate.startStr }} </h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-hidden="true"
            (click)="closeEventModal()"></button>
    </div>

    <div class="modal-body p-4">
    
    </div>
</ng-template>
 -->