import { Component, HostListener, OnInit, TemplateRef, ViewChild } from '@angular/core';


import { PatientsService } from '../calendar/services/patients.service';
import { Subject, map } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { FilePondComponent } from 'ngx-filepond';
import { FilePondOptions } from 'filepond';
import { FormatsService } from 'src/app/shared/services/formats.service';
import Swal from 'sweetalert2';
import { AlertsService } from 'src/app/shared/services/alerts.service';
import { SessionPageService } from '../services/session-page.service';
import * as _ from 'lodash';
import { CalendarService } from '../calendar/services/calendar.service';
import { CredentialsService } from 'src/app/core/services/credentials.service';

@Component({
  selector: 'app-pharmacy',
  templateUrl: './pharmacy.component.html',
  styleUrls: ['./pharmacy.component.scss']
})
export class PharmacyComponent implements OnInit {

  @ViewChild('modalFiles') modalFiles !: TemplateRef<any>
  @ViewChild("myPond") myPond: FilePondComponent;
  
  public Collapsed = true;

  record: any;
  _cita: any

  spinner1 = 'sp1';

  _jsonCalendar: any[] = []

  _citasPaciente: any[] = []

  singleConfig: DropzoneConfigInterface;

  _ngModal: NgbModalRef;

  _searchText: string

  _pacienteData: any


  @HostListener('window:resize', [])
  onResize(): void {
    this.checkView()
  }

  eventSubjectSpinner: Subject<boolean> = new Subject<boolean>();

  public innerWidth: number;

  _formatAccpeted = ['application/pdf', 'image/jpeg','video/mp4'];

  pondOptions: FilePondOptions = {
    allowMultiple: true,
    labelIdle: "Suelte aquí o seleccione el archivo a subir",
    acceptedFileTypes: this._formatAccpeted,
    maxFiles:6,
    className:'dropzone_sec',
    allowDrop: !this.isMobile,
    chunkUploads:true
  };

  pondFiles: FilePondOptions["files"] = [];

  _LaboratorioFiles: any[] = []

  _uploadFiles!: boolean
  _formatos: any[] = [];
  _formato_id: string
  

  _showUploadFiles:boolean
  _cita_id_: string;
  _paciente_id: string = ''


  constructor(
    private patient: PatientsService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private modalService: NgbModal,
    private formato: FormatsService,
    private alert: AlertsService,
    private session: SessionPageService,
    private calendar: CalendarService,
    private credentialService: CredentialsService
    
    ) {}  

  ngOnInit(): void {
    
    this._jsonCalendar = []
    const _jsonCalendar = this.session._returnJSONSession()

    if(_jsonCalendar) {
      this._jsonCalendar = _jsonCalendar

      _.orderBy(this._jsonCalendar,'Paciente_Nombre_Completo','asc')
      
    }

    this.initDropZone()
    this.checkView()
   // this.loadFiles()
  }

  private initDropZone() {
    this.singleConfig = {
      clickable: true,
      addRemoveLinks: true,
      acceptedFiles: '.pdf', // this.acceptedFiles,
      maxFiles: 1,
      
    };
  }

  get isMobile(): boolean {
    return this.innerWidth <= 1024;
  }

  private checkView(): void {    
    this.innerWidth = window.innerWidth;
  }

  /**
   * 
   * @param id 
   */
  _OnLoadPatient(paciente: any, eventEmmit?: boolean ) {

    this._showUploadFiles = false
    this._paciente_id = paciente.N_Expediente

    this._pacienteData = paciente
    this._cita_id_ = '' //this._pacienteData.Cita_Id

    this._getCitasPacient(paciente.Paciente_Id)    
    //this.loadFiles()   
    this.spinner.show(this.spinner1)

    this.patient._getPatientInfo(paciente.Paciente_Id).pipe(map((response) => {     
      this.credentialService.saveUserinfo(response);
      return response
    })).subscribe({
      next:(response: any) => {
        if(response.codigo == 200) {          
          this.record = response.data.records[0];
          this.spinner.hide(this.spinner1)
          if(eventEmmit) {
            this.eventSubjectSpinner.next(true)
          }
        }         
      },
      error:(error) => {
        console.error({error});
        this.eventSubjectSpinner.next(true)
        this.spinner.hide(this.spinner1)
        this.toastr.error('Ha ocurrido un error al obtener los datos del paciente','Error ocurrido', {
          timeOut: 3200
        })        
      }
    })
  }


  async _getCitasPacient(paciente: string) {

    this.calendar._GetCitasPaciente(paciente).then(async (resp: any) => {
      if(resp.codigo == 200) {
        this._citasPaciente = resp.data;    
      }          
    }).catch((error) => {    
      this.toastr.error('Ocurrio un error al obtener el listado de citas','Error!!',{
        timeOut: 3200
      })
    })

  }

  imageURL: string | undefined;
  fileChange(event:any) {
    let fileList: any = (event.target as HTMLInputElement);
    let file: File = fileList.files[0];

    document.getElementById('')
    const reader = new FileReader();
    reader.onload = () => {
      this.imageURL = reader.result as string;
      (document.getElementById('member-img') as HTMLImageElement).src = this.imageURL;
    }
    reader.readAsDataURL(file)
  }

  /**
   * 
   */
  private loadFiles() {
    if(this._pacienteData) {

    
    this._LaboratorioFiles = []

    this.formato._getListImages(this._cita_id_,this._pacienteData.Paciente_Id).subscribe({
      next: (response) => {
        
        if(response.codigo == 200) {
          const _Arhivos = response.data.formato.archivos

          if(_Arhivos && _Arhivos.Laboratorio) {
            this._LaboratorioFiles = _Arhivos.Laboratorio

          }

          console.log({_LaboratorioFiles: this._LaboratorioFiles});
          
          
          

        }
      },
      error(err) {
        console.error({err});
        
      },
    })
  }
  }

  /**
   * 
   * @param event 
   */
  _onEventEmmiterPaciente(paciente: string) {   
    this._OnLoadPatient(paciente, true)    
  }

  /**
   * 
   * @param cita 
   */
  _onEventEmmiterCita(cita: any) {
    this.onLoadDetallesCita(cita)
  }

  _openModal() {
    this._ngModal = this.modalService.open(this.modalFiles,{
      size:'xl',
      animation:true,
      backdrop:'static',
      fullscreen:'lg'
    })
  }


  closeEventModal () {
    this._ngModal.close()
  }
 

  pondHandleInit() {
    console.log("FilePond has initialised", this.myPond);
  }

  /**
   * Agregamos los archivos
   * @param event 
   */
  pondHandleAddFile(event: any) {
    if(!event.error) {
      this.pondFiles?.push(event.file.file)
      console.log("A file was added", event);
      console.log({pondFiles: this.pondFiles});    
    }    
  }

  pondRemoveFile(event: any) {    
    if(this.pondFiles) {
      const _id = event.file.id;    
      _.remove(this.pondFiles, (a:any) => {return a.file.id  === _id })   
     // this.eventEmmit()
    }     
  }
  


  /**
   * 
   */
  _onUploadFile() {
    
    if(this.pondFiles && this.pondFiles.length > 0 ) {        
      this._uploadFiles = true
      
    /*   let _val = {
        cita_id: this._cita_id_, paciente_id: this._pacienteData.Paciente_Id, files: this.pondFiles, laboratorio: true
      }
      console.log({_val}); */
      
      this.spinner.show(this.spinner1)
    
      this.formato._uploadImage({cita_id: this._cita_id_, paciente_id: this._pacienteData.Paciente_Id, files: this.pondFiles, laboratorio: true}).then((resp) => {
        this.spinner.hide(this.spinner1)   
        this.pondFiles = []
        this.loadFiles()     
        this.toastr.success('Se ha cargado correctamente, el archivo seleccionado','Exito al cargar', {
          timeOut: 3200
        }).onHidden.subscribe(() => {
          this._uploadFiles = false
           if(this._ngModal) {
            this._ngModal.close()
           }
        }) 
      }).catch((err) => { 
        console.error({err});
        this.spinner.hide(this.spinner1)     
        this.toastr.error('Ha ocurrido un error al subir los archivos, seleccionado, por favor intente de nuevo','Error al subir archivos',{
          timeOut: 3200
        })
        
      })
     
    }


  }

  /**
   * 
   * @param item 
   */
  onDelete(item: any) {
    
    Swal.fire({
      title:'Por favor confirme esta accion',
      text:'¿Desea eliminar este archivo?',
      icon:'question',
      showCancelButton:true,
      cancelButtonText:'No, Cancelar',
      confirmButtonText:'Sí, borrar',
    }).then((resp) => {
      if(resp.value) {
        this.alert._showLoader('Borrando espere...')
        this.formato._PostdeleteImage({ user: null, label: null, main_label: null, cita_id: this._cita_id_,  paciente_id: this._pacienteData.Paciente_Id, filename: item.filename, laboratorio: 'true'}).then((resp) => {
          this.alert._hideLoader()
          this.toastr.success('Exito al borrar el archivo','Se ha borrado',{
            timeOut: 3200
          })

         // this.loadFiles()

        }).catch((err) => {
          this.alert._hideLoader()
          this.toastr.error('Ocurrio un error al tratar de borrar el archivo, seleccionado','Error ocurrido',{
            timeOut: 3200
          })
        })
      }
    })
    
  }

  /**
   * 
   * @param filePdf 
   */
  _openPDF(filePdf: any) {
      console.log({filePdf});

    setTimeout(() => {
      window.open(filePdf.url, '_blank', 'width=1000, height=800');
    }, 100);
      
  }

  /**
   * 
   * @param cita 
   */
  onLoadDetallesCita(cita: any) {

    this._formatos = []

    this._cita_id_ = cita.Name
    this.spinner.show(this.spinner1)
    this.loadFiles()
    this.calendar._getDetallesCita(cita.Name).subscribe({
      next:(resp: any) => {
        if(resp.codigo == 200) {
          this._showUploadFiles = true
          const _Formatos = resp.data.Formatos;
          this._formatos = _Formatos
          this.spinner.hide(this.spinner1)          
        }        
      },error:(error) => {
        console.error({error});
        this.spinner.hide(this.spinner1)
        
      }
    })

  }

}
