import { Injectable, NgZone } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';

//declare var webkitSpeechRecognition: any;
declare let window: any

const SpeechRecognition =
  window.SpeechRecognition || window.webkitSpeechRecognition;

@Injectable({
  providedIn: 'root'
})
export class VoiceRecognitionService {

  recognition: typeof SpeechRecognition | undefined;
  transcript: Subject<string> = new Subject<string>();

  //recognition =  new window.SpeechRecognition
  isStoppedSpeechRecog = false;
  public text = '';
  tempWords: any;

  constructor(private toastr: ToastrService, private ngZone: NgZone  ) { }
  
  /**
   *
   *
   * @memberof VoiceRecognitionService
   */
  startListening() {
    if (!this.recognition) {
      this.recognition = new SpeechRecognition();
      this.recognition.continuous = true; // Allow continuous recognition
      this.recognition.interimResults = true; // Get interim results
      this.recognition.lang = 'es-ES';
      this.recognition.maxAlternatives = 1;
      // Handle recognition events
      this.recognition.onresult = (event: any) => {
        const transcript = Array.from(event.results)
          .map((result: any) => result[0].transcript)
          .join('');

        this.transcript.next(transcript);
      };

      this.recognition.onerror = (event: any) => {
        console.error(event.error);
        this.toastr.error(event.error, 'Error ocurrido', { timeOut: 3200 })
      };
    }

    this.recognition.start();
  }

  /**
   *
   *
   * @memberof VoiceRecognitionService
   */
  stopListening() {
    if (this.recognition) {
      this.recognition.stop();
    }
  }

}