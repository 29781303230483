export class FilterCalendar {
    doctores: any[]
    recursos: any[]
    servicios: any[]

    tipoEvento?: any[]
    
    inicio: string | null
    final:  string | null

    doctor?: string
}
