<ng-container *ngIf="_itsReady; else _loader ">
    <div class="d-flex justify-content-end">
        <button class="btn btn-icon text-primary" (click)="_openModal()">
            <i class="mdi mdi-filter align-middle me-1 fw-bold fs-2"></i>
        </button>
    </div>

    <ng-container *ngIf="_citas_today && _citas_today.length; else empty_today">
        <!-- Citas de HOY -->
        <div class="row mt-4"><!-- *ngIf="Citas" -->
            <div class="col-12">
                <!-- Blockquote Background Color -->
                <blockquote class="blockquote custom-blockquote blockquote-primary rounded mb-0">
                    <p class="text-body mb-2 text-uppercase"> citas de Hoy </p>
                    <footer class="blockquote-footer mt-0"> {{today_}}</footer>
                </blockquote>
                <div class="row d-flex justify-content-between mt-4 mb-2 ">
                    <div class="col-xxl-3 col-md-6 col-lg-6" *ngFor="let cita of _citas_today">
                        <div class="card card-body" [ngClass]="_getCardClass(cita.Tipo_Cita) "
                            (click)="_onEventEmmitCita(cita)">
                            <div class="d-flex mb-4 align-items-center">
                                <div class="flex-shrink-0">
                                    <i class=" bx bx-calendar-plus align-middle "></i>
                                </div>
                                <div class="flex-grow-1 ms-2">
                                    <h5 class="card-title mb-1 p-2"> {{ cita.Cita_Id }} | {{
                                        cita.Paciente_Nombre_Completo }} </h5>
                                    <p class="card-text mb-1 p-2">De {{ cita.Fecha_Inicio | date:'yyyy-MM-dd hh:mm a' }}
                                        A {{cita.Fecha_Final | date:'yyyy-MM-dd hh:mm a' }} </p>
                                </div>
                            </div>
                            <h4 class="card-title">{{ cita.Tipo_Cita }} | {{ cita.Estatus }} </h4>
                            <p class="fw-bold" *ngIf="_isAdmin">{{getDoctor(cita.Empleado_Id)}} </p>
                        </div>
                    </div>
                </div>
            </div><!-- end col -->
        </div><!-- end row -->
    </ng-container>

    <ng-template #empty_today>
        <!-- Blockquotes -->
        <blockquote class="blockquote fs-14">
            <p>No hay citas, que motrar para hoy </p>
            <footer class="blockquote-footer mt-0"> {{today_}} </footer>
        </blockquote>
    </ng-template>

    <ng-container *ngIf="_citas_tomorrow && _citas_tomorrow.length; else empty_tomorrw">
        <!-- Citas de Mañana -->
        <div class="row">
            <div class="col-12">
                <blockquote class="blockquote custom-blockquote blockquote-info rounded mb-0">
                    <p class="text-body mb-2 text-uppercase"> citas de mañana </p>
                    <footer class="blockquote-footer mt-0"> {{tomorrow_}}</footer>
                </blockquote>
                <div class="row d-flex justify-content-between mt-4 mb-2 ">
                    <div class="col-xxl-3 col-md-6 col-lg-6" *ngFor="let cita of _citas_tomorrow">
                        <div class="card card-body" [ngClass]="_getCardClass(cita.Estatus) "
                            (click)="_onEventEmmitCita(cita)">
                            <div class="d-flex mb-4 align-items-center">
                                <div class="flex-shrink-0">
                                    <i class=" bx bx-calendar-plus align-middle "></i>
                                </div>
                                <div class="flex-grow-1 ms-2">
                                    <h5 class="card-title mb-1 p-2"> {{ cita.Cita_Id }} | {{
                                        cita.Paciente_Nombre_Completo }} </h5>
                                    <p class="card-text mb-1 p-2">De {{ cita.Fecha_Inicio | date:'yyyy-MM-dd hh:mm a' }}
                                        A {{cita.Fecha_Final | date:'yyyy-MM-dd hh:mm a' }} </p>
                                </div>
                            </div>
                            <h4 class="card-title">{{ cita.Tipo_Cita }} | {{ cita.Estatus }} </h4>
                            <p class="fw-bold" *ngIf="_isAdmin">{{getDoctor(cita.Empleado_Id)}} </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>

    <ng-template #empty_tomorrw>
        <blockquote class="blockquote fs-14">
            <p>No hay citas, que motrar para mañana </p>
            <footer class="blockquote-footer mt-0">{{tomorrow_}} </footer>
        </blockquote>
    </ng-template>

</ng-container>

<ng-template #viewContent let-offcanvas>
    <app-canvas-detalles-citas [_detallesCita]="_detallesCita" (eventCanvasEmiiter)="onEventCanvasEmmitter($event)">
    </app-canvas-detalles-citas>
</ng-template>

<ng-template #_loader>
    <div class="row" *ngIf="!_itsReady">
        <p class="placeholder-glow" *ngIf="!_loaderError">
            <span class="placeholder placeholder-lg col-12"></span>
            <span class="placeholder col-4"></span>
            <span class="placeholder col-4"></span>
            <span class="placeholder col-6"></span>
        </p>

        <div data-aos="fade-up" *ngIf="_loaderError">
            <div class="row d-flex justify-content-center">
                <div class="col-12">
                    <blockquote class="blockquote custom-blockquote blockquote-danger rounded mb-0">
                        <p class="text-body mb-2">No se han podido recuperar resultados</p>
                    </blockquote>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<!-- Modal Filter -->
<ng-template #modalFilters let-modal>
    <div class="modal-header">
        <h4 class="modal-title">Filtros de búsqueda para calendario de citas</h4>
        <button type="button" class="btn-close" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body">
        <ngx-simplebar style="max-height: calc(70vh - 70px)">
            <app-form-filter-calendar [dateRange]="_dateRange" [_selectedDoctors]="_selectedDoctors"
                [_selectedRecursos]="_selectedRecursos" [_selectedServicios]="_selectedServicios"
                [_tipoEvento]="_tipoEvento" [_citas_por_dia]="true"
                (onEventEmmitFilters)="SetEventEmmitFilters($event)"></app-form-filter-calendar>
        </ngx-simplebar>
    </div>
</ng-template>