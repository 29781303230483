import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, HostListener, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbModalRef, NgbOffcanvasRef, NgbOffcanvas, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DatatableComponent, ColumnMode } from '@swimlane/ngx-datatable';
import flatpickr from 'flatpickr';
import { Spanish } from 'flatpickr/dist/l10n/es';
import * as _ from 'lodash';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { map } from 'rxjs';
import { CredentialsService } from 'src/app/core/services/credentials.service';
import { dataInfo } from 'src/app/layouts/models/user-data';
import { Anestesilogo } from 'src/app/pages/calendar/models/anestesiologo';
import { Cita } from 'src/app/pages/calendar/models/calendar';
import { CitaPlancha, DoctorPlancha, ServicioPlancha } from 'src/app/pages/calendar/models/calendar-response';
import { DetallesCita } from 'src/app/pages/calendar/models/detalles-cita';
import { Enfermero } from 'src/app/pages/calendar/models/enfermero';
import { PostCalendarData } from 'src/app/pages/calendar/models/post-calendar-data';
import { CalendarService } from 'src/app/pages/calendar/services/calendar.service';
import { Doctor } from 'src/app/pages/models/doctores';
import { FilterCalendar } from 'src/app/pages/models/filter-calendar';
import { LocalSessionService } from 'src/app/pages/services/local-session.service';

import { RedirectService } from 'src/app/services/redirect.service';
import Swal from 'sweetalert2';
import { AlertsService } from '../../services/alerts.service';
import { PatientsService } from 'src/app/pages/calendar/services/patients.service';

const _DATES_CALENDAR = '_dates_filter_plancha'
const _PATCH_DATE_FORM = '_patch_form_dates'

//#region Catalogos
const DOCTORES_ = '_cat_doctores'
/* const RECURSOS_  = '_cat_reursos'
const SERVICIOS_ = '_cat_servicios' */

const ANESTESIOLOGOS = '_cat_anestesiologos'
const ENFERMERO_GRAL = '_cat_enfermero_gral'
const ENFERMERO_QX = '_cat_enfermero_qx'
//#endregion

@Component({
  selector: 'app-calendario-plancha',
  templateUrl: './calendario-plancha.component.html',
  styleUrls: ['./calendario-plancha.component.scss']
})
export class CalendarioPlanchaComponent implements OnInit {

  @ViewChild('canvasDetail') canvasDetail: TemplateRef<any>;

  @ViewChild('myTable') table: any | undefined;
  @ViewChild('modalShow') modalShow !: TemplateRef<any>;
  @ViewChild('modalDetailCita') modalDetailCita !: TemplateRef<any>;

  @ViewChild('modalFilters') modalFilters: TemplateRef<any>;



  @ViewChild(DatatableComponent) ngxtable: DatatableComponent;
  @ViewChild(DatatableComponent) tablePlancha: DatatableComponent;

  public modalData: any[] = []
  public temp: any[] = [] // Para los Filtros

  public _anestesilogos: Anestesilogo[] = []
  public _enfGeneral: Enfermero[] = []
  public _enfQx: Enfermero[] = []
  public _doctores: Doctor[]

  ColumnMode = ColumnMode

  _title: string
  _type: string;
  _responseData: Cita[] = []

  //_cita: CitaPlancha;

  _filters: FilterCalendar = new FilterCalendar()

  _startDate = moment().startOf('month').format('yyyy-MM-DD')
  _today = moment().format('yyyy-MM-DD')

  _calendarPost: PostCalendarData = new PostCalendarData()

  _isAdmin: boolean;


  _isReady: boolean;
  _loaderError: boolean

  _enfermero: boolean;
  _enfGral: boolean
  _enf_qx: boolean

  //_planchaData: any[] =  []
  //_tempPlancha: any[] = []

  _CitasPlancha: CitaPlancha[] = []

  _TempCitasPlancha: CitaPlancha[] = []

  _DoctoresPlancha: DoctorPlancha[] = []
  _ServiciosPlancha: ServicioPlancha[] = []

  message = '';
  messages: string[] = [];

  _citaSelected: CitaPlancha;
  _ngModal: NgbModalRef;

  _ngModalDoctores: NgbModalRef;

  _anestesia: boolean;

  _canvasRef_: NgbOffcanvasRef;

  @HostListener('window:resize', [])
  onResize(): void {
    this.checkView()
  }

  @HostListener('window:orientationchange', ['$event'])
  onOrientationChange(event: any) {
    console.log({ event });

    console.log('orientationChanged');
    // this.detectDevice()
  }

  public innerWidth: number;

  _portrait: boolean;

  _userData: dataInfo;

  _formFilter: FormGroup;
  _spinner = 'spinnerPlancha'


  _detallesCita: DetallesCita;

  _is_anestesiologo: undefined | boolean = false
  _id_anestesiologo: undefined | string = ''

  _tipoCitaGrouped: any[] = []
  _serviciosGrouped: any[] = []
  _doctoresGrouped: any[] = []
  _recursosGrouped: any[] = []


  _ServiciosCollect: any[] = []
  _serviciosFilter: any[] = []

  _DoctoresCollect: any[] = []
  _doctoresFilter: any[] = []

  _RecursosCollect: any[] = []
  _recursosFilter: any[] = []

  _TipoCitaCollect: any[] = []
  _tipoCitaFilter: any[] = []

  _allData: any // For Filter

  constructor(
    private patients: PatientsService,
    private offCanvasService: NgbOffcanvas,
    private credentialService: CredentialsService,
    private calendar: CalendarService,
    private redirect: RedirectService,
    private modalService: NgbModal,
    private alert: AlertsService,
    private change: ChangeDetectorRef,
    private toastr: ToastrService,
    private fb: FormBuilder,
    private date: DatePipe,
    private spinner: NgxSpinnerService,
    private _local: LocalSessionService
  ) {
    flatpickr.localize(Spanish)
    //this.webSocketService.connect();
    this._userData = this.credentialService._getdataUser() as dataInfo;

  }

  ngOnInit(): void {

    this._is_anestesiologo = this.credentialService.credentials?.roles.Anestesiologo
    this._id_anestesiologo = this.credentialService.credentials?.id_salesforce;



    this.initFilters()

    // this.spinner.show(this._spinner)
    this.InitFormFilter()
    this._Categories()

    this.detectDevice()
    this.checkView()
    this._isReady = false;
    this._isAdmin = this.credentialService.credentials?.roles.Administrador ? this.credentialService.credentials.roles.Administrador : false;




    this.ValidateRole()

    //this._loadCalendarPlancha()    

  }

  private InitFormFilter() {
    this._formFilter = this.fb.group({
      _dateRange: [new Date, [Validators.required]]
    })

    this.PatchFormValues()
  }

  /**
   * 
   */
  private PatchFormValues() {

    let _FormValues = this._local.getJsonValues(_PATCH_DATE_FORM)

    if (_FormValues) {

      this._formFilter.patchValue(_FormValues)
    }

    this._loadCalendarPlancha()
  }

  private detectDevice() {
    window.matchMedia("(orientation: portrait)").addEventListener("change", e => {
      const portrait = e.matches;


    });


  }

  get isMobile(): boolean {
    return this.innerWidth <= 768;
  }

  private checkView(): void {
    this.innerWidth = window.innerWidth;
  }

  /**
   * 
   */
  private initFilters() {
    this._filters.doctor = ''

    /**
     * Preguntamos si es Doctor
     */
    if (this.credentialService.credentials?.roles.Doctor) {
      this._filters.doctor = this.credentialService.credentials.id_salesforce;
    }
    this._filters.inicio = ''; //this._startDate;
    this._filters.final = ''; //this._today
  }

  /**
   * 
   */
  private ValidateRole() {
    this._enfermero = false;

    let _role = this.credentialService.credentials?.roles.Enfermera_General;
    if (!_role) {
      _role = this.credentialService.credentials?.roles.Enfermera_Quirurgica;
    }

    if (_role) {
      this._enfermero = _role
    }
  }

  /**
   * 
   */
  private _loadCalendarPlancha() {

    this.SetValuesFilter()
    this._loaderError = false;
    this.spinner.show(this._spinner)
    this.calendar._getPlancha(this._filters).subscribe({
      next: (response) => {

        if (response.codigo == 200) {
          this._allData = response.data
          this._isReady = true
          this._ServiciosPlancha = response.data.Servicios;

          if (this._is_anestesiologo) {
            this._CitasPlancha = response.data.Citas;
          } else {
            this._CitasPlancha = response.data.Citas;
          }

          this._getFilters(response.data.Citas)
          this._TempCitasPlancha = this._CitasPlancha;
          this.change.detectChanges()
        }

        this.spinner.hide(this._spinner)

      },
      error: (error) => {
        this.spinner.hide(this._spinner)
        console.error({ error });

      }
    })
  }

  private _getFilters(Citas: any[]): void {

    const resp = this._allData;

    this._serviciosGrouped = _(Citas)
      .groupBy('Servicio_Id')
      .map((items, servicio_id) => ({
        servicio_id,
        name: _.find(resp.Servicios, { Id: servicio_id })?.Name,
        total: items.length,
        data: items
      })).value()

    this._doctoresGrouped = _(Citas)
      .groupBy('Empleado_Id')
      .map((items, empleado_id) => ({
        empleado_id,
        name: _.find(resp.Doctores, { Id: empleado_id })?.Name,
        total: items.length,
        data: items
      })).value()

    this._recursosGrouped = _(Citas)
      .groupBy('Unidad')
      .map((items, Unidad) => ({
        Unidad,
        name: items[0].Unidad,
        total: items.length,
        data: items
      })).value()

    this._tipoCitaGrouped = _(Citas)
      .groupBy('Tipo_Evento')
      .map((items, tipo_cita) => ({
        tipo_cita,
        name: items[0].Tipo_Evento,
        total: items.length,
        data: items
      })).value();



  }

  /**
   * 
   * @param id 
   * @returns 
   */
  _getDoctor(id: string) {
    let _find = _.find(this._doctores, (a: Doctor) => { return a.id_salesforce == id })

    if (_find) {
      return _find.name
    }
    return 'N/D'
  }

  /**
   * Devuelve el nombre del Servicio
   * @param id 
   * @returns 
   */
  _getServicio(id: string) {
    let _find = _.find(this._ServiciosPlancha, { Id: id })
    if (_find) {
      return _find.Name
    }
    return 'N/D'
  }

  /**
   * 
   * @param id 
   * @returns 
   */
  _getAnestesiologo(id: string) {

    let _find = _.find(this._anestesilogos, { id_salesforce: id })
    if (_find) {
      return _find.name;
    }
    return 'N/D'
  }

  /**
   * 
   * @param id 
   * @returns 
   */
  _getEnfermeroQx(id: string) {
    let _find = _.find(this._enfQx, { id_salesforce: id })
    if (_find) {
      return _find.name
    }
    return 'N/D'
  }

  /**
   * 
   * @param id 
   * @returns 
   */
  _getEnfermeroGral(id: string) {
    let _find = _.find(this._enfGeneral, { id_salesforce: id })
    if (_find) {
      return _find.name;
    }
    return 'N/D'

  }

  private _Categories() {

    const _DOCTORES_ = this._local.getJsonValues(DOCTORES_);
    /*     const _RECURSOS_ = this._local.getJsonValues(RECURSOS_);
        const _SERVICIOS_ = this._local.getJsonValues(SERVICIOS_); */

    const _ANESTESIOLOGOS = this._local.getJsonValues(ANESTESIOLOGOS)
    const _ENFERMERO_GRAL = this._local.getJsonValues(ENFERMERO_GRAL)
    const _ENFERMERO_QX = this._local.getJsonValues(ENFERMERO_QX)

    /**
     * 
     */
    if (_ANESTESIOLOGOS) {

      this._anestesilogos = _ANESTESIOLOGOS

    } else {
      this.calendar._getAntestesilogo('true').subscribe({
        next: (response) => {

          if (response.codigo == 200) {
            this._anestesilogos = response.data.info
            this._local.savedJsonValues(ANESTESIOLOGOS, this._anestesilogos)
          }

        }, error: (error) => {
          console.error({ error });

        }
      })
    }


    /**
     * 
     */
    if (_ENFERMERO_GRAL) {

      this._enfGeneral = _ENFERMERO_GRAL;

    } else {
      this.calendar._getEnfermeroGral('true').subscribe({
        next: (response) => {
          if (response.codigo == 200) {
            this._enfGeneral = response.data.info;
            this._local.savedJsonValues(ENFERMERO_GRAL, this._enfGeneral)
          }
        }, error: (error) => {
          console.error({ error });

        }

      })
    }


    /**
     * 
     */
    if (_ENFERMERO_QX) {
      this._enfQx = _ENFERMERO_QX
    } else {
      this.calendar._getEnfermeroQuirurgico('true').subscribe({
        next: (response) => {
          if (response.codigo == 200) {
            this._enfQx = response.data.info;
            this._local.savedJsonValues(ENFERMERO_QX, this._enfQx)
          }
        }
      })
    }


    /**
     * 
     */
    if (_DOCTORES_) {
      this._doctores = _DOCTORES_
    } else {
      this.calendar._getDoctores('true').subscribe({
        next: (response) => {
          if (response.codigo == 200) {
            this._doctores = response.data.info;
            this._local.savedJsonValues(DOCTORES_, this._doctores)
          }
        }
      })
    }



  }

  /**
   * 
   */
  _gotoForm(_cita: CitaPlancha) {
    this.patients._getPatientInfo(_cita.Paciente_Id).pipe(map((response) => {
      this.credentialService.saveUserinfo(response);
    })).subscribe({
      next: (response) => {
        this._ngModal.close()

        this.credentialService.saveCitaJson(_cita)

        this.redirect.to('/doctor')

      }, error: (err) => {
        console.error({ err });

      }
    })
  }

  /**
   * 
   */
  openModal(row: CitaPlancha, title: string, type: string) {
    this._title = title;
    this._type = type;

    this._citaSelected = row;

    this.modalData = []

    switch (type) {
      case 'anestesiologo':
        this.modalData = this._anestesilogos;
        break;
      case 'enfemero_qx':
        this.modalData = this._enfQx;
        break;
      case 'enfermero_gral':
        this.modalData = this._enfGeneral;
        break;
      case 'doctor_ayudante':
        this.modalData = this._doctores;
        break;
      default:
        this.modalData = []
        break;
    }
    this.temp = this.modalData;

    this._ngModalDoctores = this.modalService.open(this.modalShow, {
      size: 'xl',
      animation: true,
      backdrop: 'static',
      fullscreen: 'lg'
    })
  }

  /**
   * 
   */
  closeEventModal() {
    //this.modalService.dismissAll();
    this._ngModalDoctores.close()
    //this._loadCalendarPlancha()
  }

  /**
   * 
   * @param event 
   */
  filterDataTable(event: any) {
    const val = event.target.value.toLowerCase();

    // filter our data
    const temp = this.temp.filter(function (d) {
      return d.name.toLowerCase().indexOf(val) !== -1 ||
        d.id_empleado.toLowerCase().indexOf(val) !== -1 ||
        d.celular.toLowerCase().indexOf(val) !== -1 ||
        !val;
    });

    // update the rows
    this.modalData = temp;
    // Whenever the filter changes, always go back to the first page

    if (this.ngxtable) {
      this.ngxtable.offset = 0;
    }

  }

  /**
   * 
   * @param event 
   */
  filterDataTablePlancha(event: any) {
    const val = event.target.value.toLowerCase();




    const _temp = this._TempCitasPlancha.filter(function (d) {
      return d.Paciente_Nombre_Completo.toLowerCase().indexOf(val) !== -1 ||
        d.Paciente_Id.toLowerCase().indexOf(val) !== -1 ||
        d.Cita_Id.toLowerCase().indexOf(val) !== -1 ||
        /*  d.Doctor.toLowerCase().indexOf(val) !== -1  ||
         d.Recurso.toLowerCase().indexOf(val) !== -1 ||  */
        d.N_Expediente.toLowerCase().indexOf(val) !== -1 ||
        /*  d.Servicio.toLowerCase().indexOf(val) ! == -1 || */
        d.Tipo_Cita.toLowerCase().indexOf(val) !== -1 ||
        !val;

    })



    this._CitasPlancha = _temp


    // this.data = _temp

    if (this.tablePlancha) {
      this.tablePlancha.offset = 0;
    }
  }

  /**
   * 
   * @param row 
   * @param type 
   */
  deltedAssigned(row: CitaPlancha, type: string) {

    //this._cita =  row;

    let _title: string = ''

    //this._calendarPost.doctor = this._cita.Empleado_Id
    this._calendarPost.cita = row.Cita_Id //this._cita.Cita_Id;

    if (type == 'doctor_ayudante') {
      this._calendarPost.ayudante = null;

      //delete this._cita.Ayudante;

      _title = 'Doctor ayudante'

    } else if (type == 'anestesiologo') {
      //this._cita.Anestesiologo = ''
      this._calendarPost.anestesiologo = null
      //delete this._cita.Anestesiologo;

      _title = 'Anestesiologo'

    }


    Swal.fire({
      title: 'Por favor confirme esta accion',
      icon: 'question',
      text: `¿Desea eliminar al ${_title} asignado  a la cita ?`,
      showCancelButton: true,
      cancelButtonText: 'No, Cancelar',
      confirmButtonText: 'Si, remover'
    }).then((resp) => {
      if (resp.value) {

        this.alert._showLoader('Eliminando, espere...')

        this.calendar._setValuesCalendar(this._calendarPost).subscribe({
          next: (response) => {
            this.alert._hideLoader();
            this.alert._showSuccess('Exito al asignar', 'La eliminacion se realizo correctamente').then((result) => {
              if (result.value) {
                //   this.sessionPage._saveJSONtoSession(this.data)    
                //  this.closeEventModal()  
                this._loadCalendarPlancha()
              }
            })
          }, error: (error) => {
            this.alert._hideLoader();
            console.error({ error });
          }
        })
      }
    })

  }


  /**
   * 
   * @param row 
   */
  _onSetCalendar(row: any) {

    // this._calendarPost.doctor = this._cita.Empleado_Id
    this._calendarPost.cita = this._citaSelected.Cita_Id //row.Cita_Id //this._cita.Cita_Id;

    let _title: string = ''

    if (this._type == 'anestesiologo') {
      this._calendarPost.anestesiologo = row.id_salesforce;
      _title = 'Anestesiologo'
      this._citaSelected.Anestesista = row.id_salesforce
    } else if (this._type == 'doctor_ayudante') {
      this._calendarPost.ayudante = row.id_salesforce;
      _title = 'Doctor ayudante'
      this._citaSelected.Ayudante = row.id_salesforce
    } else if (this._type == 'enfemero_qx') {
      this._calendarPost.enfermera_quirurgica = row.id_salesforce
      _title = 'Enfermero Quirirjico'
      this._citaSelected.Enfermeria_Quirurgico = row.id_salesforce
    } else if (this._type == 'enfermero_gral') {
      this._calendarPost.enfermera_general = row.id_salesforce;
      _title = 'Enfermero General'
      this._citaSelected.Enfermeria_General = row.id_salesforce
    }




    Swal.fire({
      title: 'Por favor confirme esta accion',
      icon: 'question',
      text: `¿Desea asignar a este ${_title} a la cita ?`,
      showCancelButton: true,
      cancelButtonText: 'No, Cancelar',
      confirmButtonText: 'Si, asignar'
    }).then((resp) => {
      if (resp.value) {
        this.alert._showLoader('Asigando, espere...')
        this.calendar._setValuesCalendar(this._calendarPost).subscribe({
          next: (response) => {
            this.alert._hideLoader();
            this.alert._showSuccess('Exito al asignar', 'La asignacion se realizo correctamente').then((result) => {
              if (result.value) {
                this.closeEventModal()
              }
            })
          }, error: (error) => {
            this.alert._hideLoader();
            console.error({ error });
          }
        })
      }
    })

  }


  toggleExpandGroup(group: any) {
    console.log('Toggled Expand Group!', group);
    this.table.groupHeader.toggleExpandGroup(group);
  }


  onDetailToggle(event: any) {
    console.log('Detail Toggled', event);
  }

  /**
   * Modal Detalles de la Cita
   */
  _OpenModal(cita: any) {

    this._citaSelected = cita;
    this._ngModal = this.modalService.open(this.modalDetailCita, {
      size: 'xl',
      animation: true,
      backdrop: 'static',
      fullscreen: 'lg'

    })

  }

  /**
   * 
   */
  closemodalDetails() {
    this._ngModal.close()
  }

  /**
   * Asiganmos al los enfermeros
   */
  _onSetEnfermero(type: string, cita: CitaPlancha) {

    this._calendarPost.cita = cita.Cita_Id;


    if (type == 'enfermero_gral') {
      this._calendarPost.enfermera_general = this._userData.id_salesforce;
    } else if (type == 'enfemero_qx') {
      this._calendarPost.enfermera_quirurgica = this._userData.id_salesforce;
    }

    Swal.fire({
      title: 'Por favor confirma esta acción',
      text: `¿Deseas asignarte esta cita, como ${type == 'enfermero_gral' ? 'enfermero general' : 'enfermero quirúrgico'}?`,
      icon: 'question',
      confirmButtonText: 'Sí, asignar',
      cancelButtonText: 'No, cancelar',
      showCancelButton: true

    }).then((result) => {
      if (result.value) {
        this.alert._showLoader('Actualizando espere...')

        this.calendar._setValuesCalendar(this._calendarPost).subscribe({
          next: (response) => {
            this.alert._hideLoader();

            this.toastr.success('La actualización  se ha realizado correctamente.', 'Éxito al actualizar la información', {
              timeOut: 3200
            })

            console.log({ response });

          },
          error: (error) => {
            this.alert._hideLoader();
            this.toastr.error('Ocurrió un error al actualizar la información', 'Error ocurrido', {
              timeOut: 3200
            })
            console.error({ error });

          }

        })

      }
    })

  }

  /**
   * 
   * @param event 
   */
  _onChange(event: Event) {

    event.preventDefault();


    console.log({ event });


  }

  /**
   * 
   * @param cita 
   */
  _onSetAnestecia(event: Event, cita: CitaPlancha) {
    event.preventDefault()

    Swal.fire({
      title: 'Por favor confirme esta acción',
      text: '¿Continuar con la asignación  de anestesia, a esta cita ?',
      cancelButtonText: 'No, cancelar',
      showCancelButton: true,
      icon: 'question',
      confirmButtonText: 'Sí, confirmar'
    }).then((result) => {
      if (result.value) {
        this._calendarPost.cita = cita.Cita_Id;
        this._calendarPost.anestesia = !cita.Anestesia

        cita.Anestesia = !cita.Anestesia;


        this.alert._showLoader('Actualizando espere...')

        this.calendar._setValuesCalendar(this._calendarPost).subscribe({
          next: (response) => {
            this.alert._hideLoader()
            this.toastr.success('La actualización, se ha asigando correctamente', 'Exito al asignar', {
              timeOut: 3200
            })
          },
          error: (error) => {
            console.error({ error });
            this.alert._hideLoader();
            this.toastr.error('Ha ocurrido un error al actualizar la información', 'Error ocurrido', {
              timeOut: 3200
            })
          }
        })
      }
    })
  }

  /**
   * 
   */
  _onSetBiposia(event: Event, cita: CitaPlancha) {
    event.preventDefault();
    Swal.fire({
      title: 'Por favor confirme esta acción',
      text: '¿Continuar con la asignación de Biposia , a esta cita ?',
      cancelButtonText: 'No, cancelar',
      showCancelButton: true,
      icon: 'question',
      confirmButtonText: 'Sí, confirmar'
    }).then((result) => {
      if (result.value) {
        this._calendarPost.cita = cita.Cita_Id;
        this._calendarPost.biopsia = !cita.Biopsia

        cita.Biopsia = !cita.Biopsia

        this.alert._showLoader('Actualizando espere...')
        this.calendar._setValuesCalendar(this._calendarPost).subscribe({
          next: (response) => {

            this.alert._hideLoader()
            this.toastr.success('La actualización, se ha asigando correctamente', 'Exito al asignar', {
              timeOut: 3200
            })

          },
          error: (error) => {
            console.error({ error });

            this.alert._hideLoader();
            this.toastr.error('Ha ocurrido un error al actualizar la información', 'Error ocurrido', {
              timeOut: 3200
            })
          }
        })
      }
    })

  }

  /**
   * 
   */
  _onSearch() {

    this._local.savedJsonValues(_PATCH_DATE_FORM, this._formFilter.value)

    this.modalService.dismissAll()
    this._loadCalendarPlancha()

  }
  /**
   * 
   */
  private SetValuesFilter() {
    const _dateRange = this._formFilter.value._dateRange;

    if (_dateRange.from) {

      this._filters.inicio = this.date.transform(_dateRange.from, 'yyyy-MM-dd') // _b//new Date().toLocaleDateString() //
    }

    if (_dateRange.to) {
      this._filters.final = this.date.transform(_dateRange.to, 'yyyy-MM-dd') //new Date(_dateRange.to).toLocaleDateString()
    } else {
      this._filters.final = this.date.transform(_dateRange.from, 'yyyy-MM-dd')
    }
  }

  /**
   * 
   */
  _openFilters() {
    this.modalService.open(this.modalFilters, { centered: true, size: 'md', windowClass: 'modal-holder modal-dialog-right' })
  }

  /**
   *  
   */
  hideModal() {
    this.modalService.dismissAll()
  }

  /**
   * 
   * @param cita 
   */
  _openCanvas(cita: any) {

    this._detallesCita = new DetallesCita()

    const doctor = _.find(this._doctores, { Id: cita.Empleado_Id })
    //const servicio = _.find(this.responseData.Servicios, { Id: _cita.Servicio_Id })

    this._detallesCita = {
      cita: cita,
      //servicio

    }

    this._canvasRef_ = this.offCanvasService.open(this.canvasDetail, {
      position: 'end',
      backdrop: true,
      scroll: false,
    })


  }


  /**
   * 
   */
  _onSelectedAnestesiologo(cita: CitaPlancha) {

    if (this.credentialService.credentials?.id_salesforce && cita.Anestesia) {
      this._calendarPost.cita = cita.Cita_Id
      this._calendarPost.anestesiologo = this.credentialService.credentials.id_salesforce;

      Swal.fire({
        title: 'Por favor confirma esta acción',
        text: `¿Deseas asignarte esta Cita,a ti como anestesiologo ?`,
        icon: 'question',
        confirmButtonText: 'Sí, asignar',
        cancelButtonText: 'No, cancelar',
        showCancelButton: true

      }).then((result) => {
        if (result.value) {
          this.alert._showLoader('Actualizando espere...')

          this.calendar._setValuesCalendar(this._calendarPost).subscribe({
            next: (response) => {
              this.alert._hideLoader();

              this.toastr.success('La actualización  se ha realizado correctamente.', 'Éxito al actualizar la información', {
                timeOut: 3200
              })

              // console.log({response});
              this._loadCalendarPlancha()

            },
            error: (error) => {
              this.alert._hideLoader();
              this.toastr.error('Ocurrió un error al actualizar la información', 'Error ocurrido', {
                timeOut: 3200
              })
              console.error({ error });

            }

          })

        }
      })


    } else {
      this.toastr.warning('No es posible asignar esta cita, por favor intente de nuevo', 'Error en asignacion', {
        timeOut: 3200
      })
    }
  }

  //#region FILTERS

  _onChangeEventTipoCita(event: any[]) {
    const resp = this._allData;
    let _data_: any[] = []

    if (event.length > 0) {

      _.map(event, (a) => {
        _.map(a.data, (b) => {
          _data_.push(b)
        })
      })

      this._doctoresGrouped = _(_data_)
        .groupBy('Empleado_Id')
        .map((items, empleado_id) => ({
          empleado_id,
          name: _.find(resp.Doctores, { Id: empleado_id })?.Name,
          total: items.length,
          data: items
        })).value()


      this._recursosGrouped = _(_data_)
        .groupBy('Unidad')
        .map((items, Unidad) => ({
          Unidad,
          name: items[0].Unidad,
          total: items.length,
          data: items
        })).value()

      this._serviciosGrouped = _(_data_)
        .groupBy('Servicio_Id')
        .map((items, servicio_id) => ({
          servicio_id,
          name: _.find(resp.Servicios, { Id: servicio_id })?.Name,
          total: items.length,
          data: items
        })).value()

      console.log({ _data_ });

      return this._CitasPlancha = [..._data_]
    }
    return this._CitasPlancha = [...this._allData.Citas]
  }

  /**
   * 
   */
  _onChangeEventServicios(event: any[]) {

    const resp = this._allData;
    let _data_: any[] = []

    if (event.length > 0) {

      _.map(event, (a) => {
        _.map(a.data, (b) => {
          _data_.push(b)
        })
      })

      this._doctoresGrouped = _(_data_)
        .groupBy('Empleado_Id')
        .map((items, empleado_id) => ({
          empleado_id,
          name: _.find(resp.Doctores, { Id: empleado_id })?.Name,
          total: items.length,
          data: items
        })).value()

      this._recursosGrouped = _(_data_)
        .groupBy('Unidad')
        .map((items, Unidad) => ({
          Unidad,
          name: items[0].Unidad,
          total: items.length,
          data: items
        })).value()

      this._tipoCitaGrouped = _(_data_)
        .groupBy('Tipo_Evento')
        .map((items, tipo_cita) => ({
          tipo_cita,
          name: items[0].Tipo_Evento,
          total: items.length,
          data: items
        })).value();

        console.log({ _data_ });

        return this._CitasPlancha = [..._data_]

    } else if (this._DoctoresCollect.length > 0) {
      this._onChangeEvent(this._DoctoresCollect)
    }
    this._getFilters(resp.Citas)
    return this._CitasPlancha = [...this._allData.Citas]
  }

  /**
   * 
   * @param event 
   */
  _onChangeEvent(event: any[]) {
    const resp = this._allData;
    let _data_: any[] = []

    if (event && event.length > 0) {

      _.map(event, (a) => {
        _.map(a.data, (b) => {
          _data_.push(b)
        })
      })

      this._serviciosGrouped = _(_data_)
      .groupBy('Servicio_Id')
      .map((items, servicio_id) => ({
        servicio_id,
        name: _.find(resp.Servicios, {Id: servicio_id })?.Name,
        total: items.length,
        data: items
      })).value()

      this._recursosGrouped = _(_data_)
      .groupBy('Unidad')
      .map((items, Unidad) => ({
        Unidad,
        name: items[0].Unidad,
        total: items.length,
        data: items
      })).value()   

      this._tipoCitaGrouped = _(_data_)
      .groupBy('Tipo_Evento')
      .map((items,tipo_cita) => ({
        tipo_cita,
        name: items[0].Tipo_Evento,
        total: items.length,
        data: items
      })).value();

      console.log({ _data_ });

      return this._CitasPlancha = [..._data_]

    }else if (this._ServiciosCollect.length > 0 ) {
      this._onChangeEventServicios(this._ServiciosCollect)
    }

    this._getFilters(resp.Citas)
    return this._CitasPlancha = [...this._allData.Citas]
  }

  _onChangeEventRecursos(event: any []) { 
    const resp = this._allData;
    let _data_: any[] = []  

    if(event && event.length > 0  ) {

      _.map(event, (a) => {  
        _.map(a.data, (b) => {
         _data_.push(b)
        })          
       })   

       this._doctoresGrouped = _(_data_)
       .groupBy('Empleado_Id')
       .map((items,empleado_id) =>({
         empleado_id,
         name: _.find(resp.Doctores, {Id: empleado_id})?.Name,
         total: items.length,
         data: items
       })).value()

       this._serviciosGrouped = _(_data_)
       .groupBy('Servicio_Id')
       .map((items, servicio_id) => ({
         servicio_id,
         name: _.find(resp.Servicios, {Id: servicio_id })?.Name,
         total: items.length,
         data: items
       })).value()

       this._tipoCitaGrouped = _(_data_)
       .groupBy('Tipo_Evento')
       .map((items,tipo_cita) => ({
         tipo_cita,
         name: items[0].Tipo_Evento,
         total: items.length,
         data: items
       })).value();


       console.log({ _data_ });

       return this._CitasPlancha = [..._data_]

    }else if (this._ServiciosCollect.length > 0 ) {

      this._onChangeEventServicios(this._ServiciosCollect)

    }else if (this._DoctoresCollect.length > 0 ) {

      this._onChangeEvent(this._DoctoresCollect)


    }

    this._getFilters(resp.Citas)
    return this._CitasPlancha = [...this._allData.Citas]

  }

  //#endregion


}
