
<ng-container *ngIf="_showCamera">
    <div class="d-flex justify-content-center">
        <div class="p-3 m-4">
            <div class="card border card-border-primary"> 
                <div class="card-body border border-2 border-primary">
                    <webcam                   
                    [height]="500"
                    [width]="700" 
                    [trigger]="triggerObservable" 
                    (imageCapture)="handleImage($event)" 
                    [allowCameraSwitch]="allowCameraSwitch" 
                    [switchCamera]="nextWebcamObservable"       
                    [mirrorImage]="'auto'"   
                    [imageQuality]="1"
                    [videoOptions]="videoOptions"
                    (cameraSwitched)="cameraWasSwitched($event)"
                    (initError)="handleInitError($event)"></webcam>             
    
                </div>
               
            </div>
            <div class="d-flex justify-content-center">
                <div class="hstack gap-3">
                    <button
                    type="button" 
                    (click)="showNextWebcam(true);" 
                    [disabled]="!multipleWebcamsAvailable"
                    class="btn btn-soft-info waves-effect waves-light m-1"> 
                    <i class="ri-camera-switch-line align-bottom me-1"></i> Cambiar de camara </button>
    
                    <button 
                    type="button" 
                    (click)="triggerSnapshot();"
                    class="btn btn-soft-success waves-effect waves-light m-1">
                    <i class="ri-camera-3-line  align-bottom me-1"></i> Tomar fotografia </button>
    
                    <button 
                    type="button" 
                    (click)="toggleWebcam()"
                    class="btn btn-soft-danger waves-effect waves-light m-1">
                    <i class=" ri-camera-off-line align-bottom me-1"></i> Cerrar camara </button>
                </div>
    
          
    
            </div>
        </div>
        
    </div>

</ng-container>

<div *ngIf="_pictures && _pictures.length">
    <div class="row gallery-wrapper"  >
        <div class="element-item col-xxl-4 col-sm-6 project designing development"  data-category="designing development" *ngFor="let data of _pictures; let i=index">
            <div class="gallery-box card" style="cursor: pointer">
                <div class="gallery-container">
                    <a class="image-popup" >
                        <img class="gallery-img img-fluid mx-auto" src="{{data.src}}" alt=""/>
                        <div class="gallery-overlay" (click)="openBase64(i)">   </div>
                    </a>
                </div>
                <div class="box-content">
                    <div class="d-flex align-items-center mt-1">
                        <div class="flex-grow-1 text-muted"> <a href="javascript:void(0);" class="text-body text-truncate text-primary">{{data.filename}}</a></div>                       
                    </div>
                </div>
            </div>
            <button class="btn btn-primary btn-sm" 
            (click)="data._shoComentarios =! data._shoComentarios" *ngIf="!data._shoComentarios">Agregar comentario</button>

            <fieldset class="mt-4" *ngIf="data._shoComentarios">
                <textarea
                class="form-control"
                [rows]="4"
                [(ngModel)]="data.comentario"
                [ngModelOptions]="{standalone: true}">
                </textarea>
                <button class="mt-4 btn btn-danger btn-sm" (click)="_deleteComentario(data, i)">Quitar comentario</button>
            </fieldset>
            <button class="btn btn-icon " (click)="_deleteImg(i)"> <i class="mdi mdi-trash-can align-middle text-danger me-1 fs-3"></i> </button>
        </div>
    </div>
    <div class="d-flex justify-content-center" *ngIf="showBtnSaveIt">
        <button class="btn btn-success w-50 text-uppercase" (click)="_uploadImages()"> guardar imágenes </button>
    </div>
</div>

<hr>

<div class="d-flex justify-content-center" *ngIf="!_showCamera" >
    <button class="btn btn-primary  text-uppercase " (click)="initWebCam()">Subir desde cámara</button>
   
</div>
