<div *ngIf="_formato && _itsReady">    
    <fieldset class="mt-4">
        <div class="d-flex justify-content-end m-2">
            <button class="btn btn-primary" (click)="_onSendData()"> Enviar a salesForce    </button>
        </div>
    </fieldset>
    <form [formGroup]="_formControl" class="needs-validation createfile-form" autocomplete="off" novalidate>
        <div class="row">
            <div  *ngFor="let control of _controls" [ngClass]=" 
            control.name === 'datos_paciente_recomendaciones' || 
            control.name === 'domicilio_datos_paciente_recomendaciones' ||
            control.name === 'salida_datos_paciente_recomendaciones' ? 'col-12' : 'col-xl-6 col-lg-12 col-md-12' "  >
                <div class="row">
                    <div class="col-xl-6" *ngIf="_formato_ine_frente">                    
                        <div class="card  border border-3 border-primary ">
                            <div class="card-header"> <h4 class="card-title">INE Frente </h4> </div>
                            <div class="card-body">
                                <img [src]="_formato_ine_frente.url" class="img-fluid">
                            </div>                        
                        </div>
                    </div>
                    <div class="col-xl-6" *ngIf="_formato_ine_reverso">                    
                        <div class="card  border border-3 border-primary ">
                            <div class="card-header"> <h4 class="card-title">INE Reverso </h4> </div>
                            <div class="card-body">
                                <img [src]="_formato_ine_reverso.url" class="img-fluid">
                            </div>                        
                        </div>
                    </div>
                </div>    
                
                <div class="bg-light">
                    <h4 class="fs-bold text-uppercase text-center">{{control.label}} </h4>     
                </div>  

               <!--  <div *ngIf="control.name === 'datos_paciente_recomendaciones' || control.name ==  "> -->
                    <div class="card card-body">
                        <div class="container-fluid ">
                            <div class="row ">
                                <div  *ngFor="let objects of control.Objects; let i = index;" 
                                [ngClass]=" 
                                control.name === 'salida_datos_paciente_recomendaciones' || 
                                control.name === 'donde_enteraste_datos_paciente_recomendaciones'   ? 'col-12 ' : 
                                control.name === 'contacto_emergencia__datos_paciente_recomendaciones' ? 'col-xl-6 col-md-6 col-lg-12' :    'col-xl-6 col-md-6 col-lg-6' "  class="mt-2">

                                <ng-container *ngIf="control.name === 'donde_enteraste_datos_paciente_recomendaciones' else formControls">
                                  <div *ngIf="i  === 0">                                    
                                    <ng-select
                                    [items]="_origenPaciente"
                                    [formControlName]="objects.name"
                                    bindLabel="description"
                                    bindValue="id">
                                    </ng-select>
                                  </div>
                                </ng-container>

                                <ng-template #formControls>

                                    <ng-container *ngIf="control.name === 'seguro_medico_datos_paciente_recomendaciones'; else othersForms " >
                                        <div >
                                            <div *ngIf="objects.type === 'radio' ">
                                                <ng-template [ngTemplateOutlet]="radioTEmpalte" ></ng-template>
                                            </div>    
                                            <div *ngIf="form[control.name].value === 'si_seguro_medico_datos_paciente_recomendaciones' ">
                                                <ng-template [ngTemplateOutlet]="formControlsTempalte" ></ng-template>
                                            </div>
                                        </div>
    
                                        <ng-template #radioTEmpalte>
                                            <div class="form-check form-check-right form-radio-primary mb-3">
                                                <input 
                                                class="form-check-input" 
                                                type="radio" 
                                                [name]="control.name"         
                                                [value]="objects.name"                                        
                                                id="{{objects.name}}" 
                                                [formControlName]="control.name">
                                                <label class="form-check-label"> {{objects.label}} </label>
                                            </div>                                    
                                        </ng-template>
        
                                        <ng-template #formControlsTempalte>

                                            <div *ngIf="i === 0 ">
                                          
                                            
                                            </div>
                                        

                                            <div *ngIf="objects.type == 'text' || objects.type == 'time' || objects.type == 'number' ">
                                       

                                                <ng-container *ngIf="objects.name === 'cual_seguro_medico_datos_paciente_recomendaciones'  ; else _inputTemplate ">

                                                    <div class="mb-3">
                                                        <label class="form-label"> {{objects.label}} </label>
                                                        <ng-select
                                                        [items]="_segurosMedicos"
                                                        [formControlName]="objects.name"
                                                        ></ng-select>
                                                    </div>

                                                    <div class="form-floating" *ngIf="form['cual_seguro_medico_datos_paciente_recomendaciones'].value === 'Otro' " >
                                                        <input
                                                        [type]="objects.type"
                                                        [name]="objects.name"
                                                        [formControlName]="objects.name"
                                                        [required]="objects.required"
                                                        [readonly]="objects.readonly"                                                       
                                                        class="form-control">
                                                        <label class="form-label bold text-uppercase"> {{objects.label}} </label>
                                                    </div>

                                                </ng-container>
                                                <ng-template #_inputTemplate>
                                                    <div class="form-floating">
                                                        <input
                                                        [type]="objects.type"
                                                        [name]="objects.name"
                                                        [formControlName]="objects.name"
                                                        [required]="objects.required"
                                                        [readonly]="objects.readonly"                                                       
                                                        class="form-control">
                                                        <label class="form-label bold text-uppercase"> {{objects.label}} </label>
                                                    </div>
                                                </ng-template>

                                            
                                            </div>
                                        </ng-template>
                                    </ng-container>

                                    <ng-template #othersForms>
                                        <div *ngIf="objects.type == 'date' ">
                                            <div class="form-floating">
                                                <input                                                 
                                                class="form-control flatpickr-input"                                                                  
                                                [id]="objects.name"     
                                                [type]="objects.type"     
                                                mwlFlatpickr 
                                                [altInput]="true" 
                                                [value]="objects.value"
                                                [formControlName]="objects.name"
                                                [convertModelValue]="true"                   
                                                >
                                                <label [for]="objects.name" class="form-label" >{{objects.label}} </label> 
                                            </div>
                                        </div>
            
                                        <div *ngIf="objects.type == 'text' || objects.type == 'time' || objects.type == 'number' ">
                                            
                                            <div class="form-floating">
                                                <input
                                                [type]="objects.type"
                                                [name]="objects.name"
                                                [formControlName]="objects.name"
                                                [required]="objects.required"
                                                [readonly]="objects.readonly"                                                       
                                                class="form-control">
                                                <label class="form-label bold text-uppercase"> {{objects.label}} </label>
                                            </div>
                                        </div>   
                                    </ng-template>    
                                </ng-template>
                                </div>
                            </div>
                        </div>
                    </div>                
            </div>
        </div>    
    </form>
</div>
<!--  ------------------------- ---------- PREVIO ----------- ------------ -->
<!-- 
<div class="card" *ngIf="_formato && _itsReady">
    
    <fieldset class="mt-4">
        <div class="d-flex justify-content-end m-2">
            <button class="btn btn-primary" (click)="_onSendData()"> Enviar a salesForce    </button>
        </div>
    </fieldset>
    <div class="card-body" *ngFor="let control of _controls">

        <div *ngIf="control.name === 'datos_paciente_recomendaciones' || control.name === 'domicilio_datos_paciente_recomendaciones' ">
            <div class="row">
                <div class="col-xl-6" *ngIf="_formato_ine_frente">                    
                    <div class="card  border border-3 border-primary ">
                        <div class="card-header"> <h4 class="card-title">INE Frente </h4> </div>
                        <div class="card-body">
                            <img [src]="_formato_ine_frente.url" class="img-fluid">
                        </div>                        
                    </div>
                </div>
                <div class="col-xl-6" *ngIf="_formato_ine_reverso">                    
                    <div class="card  border border-3 border-primary ">
                        <div class="card-header"> <h4 class="card-title">INE Reverso </h4> </div>
                        <div class="card-body">
                            <img [src]="_formato_ine_reverso.url" class="img-fluid">
                        </div>                        
                    </div>
                </div>
            </div>
        </div>


        <form [formGroup]="_formControl" class="needs-validation createfile-form" autocomplete="off" novalidate>
            <div class="row g-2 mt-4">
                <ng-container *ngIf="control.type == 'frame'; else titleTemplate "> -->
                    <!-- TITULOS -->
     <!--                <div class="bg-primary">
                       <h4 class="fs-4 text-white bold text-uppercase text-center">{{control.label}} </h4>     
                   </div>   
               </ng-container>

               <ng-template #titleTemplate>
                    <div class="bg-light">
                        <h4 class="fs-bold text-uppercase text-center">{{control.label}} </h4>     
                    </div>  
                    <div class="col-12">
                        <div class="row">
                            <div *ngFor="let objects of control.Objects; let i = index" 
                            [ngClass]="objects.name === 'value_salida_datos_paciente_recomendaciones' ? 'col-12' : 'col-lg-6 col-sm-12 col-xl-3' " 
                            class="mt-4">
                            
                            <ng-container *ngIf="control.name === 'seguro_medico_datos_paciente_recomendaciones' || control.name === 'donde_enteraste_datos_paciente_recomendaciones'  ; else otherNames ">

                                <div *ngIf="control.name === 'donde_enteraste_datos_paciente_recomendaciones' && i === 0 ">
                                    <ng-select
                                    [items]="_origenPaciente"
                                    bindLabel="description"
                                    bindValue="id">
                                    </ng-select>
                                    
                                    
                                </div>

                                <div *ngIf="control.name === 'seguro_medico_datos_paciente_recomendaciones' ">
                                    <div *ngIf="objects.type === 'radio' ">
                                        <ng-template [ngTemplateOutlet]="radioTEmpalte" ></ng-template>
                                    </div>    
                                    <div *ngIf="form[control.name].value === 'si_seguro_medico_datos_paciente_recomendaciones' ">
                                        <ng-template [ngTemplateOutlet]="formControlsTempalte" ></ng-template>
                                    </div>
                                </div>
                     

                                <ng-template #radioTEmpalte>
                                    <div class="form-check form-check-right form-radio-primary mb-3">
                                        <input 
                                        class="form-check-input" 
                                        type="radio" 
                                        [name]="control.name"         
                                        [value]="objects.name"                                        
                                        id="{{objects.name}}" 
                                        [formControlName]="control.name">
                                        <label class="form-check-label"> {{objects.label}} </label>
                                    </div>                                    
                                </ng-template>

                                <ng-template #formControlsTempalte>
                                    <div *ngIf="objects.type == 'text' || objects.type == 'time' || objects.type == 'number' ">
                                        <div class="form-floating">
                                            <input
                                            [type]="objects.type"
                                            [name]="objects.name"
                                            [formControlName]="objects.name"
                                            [required]="objects.required"
                                            [readonly]="objects.readonly"                                                       
                                            class="form-control">
                                            <label class="form-label bold text-uppercase"> {{objects.label}} </label>
                                        </div>
                                    </div>
                                </ng-template>


                            </ng-container>

                            <ng-template #otherNames>
                                <div *ngIf="objects.type == 'text' || objects.type == 'time' || objects.type == 'number' ">
                                    <div class="form-floating">
                                        <input
                                        [type]="objects.type"
                                        [name]="objects.name"
                                        [formControlName]="objects.name"
                                        [required]="objects.required"
                                        [readonly]="objects.readonly"                                                       
                                        class="form-control">
                                        <label class="form-label bold text-uppercase"> {{objects.label}} </label>
                                    </div>
                                </div>
                                <div *ngIf="objects.type == 'date' ">
                                    <div class="form-floating">
                                        <input                                                 
                                        class="form-control flatpickr-input"                                                                  
                                        [id]="objects.name"     
                                        [type]="objects.type"     
                                        mwlFlatpickr 
                                        [altInput]="true" 
                                        [value]="objects.value"
                                        [formControlName]="objects.name"
                                        [convertModelValue]="true"                   
                                        >
                                        <label [for]="objects.name" class="form-label" >{{objects.label}} </label> 
                                    </div>
                                </div>
                                <div *ngIf="objects.type == 'checkbox' ">
                                    <div class="form-check form-check-right mb-3"> -->
                                        <!--        (change)="_onCheckChange($event, objects.name)" -->
    <!--                                     <input
                                        class="form-check-input" 
                                        type="checkbox"
                                        [name]="objects.name"
                                        [formControlName]="objects.name"
                                        [checked]="objects.value == 'false' ? false : objects.value "
                                        (change)="_onCheckChange($event, objects.name)"
                                        [required]="objects.required"
                                        [readonly]="objects.readonly">
                                        <label class="form-check-label">{{objects.label}}</label>
                                    </div>
                                </div>
                                <div *ngIf="objects.type === 'radio' ">
                                    <div class="form-check form-check-right form-radio-primary mb-3">
                                        <input 
                                        class="form-check-input" 
                                        type="radio" 
                                        [name]="control.name"         
                                        [value]="objects.name"                                        
                                        id="{{objects.name}}" 
                                        [formControlName]="control.name">
                                        <label class="form-check-label"> {{objects.label}} </label>
                                    </div>
                                </div>
                            </ng-template>
                            

                            
                            </div>
                        </div>
                    </div> 

               </ng-template>
            </div>
        </form>
    </div>
</div> -->