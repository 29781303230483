import { Component, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { FilePondOptions } from 'filepond';
import { FilePondComponent } from 'ngx-filepond';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription } from 'rxjs';

import * as _ from 'lodash'

@Component({
  selector: 'app-upload-files',
  templateUrl: './upload-files.component.html',
  styleUrls: ['./upload-files.component.scss']
})
export class UploadFilesComponent {

  @ViewChild('modalFiles') modalFiles !: TemplateRef<any>
  @ViewChild("myPond") myPond: FilePondComponent;

  @Input()
  showUpload: boolean = false;

  @Input()
  isMobile: boolean = true

  @Input()
  eventSubjectClerFiles: Observable<boolean>;

  @Output()
  eventEmmitFilesAdded: EventEmitter<any[]> = new EventEmitter<any[]>()

  $_subscriber: Subscription;

  pondOptions: FilePondOptions = {
    allowMultiple: true,
    labelIdle: "Suelte aquí o seleccione el archivo a subir",
    acceptedFileTypes: ['application/pdf'],
    maxFiles: 1,
    className: 'dropzone_sec',
    allowDrop: !this.isMobile,
    chunkUploads: true
  };


  pondFiles: FilePondOptions["files"] = [];


  constructor(
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    this.$_subscriber = this.eventSubjectClerFiles.subscribe({
      next: (resp) => {
        if (resp) {
          console.log({ resp });
          this._onDelete()
        }
      }
    })
  }

  _onDelete() {
    _.map(this.pondFiles, (a: any) => {
      a.pond.removeFiles()
    })
  }



  //#region Eventos filePond

  /**
   *
   *
   * @param {*} event
   * @memberof UploadFilesComponent
   */
  pondHandleAddFile(event: any) {
    this.pondFiles?.push(event)
  }

  pondHandleInit() { }
  pondHandleActivateFile(event: any) { }
  pondFileProgress(event: any, item: any) { }
  pondFileStart(event: any) { }
  pondProcessfilestart(event: any) { }
  pondAddfileprogress(event: any) { }
  pondProcessfiles(event: any) { }
  onpreparefile(event: any) { }
  onprocessfileprogress(event: any) { }

  /**
   *
   *
   * @param {*} event
   * @memberof UploadFilesComponent
   */
  onupdatefiles(event: any) {
    this.eventEmmitFilesAdded.next(event.items)
  }

  //#endregion

  /* 
    _onUploadFile() {
      if (this.pondFiles && this.pondFiles.length > 0) {
        this.eventEmmitFilesAdded.next(this.pondFiles)
      } else {
        this.toastr.info('Por favor seleccione un archivo PDF a cargar', 'No hay archivo seleccionado', {
          timeOut: 3200
        })
      }
    } */

}
