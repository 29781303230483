
import { HttpClient } from '@angular/common/http';
import { Injectable, OnInit } from '@angular/core';
import { DeletePaciente, PacientesEspera } from '@app/pages/models/pacientes_espera_info';
import { firstValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class PacientesEsperaService implements OnInit{

    private apiUrl: string = environment.apiUrl

    constructor (
        private http: HttpClient
    ) {}
    
    ngOnInit(): void {
        
    }

    /**
     *
     *
     * @return {*} 
     * @memberof PacientesEsperaService
     */
    getPacienteEsperaInfo() {
        return this.http.get<PacientesEspera>(`${this.apiUrl}users/details/paciente_espera/info`)
    }

    /**
     *
     *
     * @param {DeletePaciente} _body
     * @return {*} 
     * @memberof PacientesEsperaService
     */
    deletePacienteEspera(_body:DeletePaciente) {
        return firstValueFrom(this.http.post(`${this.apiUrl}users/details/paciente_espera/info/eliminar_cita`,_body))
    }
    
}