import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

import { Observable, Subscription } from 'rxjs';
import { FormControl } from '@angular/forms';
import { VoiceRecognitionService } from '@app/shared/services/voice-recognition.service';

@Component({
  selector: 'app-text-area-voice-recorder',
  templateUrl: './text-area-voice-recorder.component.html',
  styleUrls: ['./text-area-voice-recorder.component.scss']
})
export class TextAreaVoiceRecorderComponent implements OnInit, OnDestroy {

  @Input()
  name: string
  @Input()
  required: boolean
  @Input()
  control: FormControl
  @Input()
  readonly: boolean
  @Input()
  rows: number = 4
  @Input()
  transcript: string

  @Output()
  eventEmmiterValue: EventEmitter<string> = new EventEmitter<string>()

  itsRecording: boolean = false
  subscription: Subscription | undefined;
  _text_value: string
  _text: Observable<string>;

  constructor(public voice: VoiceRecognitionService) { }

  ngOnInit(): void {
    this._text_value = this.transcript
    this.subscription = this.voice.transcript.subscribe((text) => {
      this.transcript = text;
    });
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    this.voice.stopListening(); // Clean up when component is destroyed
  }

  /**
   *
   *
   * @memberof TextAreaVoiceRecorderComponent
   */
  startListening() {
    this.itsRecording = !this.itsRecording
    this.voice.startListening()
  }

  /**
   *
   *
   * @memberof TextAreaVoiceRecorderComponent
   */
  stopListening() {
    this.itsRecording = !this.itsRecording
    this.voice.stopListening();
    const _value_ = `${this._text_value} ${this.transcript}`
    this._text_value = _value_
    this.eventEmmiterValue.emit(_value_)

  }
}
