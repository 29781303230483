import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import * as _ from 'lodash';
import * as moment from 'moment';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ToastrService } from 'ngx-toastr';
import { Subject, Subscription, interval } from 'rxjs';
import { CredentialsService } from 'src/app/core/services/credentials.service';
import { TokenStorageService } from 'src/app/core/services/token-storage.service';
import { Cita } from 'src/app/pages/calendar/models/calendar';
import { Images } from 'src/app/shared/models/list-images';
import { AlertsService } from 'src/app/shared/services/alerts.service';
import { FormatsService } from 'src/app/shared/services/formats.service';

@Component({
  selector: 'app-nota-evolucion',
  templateUrl: './nota-evolucion.component.html',
  styleUrls: ['./nota-evolucion.component.scss']
})
export class NotaEvolucionComponent implements OnInit, OnDestroy {

  @Input()
  id: string

  @Input()
  _formatoData: any

  _Cita: Cita
  $interval!: Subscription;
  _BODYFORM: any;
  _Firma: Images[] = []
  currentUser: any

  _formNotasEvolucion: FormGroup;

  public formNotaEvolucion: FormGroup = this.formBuilder.group({})
  
  record: any;
  control: string
  isDesktopDevice!: boolean

  eventSubjectClerFiles: Subject<boolean> = new Subject<boolean>();

  _files: any[] = []
  _webcamImage: any[] = []

  _notasEvolucion: any[] = []

  _log_notas_evolucion: any[] = []
  _cita_selected: string | null
  _selected_nota_evolucion: any
  _img_nota_evolucion: any[] = []

  _Images: any[] = []

  _assetsSvg =  'assets/images/svg/circle.svg' //['']

  
  _svgEditors: any[] = [{
    id: 0,
    path: this._assetsSvg,
    _showComments:true,
    comentario: null
  }]

  _svgfilesPng: any[] = []

  
  _controls: any[] = []
  _formato: any = {}

  _label: string
  _itsReady: boolean
  _label_: string = 'nota_evolucion_frame'

  constructor(
    private formatService: FormatsService,
    private credentials: CredentialsService,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private tokenService: TokenStorageService,
    private deviceService: DeviceDetectorService,
    private alert: AlertsService
  ) {

    const _cita = this.credentials.getCitaJson()
    if (_cita) {
      this._Cita = _cita
    }

    this.record = this.credentials.userInfoData.data.records[0]
    this.currentUser = this.tokenService.getUser()   

    this.epicFunction();
  }



  


  ngOnInit(): void {

    if (this._formatoData) {
      this._formato = this._formatoData
    //  this.control = this._formato.label;
      console.log({formato___: this._formatoData});
      
      if (this._formato.form) {
        this._label = this._formato.label;
        this._controls = this._formato.form.controls;
        this.control = this._controls[0].name

        this._itsReady = true;
        this.initForm()
        this.loadNotasEvolucion()
        this._loadImage()

      }
      this.initformNotasEvolucion()
    }
  }

  private epicFunction() {
    this.isDesktopDevice = this.deviceService.isDesktop()
  }

  ngOnDestroy(): void {
    if (this.$interval) {
      this.$interval.unsubscribe()
    }
  }

  get f() {
    return this.formNotaEvolucion.controls
  }

  /**
   * 
   */
  private loadNotasEvolucion () {
    this.formatService._getNotasEvolucion(this.record.Id).subscribe({
      next:(response: any) => {        
        if(response.codigo === 200) {

          if(response.data && response.data.length) {
            const error = response.data[0]             
            if(error.error && error.details) {

              console.log('aaa');
              
            }else {
              const data = response.data
          
              this._notasEvolucion =  [...data];
            }
  
            
          }     


        }        
      },
      error:(error) => {
        console.error({error});
        
      }
    })    
  }

  private _loadImage() {
    /**
   * Cargamos la firma del doctor
   */
    this.formatService._getImageProfile(this.currentUser.username).subscribe({
      next: (response) => {
        if (response.codigo == 200) {
          this._Firma = response.data.formato.archivos.Firma;
        }
      }
    })

    this._Images = []
   // this._LaboratorioFiles = []
    this._img_nota_evolucion = []
    this.formatService._getListImages(this._Cita.Cita_Id, this.record.Name).subscribe({
      next:(response) => {
        if(response.codigo === 200)  {
          const data = response.data
          if(data.formato && data.formato.archivos) {

            const archivos = data.formato.archivos            
            this._Images = archivos.Paciente ? archivos.Paciente : [];    

            this._img_nota_evolucion = archivos.nota_evolucion_frame

          }
            
        }
      }
    })
  }

  private initformNotasEvolucion() {
    this._formNotasEvolucion = this.formBuilder.group({
      Cita_Id:[this._Cita.Cita_Id],
      fecha:[moment().format('YYYY-MM-DD') ],
      subjetivo:[''],
      objetivo:[''],
      analisis:[''],
      plan:[''],
      usuario:[this.currentUser.username],
      
    })
  }

  private initForm() {
    _.map(this._controls, (a) => {
      _.map(a.Objects, (b) => {
        this.formNotaEvolucion.addControl(
          b.name, this.formBuilder.control(b.value)
        )
      })
    })

    // this.createInterval()


  }

  private createInterval() {

    const myObservable = interval(1000);
    this.$interval = myObservable.subscribe((integer) => {
      if (integer === 20) {
        this.$interval.unsubscribe();
        console.log('GO SAVE IT!!!!');
        //this.show = !this.show;       
        this._onSaveIt()

      }
      //this._interval = integer

    })
  }

  _onSaveIt() {

    //console.log({_FORMVALUES: _formValues });
    console.log({ _FOMATO: this._formato });

    let _formato = this._formato

    _.map(_formato.form.controls, (a) => {

      if (a.type == 'frame') {
        _.map(a.Objects, (b) => {
          b.value = this.f[b.name].value
        })
      }
    })

    let _bodyForm = {
      Cita_Id: this._Cita.Cita_Id,
      label: this._formato.label,
      form: _formato.form
    }

    this.formatService._postFormato(_bodyForm).then((resp) => {
      this.toastr.info('Se ha guardado la información', 'Guardada', {
        timeOut: 3200
      })

      this.$interval.unsubscribe()

      setTimeout(() => {
        this.createInterval()
      }, 5000);

    }).catch((error) => {
      console.error({ error });

    })

    this._BODYFORM = _bodyForm


    console.log({ _BODYFORM: _bodyForm });


  }

  /**
   * 
   * @param files 
   */
  _onUploadFiles(_files: any[]) {
    this._files = _files
  }

  _onSaveItImages(webcamImage: any []) {
    this._webcamImage = webcamImage
  }

  _onsave() {

    this.alert._showLoader('Guardando, por favor espere...')
    this.formatService._GuardarNotasEvolucion(this._formNotasEvolucion.value, this.record.Name,this.control,this._files,this._webcamImage).then((resp) => {
      console.log({resp});      
      this.alert._hideLoader()

      this.toastr.success('Todos los valores se han guardado correctamente','Exito!!',{
        timeOut: 3200,
        progressBar:true
      })

      this._notasEvolucion = []
      this._selected_nota_evolucion = null
      this.loadNotasEvolucion()
      this._loadImage()

      this.eventSubjectClerFiles.next(true)
      this._formNotasEvolucion.reset()
      this._formNotasEvolucion.patchValue({
        Cita_Id:[this._Cita.Cita_Id],
        fecha:[moment().format('YYYY-MM-DD') ],
        username: [this.currentUser.username]
      })

    }).catch((error) => {
      console.error({error});      
    })   
  }

  _set_log_notasEvolucion(item: any) {
    
    this._log_notas_evolucion =  item.archivos.Nota_Evolucion
    this._selected_nota_evolucion = null

    this._cita_selected = item.Cita_Id
    
  }


  _setNotaEvolucion(_nota_evolucion: any) {
    console.log({_nota_evolucion});
    
    this._selected_nota_evolucion = _nota_evolucion
  }

  _onEvenEmmiter(event: boolean) { }

  _onEventEmmiterImg(event: any) { 
    this._svgfilesPng.push(event)   
  }

  _onEventEmmiteDelte(_path: string) { }

  
  _saveSvgToPng() {
    this.alert._showLoader('Guardando por favor espere...')   
    this.formatService.UploadFiles(this._Cita.Cita_Id,  this.record.Name,  this._label_, null, null, this._svgfilesPng).then((resp) => {
      console.log({resp});      
      this.alert._hideLoader()
      this._loadImage()

      this._svgEditors  = []
      this._svgEditors.push({
        id: 0,
        path: this._assetsSvg,
        _showComments:true,
        comentario: null
      })
      this._svgfilesPng = []


    }).catch((error) => {
      console.error({error});
      this.alert._hideLoader()      
    })
  }

}
