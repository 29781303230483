<ng-container *ngIf="rxTime">
    <app-breadcrumbs title="Registro de signos anestesia {{rxTime | date: 'H:mm:ss '}}"></app-breadcrumbs>
</ng-container>



<ng-container *ngIf="!isMobile; else mobileTemplate">
    <div class="chat-wrapper d-lg-flex gap-1 mx-n4 mt-n4 p-1">
        <div class="file-manager-sidebar border border-2" id="menusidebar">
            <div class="p-3 d-flex flex-column h-100">
                <div class="mb-3">
                    <h5 class="mb-0 fw-semibold">Formato pacientes</h5>
                </div>
                <ngx-simplebar class="mt-3 mx-n4 px-4 file-menu-sidebar-scroll">
                    <ul class="list-unstyled file-manager-menu">
                        <li *ngFor="let item of _controls; let i = index" class="active">
                            <a href="javascript:void(0);" (click)="_setObjects(item)">
                                <i class="ri-file-list-2-line align-bottom me-2"></i>
                                <span class="file-list-link">{{item.label ? item.label : item.name }}</span>
                            </a>
                        </li>
                    </ul>
                </ngx-simplebar>
            </div>
        </div>

        <div class="file-manager-content w-100 p-3 py-0 border border-2">
            <ngx-simplebar class="mx-n3 pt-4 px-4 file-manager-content-scroll">
                <ng-template [ngTemplateOutlet]="TabContent"></ng-template>
            </ngx-simplebar>
        </div>
    </div>
    <ng-template #TabContent>

        <ng-container *ngIf="_Objects ; else emptyTemplate">
            <div class="mt-4 p-2">
                <!--   <ngx-simplebar class="sidebar-menu-scroll" style="max-height: calc(100vh - 70px)"> -->
                <div class="card">
                    <div class="card-body">
                        <h4 class="fs-6 text-primary bold text-uppercase text-center">{{_Objects.label ? _Objects.label
                            : _Objects.name }} </h4>
                        <form [formGroup]="formAnestesiologo" class="needs-validation createfile-form"
                            (ngSubmit)="_onSubmit()" autocomplete="off" novalidate>
                            <!-- <ng-template [ngTemplateOutlet]="btnTemplate"></ng-template> -->
                            <div class="row g-2 m-2" *ngIf="_Objects.name != 'grafica_registro_anestesia'">

                                <div *ngFor="let _object of _Objects.Objects" [ngClass]="_object.type == 'text-area' ||
                                           _object.Objects  ? 'col-12' : 'col-xl-4 col-lg-6'">

                                    <ng-container *ngIf="_object.Objects; else _inputTemplate">
                                        <legend class="fs-14 text-muted"> {{_object.label}} </legend>
                                        <div class="row g-2">
                                            <div *ngFor="let _objects_ of _object.Objects; let i = index;"
                                                [ngClass]="_objects_.type == 'text-area'   ? 'col-12' :  _object.name == 'riesgo_anestesia_num' ? 'col' :   'col-6' ">
                                                <!--    <ng-template #_templateInputs> -->
                                                <div class="mb-4" *ngIf="_objects_.type =='text-area' ">
                                                    <label class="form-label">{{_objects_.label}}</label>
                                                    <textarea class="form-control" [name]="_objects_.name"
                                                        [required]="_objects_.required"
                                                        [formControlName]="_objects_.name"
                                                        [readonly]="_objects_.readonly" [rows]="4">
                                                        </textarea>
                                                </div>

                                                <div *ngIf="_objects_.type == 'radio'">
                                                    <div class="form-check form-check-right form-radio-primary mb-3">
                                                        <input class="form-check-input" type="radio"
                                                            [name]="_object.name" [value]="_objects_.name"
                                                            id="{{_objects_.name}}" [formControlName]="_object.name">
                                                        <label class="form-check-label" for="{{_objects_.name}}">
                                                            {{_objects_.label}}
                                                        </label>
                                                    </div>
                                                </div>

                                                <div *ngIf="_objects_.type == 'text' ">
                                                    <div class="form-floating">
                                                        <input [type]="_objects_.type"
                                                            [formControlName]="_objects_.name" class="form-control"
                                                            [name]="_objects_.name">
                                                        <label class="form-label bold text-uppercase">
                                                            {{_objects_.label}} </label>
                                                    </div>
                                                </div>
                                                <!-- </ng-template>          -->

                                            </div>
                                        </div>
                                    </ng-container>
                                    <ng-template #_inputTemplate>

                                        <div class="mb-4" *ngIf="_object.type =='text-area' ">
                                            <label class="form-label">{{_object.label}}</label>
                                            <textarea class="form-control" [name]="_object.name"
                                                [required]="_object.required" [formControlName]="_object.name"
                                                [readonly]="_object.readonly" [rows]="4">
                                            </textarea>
                                        </div>

                                        <div *ngIf="_object.type == 'text' ">
                                            <div class="form-floating">
                                                <input [type]="_object.type" [formControlName]="_object.name"
                                                    class="form-control" [name]="_object.name">
                                                <label class="form-label bold text-uppercase"> {{_object.label}}
                                                </label>
                                            </div>
                                        </div>

                                        <div *ngIf="_object.type == 'radio' ">
                                            <!--   <code>{{_object | json}} </code>
                                            <code>{{_Objects | json }} </code> -->

                                            <div class="form-check form-check-right form-radio-primary mb-3">
                                                <input class="form-check-input" type="radio" [name]="_Objects.name"
                                                    [value]="_object.name" id="{{_object.name}}"
                                                    [formControlName]="_Objects.name">
                                                <label class="form-check-label" for="{{_object.name}}">
                                                    {{_object.label}}
                                                </label>
                                            </div>
                                        </div>

                                    </ng-template>
                                </div>
                            </div>

                            <div class="row g-2 m-2" *ngIf="_Objects.name == 'grafica_registro_anestesia' ">
                                <div class="col-xl-2 col-lg-3">
                                    <button class="gap-2 p-2 btn btn-primary">GUARDAR</button>
                                    <ngx-simplebar class="sidebar-menu-scroll" style="max-height: calc(100vh - 5px)">
                                        <div class="row g-2 border border-2">
                                            <div *ngFor="let _object of _Objects.Objects" class="col-12">
                                                <div class="row g-2">
                                                    <div *ngFor="let _objs of _object.Objects" class="col-12">
                                                        <!-- else horaTemplate -->
                                                        <ng-container *ngIf="_objs.label!= 'HORA'; else horaTemplate">
                                                            <div class="form-floating">
                                                                <input [type]="_objs.type" mask="separator" prefix=""
                                                                    [formControlName]="_objs.name" class="form-control"
                                                                    [name]="_objs.name">
                                                                <label class="form-label bold text-uppercase">
                                                                    {{_objs.label}} </label>
                                                            </div>

                                                        </ng-container>

                                                        <ng-template #horaTemplate>
                                                            <input [type]="_objs.type" prefix="" readonly disabled
                                                                class="form-control text-center text_hora"
                                                                [name]="_objs.name"
                                                                [value]="_hour_caputred | date:'H:mm'">
                                                        </ng-template>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </ngx-simplebar>
                                </div>
                                <div class="col-xl-10 col-lg-9">
                                    <ng-template [ngTemplateOutlet]="graficas"></ng-template>
                                </div>

                            </div>
                            <!-- <ng-template [ngTemplateOutlet]="btnTemplate"></ng-template> -->
                        </form>
                    </div>
                </div>
                <!--     </ngx-simplebar> -->
            </div>
        </ng-container>

        <ng-template #emptyTemplate>
            <small class="text-muted">No hay nada que mostrar</small>
        </ng-template>

    </ng-template>

    <ng-template #btnTemplate>
        <div class="d-flex justify-content-end">
            <button type="submit" class="btn btn-success"> Guardar </button>
        </div>
    </ng-template>

</ng-container>

<ng-template #mobileTemplate>
    <ng-container *ngIf="_show_template">
        <app-mobile-template-anestesiologo (_eventSaveEmmitter)="_onEventEmmiter($event)" [formControls]="_controls"
            [basicLineChart]="basicLineChart" [lineWithDataLabelsChart]="lineWithDataLabelsChart"
            [eventSubjectSpinner]="eventSubjectSpinner.asObservable()">
        </app-mobile-template-anestesiologo>
    </ng-container>

</ng-template>

<ng-template #graficas>
    <div class="card">
        <div class="card-body">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title mb-0">Basic Line Chart</h4>
                </div><!-- end card header -->

                <div class="card-body">
                    <apx-chart [series]="basicLineChart.series" [chart]="basicLineChart.chart"
                        [markers]="basicLineChart.markers" [dataLabels]="basicLineChart.dataLabels"
                        [stroke]="basicLineChart.stroke" [colors]="basicLineChart.colors" [title]="basicLineChart.title"
                        [xaxis]="basicLineChart.xaxis" dir="ltr"></apx-chart>
                </div><!-- end card-body -->

            </div><!-- end card -->
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title mb-0">Line with Data Labels</h4>
                </div><!-- end card header -->

                <div class="card-body">
                    <apx-chart [series]="lineWithDataLabelsChart.series" [chart]="lineWithDataLabelsChart.chart"
                        [xaxis]="lineWithDataLabelsChart.xaxis" [stroke]="lineWithDataLabelsChart.stroke"
                        [colors]="lineWithDataLabelsChart.colors" [dataLabels]="lineWithDataLabelsChart.dataLabels"
                        [legend]="lineWithDataLabelsChart.legend" [markers]="lineWithDataLabelsChart.markers"
                        [grid]="lineWithDataLabelsChart.grid" [yaxis]="lineWithDataLabelsChart.yaxis"
                        [title]="lineWithDataLabelsChart.title" dir="ltr"></apx-chart>
                </div><!-- end card-body -->
            </div><!-- end card -->
        </div>
    </div>
</ng-template>

<ng-template #escala_aldrete>
    <div class="row g-2 mb-4 ">
        <div class="col-6 border border-2">
            <div class="d-flex justify-content-center">
                <span class="text-uppercase fs-3 fw-bold text-muted m-2"> Escala de aldrete </span>
            </div>
        </div>
        <div class="col-6 border border-2">
            <div class="float-start"><small class="text-uppercase"> quirofano </small> </div>
            <div class="float-end"><small class="text-uppercase">sala de recuperacion</small></div>
            <div class="row mt-4 g-2">
                <div class="col"
                    *ngFor="let item of ['AL SALIR','15 min', '30 min', '45 min', '60 min', '75 min', '90 min', '105 min', '120 min']">
                    <small class="fw-bold"> {{item}} </small>
                </div>
            </div>
        </div>

        <div class="col-12 mb-4  border border-1">
            <div class="row g-2"
                *ngFor="let item of  ['actividad muscular','respiracion','circulacion','estado de conciencia','colaracion','alta a su piso* medico responsalbe']">
                <div class="col-sm-6 col-md-6 col-lg-6  border border-1">
                    <div class="row g-2 border-bottom border-1">
                        <div class="col-4">
                            <span class="fs-5 fw-bold m-2 text-uppercase">
                                {{item}}
                            </span>
                        </div>
                        <div class="col-8 border-start border-1">
                            <div class="row g-2 m-2 "
                                *ngFor="let _item of ['Movimientos voluntarios (4 Extremidades) = 2', 'tension arterial + - 50% de cifras control = 0', 'respiraciones amplias y capaz de toser = 1 ']; let isLast=last ">
                                <div class="col-10">
                                    <dt>{{_item}} </dt>
                                </div>
                                <div class="col-2">
                                    <div class="float-end">
                                        <input type="text" class="form-control form-control-sm" placeholder="0">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6 col-md-6 col-lg-6  border border-1">
                    <div class="row g-2 m-4">
                        <div class="col-md-auto col-lg"
                            *ngFor="let input of ['AL SALIR','15 min', '30 min', '45 min', '60 min', '75 min', '90 min', '105 min', '120 min']">
                            <input type="text" class="form-control " placeholder="0">
                        </div>
                    </div>
                    <div class="row g-2 m-4" *ngIf="item === 'circulacion' ">
                        <div class="col-md-auto col-lg"
                            *ngFor="let input of ['AL SALIR','15 min', '30 min', '45 min', '60 min', '75 min', '90 min', '105 min', '120 min']">
                            <input type="text" class="form-control " placeholder="0">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>