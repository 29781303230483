import { CandidatosEstructurados } from './../calendar/models/candidatos';
import { Pipe, PipeTransform } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';


const CANDIDATOS_FILTER = 'filter_cadidatos'

@Pipe({
  name: 'buscadorCandidatos'
})
export class BuscadorCandidatosPipe implements PipeTransform {

  _filter: string[] = []

  constructor(
    private cookieService: CookieService
  ) { }


  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    // let _v = this.cookieService.get(CANDIDATOS_FILTER)



  }

  transform(collection: CandidatosEstructurados[], searchText: string) {


    if (typeof searchText === typeof undefined) {
      return collection
    }

    let _v = this.cookieService.get(CANDIDATOS_FILTER)
    // if (_v) {
    this._filter = JSON.parse(_v)
    if (this._filter && this._filter.length) {
      
      let _b: CandidatosEstructurados[] = []
      this._filter.forEach((name, index) => {

        if (name) {
          let _c = collection.filter((item: any) => {
            if(item[name])
              return item[name].toLocaleLowerCase().includes(searchText.toLocaleLowerCase())
          })

          if (_c && _c.length) {
            _b.push(..._c)
          }
        }
      })

      return _b
    }


    return collection.filter((item) =>
      item.Name.toLocaleUpperCase().includes(searchText.toLocaleUpperCase()) ||
      item.relacion_apellidos.toLocaleUpperCase().includes(searchText.toLocaleUpperCase()) ||
      item.generales_telefono.toLocaleUpperCase().includes(searchText.toLocaleUpperCase()) ||
      item.relacion_nombre.toLocaleUpperCase().includes(searchText.toLocaleUpperCase())
    )
  }
}
