export class Patients {
    attributes: {
        type:string
        url: string
    }
    Birthday_Day__c: number 
    Birthday_Month__c:number 
    Calle__c: string
    Campana__c: string
    Canal__c: string
    Candidato_a_cirug_a__c: string
    Candidato_en__c: string
    Candidato__c: string
    Centros_Medicos__c: string
    Ciudad__c: string
    Coaseguro__c: string
    Colonia__c: string
    Compa_a_de_SGMM__c: string
    Costo_por_resultado__c: string
    CreatedById: string
    CreatedDate: string
    Cuenta_con_Seguro_Gastos_M_dicos_Mayores__c: boolean
    Cumple_hoy__c: boolean
    C_digo_postal__c: string
    DireccionCompleta__c: string
    Empleado__c: string
    Encuesta_de_servicios__c: string
    Especialidad__c: string
    Estado_Direcci_n_paciente__c: string
    Estado__c: string
    Es_candidato_a_cirugia__c: boolean
    Fecha_nacimiento_calendario__c: string
    Id: string
    ID_Cuenta__c: string
    ID_del_anuncio__c: string
    IsDeleted: boolean
    LastActivityDate: string
    LastModifiedById: string
    LastModifiedDate: string
    LastReferencedDate: string
    LastViewedDate: string
    Mes_de_antepen_ltima_cirug_a__c:string
    Mes_de_ltima_cirug_a__c: string
    Mes_de_pen_ltima_cirug_a__c: string
    Metodo_de_contacto_del__c: string
    Misma_direcci_n_del_paciente__c: boolean
    Municipios__c: string
    Municipio__c: string
    Name: string
    Nombre_completo__c: string
    Nombre_del_conjunto_de_anuncios__c: string
    Nombre_del_Recurso__c: string
    Numero_de_Tarjeta__c: string
    Numero_Exterior__c: string
    Numero_Interior__c: string
    N_mero_de_Citas_Agendadas__c: number
    N_mero_de_Citas_Confirmadas__c: number
    N_mero_de_Citas_Exitosas_Cirug_a__c:number
    N_mero_de_Citas_Exitosas__c: number
    Objeci_n__c: string
    Origen_del_paciente_Historico__c: string
    Origen_del_Paciente__c: string
    OwnerId: string
    P00_Nombre__c: string
    P05_Antiguedad_2_anos__c:string
    P05_Apellidos__c: string
    P05_Celular__c: string
    P05_Comentario__c: string
    P05_Compania__c: string
    P05_Contacto_Emergencias_Nombre__c: string
    P05_Contacto_Emergencias_Tel_fono__c: string
    P05_Correo_de_Facturacion__c: string
    P05_Correo__c: string
    P05_Deducible__c: string
    P05_Direccion_de_Facturacion__c: string
    P05_Direccion__c: string
    P05_Edad__c: number //29.0,
    P05_Estado_civil__c: string
    P05_Fecha_de_Nacimiento__c: string
    P05_Motivo_de_consulta__c: string
    P05_No_poliza__c: string
    P05_Razon_Social__c: string
    P05_RFC__c: string
    P05_Sexo__c: string
    P05_Telefono__c: string
    Paciente_que_lo_recomienda__c: string
    Palabra_clave__c: string
    Pa_s__c: string
    Reagno_de_edades__c: string
    Recursos__c: string
    Referencia_numero_de_paciente__c: string
    Responsable_Asistencia_a_Paciente__c: string
    Responsable_Marketing__c: string
    Seguro_Antiguedad__c: string
    SystemModstamp: string
    Tasa_de_conversi_n__c: string
    Ultima_Actividad_Reporte_Embudo__c: string
    ultima_cita_en__c: string
    ID_Oddo__c: null
}