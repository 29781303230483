<form [formGroup]="_FormCandidato" (ngSubmit)="_onSubmitForm()">
    <!--  -->
    <div class="card">
        <div class="card-header bg-light">
            <h2 class="card-title fw-bold fs-14">Datos obligatorios</h2>                 
        </div>
        <div class="card-body">
            <div class="row">
                <!--  -->
                <div class="col-xl-6 col-lg-6 col-md-6"> 
                    <div class="mb-3">
                        <label class="form-label"> <span class="text-danger">*</span> Nombre de candidato    </label>                
                        <input 
                        type="text" 
                        formControlName="nombre"
                        [ngClass]="{ 'is-invalid': submitted && f['nombre'].errors }"
                        class="form-control">      
                        <div *ngIf="submitted && f['nombre'].errors" class="invalid-feedback">
                            <div *ngIf="f['nombre'].errors['required']">Este campo es requerido</div>                                       
                        </div>          
                    </div>
                </div>
                <!--  -->
                <div class="col-xl-6 col-lg-6 col-md-6">
                    <div class="mb-3">
                        <label class="form-label"><span class="text-danger">*</span>  Apellidos del candidato    </label>                
                        <input 
                        type="text" 
                        formControlName="apellidos"
                        [ngClass]="{ 'is-invalid': submitted && f['apellidos'].errors }"
                        class="form-control">     
                        <div *ngIf="submitted && f['apellidos'].errors" class="invalid-feedback">
                            <div *ngIf="f['apellidos'].errors['required']">Este campo es requerido</div>                                       
                        </div>             
                    </div>
                </div>
                <!--  -->
                <div class="col-xl-6 col-lg-6 col-md-6">
                    <div class="mb-3">
                        <label class="form-label">  Telefono o Celular   <span class="text-danger">*</span> </label>                
                        <input 
                        type="text" 
                        mask="(000) 000 0000"
                        formControlName="telefono"
                        [ngClass]="{ 'is-invalid': submitted && f['telefono'].errors }"
                        class="form-control">      
                        <div *ngIf="submitted && f['telefono'].errors" class="invalid-feedback">
                            <div *ngIf="f['telefono'].errors['required']">Este campo es requerido</div>                                       
                        </div>           
                    </div>
                </div>
                <!--  -->
                <div class="col-xl-6 col-lg-6 col-md-6">
                    <div class="mb-3">
                        <label class="form-label"> Metodo de contacto <span class="text-danger">*</span> </label>                
                        <ng-select
                        [items]="_metodo_contacto"
                        bindLabel="description"
                        bindValue="id"
                        formControlName="metodo_contacto"
                        [ngClass]="{ 'is-invalid': submitted && f['metodo_contacto'].errors }"
                        >
                        </ng-select>        
                        <div *ngIf="submitted && f['metodo_contacto'].errors" class="invalid-feedback">
                            <div *ngIf="f['metodo_contacto'].errors['required']">Este campo es requerido</div>                                       
                        </div>   
                    </div>
                </div> 
            </div>
            <div class="row">
                <div class="col-xl-6 col-lg-6 col-md-6">
                    <div class="mb-3">
                        <label class="form-label"> Origen del candidato <span class="text-danger">*</span> </label>                
                        <ng-select
                        [items]="_origenPaciente"
                        bindLabel="description"
                        bindValue="id"
                        [ngClass]="{ 'is-invalid': submitted && f['origen_paciente'].errors }"
                        formControlName="origen_paciente"
                        >
                        </ng-select>      
                        <div *ngIf="submitted && f['origen_paciente'].errors" class="invalid-feedback">
                            <div *ngIf="f['origen_paciente'].errors['required']">Este campo es requerido</div>                                       
                        </div> 
                    </div>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6">
                    <div class="mb-3">
                        <label class="form-label"> Especialidad <span class="text-danger">*</span> </label>                
                        <ng-select
                        [items]="_especialidad"        
                        formControlName="especialidad"         
                        (ngModelChange)="_onChangeEspecialidad()"               
                        [ngClass]="{ 'is-invalid': submitted && f['especialidad'].errors }"          
                        >
                        </ng-select>       
                        <div *ngIf="submitted && f['especialidad'].errors" class="invalid-feedback">
                            <div *ngIf="f['especialidad'].errors['required']">Este campo es requerido</div>                                       
                        </div>     
                    </div>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6">
                    <div class="mb-3">
                        <label class="form-label"> Centro medico <span class="text-danger">*</span> </label>                
                        <ng-select
                        [items]="_recursos"
                        bindLabel="Name"
                        formControlName="centro_medico"
                        [ngClass]="{ 'is-invalid': submitted && f['centro_medico'].errors }"            
                        (change)="_onChangeEvent($event)">
                        </ng-select>        
                        <div *ngIf="submitted && f['centro_medico'].errors" class="invalid-feedback">
                            <div *ngIf="f['centro_medico'].errors['required']">Este campo es requerido</div>                                       
                        </div>
                    </div>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6">
                    <div class="mb-3">
                        <label class="form-label"> Estado de candidato  </label>                
                        <ng-select
                        [items]="_estadosCandidato"     
                        [ngClass]="{ 'is-invalid': submitted && f['estatus'].errors }"     
                        formControlName="estatus">
                        </ng-select>     
                        <div *ngIf="submitted && f['estatus'].errors" class="invalid-feedback">
                            <div *ngIf="f['estatus'].errors['required']">Este campo es requerido</div>                                       
                        </div>   
                    </div>
                </div>
            </div>
        </div>
    </div>    
    <!--  -->
    <div class="card">
        <div class="card-header bg-light">
            <h2 class="card-title fw-bold fs-14">Personal a cargo</h2>                 
        </div>
        <div class="card-body">
            <div class="row">
                <div class="col-xl-6 col-lg-6 col-md-6">
                    <div class="mb-3">
                        <label class="form-label"> Responsable Redes Sociales </label>                
                        <ng-select
                        [items]="_listaResponsables"
                        bindLabel="Nombre"
                        formControlName="responsable_marketing"
                        bindValue="Id">
                        </ng-select>        
                    </div>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6">
                    <div class="mb-3">
                        <label class="form-label"> Responsable Call Center  </label>                
                        <ng-select
                        [items]="_listaResponsables"
                        bindLabel="Nombre"
                        formControlName="responsable_call_center"
                        bindValue="Id">
                        </ng-select>        
                    </div>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6">
                    <div class="mb-3">
                        <label class="form-label">Comentarios</label>                
                        <textarea
                        class="form-control"
                        formControlName="comentarios"
                        [rows]="3"
                        ></textarea>        
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--  -->
    <div class="card" *ngIf="_show_datos_ubicacion_">
        <div class="card-header bg-light">
            <h2 class="card-title fw-bold fs-14">Datos de ubicacion de candidato</h2>                 
        </div>
        <div class="card-body">
            <div class="row">
                <!--  -->
                <div class="col-xl-6 col-lg-6 col-md-6">
                    <div class="mb-3">
                        <label class="form-label">Código Postal <span class="text-danger">*</span>  </label>                
                        <input 
                        type="text" 
                        mask="00000" 
                        formControlName="codigo_postal"                    
                        [ngClass]="{ 'is-invalid': submitted && f['codigo_postal'].errors }"  
                        (ngModelChange)="_onChangePostalCode($event)"
                        (keypress)="onKeyPress($event.keyCode)"
                        class="form-control">         
                         <div *ngIf="submitted && f['codigo_postal'].errors" class="invalid-feedback">
                            <div *ngIf="f['codigo_postal'].errors['required']">Este campo es requerido</div>                                       
                        </div>       
                    </div>
                </div>
                <!--  -->
                <div class="col-xl-6 col-lg-6 col-md-6">
                    <div class="mb-3">
                        <label class="form-label"> País</label>                
                        <ng-select
                        [items]="_paiese"
                        bindLabel="description"
                        bindValue="id"
                        formControlName="pais">
                        </ng-select>        
                    </div>
                </div>
                <!--  -->
                <div class="col-xl-6 col-lg-6 col-md-6">
                    <div class="mb-3">
                        <label class="form-label"> Estado</label>                
                        <ng-select
                        [items]="_estados"
                        bindLabel="description"
                        bindValue="id"
                        formControlName="estado">
                        </ng-select>        
                    </div>
                </div>
                <!--  -->
                <div class="col-xl-6 col-lg-6 col-md-6">
                    <div class="mb-3">
                        <label class="form-label"> Municipio  </label>       
                        <ng-select
                        [items]="_municipalitie"
                        formControlName="municipio"
                        ></ng-select>
        <!--                 <div class="mb-3" *ngIf="f['municipio'].value === 'Otros'">                       
                            <label class="control-label">Municipio (otros)</label>
                            <input 
                            type="text" 
                            formControlName="municipio_otro"
                            class="form-control">
                        </div> -->
              
                    </div>
                </div>
                <!--  -->
                <div class="col-xl-6 col-lg-6 col-md-6">
                    <div class="mb-3">
                        <label class="form-label">Calle </label>                
                        <input type="text" formControlName="calle" class="form-control">                
                    </div>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6">
                    <div class="mb-3">
                        <div class="form-group ">
                            <label class="form-label">Colonia </label>        
                            <ng-select
                            [items]="_colonias"
                            formControlName="colonia"
                            bindLabel="colonia"
                            bindValue="colonia"                
                            ></ng-select>      
                        </div>
                        <!-- <input type="text" formControlName="colonia" class="form-control">    -->  
                         
                        <div *ngIf="f['colonia'].value === 'OTRO' " >
                            <div class="form-group">
                                <label class="control-label">Nombre de colonia</label>
                                <input type="text" class="form-control" formControlName="colonia_otro" >
                            </div>
                        </div>
                    </div>
                </div>
                <!--  -->
                <div class="col-xl-6 col-lg-6 col-md-6">
                    <div class="mb-3">
                        <label class="form-label">Numero Exterior </label>                
                        <input type="text" formControlName="num_exterior" class="form-control">                
                    </div>
                </div>
                <!--  -->
                <div class="col-xl-6 col-lg-6 col-md-6">
                    <div class="mb-3">
                        <label class="form-label"> Numero Interior </label>                
                        <input type="text" formControlName="num_interior" class="form-control">                
                    </div>
                </div>
            </div>
        </div>
    </div>

    <fieldset *ngIf="_candidato_id">
        <div *ngIf="f['especialidad'].value === 'Proctología' || f['especialidad'].value === 'Coloproctología' ">
            <app-wizard-calificacion 
            [_candidato_id]="_candidato_id" 
            [_calificacionCandidato]="_calificacionCandidato"
            ></app-wizard-calificacion>
        </div>
    
        <div *ngIf="f['especialidad'].value === 'Varices' || f['especialidad'].value === 'Várices' || f['especialidad'].value === 'Varices avanzadas' ">
            <app-calificacion-varices [_candidato_id]="_candidato_id" 
            [_calificacion]="_calificacionVarices"></app-calificacion-varices>
        </div>  
    </fieldset>

    <fieldset class="mt-4">
        <div class="d-flex justify-content-end">
            <button type="submit" class="btn m-2 " [ngClass]="_dataCandidato && _dataCandidato.Candidato_Id ? 'btn-secondary': 'btn-primary' " [disabled]="_loader" >{{_dataCandidato && _dataCandidato.Candidato_Id ? 'Actualizar': 'Guardar' }}</button>
            <button type="button" class="btn btn-danger  m-2 " (click)="onCancel()"> Cancelar</button>
        </div>        
    </fieldset>   

</form>
<ngx-spinner [name]="_spinner" type="ball-scale-multiple" [fullScreen]="false"  ></ngx-spinner>