import { Component, EventEmitter, Output, Input } from '@angular/core';
import * as _ from 'lodash';
import { Lightbox } from 'ngx-lightbox';
import { ToastrService } from 'ngx-toastr';
import { WebcamImage, WebcamInitError, WebcamUtil } from 'ngx-webcam';
import { Observable, Subject } from 'rxjs';

@Component({
  selector: 'app-ng-web-cam',
  templateUrl: './ng-web-cam.component.html',
  styleUrls: ['./ng-web-cam.component.scss']
})
export class NgWebCamComponent {

  @Input()
  allowOnlyOneImg: boolean = false
  @Input()
  showBtnSaveIt: boolean = true

  @Output()
  eventSaveImages: EventEmitter<any[]> = new EventEmitter<any[]> ()

  Base64images: { src: string; thumb: string; caption: string }[] = [];
  _webcamImage: WebcamImage[] = []
  
  //#region WebCam
  public showWebcam = false;
  public allowCameraSwitch = false;
  public multipleWebcamsAvailable = false;
  public deviceId: string;
  
  public videoOptions: MediaTrackConstraints = {
    width: {ideal: 1024},
    height: {ideal: 576}
  };
  
  public errors: WebcamInitError[] = [];
  
  // latest snapshot
  public webcamImage: WebcamImage | null
  _pictures: any[] = []
      
  // webcam snapshot trigger
  private trigger: Subject<void> = new Subject<void>();
  // switch to next / previous / specific webcam; true/false: forward/backwards, string: deviceId
  private nextWebcam: Subject<boolean|string> = new Subject<boolean|string>();
  //#endregion

  _showCamera!: boolean;

  //pictureTaken:


  constructor (
    private lightbox: Lightbox,
    private toastr: ToastrService
  ) {}

  initWebCam(){
    WebcamUtil
    .getAvailableVideoInputs()
    .then((mediaDevices: MediaDeviceInfo[]) => {
        this.multipleWebcamsAvailable = mediaDevices && mediaDevices.length > 1;
        this._showCamera = true
        this.showWebcam = true
    }); 
  }

//#region ngxWebCam

  public triggerSnapshot(): void {
    this.trigger.next();
  }

  public toggleWebcam(): void {
    this._showCamera = !this._showCamera;
  }

  public handleInitError(error: WebcamInitError): void {
    this.errors.push(error);
  }

  public showNextWebcam(directionOrDeviceId: boolean|string): void {
    // true => move forward through devices
    // false => move backwards through devices
    // string => move to device with given deviceId
    this.nextWebcam.next(directionOrDeviceId);
  }

  public handleImage(webcamImage: WebcamImage): void {
    console.info('received webcam image', webcamImage);
    //this.showWebcam = !this.showWebcam;
    if( this.allowOnlyOneImg && (this._webcamImage && this._webcamImage.length === 1 )){
      this.toastr.warning('Solo se permite una imagen ','Atención', {
        timeOut: 3200
      })

      return console.log('Solo se permite una Imagen');
      
    }
    //this.pictureTaken.emit(webcamImage);
    this._webcamImage.push(webcamImage)
    this.webcamImage = webcamImage

    this.Base64images.push({
      src: String(webcamImage.imageAsDataUrl),
      caption: `IML ${new Date().toISOString()}`,
      thumb: `IML ${new Date().toISOString()}`,
    })

   this._pictures.push({
    filename: `IML ${new Date().toISOString()}`,
    src: webcamImage.imageAsDataUrl,
    file:webcamImage,
    _shoComentarios: false,
    comentario: null
   })

   if(!this.showBtnSaveIt) {
    this.eventSaveImages.emit(this._pictures)
   }

  }

  public cameraWasSwitched(deviceId: string): void {
    console.log('active device: ' + deviceId);
    this.deviceId = deviceId;
  }

  public get triggerObservable(): Observable<void> {
    return this.trigger.asObservable();
  }

  public get nextWebcamObservable(): Observable<boolean|string> {
    return this.nextWebcam.asObservable();
  }

  openBase64(index: number) {
    this.lightbox.open(this.Base64images, index, { });
  }

  //#endregion

  /**
   * 
   * @param _index 
   */
  _deleteImg(_index: number) {

    this._pictures.splice(_index,1)
    this.Base64images.splice(_index, 1)
    this._webcamImage.splice(_index, 1)

    if(!this.showBtnSaveIt) {
      this.eventSaveImages.emit(this._pictures)
     }

  }
  _uploadImages() : void {
    this.eventSaveImages.emit(this._pictures)
  }

  /**
   * 
   * @param data 
   * @param index 
   */
  _deleteComentario(data: any, index: number) {
    data._shoComentarios= false
    data.comentario= null

    this._pictures[index] = data
  }

}
