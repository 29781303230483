<form [formGroup]="_form_" *ngIf="_form_">
    <div class="row">
        <div class="col-sm-4">
            <div class="form-check form-switch form-switch-lg" dir="ltr">
                <label class="form-check-label" for="customSwitchsizelg">Mostrar citas por día</label>
                <input type="checkbox" formControlName="showDays" [value]="_showdays" [checked]="_showdays"
                    class="form-check-input" id="customSwitchsizelg" formControlName="showDays"
                    (change)="Showday($event)">
            </div>
        </div>
    </div>
</form>

<div class="row" *ngIf="_showCoutnDown">
    <div class="col-sm-6 col-md-6 col-lg-4 col-xl-4 float-start">
        <app-cita-pendiente></app-cita-pendiente>
    </div>
</div>


<div class="p-2" *ngIf="!_showdays ">
    <app-full-calendar-dashboard (eventShowCoutDown)="_onEventShowCoutDown($event)"></app-full-calendar-dashboard>
    <!-- <app-full-calendar [loaderError]="_loaderError"  [responseData]="responseData" ></app-full-calendar> -->
</div>

<div class="p-2" *ngIf="_showdays">
    <app-calandario-citas-por-dia></app-calandario-citas-por-dia>
</div>