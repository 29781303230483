import { DatePipe } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import * as _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { Subscription, interval, map } from 'rxjs';
import { CredentialsService } from 'src/app/core/services/credentials.service';
import { TokenStorageService } from 'src/app/core/services/token-storage.service';
import { Cita } from 'src/app/pages/calendar/models/calendar';
import { Images } from 'src/app/shared/models/list-images';
import { FormatsService } from 'src/app/shared/services/formats.service';
import { VoiceRecognitionService } from 'src/app/shared/services/voice-recognition.service';

@Component({
  selector: 'app-nota-ingreso',
  templateUrl: './nota-ingreso.component.html',
  styleUrls: ['./nota-ingreso.component.scss']
})
export class NotaIngresoComponent implements OnInit, OnDestroy {

  @Input()
  id: string;

  @Input()
  _formato: any

  public _basic_info: any[] = []


  formato: any = {}

  controls: any[] = []
  _formControls: any[] = []
  _footer: any[] = []

  _LaboratorioFiles: any[] = []

  _label: string;

  _itsReady: boolean;

  record: any;

  _Cita: Cita
  _Paciente: any;

  $interval!: Subscription;
  _BODYFORM: any;

  _Firma: Images[] = []
  currentUser: any

  public formNotaIngreso: FormGroup = this.formBuilder.group({})

  constructor(
    private formatService: FormatsService,
    private date: DatePipe,
    private toastr: ToastrService,
    public VoiceS: VoiceRecognitionService,
    private credentials: CredentialsService,
    private formBuilder: FormBuilder,
    private tokenService: TokenStorageService
  ) {
   // this.VoiceS.init()
    this.record = this.credentials.userInfoData.data.records[0]

    this._Paciente = this.credentials.userInfoData.data?.records[0]

    const _cita = this.credentials.getCitaJson() //this._session._get
    if (_cita) {
      this._Cita = _cita
    }

    this.currentUser = this.tokenService.getUser()
  }


  ngOnInit(): void {
    this._itsReady = false;
    if (this._formato) {
      this.formato = this._formato //response.data.formato;

      if (this.formato.form) {
        this._label = this._formato.label//response.data.formato.label;
        this.controls = this.formato.form.controls;

        this.initForm()
        this._loadImages()
        this._itsReady = true;
      }
    }
  }

  private _loadImages() {

    this.formatService._getListImages(this._Cita.Cita_Id, this._Paciente.Name).subscribe({
      next: (response) => {
        if (response.codigo == 200) {
          console.log({ response });

          const data = response.data

          if (data.formato && data.formato.archivos) {
            const archivos = data.formato.archivos

            //   this._Images = archivos.Paciente ? archivos.Paciente : [];         

            this._LaboratorioFiles = archivos.Laboratorio ? archivos.Laboratorio : []

            //console.log({archivos, _Images: this._Images});

          }

          /*       const _FORMATOS = response.data.formato.Formatos
                if(_FORMATOS) {            
                  _.map(_FORMATOS[0].form.controls, (a) => {              
                      if(a.archivos) {
                        this._Images.push(a)
                      }                
                  })     
                }    */
        }
      },
      error: (error: any) => {
        console.error({ error });
      }
    })


    /**
 * Cargamos la firma del doctor
 */
    this.formatService._getImageProfile(this.currentUser.username).subscribe({
      next: (response) => {
        if (response.codigo == 200) {
          this._Firma = response.data.formato.archivos.Firma;

        }

      }
    })
  }

  ngOnDestroy(): void {
    if (this.$interval) {
      this.$interval.unsubscribe()
    }
  }

  get f() {
    return this.formNotaIngreso.controls
  }

  /**
   * 
   */
  private initForm() {

    _.map(this.controls, (a) => {

      _.map(a.Objects, (b) => {
        this.formNotaIngreso.addControl(
          b.name, this.formBuilder.control(b.value)
        )

        if (b.type == 'label') {
          _.map(b.Objects, (c) => {
            this.formNotaIngreso.addControl(
              c.name, this.formBuilder.control(c.value)
            )
          })
        }
      })
    })

    this.createInterval()
    //console.log({formNotaIngreso: this.formNotaIngreso.controls});    
  }

  /**
   * 
   */
  private createInterval() {

    const myObservable = interval(1000);
    this.$interval = myObservable.subscribe((integer) => {
      if (integer === 20) {
        this.$interval.unsubscribe();
        console.log('GO SAVE IT!!!!');
        //this.show = !this.show;       
        //this._onSaveIt()     
        this.newSaveFormat()
      }
    })
  }

  /**
   * 
   */
  private newSaveFormat(): void {
    let _formValues = this.formNotaIngreso.value
    let _body_: any[] = []

    _.map(_formValues, (a, i) => {
      _body_.push({
        name: i,
        value: a
      })
    })


    let _bodyForm = {
      Cita_Id: this._Cita.Cita_Id,
      label: this._formato.label,
      new_data: _body_
    }

    this._saveFormatValues(_bodyForm, true)
  }

  /**
   * 
   * @param _jsonObject 
   * @param createInterval 
   */
  _saveFormatValues(_jsonObject: any, createInterval: boolean = false): void {

    this.formatService._postFormato(_jsonObject).then((resp) => {
      this.toastr.info('Se ha guardado la información', 'Guardada', {
        timeOut: 3200
      }).onHidden.subscribe(() => {
        if (createInterval) {
          this.createInterval()
        }
      })


    }).catch((error) => {
      console.error({ error });
    })

  }


  /**
   * 
   * @param name 
   */
  _getValue(name: string) {

    switch (name) {
      case 'name':
        return this.record.Nombre_completo__c
      case 'age':
        return this.record.P05_Edad__c;
      case 'sex':
        return this.record.P05_Sexo__c;
      case 'n_expediente':
        return this.record.Name;
      case 'birth_date':
        return this.record.P05_Fecha_de_Nacimiento__c
      case 'fecha':
        return this.record.CreatedDate //this.date.transform(, 'yyyy-MM-dd')
      case 'hora':
        return this.date.transform(this.record.CreatedDate, 'hh:mm a')

      default:
        return 'N/D'
        break;
    }
  }

  private _onSaveIt() {

    let _formValues = this.formNotaIngreso.value

    console.log({ _FORMVALUES: _formValues });
    console.log({ _FOMATO: this.formato });

    let _formato = this.formato

    _.map(_formato.form.controls, (a) => {

      if (a.type == 'subtitle') {
        _.map(a.Objects, (b) => {
          if (b.type == 'label') {
            _.map(b.Objects, (c) => {
              console.log({ LABEL: b });

              c.value = this.f[c.name].value
            })
          } else {
            b.value = this.f[b.name].value
          }
        })
      }
    })

    // TODO

    /**
     * Los valores del Radio buttton estan mal -- Desde el Formato pacientes
     */

    let _bodyForm = {
      Cita_Id: this._Cita.Cita_Id,
      label: this.formato.label,
      form: _formato.form
    }

    this.formatService._postFormato(_bodyForm).then((resp) => {
      this.toastr.info('Se ha guardado la información', 'Guardada', {
        timeOut: 3200
      })
      this.$interval.unsubscribe()
      setTimeout(() => {
        //  this.createInterval()     
      }, 5000);

    }).catch((error) => {
      console.error({ error });
    })



    console.log({ _BODYFORM: _bodyForm });

    this._BODYFORM = _bodyForm

  }

  /**
* 
* @param filePdf 
*/
  _openPDF(filePdf: any) {
    console.log({ filePdf });

    setTimeout(() => {
      window.open(filePdf.url, '_blank', 'width=1000, height=800');
    }, 100);

  }

  _onEventEmmiterValue(text: string, control: any) {
    console.log({ _TEXT________: text, control });
    this.f[control.name].patchValue(text)

  }
}
