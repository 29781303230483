import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { NgbDropdownModule, NgbNavModule, NgbOffcanvasModule, NgbToastModule } from '@ng-bootstrap/ng-bootstrap';
import { SimplebarAngularModule } from 'simplebar-angular';
import { LanguageService } from '../core/services/language.service';
import { TranslateModule } from '@ngx-translate/core';

// Feather Icon
import { FeatherModule } from 'angular-feather';

import { defineElement } from 'lord-icon-element';
import lottie from 'lottie-web';

import { allIcons } from 'angular-feather/icons';

// Component pages
import { LayoutComponent } from './layout.component';
import { VerticalComponent } from './vertical/vertical.component';
import { TopbarComponent } from './topbar/topbar.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { FooterComponent } from './footer/footer.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { JwtInterceptor } from '../core/helpers/jwt.interceptor';
import { ErrorInterceptor } from '../core/helpers/error.interceptor';
import { ReactiveFormsModule } from '@angular/forms';
import { CanvasSearchSMComponent } from './topbar/canvas-search-sm/canvas-search-sm.component';
import { SharedModule } from '../shared/shared.module';


@NgModule({
  declarations: [
    LayoutComponent,
    VerticalComponent,
    TopbarComponent,
    SidebarComponent,    
    FooterComponent, CanvasSearchSMComponent           
  ],
  imports: [
    CommonModule,
    RouterModule,
    SharedModule,
    NgbDropdownModule,
    NgbNavModule,
    SimplebarAngularModule,
    TranslateModule,
    NgbToastModule,
    ReactiveFormsModule,
    
    NgbOffcanvasModule,
    FeatherModule.pick(allIcons),
  ],
  providers: [LanguageService,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }
  ]
})
export class LayoutsModule { 
  constructor() {
    defineElement(lottie.loadAnimation);
  }
}
