<div class="card" *ngIf="_formato && _itsReady">
<div class="card-body">
    <form [formGroup]="formEnfermeriaOperatorios" class="needs-validation createfile-form" autocomplete="off" novalidate>
        <div class="row g-2 mt-4" *ngFor="let control of _controls">
            <ng-container *ngIf="control.type == 'frame'; else titleTemplate ">
                 <!-- TITULOS -->
                 <div class="bg-primary">
                    <h4 class="fs-4 text-white bold text-uppercase text-center">{{control.label}} </h4>     
                </div>

            </ng-container>

            <ng-template #titleTemplate>
                <div class="col-12 ">
                    <h4 class="fs-4 text-primary bold text-uppercase text-center">{{control.label}} </h4>     
                </div>

                <div class="col-12" *ngIf="control.name == 'PreOperatorios' ">
                    <div class="row g-2">                        
                        <div  *ngFor="let objects of control.Objects" class="col-12"  >                            
                            <div class="card">                           
                                <div class="card-body">
                                    <div class="bg-success">
                                        <h4 class="fs-16 text-white bold text-uppercase text-center">{{objects.label}} </h4>     
                                    </div>
                                    <div class="row g-2">
                                        <div  *ngFor="let objs of objects.Objects" [ngClass]="objs.name == 'nota_enfermeria_PreOperatorios' ? 'col-12' : 'col-6' " >

                                            <div class="bg-light text-center">
                                                <!-- <h4 class="fs-16 text-white bold text-uppercase text-center">{{objs.label}} -- {{objs.name}} </h4>    -->  
                                                <p class="fw-normal">{{objs.label}}</p>
                                            </div>

                                            <div *ngIf="objs.name != 'nota_enfermeria_PreOperatorios'" class="row g-2">
                                                <div  *ngFor="let _obj of objs.Objects" 
                                                [ngClass]=" _obj.type == 'text' || _obj.type == 'label' ? objs.name == 'signos_vitales_PreOperatorios' || objs.name == 'medicamentos_PreOperatorios' ? 'col-6' :  'col-12' : 'col-md-6 col-lg-6' " >

                                                    <div *ngIf="_obj.type== 'checkbox' ">
                                                        <div class="form-check mb-2">
                                                            <input class="form-check-input" 
                                                            type="checkbox"      
                                                            [formControlName]="_obj.name" 
                                                            [required]="_obj.required"
                                                            [readonly]="_obj.readonly"                                                     
                                                            [id]="_obj.name">
                                                            <label class="form-check-label" [for]="_obj.name">
                                                                {{_obj.label}}
                                                            </label>
                                                        </div>  
                                                    </div>

                                                
                                                    <div *ngIf="_obj.type == 'text' || _obj.type == 'time' ">
                                                        <div class="form-floating">
                                                            <input
                                                            [type]="_obj.type"
                                                            [name]="_obj.name"
                                                            [formControlName]="_obj.name"
                                                            [required]="_obj.required"
                                                            [readonly]="_obj.readonly"                                                       
                                                            class="form-control">
                                                            <label class="form-label bold text-uppercase"> {{_obj.label}} </label>
                                                        </div>
                                                    </div>

                                                    <div *ngIf="_obj.type == 'label' ">
                                                        <p class="fw-normal">{{_obj.label}}</p>
                                                        <div class="row g-2">
                                                            <div *ngFor="let _objs of _obj.Objects" [ngClass]="_objs.type == 'checkbox' ? 'col-3' : 'col-6' "  >
                                                                <ng-container *ngIf="_objs.type == 'text' || _objs.type == 'time'">
                                                                    <div class="form-floating">
                                                                        <input
                                                                        [type]="_objs.type"
                                                                        [name]="_objs.name"
                                                                        [formControlName]="_objs.name"
                                                                        [required]="_objs.required"
                                                                        [readonly]="_objs.readonly"                                                       
                                                                        class="form-control">
                                                                        <label class="form-label bold text-uppercase"> {{_objs.label}} </label>
                                                                    </div>
                                                                </ng-container>

                                                                <ng-container *ngIf="_objs.type == 'checkbox' ">
                                                                    <div class="form-check mb-2">
                                                                        <input class="form-check-input" 
                                                                        type="checkbox"             
                                                                        [formControlName]="_objs.name"
                                                                        [required]="_objs.required"
                                                                        [readonly]="_objs.readonly"                                                 
                                                                        [id]="_objs.name">
                                                                        <label class="form-check-label" [for]="_objs.name">
                                                                            {{_objs.label}}
                                                                        </label>
                                                                    </div>
                                                                </ng-container>                                                                
                                                            </div>
                                                        </div>                                                        
                                                    </div>   
                                                </div>
                                            </div>

                                            <div *ngIf="objs.name == 'nota_enfermeria_PreOperatorios'">
                                                <div class="row g-2">
                                                    <div *ngFor="let _obj of objs.Objects" [ngClass]="_obj.type == 'textarea' ? 'col-12': 'col-6' " >
                                                        <ng-container *ngIf="_obj.name != 'firma_nota_enfermeria_preoperatorios'; else firmaTemplate ">
                                                            <div *ngIf="_obj.type == 'textarea' ">
                                                                <label class="form-label"> {{_obj.label}} </label>
                                                                <app-text-area-voice-recorder 
                                                                [name]="_obj.name"      
                                                                [transcript]="formEnfermeriaOperatorios.controls[_obj.name].value"                  
                                                                [required]="_obj.required"                                        
                                                                ngDefaultControl 
                                                                [formControlName]="_obj.name"
                                                                [rows]="4"                            
                                                                (eventEmmiterValue)="_onEventEmmiterValue($event, _obj)">
                                                                </app-text-area-voice-recorder>
                                                         <!--        <textarea
                                                                class="form-control"
                                                                [name]="_obj.name"
                                                                [value]="_obj.value"
                                                                [formControlName]="_obj.name"
                                                                [required]="_obj.required"
                                                                [readonly]="_obj.readonly"  
                                                                [rows]="4"
                                                                >
                                                                </textarea>    -->
                                                            </div>
                                                        </ng-container>
                                                        <ng-template #firmaTemplate>
                                                            <fieldset>
                                                                <legend class="fs-14 text-muted">{{_obj.label}} </legend>
                                                                <div class="card" style="border: none;">
                                                                    <div class="card-body">
                                                                        <app-canvas-firma-doctor></app-canvas-firma-doctor>
                                                                    </div>
                                                                </div>
                                                            </fieldset>
                                                        </ng-template>
                                                    </div>

                                                </div>

                                            </div>
                                        
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-12" *ngIf="control.name == 'TransOperatorio'">
                    <div class="row g-2">
                        <div  *ngFor="let objects of control.Objects" 
                        [ngClass]="objects.name == 'personal_enfermeria_transOperatorio' || 
                                   objects.name == 'tiempo_fuera_transOperatorio' ||
                                   objects.name == 'horarios_transOperatorio' ?  'col-md-6 col-lg-4' :   'col-12'  "  >
                            <div class="card">
                                <div class="card-body">

                                    <div class="bg-success">
                                        <h4 class="fs-16 text-white bold text-uppercase text-center">{{objects.label}} </h4>     
                                    </div>

                                    <ng-container 
                                    *ngIf="objects.name == 'personal_enfermeria_transOperatorio' ||
                                           objects.name == 'tiempo_fuera_transOperatorio' || 
                                           objects.name == 'horarios_transOperatorio'">  
                                        <div class="row g-2">                                            
                                            <div class="col-12" *ngFor="let objs of objects.Objects">
                                                <div class="bg-light text-center">
                                                    <p class="fw-normal">{{objs.label}}</p>
                                                </div>
                                                <div class="row g-2">
                                                    <div class="col-6" *ngFor="let _obj of objs.Objects">
                                                        <div *ngIf="_obj.type== 'checkbox' ">
                                                            <div class="form-check mb-2">
                                                                <input class="form-check-input" 
                                                                type="checkbox"   
                                                                [required]="_obj.required"
                                                                [readonly]="_obj.readonly"   
                                                                [formControlName]="_obj.name"                                                     
                                                                [id]="_obj.name">
                                                                <label class="form-check-label" [for]="_obj.name">
                                                                    {{_obj.label}}
                                                                </label>
                                                            </div>  
                                                        </div>
                                                        <div *ngIf="_obj.type == 'text' || _obj.type == 'time' ">
                                                            <div class="form-floating">
                                                                <input
                                                                [type]="_obj.type"
                                                                [name]="_obj.name"
                                                                [formControlName]="_obj.name"
                                                                [required]="_obj.required"
                                                                [readonly]="_obj.readonly"                                                       
                                                                class="form-control">
                                                                <label class="form-label bold text-uppercase"> {{_obj.label}} </label>
                                                            </div>
                                                        </div>
                                                        
                                                    </div>
                                                </div>  
                                                <ng-container *ngIf="objs.type == 'text' || objs.type == 'time' ">
                                                    <div class="form-floating">
                                                        <input
                                                        [type]="objs.type"
                                                        [name]="objs.name"
                                                        [formControlName]="objs.name"
                                                        [required]="objs.required"
                                                        [readonly]="objs.readonly"                                                       
                                                        class="form-control">
                                                       <!--  <label class="form-label bold text-uppercase"> {{objs.label}} </label> -->
                                                    </div>
                                                </ng-container>
                                            </div>                                            
                                        </div>
                                    </ng-container>

                                    <ng-container *ngIf="objects.name == 'signos_vitales_transOperatorio' " >
                                        <div class="row g-2">
                                            <div class="col-12" *ngFor="let objs of objects.Objects">        
                                              <div class="row g-2">
                                                <div class="col-3">
                                                    <div class="bg-light text-center">
                                                        <p class="fw-bold">{{objs.label}}</p>
                                                    </div> 
                                                </div>    
                                                
                                                <div class="col-9">
                                                    <div class="row g-2 d-flex justify-content-between">
                                                        <div class="col" *ngFor="let _obj of objs.Objects">
                                                            <div class="form-floating">
                                                                <input
                                                                [type]="_obj.type"
                                                                [name]="_obj.name"
                                                                [formControlName]="_obj.name"
                                                                [required]="_obj.required"
                                                                [readonly]="_obj.readonly"                                                       
                                                                class="form-control">
                                                                <label class="form-label bold text-uppercase"> {{_obj.label}} </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                              </div>
                                            </div>
                                        </div>
                                    </ng-container> 

                                    <ng-container 
                                    *ngIf="objects.name == 'cuenta_material_transOperatorio' || 
                                           objects.name == 'placa_cauterio_transOperatorio'">
                                        <ng-container *ngIf="objects.name == 'cuenta_material_transOperatorio'; else _template_placa" >

                                            <div class="row g-2">
                                                <div class="col-12" *ngFor="let objs of objects.Objects">
                                                    <div class="row g-2">
                                                        <div class="col-12" *ngFor="let _obj of objs.Objects">
                                                            <div class="row g-2">
                                                                <div class="col-3">
                                                                    <div class="bg-light text-center">
                                                                        <p class="fw-normal">{{_obj.label}}</p>
                                                                    </div> 
                                                                </div>
                                                                <div class="col-9">
                                                                    <div class="row g-2 d-flex justify-content-between">
                                                                        <div class="col" *ngFor="let _objs of _obj.Objects">
                                                                            <div class="form-floating">
                                                                                <input
                                                                                [type]="_objs.type"
                                                                                [name]="_objs.name"
                                                                                [formControlName]="_objs.name"
                                                                                [required]="_objs.required"
                                                                                [readonly]="_objs.readonly"                                                       
                                                                                class="form-control">
                                                                                <label class="form-label bold text-uppercase"> {{_objs.label}} </label>
                                                                            </div>
                                                                        </div>    
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    
                                                  <!--     -->
                                                </div>
                                            </div>
                                        
                                        </ng-container>

                                        <ng-template #_template_placa>
                                            <div class="row g-2">                                            
                                                <div class="col-12" *ngFor="let objs of objects.Objects">
                                                    <div class="row g-2">
                                                        <div class="col-3">
                                                            <div class="bg-light text-center">
                                                                <p class="fw-normal">{{objs.label}}</p>
                                                            </div> 
                                                        </div>
                                                        <div class="col-9">
                                                            <div class="row g-2 d-flex justify-content-between">
                                                                <div class="col" *ngFor="let _obj of objs.Objects">
                                                                    <div class="form-floating">
                                                                        <input
                                                                        [type]="_obj.type"
                                                                        [name]="_obj.name"
                                                                        [formControlName]="_obj.name"
                                                                        [required]="_obj.required"
                                                                        [readonly]="_obj.readonly"                                                       
                                                                        class="form-control">
                                                                        <label class="form-label bold text-uppercase"> {{_obj.label}} </label>
                                                                    </div>
                                                                </div>    
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>                                            
                                            </div>
                                        </ng-template>   
                                    </ng-container>

                                    <ng-container *ngIf="objects.name == 'medicamentos_transoperatorios' || objects.name == 'soluciones_transoperatorio'">                                 
                                        <div class="row g-2">
                                            <div class="col-xl-3 col-md-4" *ngFor="let objs of objects.Objects">
                                                <div class="bg-light text-center">
                                                    <p class="fw-normal">{{objs.label}}</p>     
                                                </div>
                                                <ng-container *ngIf="objs.type == 'text' || objs.type == 'time' ">
                                                    <div class="form-floating">
                                                        <input
                                                        [type]="objs.type"
                                                        [name]="objs.name"
                                                        [formControlName]="objs.name"
                                                        [required]="objs.required"
                                                        [readonly]="objs.readonly"                                                       
                                                        class="form-control">
                                                       <!--  <label class="form-label bold text-uppercase"> {{objs.label}} </label> -->
                                                    </div>
                                                </ng-container>
                                            </div>
                                        </div>                                    
                                    </ng-container>

                                    <ng-container *ngIf="objects.name == 'sangre_hemoderivados_transoperatorios' ">
                                        <div class="row g-2">
                                            <div *ngFor="let objs of objects.Objects" class="col-xl-3 col-md-4">
                                                <div class="bg-light text-center">
                                                    <p class="fw-normal">{{objs.label}}</p>     
                                                </div>

                                                <ng-container *ngIf="objs.type == 'text' || objs.type == 'time' ">
                                                    <div class="form-floating">
                                                        <input
                                                        [type]="objs.type"
                                                        [name]="objs.name"
                                                        [formControlName]="objs.name"
                                                        [required]="objs.required"
                                                        [readonly]="objs.readonly"                                                       
                                                        class="form-control">
                                                    </div>
                                                </ng-container>

                                                <ng-container *ngIf="objs && objs.Objects">
                                                    <div class="row g-2">
                                                        <div class="col-6" *ngFor="let _obj of objs.Objects" >
                                                            <ng-container *ngIf="_obj.type == 'text' || _obj.type == 'time' ">
                                                                <div class="form-floating">
                                                                    <input
                                                                    [type]="_obj.type"
                                                                    [name]="_obj.name"
                                                                    [formControlName]="_obj.name"
                                                                    [required]="_obj.required"
                                                                    [readonly]="_obj.readonly"                                                       
                                                                    class="form-control">
                                                                    <label class="form-label bold text-uppercase"> {{_obj.label}} </label>
                                                                </div>
                                                            </ng-container>
                                                        </div>
                                                    </div>
                                                </ng-container>


                                            </div>
                                        </div>
                                    </ng-container>                                  
                           
                                    <!-- TRANS OPERATORIO 2 -->
                                    <ng-container *ngIf="objects.name == 'transoperatorio_2' ">
                                        <div class="row g-2">
                                            <div *ngFor="let objs of objects.Objects" 
                                            [ngClass]="
                                            objs.name == 'proceso_atencion_enfermeria_transoperatorio' ||
                                            objs.name == 'tabla_observaciones_transoperatorio' || 
                                            objs.name == 'incidente_transoperatorio_transoperatorio' ||
                                            objs.name == 'transferencia_paciente_transoperatorio' ? 'col-12':  'col-6'  " >

                                                <div class="bg-light text-center">
                                                    <p class="fw-normal">{{objs.label}}</p>
                                                </div>

                                                <ng-container *ngIf="
                                                objs.name == 'proceso_atencion_enfermeria_transoperatorio' ||
                                                objs.name == 'tabla_observaciones_transoperatorio' || 
                                                objs.name == 'incidente_transoperatorio_transoperatorio' ||
                                                objs.name == 'transferencia_paciente_transoperatorio'; else _inputsTemplate">                                                
                                                    <div *ngIf="objs.name == 'proceso_atencion_enfermeria_transoperatorio' ">
                                                        <div class="row g-2">
                                                            <div class="col-md-6 col-lg-4 col-xl-3" *ngFor="let _obj of objs.Objects">
                                                                <div class="bg-primary text-white text-center">
                                                                    <p class="fw-normal">{{_obj.label}}</p>
                                                                </div>
                                                                <div class="row g-2">
                                                                    <div class="col-12" *ngFor="let _objs of _obj.Objects">
                                                                        <div *ngIf="_objs.type == 'text' || _objs.type == 'time' ">
                                                                            <div class="form-floating">
                                                                                <input
                                                                                [type]="_objs.type"
                                                                                [name]="_objs.name"
                                                                                [formControlName]="_objs.name"
                                                                                [required]="_objs.required"
                                                                                [readonly]="_objs.readonly"                                                       
                                                                                class="form-control">
                                                                                <label class="form-label bold text-uppercase"> {{_objs.label}} </label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
    
                                                            </div>                                                          
                                                        </div>
                                                    </div>       
                                                    
                                                    <div *ngIf="objs.name == 'tabla_observaciones_transoperatorio' || objs.name == 'transferencia_paciente_transoperatorio' ">
                                                        <div class="row g-2">
                                                            <div class="col-12" *ngFor="let _obj of objs.Objects" [ngClass]="_obj.Objects ? 'col-md-12 col-xl-6' : 'col-12' " >
                                                                <ng-container *ngIf="_obj && _obj.Objects; else _observationsTemplate">
                                                                    <div class="bg-primary text-white text-center">
                                                                        <p class="fw-normal">{{_obj.label}}</p>
                                                                    </div>
                                                                    <div class="row g-2">
                                                                        <div *ngFor="let _objs of _obj.Objects" [ngClass]="_objs.type == 'checkbox' ? 'col-6' : 'col-12'  " >
                                                                            
                                                                            <div *ngIf="_objs.type == 'checkbox' ">
                                                                                <div class="form-check mb-2">
                                                                                    <input class="form-check-input" 
                                                                                    type="checkbox"                                               [formControlName]="_objs.name"        
                                                                                    [id]="_objs.name">
                                                                                    <label class="form-check-label" [for]="_objs.name">
                                                                                        {{_objs.label}}
                                                                                    </label>
                                                                                </div>  
                                                                            </div>

                                                                            <div *ngIf="_objs.type == 'text' || _objs.type == 'time' " >
                                                                                <div class="form-floating">
                                                                                    <input
                                                                                    [type]="_objs.type"
                                                                                    [name]="_objs.name"
                                                                                    [formControlName]="_objs.name"
                                                                                    [required]="_objs.required"
                                                                                    [readonly]="_objs.readonly"                                                       
                                                                                    class="form-control">
                                                                                    <label class="form-label bold text-uppercase"> {{_objs.label}} </label>
                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                </ng-container>
                                                                <ng-template #_observationsTemplate>
                                                                    <div *ngIf="_obj.type == 'text' || _obj.type == 'time' ">
                                                                        <div class="form-floating">
                                                                            <input
                                                                            [type]="_obj.type"
                                                                            [name]="_obj.name"
                                                                            [formControlName]="_obj.name"
                                                                            [required]="_obj.required"
                                                                            [readonly]="_obj.readonly"                                                       
                                                                            class="form-control">
                                                                            <label class="form-label bold text-uppercase"> {{_obj.label}} </label>
                                                                        </div>
                                                                    </div>
                                                                </ng-template>                                                            
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div *ngIf="objs.name == 'incidente_transoperatorio_transoperatorio' ">
                                                        <div class="row g-2">
                                                            <div class="col-12" *ngFor="let _obj of objs.Objects">
                                                                <div *ngIf="_obj.type == 'textarea' ">   
                                                                    <app-text-area-voice-recorder 
                                                                    [transcript]="f[_obj.name].value"
                                                                    [name]="_obj.name"                            
                                                                    [required]="_obj.required"                            
                                                                    ngDefaultControl 
                                                                    [formControlName]="_obj.name"
                                                                    [rows]="6"                            
                                                                    (eventEmmiterValue)="_onEventEmmiterValue($event, _obj)">
                                                                    </app-text-area-voice-recorder>                                                                                                                                                                                                             
                                                                 <!--     <textarea
                                                                     class="form-control"
                                                                     [name]="_obj.name"
                                                                     [formControlName]="_obj.name"
                                                                     [value]="_obj.value"                                                                     
                                                                     [rows]="6"
                                                                     >
                                                                     </textarea>  -->  
                                                                 </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </ng-container>

                                                <ng-template #_inputsTemplate>
                                                    <div class="row g-2">
                                                        <div  *ngFor="let _obj of objs.Objects" [ngClass]="_obj.type == 'checkbox' ? 'col-6' : 'col-12' " >
                                                            <div *ngIf="_obj.type == 'text' || _obj.type == 'time' ">
                                                                <div class="form-floating">
                                                                    <input
                                                                    [type]="_obj.type"
                                                                    [name]="_obj.name"
                                                                    [formControlName]="_obj.name"
                                                                    [required]="_obj.required"
                                                                    [readonly]="_obj.readonly"                                                       
                                                                    class="form-control">
                                                                    <label class="form-label bold text-uppercase"> {{_obj.label}} </label>
                                                                </div>
                                                            </div>
                                                            <div *ngIf="_obj.type == 'textarea' ">
                                                               <!--  <label class="form-label"> {{_obj.label}} </label> -->
                                                               <app-text-area-voice-recorder 
                                                               [transcript]="f[_obj.name].value"
                                                               [name]="_obj.name"                            
                                                               [required]="_obj.required"                            
                                                               ngDefaultControl 
                                                               [formControlName]="_obj.name"
                                                               [rows]="4"                            
                                                               (eventEmmiterValue)="_onEventEmmiterValue($event, _obj)">
                                                               </app-text-area-voice-recorder> 
                                                          <!--       <textarea
                                                                class="form-control"
                                                                [name]="_obj.name"
                                                                [value]="_obj.value"
                                                                [formControlName]="_obj.name"
                                                                [rows]="6"
                                                                >
                                                                </textarea>    -->
                                                            </div>

                                                            <ng-container *ngIf="_obj && _obj.Objects">
                                                                <div class="row g-2">
                                                                    <div class="col-4"> 
                                                                        <div class="bg-light text-center">
                                                                            <p class="fw-normal">{{_obj.label}}</p>     
                                                                        </div> 
                                                                    </div>
                                                                    <div class="col-8 ">
                                                                        <div class="row g-2 d-flex justify-content-between">
                                                                            <!-- class="col-md-6 col-xl-4" -->
                                                                            <div  *ngFor="let _objs of _obj.Objects" [ngClass]="_objs.type == 'checkbox' ? 'col-6': 'col-12' "  >
                                                                                <div *ngIf="_objs.type == 'text' || _objs.type == 'time' ">
                                                                                    <div class="form-floating">
                                                                                        <input
                                                                                        [type]="_objs.type"
                                                                                        [name]="_objs.name"
                                                                                        [formControlName]="_objs.name"
                                                                                        [required]="_objs.required"
                                                                                        [readonly]="_objs.readonly"                                                       
                                                                                        class="form-control">
                                                                                        <label class="form-label bold text-uppercase"> {{_objs.label}} </label>
                                                                                    </div>
                                                                                </div>
                                                                                <div *ngIf="_objs.type== 'checkbox' ">
                                                                                    <div class="form-check mb-2">
                                                                                        <input class="form-check-input" 
                                                                                        type="checkbox"       
                                                                                        [formControlName]="_objs.name"                                                   
                                                                                        [id]="_objs.name">
                                                                                        <label class="form-check-label" [for]="_objs.name">
                                                                                            {{_objs.label}}
                                                                                        </label>
                                                                                    </div>  
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>

                                                            </ng-container>

                                                            <div *ngIf="_obj.type== 'checkbox' ">
                                                                <div class="form-check mb-2">
                                                                    <input class="form-check-input" 
                                                                    type="checkbox"               
                                                                    [formControlName]="_obj.name"                                           
                                                                    [id]="_obj.name">
                                                                    <label class="form-check-label" [for]="_obj.name">
                                                                        {{_obj.label}}
                                                                    </label>
                                                                </div>  
                                                            </div>


                                                        </div>                                                        
                                                    </div>
                                                </ng-template>                                                
                                            </div>
                                        </div>
                                    </ng-container>                             
                                </div>
                            </div>
                        </div>
                    </div>                
                </div>

                <div class="col-12" *ngIf="control.name == 'posoperatorio_tabla' ">
                    <div class="row g-2">
                        <div  *ngFor="let objects of control.Objects" 
                        [ngClass]="objects.name == 'entrega_paciente_expediente_completo_posoperatorio_tabla' ||
                        objects.name == 'entrega_fisica_drenes_cateter_identificados_posoperatorio_tabla' ||
                        objects.name == 'permeabilidad_vena_posoperatorio_tabla' ||
                        objects.name == 'vigilar_sangrado_incision_quirurgica_posoperatorio_tabla' ? 'col-6': 
                            objects.name == 'signos_vitales_posoperatorio' || 
                            objects.name == 'control_liquidos_posoperatorio' || 
                            objects.name == 'proceso_atencion_enfermeria_posoperatorio' ||
                            objects.name == 'valoracion_aldrete_posoperatorio' ||
                            objects.name == 'grafica_valoracion_aldrete_posoperatorio' ||
                            objects.name == 'medicamentos_postoperatorio' ||
                            objects.name == 'hemoderivados_posoperatorio' ? 'col-12' :   'col-md-12 col-lg-6' "  >
                            <div class="card">
                                <div class="card-body">
                                    <ng-container *ngIf="objects.Objects; else _inputsTemplate">

                                        <div class="bg-success">
                                            <h4 class="fs-16 text-white bold text-uppercase text-center">{{objects.label}} </h4>     
                                        </div>
                                        
                                        <!-- <div class="bg-light text-center" *ngIf="objects.name != 'valoracion_aldrete_posoperatorio' ">
                                            <p class="fw-normal">{{objects.label}}</p>     
                                        </div>  -->

                                        <div *ngIf="objects.name == 'entrega_paciente_expediente_completo_posoperatorio_tabla' ||
                                        objects.name == 'entrega_fisica_drenes_cateter_identificados_posoperatorio_tabla' ||
                                        objects.name == 'permeabilidad_vena_posoperatorio_tabla' ||
                                        objects.name == 'riesgo_caidas_posoperatorio' ||
                                        objects.name == 'seguimiento_dolor_posoperatorio' ||
                                        objects.name == 'escala_dolor_posoperatorio' ||
                                        objects.name == 'grado_dolor_posoperatorio' ||
                                        objects.name == 'vigilar_sangrado_incision_quirurgica_posoperatorio_tabla'">
                                            <div class="row g-2">
                                                <div  *ngFor="let obj of objects.Objects" 
                                                [ngClass]="obj.type == 'checkbox' ? 'col-6': 'col-12' ">
                                                    <div *ngIf="obj.type== 'checkbox' ">
                                                        <div class="form-check mb-2">
                                                            <input class="form-check-input" 
                                                            type="checkbox"        
                                                            [formControlName]="obj.name"                                                  
                                                            [id]="obj.name">
                                                            <label class="form-check-label" [for]="obj.name">
                                                                {{obj.label}}
                                                            </label>
                                                        </div>  
                                                    </div>

                                                    <div *ngIf="obj.type == 'text' || obj.type == 'time' || obj.type == 'number' ">
                                                        <div class="form-floating">
                                                            <input
                                                            [type]="obj.type"
                                                            [name]="obj.name"
                                                            [formControlName]="obj.name"   
                                                            [required]="obj.required"
                                                            [readonly]="obj.readonly"                                                       
                                                            class="form-control">
                                                            <label class="form-label bold text-uppercase"> {{obj.label}} </label>
                                                        </div>
                                                    </div>


                                                </div>
                                            </div>                                        
                                        </div>

                                        <div *ngIf="objects.name == 'control_liquidos_posoperatorio' ">
                                            <div class="row g-2">
                                                <div *ngFor="let objs of objects.Objects" class="col-4" >
                                                
                                                    <div class="bg-primary text-white text-center">
                                                        <p class="fw-normal text-uppercase">{{objs.label}}</p>
                                                    </div>

                                                    <div class="row g-2">
                                                        <div class="col-12" *ngFor="let _obj of objs.Objects">
                                                            <div *ngIf="_obj.type == 'text' || _obj.type == 'time' ">
                                                                <div class="form-floating">
                                                                    <input
                                                                    [type]="_obj.type"
                                                                    [name]="_obj.name"
                                                                    [formControlName]="_obj.name"   
                                                                    [required]="_obj.required"
                                                                    [readonly]="_obj.readonly"                                                       
                                                                    class="form-control">
                                                                    <label class="form-label bold text-uppercase"> {{_obj.label}} </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    
                                                
                                                </div>
                                            </div>
                                        </div>

                                        <div *ngIf="objects.name == 'signos_vitales_posoperatorio' ">
                                            <div class="row g-2">
                                                <div class="col-12" *ngFor="let objs of objects.Objects">
                                                    <div class="row">
                                                        <div class="col-3">
                                                            <div class="bg-light text-dark text-center">
                                                                <p class="fw-bold text-uppercase">{{objs.label}}</p>
                                                            </div>                                                   
                                                        </div>
                                                        <div class="col-9">
                                                            <div class="row g-2 d-flex justify-content-between ">
                                                                <div class="col" *ngFor="let obj of objs.Objects">
                                                                    <div *ngIf="obj.type == 'text' || obj.type == 'time' ">
                                                                        <div class="form-floating">
                                                                            <input
                                                                            [type]="obj.type"
                                                                            [name]="obj.name"
                                                                            [formControlName]="obj.name"   
                                                                            [required]="obj.required"
                                                                            [readonly]="obj.readonly"                                                       
                                                                            class="form-control">
                                                                            <label class="form-label bold text-uppercase"> {{obj.label}} </label>
                                                                        </div>
                                                                    </div>
                            
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>     
                                                                   

                                        <div *ngIf="objects.name == 'medicamentos_postoperatorio' ||
                                        objects.name == 'hemoderivados_posoperatorio'">
                                            <div class="row g-2">
                                                <div class="col-md-6 col-lg-4" *ngFor="let obj of objects.Objects">
                                                    <ng-container *ngIf="obj && obj.Objects">
                                                        <div class="bg-light text-center">
                                                            <p class="fw-bold">{{obj.label}}</p>
                                                        </div> 
                                                        <div class="row g-2">
                                                            <div class="col-6" *ngFor="let objs of obj.Objects">
                                                                <div *ngIf="objs.type == 'text' || objs.type == 'time' ">
                                                                    <div class="form-floating">
                                                                        <input
                                                                        [type]="objs.type"
                                                                        [name]="objs.name"
                                                                        [formControlName]="objs.name"   
                                                                        [required]="objs.required"
                                                                        [readonly]="objs.readonly"                                                       
                                                                        class="form-control">
                                                                        <label class="form-label bold text-uppercase"> {{objs.label}} </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                       <!--  <code> {{obj | json }} </code> -->
                                                    </ng-container>
                                                    <div *ngIf="obj.type == 'text' || obj.type == 'time' ">
                                                        <div class="form-floating">
                                                            <input
                                                            [type]="obj.type"
                                                            [name]="obj.name"
                                                            [formControlName]="obj.name"   
                                                            [required]="obj.required"
                                                            [readonly]="obj.readonly"                                                       
                                                            class="form-control">
                                                            <label class="form-label bold text-uppercase"> {{obj.label}} </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div *ngIf="objects.name == 'valoracion_aldrete_posoperatorio' ">
                                          <!--   <div class="bg-primary text-white text-center">
                                                <p class="fw-normal">{{objects.label}}</p>
                                            </div> -->

                                            <div class="row g-2">
                                                <div class="col-12" *ngFor="let obj of objects.Objects">
                                                    <div class="row">
                                                        <div class="col-12">
                                                            <div class="bg-light text-dark text-center">
                                                                <p class="fw-bold text-uppercase">{{obj.label}}</p>                                                                                                                          
                                                            </div>                                               
                                                        </div>    
                                                        <ng-container *ngIf="obj.name == 'valoracion_aldrete_posoperatorio_total'; else _table_template ">
                                                            <div class="row g-2 d-flex justify-content-between ">
                                                                <div class="col" *ngFor="let objs of obj.Objects">
                                                                    <div *ngIf="objs.type == 'text' || objs.type == 'time' || objs.type == 'number' ">
                                                                        <div class="form-floating">
                                                                            <input
                                                                            [type]="objs.type"
                                                                            [name]="objs.name"
                                                                            [formControlName]="objs.name"   
                                                                            [required]="objs.required"
                                                                            [readonly]="objs.readonly"                                                       
                                                                            class="form-control">
                                                                            <label class="form-label bold text-uppercase"> {{objs.label}} </label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                          
                                                        </ng-container>
                                                        <ng-template #_table_template>
                                                            <div class="col-12">
                                                                <div class="row g-2">
                                                                    <div  *ngFor="let _obj of obj.Objects" [ngClass]="_obj.type == 'title' ? 'col-4' : 'col-8' " >
                                                                        <div *ngIf="_obj.type == 'title' "> 
                                                                            <div class="row g-2">
                                                                                <div class="col-12" *ngFor="let _objs of _obj.Objects">
                                                                                    <div class="row d-flex justify-content-between">
                                                                                        <div class="col-8">
                                                                                            <p class="fw-normal"> {{_objs.label}} </p>
                                                                                        </div>
                                                                                        <div class="col-4">
                                                                                            <div *ngIf="_objs.type == 'text' || _objs.type == 'number' ">
                                                                                                <div class="form-floating">
                                                                                                    <input
                                                                                                    [type]="_objs.type"
                                                                                                    [name]="_objs.name"
                                                                                                    [formControlName]="_objs.name"   
                                                                                                    [required]="_objs.required"
                                                                                                    [value]="_objs.value"
                                                                                                    [readonly]="_objs.readonly"                                                       
                                                                                                    class="form-control">
                                                                                                 <!--    <label class="form-label bold text-uppercase"> {{_objs.label}} </label> -->
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div *ngIf="_obj.type == 'input' ">
                                                                            <div class="row g-2 d-flex justify-content-between ">
                                                                                <div class="col" *ngFor="let _objs of _obj.Objects">
                                                                                    <div class="row d-flex justify-content-between">
                                                                                        <div class="col">
                                                                                            <div *ngIf="_objs.type == 'text' || _objs.type == 'time' ">
                                                                                                <div class="form-floating">
                                                                                                    <input
                                                                                                    [type]="_objs.type"
                                                                                                    [name]="_objs.name"
                                                                                                    [formControlName]="_objs.name"   
                                                                                                    [required]="_objs.required"
                                                                                                    [readonly]="_objs.readonly"                                                       
                                                                                                    class="form-control">
                                                                                                    <label class="form-label bold text-uppercase"> {{_objs.label}} </label>
                                                                                                </div>
                                                                                            </div>
                                                                                           <!--  <p class="fw-normal"> {{_objs.label}} </p> -->
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>  
                                                        </ng-template>                                                                                                     
                                                    </div>                                                
                                                </div>
                                            </div>
                                        </div>

                                        <div *ngIf="objects.name == 'medidas_preventivas_caidas_posoperatorio' ">
                                            <div class="row g-2">
                                                <div class="col-12" *ngFor="let obj of objects.Objects"> 
                                                    <div class="bg-light text-dark text-center">
                                                        <p class="fw-bold">{{obj.label}}</p>
                                                    </div>
                                                    <div class="row g-2">
                                                        <div class="col-6" *ngFor="let _obj of obj.Objects" >
                                                            <div *ngIf="_obj.type== 'checkbox' ">
                                                                <div class="form-check mb-2">
                                                                    <input class="form-check-input" 
                                                                    type="checkbox"  
                                                                    [formControlName]="_obj.name"
                                                                    [id]="_obj.name">
                                                                    <label class="form-check-label" [for]="_obj.name">
                                                                        {{_obj.label}}
                                                                    </label>
                                                                </div>  
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div *ngIf="objects.name == 'proceso_atencion_enfermeria_posoperatorio' ">
                                            <div class="row g-2">
                                                <div class="col-md-12 col-lg-6" *ngFor="let objs of objects.Objects">
                                                    <div class="bg-light text-dark text-center">
                                                        <p class="fw-normal text-uppercase">{{objs.label}}</p>
                                                    </div>
                                                    <div class="row g-2">
                                                        <div class="col-12" *ngFor="let _obj of objs.Objects">
                                                            <div *ngIf="_obj.type == 'text' || _obj.type == 'time' ">
                                                                <div class="form-floating">
                                                                    <input
                                                                    [type]="_obj.type"
                                                                    [name]="_obj.name"
                                                                    [formControlName]="_obj.name"
                                                                    [required]="_obj.required"
                                                                    [readonly]="_obj.readonly"                                                       
                                                                    class="form-control">
                                                                    <label class="form-label bold text-uppercase"> {{_obj.label}} </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </ng-container>
                                  
                                    <ng-template #_inputsTemplate>

                                        <div *ngIf="objects.type == 'text' || objects.type == 'time' ">
                                            <div class="form-floating">
                                                <input
                                                [type]="objects.type"
                                                [name]="objects.name"
                                                [formControlName]="objects.name"
                                                [required]="objects.required"
                                                [readonly]="objects.readonly"                                                       
                                                class="form-control">
                                                <label class="form-label bold text-uppercase"> {{objects.label}} </label>
                                            </div>
                                        </div>

                                        
                                        <div *ngIf="objects.type== 'checkbox' ">
                                            <div class="form-check mb-2">
                                                <input class="form-check-input" 
                                                type="checkbox"                  
                                                [formControlName]="objects.name"                   
                                                [id]="objects.name">
                                                <label class="form-check-label" [for]="objects.name">
                                                    {{objects.label}}
                                                </label>
                                            </div>  
                                        </div>

                                    </ng-template>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </ng-template>
        </div>
    </form>
</div>
</div>