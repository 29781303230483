import { WebcamModule } from 'ngx-webcam';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';


// Feather Icon
import { FeatherModule } from 'angular-feather';
import { allIcons } from 'angular-feather/icons';

// Load Icons
import { defineElement } from 'lord-icon-element';
import lottie from 'lottie-web';

//
import { Ng2SearchPipeModule } from 'ng2-search-filter';

//
import {
  NgbCollapseModule,
  NgbAccordionModule,
  NgbPaginationModule

 } from '@ng-bootstrap/ng-bootstrap'

// Pages Routing
import { PagesRoutingModule } from "./pages-routing.module";
import { SharedModule } from "../shared/shared.module";
import { DashboardComponent } from './dashboards/dashboard/dashboard.component';
import { DashboardsModule } from "./dashboards/dashboards.module";

import { SimplebarAngularModule } from 'simplebar-angular';

import { NgxSpinnerModule } from 'ngx-spinner'

import { DROPZONE_CONFIG, DropzoneConfigInterface, DropzoneModule } from 'ngx-dropzone-wrapper';


import { NgbToastModule, NgbAlertModule, NgbNavModule } from '@ng-bootstrap/ng-bootstrap'
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SearchResultsComponent } from './search-results/search-results.component';
import { SearchResultTableComponent } from './search-results/search-result-table/search-result-table.component';
import { ProfileModule } from './profile/profile.module';
import { PharmacyComponent } from './pharmacy/pharmacy.component';
import { MobileTemplatePharmacyComponent } from './pharmacy/mobile-template-pharmacy/mobile-template-pharmacy.component';
import { OldHistoryClinicalComponent } from './old-history-clinical/old-history-clinical.component';

import { FilePondModule,registerPlugin } from 'ngx-filepond'

// import and register filepond file type validation plugin
import * as filePondFileValidate from "filepond-plugin-file-validate-type";
import { IndexAntesiologoComponent } from './anestesiologo/index-antesiologo/index-antesiologo.component';
import { MobileTemplateAnestesiologoComponent } from './anestesiologo/mobile-template-anestesiologo/mobile-template-anestesiologo.component';
import { NgApexchartsModule } from 'ng-apexcharts';
import { HistoryLogsComponent } from './history-logs/history-logs.component';

//ngMask
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { LayoutPatientsFormatComponent } from './components/layout-patients-format/layout-patients-format.component';
import { LayoutPatientsComponent } from './components/layout-patients/layout-patients.component';
import { LayoutDashboardComponent } from './components/layout-dashboard/layout-dashboard.component';
import { FilterPatientsPipe } from './pipes/filter-patients.pipe';
import { CandidatosComponent } from './components/candidatos/candidatos.component';
import { ListadoPacientesComponent } from './components/listado-pacientes/listado-pacientes.component';
import { BuscadorCandidatosPipe } from './pipes/buscador-candidatos.pipe';
import { BuscadorPacientesPipe } from './pipes/buscador-pacientes.pipe';




registerPlugin(filePondFileValidate);

const DEFAULT_DROPZONE_CONFIG: DropzoneConfigInterface = {
  url: 'https://httpbin.org/post',
  maxFilesize: 50,
  acceptedFiles: 'image/*'
};

@NgModule({
  declarations: [
    DashboardComponent,
    SearchResultsComponent,
    SearchResultTableComponent,
    PharmacyComponent,
    MobileTemplatePharmacyComponent,
    OldHistoryClinicalComponent,
    IndexAntesiologoComponent,
    MobileTemplateAnestesiologoComponent,
    HistoryLogsComponent,
    LayoutPatientsFormatComponent,
    LayoutPatientsComponent,
    LayoutDashboardComponent,
    FilterPatientsPipe,
    CandidatosComponent,
    ListadoPacientesComponent,
    BuscadorCandidatosPipe,
    BuscadorPacientesPipe
  ],
  imports: [
    CommonModule,
    PagesRoutingModule,
    FormsModule,    
    NgbToastModule,
    NgbAlertModule,
    SimplebarAngularModule,
   
    SharedModule,    
    NgbNavModule,
    NgbCollapseModule,
    NgbAccordionModule,
    DashboardsModule,
    ProfileModule,
    ReactiveFormsModule,
    WebcamModule,
    DropzoneModule,
    Ng2SearchPipeModule,
    NgxSpinnerModule,
    FilePondModule,
    NgApexchartsModule,
    NgxMaskDirective,
    NgxMaskPipe,
    NgbPaginationModule,
    FeatherModule.pick(allIcons),
  ],
  providers:[
    provideNgxMask(),
    DatePipe,{
    provide: DROPZONE_CONFIG,
    useValue:DEFAULT_DROPZONE_CONFIG
  }],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})

export class PagesModule {
  constructor() {
    defineElement(lottie.loadAnimation)
  }
 }

