<div *ngIf="_detallesCita"> 
    <div class="d-flex align-items-center bg-{{_getBgClass(_detallesCita.cita.Estatus)}} bg-gradient p-3 offcanvas-header">
        <h5 class="m-0 me-2 text-white text-uppercase">Detalles de la cita</h5>
        <button type="button" class="btn-close btn-close-white ms-auto " data-bs-dismiss="offcanvas" aria-label="Close"
            (click)="hideCanvas()"></button>
    </div>

    <ngx-simplebar style="height: 90vh;">     

        <div class="p-4">
            <h5 class="fs-15 mb-4">Datos Generales</h5>
            <div class="d-flex mb-3">
                <div class="flex-shrink-0 avatar-xs">
                    <div class="avatar-title bg-{{_getBgClass(_detallesCita.cita.Estatus) }}-subtle rounded fs-16" [ngClass]="_getTextStyle(_detallesCita.cita.Estatus)">
                       <i class="ri-calendar-line "></i>
                    </div>
                </div>
                <div class="flex-grow-1 ms-3">
                    <h6 class="mb-1"><a href="javascript:void(0);" [ngClass]="_getTextStyle(_detallesCita.cita.Estatus)" >Fecha de inicio</a></h6>
                    <p class="mb-0">{{_detallesCita.cita.Fecha_Inicio | date:'yyyy-MM-dd HH:mm ' }} </p>
                </div>   
                <div class="flex-grow-1 ms-3">
                    <h6 class="mb-1"><a href="javascript:void(0);" [ngClass]="_getTextStyle(_detallesCita.cita.Estatus)">Fecha de final</a></h6>
                    <p class="mb-0">{{_detallesCita.cita.Fecha_Final | date:'yyyy-MM-dd HH:mm ' }} </p>
                </div>  
            </div>              
      
            <div class="d-flex mb-3 mt-4">       
                <div class="flex-shrink-0 avatar-xs">
                    <div class="avatar-title bg-{{_getBgClass(_detallesCita.cita.Estatus)}}-subtle rounded fs-16" [ngClass]="_getTextStyle(_detallesCita.cita.Estatus)" >
                       <i class="ri-star-s-fill"></i>
                    </div>
                </div>           
                <div class="flex-grow-1 ms-3">
                    <h6 class="mb-1"><a href="javascript:void(0);" [ngClass]="_getTextStyle(_detallesCita.cita.Estatus)">Estatus</a></h6>
                    <p class="mb-0">{{_detallesCita.cita.Estatus}} </p>
                </div>  
                <div class="flex-grow-1 ms-3">
                    <h6 class="mb-1"><a href="javascript:void(0);" [ngClass]="_getTextStyle(_detallesCita.cita.Estatus)">Folio de cita </a></h6>
                    <p class="mb-0">{{_detallesCita.cita.Cita_Id }}  <button type="button" (click)="_OpenModalCita(2)"  class="btn btn-outline-primary btn-icon waves-effect waves-light mr-4 ml-4"><i class=" ri-edit-line"></i></button> </p>
                    
                </div> 
            </div>

            <div class="d-flex mb-3">       
                <div class="flex-shrink-0 avatar-xs">
                    <div class="avatar-title bg-{{_getBgClass(_detallesCita.cita.Estatus)}}-subtle  rounded fs-16" [ngClass]="_getTextStyle(_detallesCita.cita.Estatus)" >
                       <i class="ri-account-box-line"></i>
                    </div>
                </div>           
                <div class="flex-grow-1 ms-3">
                    <h6 class="mb-1"><a href="javascript:void(0);" [ngClass]="_getTextStyle(_detallesCita.cita.Estatus)">Tipo de evento</a></h6>
                    <p class=" mb-0">{{_detallesCita.cita.Tipo_Cita}} </p> 
                </div>  
            </div>

        </div>  

        <div class="p-4">
            <h5 class="fs-15 mb-4">Detalles de la cita</h5>
            <div class="d-flex mb-3">
                <div class="flex-shrink-0 avatar-xs">
                    <div class="avatar-title bg-{{_getBgClass(_detallesCita.cita.Estatus)}}-subtle rounded fs-16" [ngClass]="_getTextStyle(_detallesCita.cita.Estatus)" >
                       <i class=" ri-account-box-line  "></i>
                    </div>
                </div>
                <div class="flex-grow-1 ms-3">
                    <h6 class="mb-1"><a href="javascript:void(0);" [ngClass]="_getTextStyle(_detallesCita.cita.Estatus)">Doctor</a></h6>
                    <p class="text-muted mb-0">
                        <a href="javascript:;" 
                        class="link-secondary text-decoration-underline  link-underline-opacity-100-hover" 
                        (click)="_showDetails(_detallesCita.cita.Empleado_Id)" > {{ _detallesCita.doctor?.Name }} </a>    
                    </p>
                </div>          
            </div>

            <div class="d-flex mb-3">      
                <div class="flex-shrink-0 avatar-xs">
                    <div class="avatar-title bg-{{_getBgClass(_detallesCita.cita.Estatus)}}-subtle rounded fs-16" [ngClass]="_getTextStyle(_detallesCita.cita.Estatus)" >
                       <i class="ri-hearts-line"></i>
                    </div>
                </div>  
                 
                <div class="flex-grow-1 ms-3">
                    <h6 class="mb-1"><a href="javascript:void(0);" [ngClass]="_getTextStyle(_detallesCita.cita.Estatus)">Servicio o Procedimiento</a></h6>
                    <p class="mb-0">{{_detallesCita.servicio?.Name }} </p>
                </div>  
            </div>

            <div class="d-flex mb-3">       
                <div class="flex-shrink-0 avatar-xs">
                    <div class="avatar-title bg-{{_getBgClass(_detallesCita.cita.Estatus)}}-subtle  rounded fs-16" [ngClass]="_getTextStyle(_detallesCita.cita.Estatus)" >
                       <i class=" ri-folder-user-line "></i>
                    </div>
                </div>           
                <div class="flex-grow-1 ms-3">
                    <h6 class="mb-1">
                        <a href="javascript:void(0);" [ngClass]="_getTextStyle(_detallesCita.cita.Estatus)" >Paciente</a></h6>
                    <p class="mb-0"><a href="javascript:;" class="link-secondary text-decoration-underline  link-underline-opacity-100-hover" (click)="_gotoForm(_detallesCita.cita)"> {{_detallesCita.cita.N_Expediente}} - {{_detallesCita.cita.Paciente_Nombre_Completo }} </a>  
                 
                    </p>
                    <div class="d-flex justify-content-center mt-2" *ngIf="_canEdit">
                        <button type="button" class="btn btn-sm btn-dark" (click)="_OpenModalCita(1)">Editar paciente</button>
                    </div>
                    
                </div>  
            </div>

            <div class="d-flex mb-3">       

                <div class="flex-shrink-0 avatar-xs">
                    <div class="avatar-title bg-{{_getBgClass(_detallesCita.cita.Estatus)}}-subtle  rounded fs-16" [ngClass]="_getTextStyle(_detallesCita.cita.Estatus)" >
                       <i class=" ri-shield-cross-line "></i>
                    </div>
                </div>         

                <div class="flex-grow-1 ms-3">
                    <h6 class="mb-1"><a href="javascript:void(0);" [ngClass]="_getTextStyle(_detallesCita.cita.Estatus)">Recursos</a></h6>
                    <p class="mb-0"><a href="javascript:;" 
                        class="link-secondary text-decoration-underline  link-underline-opacity-100-hover"
                        (click)="_showDetailsRecurso(_detallesCita.cita.Recurso_Id )"> {{_detallesCita.cita.Recurso}} </a>  </p>
                </div>  

            </div>
        </div>  
        <div class="p-4">   
            <div class="alert alert-{{_getBgClass(_detallesCita.cita.Estatus)}} alert-dismissible alert-label-icon rounded-label fade show" role="alert">
                <i class=" ri-account-box-fill label-icon"></i><strong class="text-uppercase">Cita {{ _detallesCita.cita.Estatus }} </strong>                 
            </div>
        </div>      

        <div class="p-4">
            <div class="d-flex justify-content-center">
                <!-- Buttons Grid -->
                <div class="d-grid gap-2" >
                    <button type="button" class="btn btn-primary w-lg" (click)="_sendNotification()" >Notificar llegada <i class="ri-send-plane-fill"></i> 
                    </button>                    
                </div>
            </div>
        </div>

    </ngx-simplebar>
</div>

<app-details-doctor  [events]="eventSubjectId.asObservable()"></app-details-doctor>
<app-details-recurso [events]="eventSubjectRecurdo.asObservable()" ></app-details-recurso>

<ng-template #modalCita let-modal>

    <div class="modal-header p-3 bg-primary-subtle">
        <h5 class="modal-title" id="modal-title">Editar {{_optionForm == 1 ? 'Paciente' : 'Cita' }} </h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-hidden="true"
            (click)="closeEventModal()">
        </button>
    </div> 

    <div class="modal-body p-4">
        <ng-container *ngIf="_optionForm == 1; else citaTemplate" >
            <app-create-patient 
            (eventClickCancel)="onClickEvent($event)" 
            [_paciente_id]="_detallesCita.cita.N_Expediente" 
            [editable]="true">
        </app-create-patient>
        
        </ng-container>
        <ng-template #citaTemplate>
            <app-create-citas 
            (eventClickCancel)="onClickEvent($event)"
            [editable]="true"
            [_detalles_cita]="_detallesCita"
            [_cita_id]="_detallesCita.cita.Cita_Id"
            ></app-create-citas>
        </ng-template>
    </div>
</ng-template>

<ngx-spinner [name]="_spinner_canvas" type="ball-scale-multiple" [fullScreen]="false"  ></ngx-spinner>
