
import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { CredentialsService } from 'src/app/core/services/credentials.service';
import { SessionPageService } from '../../services/session-page.service';
import { FormatsService } from 'src/app/shared/services/formats.service';
import { AuthenticationService } from 'src/app/core/services/auth.service';

import * as _ from 'lodash';
import { NgxSpinnerService } from 'ngx-spinner';
import { CalendarService } from '../../calendar/services/calendar.service';
import { PatientsService } from '../../calendar/services/patients.service';
import { LocalSessionService } from '../../services/local-session.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { NavigationService } from 'src/app/layouts/services/navigation.service';
import { Location } from '@angular/common';
import { AlertsService } from 'src/app/shared/services/alerts.service';
import { Cita } from '../../calendar/models/calendar';
import { VoiceRecognitionService } from 'src/app/shared/services/voice-recognition.service';
import { map, Observable, scan, Subscription, takeWhile, timer } from 'rxjs';
import { Recetas } from '../../models/medicamentos';
import { RecetasService } from '../../services/recetas.service';
import { CandidatosService } from '../../calendar/services/candidatos.service';
import { CalificacionCandidato, CalificacionVarices } from '../../calendar/models/candidatos';
import { ToastrService } from 'ngx-toastr';
import { PacientesEsperaService } from '@app/services/pacientes-espera.service';
import { LogsResponseWs } from '@app/layouts/models/user-data';
import { DeletePaciente } from '@app/pages/models/pacientes_espera_info';
import Swal from 'sweetalert2';

const _LAYOUT_FORMATO = '_layout_template'


@Component({
  selector: 'app-layout-patients-format',
  templateUrl: './layout-patients-format.component.html',
  styleUrls: ['./layout-patients-format.component.scss']
})
export class LayoutPatientsFormatComponent implements OnInit, OnDestroy {

  @ViewChild('modalPatient') modalPatient !: TemplateRef<any>
  @ViewChild('modalCrearCita') modalCrearCita!: TemplateRef<any>
  @ViewChild('modalCrearReceta') modalCrearReceta!: TemplateRef<any>

  @ViewChild('modalBtns') modalBtns!: TemplateRef<any>;

  // bread crumb items
  breadCrumbItems!: Array<{}>;

  record: any | null = null
  _cita: any | null = null
  _nombre_completo: string

  _pacientesFormatos: any[] = []
  _jsonCitas: any[] = []

  isCollapsed: boolean = true
  isCollapsedPacientes: boolean = true
  isCollapsedOthers: boolean = true

  _formato: any;
  formato: any

  _formato_id: string
  _label_formato: string

  _clientes_espera: any[] = []

  _spinner = 'spinner'

  _ngModal: NgbModalRef;
  _ngbModal: NgbModalRef
  _ngbModalReceta: NgbModalRef
  _ngModalBtns: NgbModalRef

  _loadFromCita!: boolean
  _citasSubsecuentes: any[] = []
  _dataCita: any;
  _cita_sub_secuente_id: string;
  _citas_paciente: any[] = []
  _readyFormat: boolean;

  _voiceText: string = '_text_voice'

  $loadCitasSubsecuentes: Subscription

  _modalOption!: string


  _recetas: Recetas[] = []
  _LaboratorioFiles: any[] = []

  historia_clinica_frame_7: string[] = []
  historia_clinica_frame_8: string[] = []

  registro_anestesia: string[] = []

  _historiaClinica: string[] = []

  _Historia_Clinica_Antigua: string[] = []

  _calificacionCandidato: CalificacionCandidato = new CalificacionCandidato()
  _calificacionVarices: CalificacionVarices = new CalificacionVarices()
  _prioridad: number = 0

  formato__: string | null = 'Historia_Clinica'

  _cita_set_: any;

  _showBtnPrint: boolean

  timer$: Observable<any>;

  constructor(
    private credentials: CredentialsService,
    private sessionPage: SessionPageService,
    private format: FormatsService,
    private spinner: NgxSpinnerService,
    private authService: AuthenticationService,
    private calendarService: CalendarService,
    private patientService: PatientsService,
    private modalService: NgbModal,
    private local_: LocalSessionService,
    private navigation: NavigationService,
    private location: Location,
    private alert: AlertsService,
    private receta: RecetasService,
    public voice: VoiceRecognitionService,
    private candidato: CandidatosService,
    private toastr: ToastrService,
    private pacientes: PacientesEsperaService
  ) {
    const _record = this.credentials.userInfoData

    if (_record && _record.data) {
      this.record = _record.data.records[0]

      const especialidad: string = this.record.Especialidad__c

      if (especialidad === 'Proctología' || especialidad === 'Coloproctología') {
        this._getCandidaoResultado(this.record.Candidato__c)
      }

      if (especialidad === 'Varices' || especialidad === 'Várices' || especialidad === 'Varices avanzadas'
      ) {
        this._getCalificacionVarices(this.record.Candidato__c)
      }

    }
  }

  ngOnInit(): void {
    this._loadFromCita = false
    this._loadDataCita()

    this.$loadCitasSubsecuentes = this.calendarService.CurrentCitaSubsecuente.subscribe({
      next: (value) => {
        if (value) {
          this._loadCitasSubsecuents(this.record)
          return
        }
      },
      error(err) {
        console.error({ err });
      },
    })


    if (this._cita && this.record) {
      this._nombre_completo = this._cita.Paciente_Nombre_Completo

      this._loadCitasSubsecuents(this.record)
      this._loadCitasPaciente(this.record)

      this.breadCrumbItems = [
        { label: this.record.Name, type: 'paciente' },
        { label: this._cita.Cita_Id, type: 'cita' }
      ];
    }

    this.getPatientsFormatos()

    // Compose Model Hide/Show
    let isShowMenu = false;
    this._showBtnPrint = false

    document.querySelectorAll(".file-menu-btn").forEach(function (item) {
      item.addEventListener("click", function (e) {
        e.preventDefault();
        isShowMenu = true;
        document.getElementById('menusidebar')?.classList.add("menubar-show");
      });
    });

    document.querySelector('.chat-wrapper')?.addEventListener('click', function () {
      if (document.querySelector(".file-manager-sidebar")?.classList.contains('menubar-show')) {
        if (!isShowMenu) {
          document.querySelector(".file-manager-sidebar")?.classList.remove("menubar-show");
        }
        isShowMenu = false;
      }
    });

    this.loadClientesEspera()

  }

  private _getCandidaoResultado(_candidato_id: string) {
    this.alert._showLoader('Consultando calificacion, paciente')
    this.candidato._consultarCalifPaciente(_candidato_id).subscribe({
      next: (value: any) => {

        if (value && value.codigo === 200) {
          this.alert._hideLoader()
          //value.data.candidato_id = this._candidato_id
          this._calificacionCandidato = value.data;
          this._prioridad = this._calificacionCandidato.prioridad
        }
      },
      error: (err) => {
        this.alert._hideLoader()
        console.error({ err });
      },
    })
  }

  private _getCalificacionVarices(_cadidato_id: string) {
    this.alert._showLoader('Consultando calificacion, paciente')
    this.candidato._consultarCalificacionVarices(_cadidato_id).subscribe({
      next: (value: any) => {

        if (value && value.codigo === 200) {
          this.alert._hideLoader()
          //value.data.candidato_id = this._candidato_id
          this._calificacionVarices = value.data;
          this._prioridad = this._calificacionVarices.prioridad
        }
      },
      error: (err) => {
        this.alert._hideLoader()
        console.error({ err });
      },
    })
  }

  /**
   * 
   * @param record 
   */
  private _loadCitasPaciente(record: any) {

    this.calendarService._GetCitasPaciente(record.Id).then((resp: any) => {
      if (resp.codigo === 200) {
        this._citas_paciente = resp.data

        let _cita_find = _.filter(resp.data, { Name: this._cita.Cita_Id })

        if (_cita_find) {

          this._cita_set_ = _cita_find[0]

        }


      }
    }).catch((error) => {
      console.error({ error });
    })
  }

  /** */
  private getPatientsFormatos(): void {
    this._loadFromCita = true
    let _formatos_cita = this.local_.getJsonValues('_json_formatos_cita')

    if (_formatos_cita) {

      let _menu = this.navigation.getMenuItems(_formatos_cita)
      //console.log({_menu});

      this._pacientesFormatos = _menu[0].menu

    }
  }

  private _loadDataCita() {


    //console.log({record_: this.record});


    this._cita = this.credentials.getCitaJson()
    //const _dataUser = this.credentials._getdataUser();  

    const _jsonCitas = this.sessionPage._returnJSONSession()
    if (_jsonCitas) {
      this._jsonCitas = _jsonCitas
    }
  }

  private _loadCitasSubsecuents(_record: any) {
    //const _record = this.credentials.userInfoData
    this._citasSubsecuentes = []
    this.calendarService._GetCitasSubsecuentes(_record.Id).then((resp: any) => {

      if (resp.codigo === 200) {
        this._citasSubsecuentes = resp.data
      }

    }).catch((error) => {
      console.error({ error });
    })
  }

  _onBack(): void {
    this.location.back()
  }

  ngOnDestroy(): void {
    this.record = null
    if (this.$loadCitasSubsecuentes) {
      this.$loadCitasSubsecuentes.unsubscribe()
    }
    //sessionStorage.removeItem('data_user')
  }

  _onEventEmmiterClick(item: any) {

  }

  private loadClientesEspera() {
    this._clientes_espera = []

    this.pacientes.getPacienteEsperaInfo().pipe(map((response) => {
      const Logs = response.data.info.Websockets.paciente_espera.Logs
      const _uniq = _.uniqBy(Logs, (a) => {
        return a.data.cita_id && a.data.pacient
      })

      //      console.table(_uniq)

      console.log({ _uniq });

      response.data.info.Websockets.paciente_espera.Logs = _uniq
      return response

    })).subscribe({
      next: (response) => {

        if (response && response.codigo === 200) {
          this._clientes_espera = response.data.info.Websockets.paciente_espera.Logs
        }

      },
      error: (err) => {
        console.error({ err });

      },
    })

    /*     this.authService._getUserDetail().then((resp) => {
          if (resp.codigo == 200) {
            const webSocket = resp.data.info.Websockets
            if (webSocket && webSocket.paciente_espera) {
              console.log({ webSocket });
              this._clientes_espera = webSocket.paciente_espera.Logs;
            }        
          }
        }) */
  }


  /**
   * 
   * @param formato 
   */
  async _routerLink(formato: any) {
    this._formato = null
    this._readyFormat = false
    this.formato = formato
    this._formato_id = formato._sub_link
    this._label_formato = formato.label
    let _formato_id_ = formato._formato_id
    this.formato__ = formato._formato_id

    if (_formato_id_ == 'Hoja_Indicaciones') {
      _formato_id_ = 'Hoja_Indicaciones_Medicas'
    }

    if (_formato_id_ === 'Registro_Anestesia') {
      _formato_id_ = 'registro_anestesia'
    }

    /*   if(_formato_id_ == 'Enfermeria_Operatorios') {
        _formato_id_ = 'registro_enfermeria_preTrans_postOperatorio'
      } */

    let _formato_ = {
      _formato_id_,
      _cita_id: this._cita.Cita_Id,
      _formato_id: this._formato_id
    }

    this.local_.savedJsonValues(_LAYOUT_FORMATO, _formato_)

    await this.format._getFormatoAsync(_formato_id_, this._cita.Cita_Id).then(async (response) => {
      this._formato = response.data.formato;
      this._readyFormat = true
      this.initTimer()
    }).catch((error) => {
      console.error({ 'ERROR__': error });
    })
  }

  private initTimer() {
    this.timer$ = timer(0, 1000).pipe(
      scan(acc => --acc, 24),
      map(resp => { return resp }),
      takeWhile(x => x >= 0)
    )

    this.timer$.subscribe((resp) => {
      if (resp === 0) {
        this._showBtnPrint = true
      }
    })
  }

  /**
   * 
   */
  OtherFormats(link: string) {

    this._formato = null

    this._formato_id = link

    this.format._getFormat(link, this._cita.Cita_Id).subscribe({
      next: (response) => {
        if (response.codigo == 200) {
          this._formato = response.data.formato;
        }
      }
    })
  }


  /**
   *
   *
   * @param {*} event
   * @memberof LayoutPatientsFormatComponent
   */
  _onloadHistory(event: any) {

    let _cita_id = event.data.cita_id
    let _find = _.find(this._jsonCitas, { Cita_Id: _cita_id })

    Swal.fire({
      title: 'Por favor confirme esta accion',
      text: '¿Deseas atender a este paciente?',
      icon: 'question',
      showCancelButton: true,
      cancelButtonText: 'No',
      confirmButtonText: 'Sí, atender',
      confirmButtonColor: '#00bd9d',
      cancelButtonColor: '#f06548'
    }).then((result) => {
      if (result.value) {
        if (_find) {
          this.credentials.saveCitaJson(_find)
          this._getPatientDetalle(_find.Paciente_Id, event)

        } else {
          this.alert._showLoader('Cargando detalles, espere...')
          this.calendarService._getDetallesCita(event.data.cita_id).subscribe({
            next: (response: any) => {
              this.alert._hideLoader()
              if (response.codigo == 200) {


                const _data = response.data
                const _dataCita = _data.Datos_Cita

                if (_data && _data.length) {
                  const _resp = _data[0]
                  console.log({ response });

                  this.toastr.error(_resp.details, _resp.error, {
                    timeOut: 3200
                  })
                  return
                } else if (response.data && response.data.error) {
                  this.toastr.error('Ocurrio un error al consultar los detalles de la cita', `Error al consultar ${event.data.cita_id}`, {
                    timeOut: 3200,
                    progressBar: true
                  })
                }

                this._getFormatosByCita(_data, _dataCita, event)


                /*       const _data = response.data[0];
                      if (_data && _data.error) {
                        console.log('AAAAAAAAAA');
                      } else if (response.data && response.data.error) {
                        this.toastr.error('Ocurrio un error al consultar los detalles de la cita',`Error al consultar ${event.data.cita_id}`,{
                          timeOut: 3200,
                          progressBar: true
                        })
                      } */
              }
            },
            error: (error: any) => {
              console.error({ error });
              this.alert._hideLoader()
            }
          })
        }
      }
    })


  }

  private _getFormatosByCita(_data: any, _dataCita: any, event: LogsResponseWs) {
    this.format._getForamtoByCita(_data.Cita_Id).then((resp) => {
      this.spinner.hide()
      if (resp) {
        this.local_.savedJsonValues('_json_formatos_cita', resp)
        // this._getPatientsData(_cita)    

        let _cita: Cita;
        _cita = {
          Cita_Id: _data.Cita_Id,
          Servicio: '',
          Recurso: _dataCita.detalle_cita.recurso,
          Fecha_Inicio: _dataCita.detalle_cita.fecha_inicio,
          Fecha_Final: _dataCita.detalle_cita.fecha_final,
          Paciente_Id: _dataCita.Salesforce_Id,
          Recurso_Id: _dataCita.detalle_cita.recurso,
          Servicio_Id: _dataCita.detalle_cita.servicio,
          Tipo_Cita: _data.Tipo_Evento,
          Procedimiento: '',
          Estatus: _data.Estatus,
          Empleado_Id: _data.Empleado_Id,
          N_Expediente: 'aaaa', //item.Name,
          Paciente_Nombre_Completo: '',
          Candidato_Cirugia: ''

        }
        this.credentials.saveCitaJson(_cita)
        //this.redirect.to('/pacientes_formatos')
        //window.location.reload()
        this._deletePacienteEspera(event)

      }
    }).catch((error) => {
      this.spinner.hide()
      console.error({ error });

    })
  }

  /**
   *
   *
   * @private
   * @param {LogsResponseWs} _pacienteData
   * @memberof LayoutPatientsFormatComponent
   */
  private async _deletePacienteEspera(_pacienteData: LogsResponseWs) {

    let _pacienteEspera: DeletePaciente = new DeletePaciente()

    _pacienteEspera = {
      id_cita: _pacienteData.data.cita_id,
      patient: _pacienteData.data.pacient
    }

    await this.pacientes.deletePacienteEspera(_pacienteEspera).then((resp) => {
      console.log({ resp });

      window.location.reload()

    }).catch((error) => {
      console.error({ error });
    })
  }

  /**
  * 
  * @param paciente_id 
  */
  _getPatientDetalle(paciente_id: string, event: LogsResponseWs) {
    this.spinner.show(this._spinner)
    this.patientService._getPatientInfo(paciente_id).subscribe({
      next: (response: any) => {
        // 
        this.spinner.hide(this._spinner)
        this.credentials.saveUserinfo(response);


        this._deletePacienteEspera(event)
        //window.location.reload()
      },
      error: (error: any) => {
        this.spinner.hide(this._spinner)
        console.error({ error });
      }
    })
  }


  /**
   * 
   * @param item 
   */
  _onOpenCita(item: any): void {

    console.log({ item });
    this._dataCita = []

    this._cita_sub_secuente_id = item.cita_sub_secuente_id

    this._dataCita = item.Datos_Cita

    this._ngModal = this.modalService.open(this.modalPatient, {
      size: 'xl',
      animation: true,
      backdrop: 'static',
      fullscreen: 'lg'
    })

  }

  closeEventModal() {
    this._ngModal.close()
  }

  onClickEvent(event: boolean) {
    if (event) {
      this._ngModal.close()
    }
  }

  /**
   * 
   */
  onSettingsButtonClicked() {
    document.body.classList.toggle('right-bar-enabled');
    const rightBar = document.getElementById('theme-settings-offcanvas');
    if (rightBar != null) {
      rightBar.classList.toggle('show');
      rightBar.setAttribute('style', "visibility: visible;");
    }
  }

  /**
   * 
   * @param formato 
   */
  onSetIdFormato(formato: any) {
    this._routerLink(formato)
  }

  /**
   * 
   * @param _cita 
   */
  _setCita(_cita: any) {
    console.log({ _cita });
    this.alert._showLoader('Cargando, espere...')
    let _cita_: Cita;

    _cita_ = {
      Cita_Id: _cita.Name,
      Recurso: _cita.Recurso,
      Fecha_Inicio: _cita.Fecha_Inicio ? _cita.Fecha_Inicio : _cita.Fecha_inicio,
      Fecha_Final: _cita.Fecha_Final ? _cita.Fecha_Final : _cita.Fecha_final,
      Paciente_Id: _cita.Paciente_Id,
      Recurso_Id: _cita.Recurso_Id,
      Tipo_Cita: _cita.Tipo_Evento ? _cita.Tipo_Evento : _cita.Tipo_cita,
      Servicio_Id: _cita.Servicio_Id,
      Empleado_Id: _cita.Empleado_Id,
      Procedimiento: '',
      Estatus: _cita.Estatus,
      Candidato_Cirugia: this.record.Candidato_a_cirug_a__c,
      N_Expediente: this.record.Name,
      Paciente_Nombre_Completo: this.record.Nombre_completo__c,
      Servicio: _cita.Servicio
    }
    //return console.log({_cita_});

    this.format._getForamtoByCita(_cita.Name).then((resp) => {


      /*       this.calendarService._getDetallesCita(_cita.Name).subscribe({
              next:(value) => {
                  console.log({value});
                  
              },
            }) */
      console.log({ resp });
      this.local_.savedJsonValues('_json_formatos_cita', resp)
      this.credentials.saveCitaJson(_cita_)

      setTimeout(() => {
        window.location.reload()
      }, 300);

    }).catch((error) => {
      console.error({ error });

    })

  }

  _onEventLoadRecord(event: boolean) {
    if (event) {
      this.record = null
      const _record = this.credentials.userInfoData
      if (_record && _record.data) {
        this.record = _record.data.records[0]
      }
    }
  }


  /**
   * 
   * @param value 
   */
  _onEventEmmiterValue(value: string) {
    console.log({ ________TEXT___________: this.voice.text });
    console.log({ TEXT___: value });
  }

  /**
   * 
   */
  _openModal() {
    this._ngbModal = this.modalService.open(this.modalCrearCita, {
      size: 'xl',
      animation: true,
      backdrop: 'static',
      fullscreen: 'lg'
    })
  }

  /**
   * 
   */
  _openModal2() {
    this._ngbModalReceta = this.modalService.open(this.modalCrearReceta, {
      size: 'xl',
      animation: true,
      backdrop: 'static',
      fullscreen: 'lg'
    })
  }

  _onEventCloseModal(val: boolean) {
    if (val) {
      this._ngbModal.close()
    }
  }

  /**
   * 
   * @param value 
   */
  _onEventCloseModal2(value: boolean) {
    if (value) {
      this._ngbModalReceta.close()
    }
  }

  /**
   * 
   * @param option 
   */
  _openModalBtns(option: string) {

    this._modalOption = option

    if (option === 'recetas') {
      this._loadRecetas()
    } else if (option === 'estudios') {
      this._loadFilesImgaes()
    }

    this._ngModalBtns = this.modalService.open(this.modalBtns, {
      size: 'xl',
      animation: true,
      backdrop: 'static',
      fullscreen: true
    })
  }

  /**
   * 
   */
  private _loadRecetas() {
    this._recetas = []
    this.receta.GetRecetaByCita(this._cita.Cita_Id).subscribe({
      next: (value: any) => {
        if (value.codigo === 200) {
          const data = value.data
          if (data) {
            if (data && data.length >= 1) {
              const _type = typeof (data)
              if (_type === 'object') {
                this._recetas = data //[...data]
              }
            }
          }
        }

      },
      error(err) {
        console.error({ err });

      },
    })
  }

  /**
   * 
   */
  private _loadFilesImgaes() {
    this.alert._showLoader('Cargando...')
    this.format._getListImages(this._cita.Cita_Id, this.record.Name).subscribe({
      next: (response) => {
        if (response.codigo == 200) {
          const data = response.data
          if (data.formato && data.formato.archivos) {
            const archivos = data.formato.archivos

            this._LaboratorioFiles = archivos.Laboratorio ? archivos.Laboratorio : []


            this.historia_clinica_frame_7 = archivos.historia_clinica_frame_7 ? archivos.historia_clinica_frame_7 : []
            this.historia_clinica_frame_8 = archivos.historia_clinica_frame_8 ? archivos.historia_clinica_frame_8 : []
            this.registro_anestesia = archivos.registro_anestesia ? archivos.registro_anestesia : []

            this._Historia_Clinica_Antigua = archivos.Historia_Clinica_Antigua ? archivos.Historia_Clinica_Antigua : []

            this._historiaClinica = [...this.historia_clinica_frame_7, ...this.historia_clinica_frame_8, ...this._LaboratorioFiles, ...this.registro_anestesia,...this._Historia_Clinica_Antigua]

          }

          this.alert._hideLoader()
        }
      },
      error: (error: any) => {
        console.error({ error });
        this.alert._hideLoader()
      }
    })
  }


  _onEventLoadRecetas(event: boolean) {
    if (event) {
      this._loadRecetas()
    }
  }


  getbgBadge(total: number): String {

    if (total >= 0 && total <= 1) {
      return 'bg-danger'
    }/* else if (total >=5 && total <= 9) {
      return 'bg-brown'
    } */else if (total === 2) {
      return 'bg-warning'
    } else if (total === 3) {
      return 'bg-yellow'
    } else if (total >= 4 && total <= 10) {
      return 'bg-success'
    }

    return 'bg-light'
  }

  getLevel(total: number): number {

    if (total >= 0 && total <= 1) {
      return 5
    } else if (total === 2) {
      return 3
    } else if (total === 3) {
      return 2
    } else if (total >= 4 && total <= 10) {
      return 1
    }

    return 0
  }

  _printFormato() {
    if (this.formato__ && this._cita && this.record) {
      this.alert._showLoader('Generando, por favor espere...')
      this.format._generarPDFformato(this.formato__, this._cita.Cita_Id, this.record.Name).then((resp: any) => {
        this.alert._hideLoader()
        if (resp && resp.codigo === 200) {
          setTimeout(() => {
            window.open(resp.data.url, '_blank', 'width=1000, height=800');
          }, 100);
        }
      }).catch((error) => {
        console.error({ error });

        this.alert._hideLoader()
        this.toastr.error('Error', 'Ocurrio un error', {
          timeOut: 3200,
          progressBar: true
        })
      })
    } else {
      let msg = ''
      if (this.formato__ === null) {
        msg += 'Seleccione un formato'
      }
      if (this._cita === null) {
        msg += 'Seleccione una cita'
      }
      if (this.record === null) {
        msg += 'Los datos del paciente no se encuentran'
      }

      this.toastr.warning(msg, 'Espere!!', {
        timeOut: 3200,
        progressBar: true
      })
    }


  }

}
