import { Component, EventEmitter, Input, Output, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subject, map } from 'rxjs';
import { CredentialsService } from '@app/core/services/credentials.service';
import { Cita } from '@app/pages/calendar/models/calendar';
import { DetallesCita } from '@app/pages/calendar/models/detalles-cita';
import { CalendarService } from '@app/pages/calendar/services/calendar.service';
import { PatientsService } from '@app/pages/calendar/services/patients.service';
import { LocalSessionService } from '@app/pages/services/local-session.service';
import { InitWebSocketService } from '@app/services/init-web-socket.service';
import { FormatsService } from '@shared/services/formats.service';
import { Patients } from '@app/shared/models/Patients';


@Component({
  selector: 'app-canvas-detalles-citas',
  templateUrl: './canvas-detalles-citas.component.html',
  styleUrls: ['./canvas-detalles-citas.component.scss']
})
export class CanvasDetallesCitasComponent implements OnInit {

  @ViewChild('modalCita') modalCita !:TemplateRef<any>
  @ViewChild('modalDetailPatient') modalDetailPatient!: TemplateRef<any>

  @Input()
    _detallesCita: DetallesCita;

  @Output()
  eventCanvasEmiiter: EventEmitter<string> = new EventEmitter();

  _ngModal: NgbModalRef;

  _optionForm: number;

  eventSubjectId: Subject<string> = new Subject<string>();
  eventSubjectRecurdo: Subject<string> = new Subject<string>();

  _canEdit: boolean = false;

  _sendNotificaction: boolean

  _showBtnNotification: boolean

  _spinner_canvas = 'spinner_canvas'

  _ngbModal: NgbModalRef

  _patientSelected: Patients = new Patients()

  constructor(
    private patients: PatientsService,
    private credentials: CredentialsService,
    private modalService: NgbModal,
    private calendar: CalendarService,
    private wbSocket: InitWebSocketService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private foramto: FormatsService,
    private localService: LocalSessionService
  ) {
    
  }
  
  ngOnInit(): void {

   // console.log({DETALLE_CITA: this._detallesCita});
    

    this._showBtnNotification = false

    /**
     * // TODO
     * Validar role de Recepcionista
     */
    if(this.credentials.credentials?.roles.Administrador) {
      this._canEdit =  true
      this._showBtnNotification = true
    }
    
  }

  

   /**
   * 
   * @param status 
   * @returns 
   */
   _getTextStyle(status: string) {
    switch (status) {
      case 'Tratamiento':
          return 'text-primary';
      case 'Revisión':
          return 'text-success';
      case 'Bloqueo' :
          return 'text-secondary';
      case 'Consulta':
          return 'text-info'
      case 'Cirugía':
          return 'text-dark';
      case 'Primera Cita':
          return 'text-warning';
      case 'Cancelada':
          return 'text-danger';
      default: 
        return 'text-dark'
    }
  }

  _getAlertClass(status: string) { 
    switch (status) {
      case 'Tratamiento':
          return 'alert-primary';
      case 'Revisión':
          return 'alert-success';
      case 'Bloqueo' :
          return 'alert-secondary';
      case 'Consulta':
          return 'alert-info'
      case 'Cirugía':
          return 'alert-dark';
      case 'Primera Cita':
          return 'alert-warning';
      case 'Cancelada':
          return 'alert-danger';
      default: 
        return 'alert-light'
    }
  }

  /**
   * 
   * @param status 
   */
  _getBgClass(status: string) {
    return this.calendar._getColorCalendar(status)
 /*    switch (status) {
      case 'Programada':
        return 'primary'
    case 'Exitosa':
        return 'info'
    case 'Confirmada':
        return 'success'
    case 'Cancelada':
        return 'danger'    
    default:
      return 'warning'   
    } */
  }

  /**
   * 
   */
  hideCanvas() {
    this.eventCanvasEmiiter.emit('onclose')

  }

  _gotoForm(_cita: Cita){       
   this.spinner.show(this._spinner_canvas)    
    this.foramto._getForamtoByCita(_cita.Cita_Id).then((resp) => {
      this.spinner.hide()   
      if(resp){
        this.localService.savedJsonValues('_json_formatos_cita',resp)
        this._getPatientsData(_cita)            
      }
    }).catch((error) => {
      this.spinner.hide()
      console.error({error});     
    })




    //this.redirect.to('')
  }
  
  private _getPatientsData(_cita: Cita) {

  this.patients._getPatientInfo(_cita.Paciente_Id).pipe(map((response) => {     
      this.credentials.saveCitaJson(_cita)
      this.credentials.saveUserinfo(response);
      return response
    })).subscribe((resp: any) => {
      console.log({resp});      
      if(this._canViewCalendar()) {
        this.eventCanvasEmiiter.emit('redirect')  
      }else if (this.credentials.credentials?.roles.Callcenter) {
        this.spinner.hide(this._spinner_canvas)
        this._patientSelected = resp.data.records[0]
        this._ngbModal = this.modalService.open(this.modalDetailPatient, {
          size: 'xl',
          animation: true,
          backdrop: 'static',
          fullscreen: 'lg'
        })
      }
     //      
    }) 

  }
  
  _showDetails(id: string) {
    this.eventSubjectId.next(id)
    //this.eventSubject.next({id: id, isClose: false, isOpen: true, title }) 
  }

  /**
   * 
   * @param id 
   */
  _showDetailsRecurso(id: string) {
    this.eventSubjectRecurdo.next(id)
  } 


  /**
   * 
   */
  _OpenModalCita(option: number) {
    
    this._optionForm = option

    this._ngModal = this.modalService.open(this.modalCita, {
      size:'xl',
      animation:true,
      backdrop:'static',
      fullscreen:'lg'
    })

  }

  /**
   * 
   */
  closeEventModal(){
    this._ngModal.close()
  }

  /**
   * 
   */
  onClickEvent(event:boolean){
    if(event) {
      this._ngModal.close()
    }
  }

  /**
   * 
   */
  _sendNotification() {
    this._sendNotificaction =  true
    this.spinner.show(this._spinner_canvas)

    if(this._detallesCita) {
      const _doctorId = this._detallesCita.doctor ? this._detallesCita.doctor.Id : null  ///.Id

      if(_doctorId != null) {
        
        this.calendar._getDoctorByid(_doctorId).subscribe({
          next:(response) => {
              if(response.codigo == 200) {
                const _webSocket = response.data.info?.Websockets
                if(_webSocket) {
                  //console.log({_webSocket});
                  this.wbSocket.connectClientWs(_webSocket.paciente_espera.cliente)    
                  this.wbSocket.sendMessage(this._detallesCita.cita.Cita_Id,_webSocket.paciente_espera.cliente, this._detallesCita.cita.Paciente_Nombre_Completo)
                  this.toastr.success('Notificacion enviada con exito','Exito al enviar',{
                    timeOut: 3200
                  })
                }else { 
                  console.log('NOT FOUND WS');
                  this.toastr.warning('No existe una forma de notificar al doctor','Error en notififacion',{
                    timeOut: 3200
                  })                  
                }
                this._sendNotificaction = false
                this.spinner.hide(this._spinner_canvas)

              }
          },
          error:(error) => {
            this._sendNotificaction = false
            this.spinner.hide(this._spinner_canvas)
            console.error({error});        
          }
        })
      }
    }  

  }

  private  _canViewCalendar(): boolean {
    if(this.credentials.credentials?.roles.Administrador || this.credentials.credentials?.roles.Doctor || this.credentials.credentials?.roles.Asistencia /* || this.credentials.credentials?.roles.Callcenter */) {
      return true
    }
    return false
  }


}
