import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import * as _ from 'lodash';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';

import { CredentialsService } from 'src/app/core/services/credentials.service';
import { Cita } from 'src/app/pages/calendar/models/calendar';
import { NotaDeEvolucion } from 'src/app/shared/models/nota-evolucion';
import { AlertsService } from 'src/app/shared/services/alerts.service';
import { FormatsService } from 'src/app/shared/services/formats.service';

@Component({
  selector: 'app-form-nota-evolucion',
  templateUrl: './form-nota-evolucion.component.html',
  styleUrls: ['./form-nota-evolucion.component.scss']
})
export class FormNotaEvolucionComponent implements OnInit {

  @Input()
  item: any

  @Input()
  controls: any

  @Input()
  isDesktopDevice: boolean

  @Input()
  _cita: Cita;

  @Input()
  record: any

  @Output()
  _eventLoadHistoriaClinica: EventEmitter<boolean> = new EventEmitter<boolean>()

  _formNotasEvolucion: FormGroup = this.formBuilder.group({})
  _showForm: boolean

  _userData: any

  _svgFiles: any[] = []
  
  constructor(
    private formBuilder: FormBuilder,
    private formatService: FormatsService,
    private toastr: ToastrService,
    private credentials: CredentialsService,
    private alert: AlertsService
  ) {
    const _dataUser = this.credentials._getdataUser()

    if(_dataUser) {
      this._userData = _dataUser

      console.log({_dataUser});
      
    }
  }

  /**
   * 
   */
  ngOnInit(): void {

    console.log({ 
      item: this.item,
      controls: this.controls

    });
    

    if(this.controls) {      
      _.map(this.controls.Objects, (a) => {
        this._formNotasEvolucion.addControl(a.name, this.formBuilder.control(a.value))                
      })
      this._showForm = true
    }
  }

  /**
   * 
   */
  _onsave(): void {

    let _notaEvolucion: NotaDeEvolucion = new NotaDeEvolucion()
    const _values = this._formNotasEvolucion.value
    
    this.alert._showLoader('Guardando, por favor espere...')

    _notaEvolucion = {
      Cita_Id: this._cita.Cita_Id,
      analisis: _values.analisis_nota_evolucion_historia_clinica_varices,
      fecha: moment(_values.fecha_nota_evolucion_historia_clinica_varices).format('YYYY-MM-DD'),
      objetivo: _values.objetivo_nota_evolucion_historia_clinica_varices,
      plan: _values.plan_nota_evolucion_historia_clinica_varices,
      subjetivo: _values.subjetivo_nota_evolucion_historia_clinica_varices,
      usuario:this._userData.usuario
    }

    this.formatService.AgregarNotaEvolucion(_notaEvolucion,this._svgFiles,this.record.Name,this.controls.name).then((resp) => {
      this.alert._hideLoader()
      this.toastr.info('Se ha guardado la información','Exito', {
        timeOut: 3200
      })
      
      this._formNotasEvolucion.reset()
      

      this._eventLoadHistoriaClinica.emit(true)


    }).catch((error) => {
      this.alert._hideLoader()
      this.toastr.error('Error al guardar la nota de evolucion','Error',{
        timeOut: 3200
      })  
    })
  }

  /**
   * 
   * @param evet 
   */
  _onEventEmmiter(evet: any[]) {
    this._svgFiles = evet    
  }

  /**
   * 
   * @param _files 
   */
  _onUploadFiles(_files: any[]) {
    this.alert._showLoader('Subiendo, por favor espere')
    console.log({_files});   
    this.formatService.UploadFiles(this._cita.Cita_Id , this.record.Name, this.controls.name , _files).then((resp) => {
      this.alert._hideLoader()
      this.toastr.success('Exito al subir los archivos','Exito!!',{
        timeOut: 3200
      })
    }).catch((error) => {
      this.alert._hideLoader()
      this.toastr.error('Algo salio mal, al tratar de subir los archivos','Error!!', {
        timeOut: 3200
      })
    })
  }

  /**
   * 
   * @param webcamImage 
   */
  _onSaveItImages(webcamImage: any[]) {
    if(webcamImage && webcamImage.length) {
      this.alert._showLoader('Guardando, espere...')

      console.log({webcamImage: webcamImage});    
      this.formatService.UploadFiles(this._cita.Cita_Id, this.record.Name, this.controls.name, webcamImage).then((resp) => {
        this.alert._hideLoader()
        this.toastr.success('Exito al subir las imagenes','Exito!!',{
          timeOut: 3200
        })
      }).catch((error) => {
        console.error({error});
        
        this.alert._hideLoader()
        this.toastr.error('Algo salio mal, al tratar de subir las imagenes','Error!!', {
          timeOut: 3200
        })
      })
    }
  }

}
