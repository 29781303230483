<div class="" *ngIf="_formato && _itsReady">
    <div class="mt-2" *ngFor="let control of _controls">
        <form [formGroup]="_formVarices" class="needs-validation createfile-form" autocomplete="off" novalidate>

            <ng-container *ngIf="control.type == 'frame'; else titleTemplate ">
                <!-- TITULOS -->
                <div class="bg-primary">
                   <h4 class="fs-4 text-white bold text-uppercase text-center">{{control.label}} </h4>     
               </div>
            </ng-container>

           <ng-template #titleTemplate>
            
            <div class="bg-primary">
                <h4 class="fs-4 text-white bold text-uppercase text-center">{{control.label}} </h4>     
            </div>

            <div class="card card-body mb-0">
                <div class="row ">
                    
                    <ng-container *ngIf="control.name === 'exploracion_fisica_historia_clinica_varices'; else _inputsTemplate ">
                        <div *ngFor="let objects of control.Objects" 
                        [ngClass]="objects.Objects ? 'col-12': 'col-6' ">
                            <div *ngIf="objects.name === 'signos_vitales_exploracion_fisica_historia_clinica_varices' ">
                                <div class="row mt-4">
                                    <div class="col-md-6 col-xxl-4" *ngFor="let _obj of objects.Objects" >
                                        <div *ngIf="_obj.type == 'text' || _obj.type == 'time' || _obj.type == 'number' ">
                                            <div class="mt-2">
                                                <label class="form-label">{{_obj.label}} </label>
                                                <input
                                                [type]="_obj.type"
                                                [name]="_obj.name"
                                                [formControlName]="_obj.name"
                                                [required]="_obj.required"
                                                [readonly]="_obj.readonly"                                                       
                                                class="form-control">  
                                            </div>
                                        </div>                                  
                                    </div>
                                </div>
                            </div>                      
                            
                            <hr>

                            <div *ngIf="objects.type == 'text' || objects.type == 'time' || objects.type == 'number' ">
                                <div class="mt-2">
                                    <label class="form-label">{{objects.label}} </label>
                                    <input
                                    [type]="objects.type"
                                    [name]="objects.name"
                                    [formControlName]="objects.name"
                                    [required]="objects.required"
                                    [readonly]="objects.readonly"                                                       
                                    class="form-control">  
                                </div>
                            </div>  
                            
                            <div *ngIf="objects.name === 'tabla_clinica_exploracion_fisica_historia_clinica_varices'">

                                <div class="d-flex justify-content-center mt-4">
                                    <div class="">
                                        <button type="button" class="btn btn-primary text-uppercase" (click)="_openModalSvg()"> <i class="mdi mdi-plus-box-multiple align-middle me-1 "></i> Agregar exploración física</button>
                                    </div>
                                </div>

                                <div class="d-flex mt-4" *ngIf="_svgEditors && _svgEditors.length">
                                    <div class="card card-body">
                                        <div class="row gp-2 ">
                                            <div class="col-xxl-4 col-lg-6" *ngFor="let item of _svgEditors"> 
                                                <app-svg-editor
                                                [_path]="item.path"
                                                [_svgItem]="item"
                                                (eventEmmiterSaveImg)="_onEvenEmmiter($event)"
                                                (eventEmmiterImage)="_onEventEmmiterImg($event)"
                                                ></app-svg-editor>
                                            </div>
                                        </div>   
                                        
                                        <div class="d-flex justify-content-center mt-4" *ngIf="_svgfilesPng && _svgfilesPng.length">
                                            <button class="btn btn-success w-100" (click)="_saveSvgToPng(control)">Guardar</button>
                                        </div>
                                    </div>
                                </div>


                                <div class="mt-4">
                                    <fieldset>  
                                        <div class="card card-body">
                                        <app-custom-upload-files 
                                        [showUpload]="true" 
                                        [_maxFiles]="10" 
                                        [_showGallery]="true"
                                        [_acceptedFileTypes]="['image/jpeg','image/png','image/jpg','application/pdf']"
                                        (_filesPondsEvent)="_onUploadFiles($event,control)"
                                        [eventSubjectClerFiles]="eventSubjectClerFiles.asObservable()">
                                        </app-custom-upload-files>
                                        </div>
                                    </fieldset>    
                                    <div class="mt-4" *ngIf="isDesktopDevice">
                                        <app-ng-web-cam 
                                        (eventSaveImages)="_onSaveItImages($event, control)"
                                        ></app-ng-web-cam>                                         
                                    </div>
                                </div>

                                <div class="d-flex mt-4" *ngIf="_historia_Clinica_Varices && _historia_Clinica_Varices.length">
                                    <div class="card card-body">
                                        <app-gallery-image [base64]="false" [GalleryImage]="_historia_Clinica_Varices" (eventEmmitDelete)="_onDeleteImg($event)"></app-gallery-image>
                                    </div>
                                </div>

                                <div class="d-flex mt-4" *ngIf="_historia_clinica_pdf && _historia_clinica_pdf.length">
                                  <fieldset>
                                    <legend class="text-muted fs-12 fw-bold"> Archivos</legend>
                                  <!--   <div class="card card-body">
                                  
                                    </div>   -->
                                    <div class="row m-2">
                                        <div class="col-4" *ngFor="let file of _historia_clinica_pdf">
                                            <div class="d-flex justify-content-center">
                                                <a href="javascript:void(0);" (click)="_openPDF(file)">
                                                    <i style="font-size: 40px;" class='bx bx-file-blank' > </i>                               
                                                </a>                               
                                            </div> 
                                        </div>
                                    </div>
                                  </fieldset>                                      
                                </div>

                                <fieldset class="mt-4" *ngIf="_LaboratorioFiles && _LaboratorioFiles.length">
                                    <legend class="text-muted fs-12 fw-bold"> Archivos cargados/Laboratorio</legend>                       
                                    <div class="row m-2">
                                        <div class="col-4" *ngFor="let file of _LaboratorioFiles">
                                            <div class="d-flex justify-content-center">
                                                <a href="javascript:void(0);" (click)="_openPDF(file)">
                                                    <i style="font-size: 40px;" class='bx bx-file-blank' > </i>                               
                                                </a>                               
                                            </div>     
                                        </div>
                                    </div>
                                </fieldset>

                                <hr>
                                
                                <div class="row mt-4">
                                    <div  *ngFor="let _obj of objects.Objects" [ngClass]="_obj.type === 'label' ? 'col-xxl-3 col-lg-6' : _obj.type === 'canvas' ? 'col-12' : 'col-xxl-4 col-lg-6'  ">
                                        <ng-container *ngIf="_obj.type === 'label'; else otherTemplate">   
                                            <div class="card">
                                                <div class="card-header">
                                                    <span class="fw-bold text-primary"> {{_obj.label}} </span>
                                                </div>
                                                <div class="card-body">
                                                    <div class="row">
                                                        <div class="col-12" *ngFor="let objs of _obj.Objects">
                                                                <ng-container *ngIf="objs.Objects; else _checkTemplate">
                                                                    <div class="row" *ngFor="let _objs of objs.Objects">
                                                                        <div class="col-3">{{objs.label}} </div>
                                                                        <div class="col-9">
                                                                            <div *ngIf="_objs.type === 'checkbox'">
                                                                                <div class="form-check form-check-right mb-2">
                                                                                    <input 
                                                                                    class="form-check-input" 
                                                                                    type="checkbox" 
                                                                                    [formControlName]="_objs.name"     
                                                                                    [value]="_objs.value"     
                                                                                    [id]="_objs.name">
                                                                                    <label class="form-check-label" for="formCheckboxRight1">
                                                                                        {{_objs.label}}
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </ng-container>
                                                                <ng-template #_checkTemplate>
                                                                    <div *ngIf="objs.type === 'checkbox' ">
                                                                        <!-- Form Checkbox Right -->
                                                                        <div class="form-check form-check-right mb-2">
                                                                            <input 
                                                                            class="form-check-input" 
                                                                            type="checkbox"
                                                                            [formControlName]="objs.name"
                                                                            [value]="objs.value"
                                                                            [id]="objs.name">
                                                                            <label class="form-check-label" for="formCheckboxRight1">
                                                                                {{objs.label}}
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                </ng-template>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-container>
                                        <ng-template #otherTemplate>
                                            <div *ngIf="_obj.type === 'canvas'">
                                                <fieldset class="mt-4">
                                                    <legend class="text-muted fs-12 text-uppercase">{{_obj.label}} </legend>            
                                                    <ng-template [ngTemplateOutlet]="firmaMedico"></ng-template>     
                                                </fieldset>  
                                            </div>
                                            <div class="mt-2" *ngIf="_obj.type === 'text' ">
                                                <label class="form-label">{{_obj.label}} </label>
                                                <input
                                                [type]="_obj.type"
                                                [name]="_obj.name"
                                                [formControlName]="_obj.name"
                                                [required]="_obj.required"
                                                [readonly]="_obj.readonly"                                                       
                                                class="form-control">  
                                            </div>
                                        </ng-template>
                                    </div>   
                                </div>
                            </div>
                        </div>
                    </ng-container>
              
                    <ng-template #_inputsTemplate>
                        <!-- NOTA DE EVOLUCION -->
                        <ng-container *ngIf="control.name === 'nota_evolucion_historia_clinica_varices'; else formsControl">

                            <!-- LISTADO NOTAS DE EVOLUCION -->
                            <div class="d-flex mt-4" *ngIf="_citas_notas_evolucion && _citas_notas_evolucion.length">
                                <div class="col-12">
                                    <fieldset>
                                        <legend class="text-muted text-uppercase fs-16 text-center ">Notas de evolución </legend>
                                        <div class="row">
                                            <div class="col-md-auto" *ngFor="let item of _citas_notas_evolucion">
                                                <div class="float-start">
                                                    <div class="d-grid gap-2" >                       
                                                        <button type="button" class="btn btn-light btn-label waves-effect waves-light rounded-pill" (click)="_set_log_notasEvolucion(item)">
                                                            <i class="ri-error-warning-line label-icon align-middle rounded-pill fs-16 me-2"></i> {{item.Cita_Id}}
                                                        </button>                                    
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </fieldset>

                                    <hr>
                                    <fieldset class="mt-4">
                                        <legend>
                                            <h5 class="text-muted text-uppercase mb-3">Notas de evolución -  {{_cita_selected}} </h5>
                                        </legend>
                                        <div class="row">
                                            <div class="col-md-4 col-xxl-2 col-xl-3">
                                                <ngx-simplebar style="max-height: calc(50vh - 70px)">                                                    
                                                    <div class="d-flex align-items-center ">
                                                        <div class="flex-grow-1 ms-3 ">                     
                                                            <div class="row gap-2 m-2">
                                                                <div class="col-12" *ngFor="let item of _log_notas_evolucion">
                                                                    <div class="d-grid gap-2" >                       
                                                                        <button type="button" class="btn btn-light btn-label waves-effect waves-light rounded-pill" (click)="_setNotaEvolucion(item)">
                                                                            <i class="ri-error-warning-line label-icon align-middle rounded-pill fs-16 me-2"></i> Nota # {{item.sesion}}
                                                                        </button>                                    
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </ngx-simplebar>
                                            </div>
                                            <div class="col-md-8 col-xxl-10 col-xl-9" *ngIf="_selected_nota_evolucion">
                                                <div class="card card-body">
                                                    <div class="row">                                                    
                                                        <div class="col-xxl-4 col-md-6">
                                                            <label class="control-label">Fecha</label>
                                                            <input class="form-control" [value]="_selected_nota_evolucion.fecha" readonly disabled>
                                                        </div>
                                                        <div class="col-xxl-6 col-md-12">
                                                            <label class="control-label">subjetivo</label>
                                                            <input class="form-control" [value]="_selected_nota_evolucion.subjetivo" readonly disabled>
                                                        </div>
                                                        <div class="col-xxl-6 col-md-6">
                                                            <label class="control-label">objetivo</label>
                                                            <input class="form-control" [value]="_selected_nota_evolucion.objetivo" readonly disabled>
                                                        </div>
                                                        <div class="col-xxl-6 col-md-6">
                                                            <label class="control-label">analisis</label>
                                                            <input class="form-control" [value]="_selected_nota_evolucion.analisis" readonly disabled>
                                                        </div>
                                                        <div class="col-xxl-6 col-md-6">
                                                            <label class="control-label">plan</label>
                                                            <input class="form-control" [value]="_selected_nota_evolucion.plan" readonly disabled>
                                                        </div>
                                                        <div class="col-xxl-6 col-md-6">
                                                            <label class="control-label">Medico</label>
                                                            <input class="form-control" [value]="_selected_nota_evolucion.nombre_medico" readonly disabled>
                                                        </div>
                                                    </div>                                              
                                                </div>
                                            </div>
                                        </div>
                                        <div class="d-flex mt-4" *ngIf="_selected_nota_evolucion && _selected_nota_evolucion.imagenes.length">
                                            <fieldset>
                                                <div class="mt-4">
                                                    <app-gallery-image [base64]="false" 
                                                    [GalleryImage]="_selected_nota_evolucion.imagenes"
                                                    ></app-gallery-image>
                                                </div>
                                            </fieldset>
                                        </div>                                    
                                        <hr>
                                        <div class="d-flex mt-4" *ngIf="_img_nota_evolucion_historia_clinica_varices && _img_nota_evolucion_historia_clinica_varices.length">
                                            <fieldset>
                                                <legend class="text-muted fw-bold fs-14">Todos los archivos</legend>                                                
                                                <div class="mt-4">
                                                    <app-gallery-image [base64]="false" [GalleryImage]="_img_nota_evolucion_historia_clinica_varices"></app-gallery-image>
                                                </div>
                                            </fieldset>
                                        </div>
                                    </fieldset>
                                </div>                                                                                          
                            </div> <!-- END  LISTADO NOTAS DE EVOLUCION -->
                            
                            <div class="d-flex justify-content-end">
                                <button class="btn btn-primary" (click)="_AddedNote(control)"> 
                                    <i class="mdi mdi-account-plus align-middle me-1"></i> Agregar nota de evolución  
                                </button>
                            </div>

                            <fieldset class="mb-0 m-2">
                                <legend>Notas de Evolucion</legend>        
                                <hr>  
                                <div class="row d-flex justify-content-beetwen">   
                                    <div *ngFor="let item of _notas_evolucion; let i = index" class="col-12">
                                        <ngx-simplebar style="max-height: calc(100vh - 70px)">
                                            <div class="card card-body">
                                                <fieldset class="mt-4">
                                                    <legend class="small text-muted"> {{item.label}} #{{ _log_notas_evolucion.length +1}} 
                                                        <div class="float-end"> <button class="btn btn-icon" (click)="_deleteNota(i)"> 
                                                            <i class="mdi mdi-trash-can-outline align-middle me-2 text-danger fs-2"></i> </button> 
                                                        </div>    
                                                    </legend>                     
                                                    <app-form-nota-evolucion 
                                                    [item]="item" 
                                                    [controls]="control" 
                                                    [isDesktopDevice]="isDesktopDevice" 
                                                    [_cita]="_Cita" 
                                                    [record]="record"
                                                    (_eventLoadHistoriaClinica)="_onEventSaveItFormNotasEvolucion($event)"></app-form-nota-evolucion>  
                                                </fieldset>    
                                            </div>                                            
                                        </ngx-simplebar>
                                    </div>
                                </div>
                                <hr>
                                <div class=" mt-4" *ngIf="_notas_evolucion && _notas_evolucion.length">
                                    <fieldset>
                                        <legend class="text-muted fs-12 text-uppercase">FIRMA DEL MÉDICO TRATANTE: </legend>
                                        <ng-template [ngTemplateOutlet]="firmaMedico"></ng-template>                                      
                                     </fieldset>                                     
                                </div>
                            </fieldset>   

                        </ng-container>
                        <!--  -->
                        <ng-template #formsControl>

                            <ng-container *ngIf="control.name === 'reporte_quirurgico_historia_clinica_varices'; else _controlsForm " >
                                <div class="row">
                                    <div  *ngFor="let objects of control.Objects; let i = index" 
                                    [ngClass]="objects.type === 'canvas' || objects.type === 'textarea' ? 'col-12': 'col-xx-4 col-md-6' " >
                                        <div class="mb-0" *ngIf="objects.type != 'canvas' ">
                                            <label class="control-label">{{objects.label}} </label>
                                            <div *ngIf="objects.type == 'text' || objects.type == 'time' || objects.type == 'number' ">
                                                <input
                                                [type]="objects.type"
                                                [name]="objects.name"
                                                [formControlName]="objects.name"
                                                [required]="objects.required"
                                                [readonly]="objects.readonly"                                                       
                                                class="form-control">   
                                            </div>
                                            <div *ngIf="objects.type === 'textarea' ">
                                                <textarea
                                                [name]="objects.name"
                                                [formControlName]="objects.name"
                                                [rows]="4"
                                                [required]="objects.required"
                                                [readonly]="objects.readonly"   
                                                class="form-control"
                                                ></textarea>
                                            </div>
                                            <div *ngIf="objects.type === 'date' ">
                                                <input                                                 
                                                class="form-control flatpickr-input"                                                                 
                                                [id]="objects.name"     
                                                [type]="objects.type"     
                                                mwlFlatpickr 
                                                [altInput]="true" 
                                                [formControlName]="objects.name"
                                                [value]="objects.value"
                                                [convertModelValue]="true"> 
                                            </div>
                                        </div>
                                        <div *ngIf="objects.type === 'canvas' " class="mt-4">
                                            <fieldset>
                                                <legend class="text-muted fs-12 text-uppercase">{{objects.label}}  </legend>
                                                <ng-template [ngTemplateOutlet]="firmaMedico"></ng-template>
                                            </fieldset>  
                                        </div>
                                    </div>
                                </div>
                            </ng-container>

                            <ng-template #_controlsForm>
                                <div *ngFor="let objects of control.Objects; let i = index" class="col-12">
                                    <div class="row mt-4">          
                                        <div class="col-4">
                                            <dt class="fs-normal lh-sm"> {{objects.label}} </dt>
                                        </div>
                                        <div class="col-8">
                                            <div *ngIf="objects.type == 'text' || objects.type == 'time' || objects.type == 'number' ">
                                                <div class="">
                                                    <input
                                                    [type]="objects.type"
                                                    [name]="objects.name"
                                                    [formControlName]="objects.name"
                                                    [required]="objects.required"
                                                    [readonly]="objects.readonly"                                                       
                                                    class="form-control">                                                      
                                                </div>
                                            </div>
    
                                            <div *ngIf="objects.type == 'date' ">
                                                <div class="">
                                                    <input                                                 
                                                    class="form-control flatpickr-input"                                                                 
                                                    [id]="objects.name"     
                                                    [type]="objects.type"     
                                                    mwlFlatpickr 
                                                    [altInput]="true" 
                                                    [formControlName]="objects.name"
                                                    [value]="objects.value"
                                                    [convertModelValue]="true">                                                        
                                                </div>
                                            </div>
    
                                            <div *ngIf="objects.type == 'checkbox' ">
                                                <div class="form-check form-check-right mt-4">                                                        
                                                    <input
                                                    class="form-check-input" 
                                                    [type]="objects.type" 
                                                    [name]="objects.name" 
                                                    [formControlName]="objects.name"
                                                    [required]="objects.required"                                                       
                                                    [checked]="objects.value == 'false' ? false : objects.value"        
                                                    [id]="objects.name + i">                                                       
                                                </div>
                                            </div>
    
                                            <ng-container *ngIf="objects && objects.Objects">
                                                <div class="row g-2">
                                                    <div  *ngFor="let objs of objects.Objects" [ngClass]="objs.type == 'checkbox' ? 'col-4' : 'col-12' "  >
    
                                                        <div *ngIf="objs.type == 'text' || objs.type == 'time' || objs.type == 'number' ">
    
                                                            <div class="row g-2">
                                                                <div class="col-5">
                                                                    <dt class="fs-normal lh-sm"> {{objs.label}} </dt>
    
                                                                </div>
                                                                <div class="col-7">
                                                                    <div class="">
                                                                        <input
                                                                        [type]="objs.type"
                                                                        [name]="objs.name"
                                                                        [formControlName]="objs.name"
                                                                        [required]="objs.required"
                                                                        [readonly]="objs.readonly"                                                       
                                                                        class="form-control">
                                                                    <!--   <label class="form-label bold text-uppercase"> {{objs.label}} </label> -->
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div *ngIf="objs.type == 'checkbox' ">
                                                            <div class="form-check  mb-3">
                                                                <input
                                                                class="form-check-input" 
                                                                type="checkbox"
                                                                [name]="objs.name"
                                                                [required]="objs.required"
                                                                [formControlName]="objs.name"
                                                                [readonly]="objs.readonly">
                                                                <label class="form-check-label" for="{{objs.name}}">{{objs.label}} </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-container>
                                        </div>  
                                    </div>
                                </div>
                            </ng-template>
                        
                        </ng-template>
                    </ng-template>              
                </div>
            </div>
           </ng-template>
        </form>
    </div>
</div>

<ng-template #modalSvg let-modal>
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="text-muted text-uppercase">selecciona la exploracion para agregar </h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="btn-close" (click)="modal.dismiss('Cross click')"></button>
        </div>
        <div class="modal-body">
            <ngx-simplebar style="max-height: calc(70vh - 70px)">
                <div class="row m-2">
                    <div class="col-md-6 col-lg-4 col-xl-3" *ngFor="let item of _assetsSvg; let i = index">
                        <div class="card">
                            <div class="card-body">
                                <img src="{{item}}" class="img-fluid">                              
                            </div>
                            <div class="card-footer">
                                <button class="btn w-100 btn-primary text-uppercase" (click)="_onSetsvg(item, i)">seleccionar</button>
                            </div>
                        </div>
                    </div>
                </div>
            </ngx-simplebar>           
        </div>
    </div>
</ng-template>

<ng-template #firmaMedico>
    <ng-container *ngIf="_firma && _firma.length; else _signatureTemplate">
        <fieldset class="mt-4" >
            <div class="card" *ngFor="let item of _firma" >
             <div class="card-body">
                 <div class="d-flex justify-content-center"  >
                     <figure class="figure mb-0">
                         <img [src]="item.url" class="figure-img img-fluid" max  alt="" width="300">
                     </figure>                                        
                 </div>                                    
             </div>                   
            </div>
         </fieldset>
    </ng-container>
    <ng-template #_signatureTemplate>
        <!-- <div class="d-flex justify-content-center">
            <button class="btn btn-primary w-50">Cargar firma</button>
        </div> -->
    </ng-template>
</ng-template>