<div class="card" *ngIf="_formato && _itsReady">
    <div class="card-body">
        <form [formGroup]="formDescQuirurgica" class="needs-validation createfile-form" autocomplete="off" novalidate>
            <div class="row g-2 m-4" *ngFor="let item of _controls">

                <ng-container *ngIf="item.type == 'frame' ">

                    <div class="col-12">
                        <div class="bg-primary">
                            <h4 class="fs-4 text-white bold text-uppercase text-center">{{item.label}} </h4>
                        </div>
                    </div>

                    <div class="row">
                        <div class="mt-4 mr-2" *ngFor="let obj of item.Objects"
                            [ngClass]="obj.type == 'textarea' || obj.type =='label' ? 'col-12' : 'col-sm-12 col-md-6 col-lg-6 col-xl-4' ">

                            <div *ngIf="obj.type == 'date' ">
                                <div class="form-floating">
                                    <input class="form-control flatpickr-input" [id]="obj.name" [type]="obj.type"
                                        [formControlName]="obj.name" readonly [readonly]="obj.readonly">
                                    <label [for]="obj.name" class="form-label">{{obj.label}} </label>
                                </div>
                            </div>

                            <div *ngIf="obj.type == 'text' || obj.type == 'number' ">
                                <div class="form-floating">
                                    <input class="form-control" [type]="obj.type" [name]="obj.name"
                                        [readonly]="obj.readonly" [formControlName]="obj.name"
                                        [value]="_getValue(obj.name)" [required]="obj.required">
                                    <label class="form-label" for="{{obj.name}}">{{obj.label}} </label>
                                </div>
                            </div>

                            <div *ngIf="obj.type == 'time' ">
                                <input type="text" [name]="obj.name" [formControlName]="obj.name"
                                    [value]="_getValue(obj.name)" class="form-control">
                            </div>

                            <div *ngIf="obj.type == 'textarea' && obj.name != 'texto_descripcion_tecnica_quirurgica' ">
                                <div class="card-body">
                                    <div class="row mt-3">
                                        <div class="col-xl-3">
                                            <label for="{{obj.name}}" class="form-label">{{obj.label}}</label>
                                        </div>
                                        <div class="col-xl-9 col-lg-12">
                                            <app-text-area-voice-recorder [transcript]="f[obj.name].value"
                                                [name]="obj.name" [required]="obj.required" ngDefaultControl
                                                [formControlName]="obj.name" [rows]="obj.name === 'hallazgos_transoperatorios' || 
                                                        obj.name === 'otros_hallazgos_transoperatorios' || 
                                                        obj.name === 'reporte_gasas_compresas' ||
                                                        obj.name === 'incidentes_accidentes' ||
                                                        obj.name === 'cuantificacion_sangrado' ? 2 : 4 "
                                                (eventEmmiterValue)="_onEventEmmiterValue($event, obj)">
                                            </app-text-area-voice-recorder>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div *ngIf="obj.name === 'texto_descripcion_tecnica_quirurgica' ">
                                <app-text-area-voice-recorder [transcript]="f[obj.name].value" [name]="obj.name"
                                    [required]="obj.required" ngDefaultControl [formControlName]="obj.name" [rows]="20"
                                    (eventEmmiterValue)="_onEventEmmiterValue($event, obj)">
                                </app-text-area-voice-recorder>
                            </div>
                        </div>
                    </div>
                </ng-container>

                <ng-container *ngIf="item.type === 'label' ">
                    <div class="row d-flex justify-content-beetwen  ">
                        <div class="col-xl-6 col-lg-12" *ngFor="let objs of item.Objects">
                            <ng-container *ngIf="objs.type === 'canvas' ">
                                <div class="form-group">
                                    <label class="form-label"> {{objs.label}} </label>
                                    <p class="text-muted"> {{objs.label2}} </p>

                                    <div *ngIf="objs.name === 'firma_enfermera_intrumentista' ">
                                        <ng-container
                                            *ngIf="firma_enfermera_intrumentista != null; else _firmaTemplate">
                                            <div class="card card-body">
                                                <div class="d-flex justify-content-center">
                                                    <figure class="figure mb-0">
                                                        <img [src]="firma_enfermera_intrumentista"
                                                            class="figure-img img-fluid" max alt="" width="300">
                                                    </figure>
                                                </div>
                                            </div>
                                        </ng-container>
                                    </div>

                                    <div *ngIf="objs.name === 'firma_enfermera_circulante' ">
                                        <ng-container *ngIf="firma_enfermera_circulante != null; else _firmaTemplate">
                                            <div class="card card-body">
                                                <div class="d-flex justify-content-center">
                                                    <figure class="figure mb-0">
                                                        <img [src]="firma_enfermera_intrumentista"
                                                            class="figure-img img-fluid" max alt="" width="300">
                                                    </figure>
                                                </div>
                                            </div>
                                        </ng-container>

                                    </div>
                                    <div *ngIf="objs.name === 'firma_ayudante' ">
                                        <ng-container *ngIf="firma_ayudante != null; else _firmaTemplate">
                                            <div class="card card-body">
                                                <div class="d-flex justify-content-center">
                                                    <figure class="figure mb-0">
                                                        <img [src]="firma_ayudante" class="figure-img img-fluid" max
                                                            alt="" width="300">
                                                    </figure>
                                                </div>
                                            </div>
                                        </ng-container>

                                    </div>
                                    <div *ngIf="objs.name === 'firma_anestesiologo' ">
                                        <ng-container *ngIf="firma_anestesiologo != null; else _firmaTemplate">
                                            <div class="card card-body">
                                                <div class="d-flex justify-content-center">
                                                    <figure class="figure mb-0">
                                                        <img [src]="firma_anestesiologo" class="figure-img img-fluid"
                                                            max alt="" width="300">
                                                    </figure>
                                                </div>
                                            </div>
                                        </ng-container>

                                    </div>
                                    <div *ngIf="objs.name === 'firma_cirujano' ">
                                        <ng-container *ngIf="firma_cirujano != null; else _firmaTemplate">
                                            <div class="card card-body">
                                                <div class="d-flex justify-content-center">
                                                    <figure class="figure mb-0">
                                                        <img [src]="firma_cirujano" class="figure-img img-fluid" max
                                                            alt="" width="300">
                                                    </figure>
                                                </div>
                                            </div>
                                        </ng-container>

                                    </div>


                                    <ng-template #_firmaTemplate>
                                        <app-firma-doctor
                                            (eventsignatureImg)="_onSaveSing($event,objs.name)"></app-firma-doctor>
                                    </ng-template>

                                </div>
                            </ng-container>
                        </div>
                    </div>
                </ng-container>

            </div>
        </form>
    </div>
</div>