<div class="mt-4">
  <div class="d-flex justify-content-end">
    <a href="javascript:void(0);" (click)="_openFilters()"> <i-feather name="filter" class="fill-primary"></i-feather>
    </a>
  </div>

  <div class="row ">
    <div class="col-12">
      <div class="form-group">
        <label class="form-label">Buscar</label>
        <input type="text" class="form-control"
          placeholder="Filtrar por: Nombre de paciente / Nombre de doctor / Recurso / Numero de expediente"
          (keyup)="filterDataTablePlancha($event)">
      </div>
    </div>
  </div>
  <ng-container *ngIf="_isReady; else _loader">

    <ng-container *ngIf="_CitasPlancha && _CitasPlancha.length;  else emptyTemplate" class="mt-4">

      <!-- <code> {{_CitasPlancha | json }} </code> -->

      <ngx-datatable class="material mt-4" [rows]="_CitasPlancha" [columnMode]="ColumnMode.force" [headerHeight]="55"
        [footerHeight]="50" [rowHeight]="75" [count]="_CitasPlancha.length" [scrollbarH]="true"
        [messages]="{emptyMessage: 'No hay registros que mostrar'}" [limit]="7">

        <ngx-datatable-column name="Ver detalles" prop="Cita_Id" [resizeable]="false" [sortable]="false" [width]="100">
          <ng-template let-row="row" ngx-datatable-cell-template>
            <button class="btn btn-sm btn-primary" (click)="_OpenModal(row)">
              <i class="bx bx-windows align-center text-white"></i>
            </button>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column name="Ubicacion" prop="Ubication" [frozenLeft]="!isMobile ? true: false" [width]="300">
          <ng-template let-row="row" ngx-datatable-cell-template>
            <span class="text-primary fw-semibold">{{row.Ubication}}</span>
            <div class="row g-2  d-flex justify-content-between ">
              <div class="col">
                <i class="bx bx-error-circle fs-5 text-warning"></i>
              </div>
              <div class="col">
                <i class="mdi mdi-clipboard-clock text-info fs-6"></i>
                <!-- <i class=" bx bx-health text-ligth"></i> -->
              </div>
              <div class="col">
                <i class="bx bx-dollar fs-5 text-success"></i>
              </div>
            </div>

          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column name="Unidad" prop="Unidad" [width]="300">
          <ng-template let-row="row" ngx-datatable-cell-template>
            <span class="text-muted text-center"> {{row.Unidad}} </span>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column name="Fecha" prop="Fecha_Inicio">
          <ng-template let-row="row" ngx-datatable-cell-template>
            {{row.Fecha_Inicio | date: 'dd/MM/yyyy' }}
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column name="Horario" prop="Fecha_Inicio" [resizeable]="false" [width]="200">
          <ng-template let-row="row" ngx-datatable-cell-template>
            {{row.Fecha_Inicio | date: 'HH:mm a'}}
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column name="Doctor" prop="Empleado_Id" [width]="300">
          <ng-template let-row="row" ngx-datatable-cell-template>
            {{ _getDoctor(row.Empleado_Id) }}
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column name="#Paciente" prop="Paciente_Id">
          <ng-template let-row="row" ngx-datatable-cell-template>
            <div class="text-center">
              <a href="javascript:void(0);"
                class="link-secondary  text-decoration-underline  link-underline-opacity-100-hover"
                href="javascript:void(0);" (click)="_gotoForm(row)"> {{row.N_Expediente}} </a>
            </div>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column name="Nombre completo Paciente" prop="Paciente_Nombre_Completo" [width]="350">
          <ng-template let-row="row" ngx-datatable-cell-template>
            {{row.Paciente_Nombre_Completo}}
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column name="Servicio o procedimiento" prop="Servicio_Id" [width]="300">
          <ng-template let-row="row" ngx-datatable-cell-template>
            <span class="custom-text"> {{ _getServicio(row.Servicio_Id ) }} </span>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column name="Tipo de Evento" prop="Tipo_Cita">
          <ng-template let-row="row" ngx-datatable-cell-template>
            {{row.Tipo_Cita}}
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column name="Estatus" prop="Estatus">
          <ng-template let-row="row" ngx-datatable-cell-template>
            {{row.Estatus}}
          </ng-template>
        </ngx-datatable-column>
        <!-- Doctor ayudante -->
        <ngx-datatable-column name="Doctor ayudante" prop="Doctor" [width]="300">
          <ng-template let-row="row" ngx-datatable-cell-template>
            <ng-container *ngIf="!_enfermero else defaultTemplate">
              <ng-container *ngIf="row.Ayudante != null ; else btnDoctorTemplate">
                <span> {{_getDoctor(row.Ayudante) }} </span>
                <div class="row d-flex justify-content-between">
                  <div class="col" *ngIf="_isAdmin">
                    <button class="btn btn-icon btn-topbar rounded-circle btn-md btn-ghost-info"
                      (click)="openModal(row,'Editar doctor ayudante','doctor_ayudante')">
                      <i class="ri-pencil-line align-bottom text-info"></i>
                    </button>
                  </div>
                  <div class="col" *ngIf="_isAdmin;">
                    <button class="btn btn-icon btn-topbar rounded-circle btn-md btn-ghost-danger"
                      (click)="deltedAssigned(row,'doctor_ayudante')">
                      <i class="ri-delete-bin-7-line align-bottom text-danger"></i>
                    </button>
                  </div>
                </div>
              </ng-container>
              <ng-template #btnDoctorTemplate>
                <div class="text-center">
                  <button *ngIf="_isAdmin" class="btn btn-icon btn-md btn-topbar btn-ghost-primary rounded-circle"
                    (click)="openModal(row,'Seleccione al doctor ayudante','doctor_ayudante')">
                    <i class="ri-user-add-line align-bottom text-primary"></i>
                  </button>
                </div>
              </ng-template>
            </ng-container>
            <ng-template #defaultTemplate>

              <small class="text-muted">

                <ng-container *ngIf="row.Ayudante != null; else emptyAyudante">
                  {{_getDoctor(row.Ayudante) }}
                </ng-container>

                <ng-template #emptyAyudante> <span>Pendiente</span> </ng-template>

              </small>

            </ng-template>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column name="Anestesia">
          <ng-template let-row="row" ngx-datatable-cell-template>
            <ng-container *ngIf="_isAdmin else templateAnestesia">
              <div class="text-center" *ngIf="_isAdmin">
                <input class="form-check-input" (click)="_onSetAnestecia($event, row)" [checked]="row.Anestesia"
                  type="checkbox" id="form-{{row.Cita_Id}}">
              </div>
            </ng-container>
            <ng-template #templateAnestesia>
              <div class="text-center non-events">
                <input class="form-check-input" (click)="_onSetAnestecia($event, row)" [checked]="row.Anestesia"
                  type="checkbox" id="form-{{row.Cita_Id}}">
              </div>
            </ng-template>
          </ng-template>

        </ngx-datatable-column>

        <!-- Anestesilogo -->
        <ngx-datatable-column name="Anestesiólogo" prop="Cita_Id" [width]="300">
          <ng-template let-row="row" ngx-datatable-cell-template>
            <ng-container *ngIf="!_enfermero; else templateAnestesilogo">

              <ng-container *ngIf="row.Anestesista; else btnTemplate">
                <span> {{ _getAnestesiologo(row.Anestesista)}} </span>
                <div class="row d-flex justify-content-between ">
                  <div class="col" *ngIf="_isAdmin; else undoAnestesiologo">
                    <button class="btn btn-icon btn-topbar rounded-circle btn-md btn-ghost-danger"
                      (click)="deltedAssigned(row,'anestesiologo')">
                      <i class="ri-delete-bin-7-line align-bottom text-danger"></i>
                    </button>
                  </div>

                  <ng-template #undoAnestesiologo>
                    <div class="col">
                      <ng-container *ngIf="_is_anestesiologo  && _id_anestesiologo == row.Anestesista  ">
                        <button class="btn btn-icon btn-topbar rounded-circle btn-md btn-ghost-danger"
                          (click)="deltedAssigned(row,'anestesiologo')">
                          <i class="ri-delete-bin-7-line align-bottom text-danger"></i>
                        </button>
                      </ng-container>
                    </div>
                  </ng-template>

                </div>



              </ng-container>

              <ng-template #btnTemplate>
                <!-- btn secondary  -->
                <ng-container *ngIf="_isAdmin; else _btnAnestesiologo">
                  <div class="text-center">
                    <button class="btn btn-icon btn-md btn-topbar btn-ghost-primary rounded-circle"
                      (click)="openModal(row,'Seleccione al anestesiologo','anestesiologo')">
                      <i class="ri-user-add-line align-bottom text-primary"></i>
                    </button>
                  </div>
                </ng-container>

                <ng-template #_btnAnestesiologo>

                  <button type="button" class="btn btn-icon btn-md btn-topbar btn-ghost-primary rounded-circle"
                    *ngIf="_is_anestesiologo" (click)="_onSelectedAnestesiologo(row)">
                    <i class="bx bx-paste align-bottom"></i>
                  </button>

                </ng-template>
                <!--         -->
              </ng-template>
            </ng-container>

            <ng-template #templateAnestesilogo>
              {{_getAnestesiologo(row.Anestesista)}}
            </ng-template>

          </ng-template>
        </ngx-datatable-column>

        <!-- Enfermero Quirurgico -->
        <ngx-datatable-column name=" Enfermeria Quirurgica" prop="Cita_Id" [width]="250">
          <ng-template let-row="row" ngx-datatable-cell-template>
            <ng-container *ngIf="!_enfermero; else templateEnfermeroQx">
              <ng-container *ngIf="_isAdmin; else templateValEnfQx">
                <ng-container *ngIf="!row.Enfermeria_Quirurgico; else templateValEnfQx">
                  <div class="text-center">
                    <button class="btn btn-icon btn-md btn-topbar btn-ghost-success rounded-circle"
                      (click)="openModal(row,'Seleccione al enfermero quirurgico','enfemero_qx')">
                      <i class="ri-user-add-line align-bottom text-success"></i>
                    </button>
                  </div>
                </ng-container>
              </ng-container>
            </ng-container>

            <ng-template #templateEnfermeroQx>
              <ng-container *ngIf="_enfermero && !row.Enfermeria_Quirurgico; else templateValEnfQx">
                <div class="text-center">
                  <button type="button" (click)="_onSetEnfermero('enfemero_qx', row)"
                    class="btn btn-icon btn-md btn-topbar btn-ghost-primary rounded-circle">
                    <i class="bx bx-paste align-bottom"></i>
                  </button>
                </div>
              </ng-container>
            </ng-template>
            <!-- Valor Enfermeria Quirurgico  -->
            <ng-template #templateValEnfQx>
              <span> {{_getEnfermeroQx(row.Enfermeria_Quirurgico)}} </span>
            </ng-template>
          </ng-template>

        </ngx-datatable-column>

        <!-- Enfermero General -->
        <ngx-datatable-column name="Enfermeria General" prop="Cita_Id" [width]="250">
          <ng-template let-row="row" ngx-datatable-cell-template>

            <ng-container *ngIf="_isAdmin; else templateEnfrGral">
              <ng-container *ngIf="!row.Enfermeria_General; else templateValEnfGral ">
                <button class="btn btn-icon btn-md btn-topbar btn-ghost-success rounded-circle"
                  (click)="openModal(row, 'Seleccione al enfermero general','enfermero_gral')">
                  <i class="ri-user-add-line align-bottom text-success"></i>
                </button>
              </ng-container>
            </ng-container>

            <ng-template #templateEnfrGral>
              <ng-container *ngIf="_enfermero && !row.Enfermeria_General; else templateValEnfGral">
                <button type="button" (click)="_onSetEnfermero('enfermero_gral', row)"
                  class="btn btn-icon btn-md btn-topbar btn-ghost-primary rounded-circle">
                  <i class="bx bx-paste align-bottom"></i>
                </button>
              </ng-container>
            </ng-template>

            <ng-template #templateValEnfGral>
              <span> {{ _getEnfermeroGral(row.Enfermeria_General) }} </span>
            </ng-template>

          </ng-template>
        </ngx-datatable-column>

        <!-- Biopsia -->
        <ngx-datatable-column name="Bioposia" prop="Cita_Id" [width]="80" [resizeable]="false" [sortable]="false">
          <ng-template let-row="row" ngx-datatable-cell-template>
            <ng-container *ngIf="_isAdmin else templateBiopsia">
              <div class="text-center" *ngIf="_isAdmin">
                <input class="form-check-input" (click)="_onSetBiposia($event, row)" [checked]="row.Biopsia"
                  type="checkbox" id="form-{{row.Cita_Id}}">
              </div>

            </ng-container>
            <ng-template #templateBiopsia>
              <div class="text-center non-events">
                <input class="form-check-input" (click)="_onSetBiposia($event, row)" [checked]="row.Biopsia"
                  type="checkbox" id="form-{{row.Cita_Id}}">
              </div>

            </ng-template>
            <!--       <div class="text-center" *ngIf="_isAdmin">
              <input class="form-check-input" type="checkbox" id="formCheck1">  
            </div>  -->
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column name="Seguro" prop="Seguro">
          <ng-template let-row="row" ngx-datatable-cell-template>
            <span>{{row.Seguro}} </span>
          </ng-template>
        </ngx-datatable-column>

      </ngx-datatable>

    </ng-container>

    <ng-template #emptyTemplate>
      <fieldset>
        <legend class="text-muted"> No hay datos que mostrar </legend>
      </fieldset>
    </ng-template>

  </ng-container>

</div>

<ng-template #modalShow let-modal>
  <div class="modal-header p-3 bg-primary-subtle">
    <h5 class="modal-title" id="modal-title">{{_title}} </h5>
    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-hidden="true"
      (click)="closeEventModal()"></button>
  </div>
  <div class="modal-body p-4">
    <form class="needs-validation" name="event-form" id="form-event" novalidate>
      <div class="row event-form">
        <div class="row ">
          <div class="col-12">
            <div class="form-group">
              <label class="form-label">Buscar</label>
              <input type="text" class="form-control" (keyup)="filterDataTable($event)">
            </div>
          </div>
        </div>
        <ngx-datatable #ngxtable class="material mt-4" [rows]="modalData" [scrollbarH]="true" rowHeight="auto"
          [footerHeight]="100" [headerHeight]="50" [limit]="15" [se] [rowHeight]="'auto'"
          [columnMode]="ColumnMode.force">
          <ngx-datatable-column name="#Empleado" prop="id_empleado">
            <ng-template let-row="row" ngx-datatable-cell-template>
              {{row.id_empleado}}
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column name="Nombre" prop="name">
            <ng-template let-row="row" ngx-datatable-cell-template>
              {{row.name}}
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column name="Tel. Celular" prop="celular">
            <ng-template let-row="row" ngx-datatable-cell-template>
              {{row.celular}}
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column name="Opcion" prop="id_salesforce" [resizeable]="false" [sortable]="false">
            <ng-template let-row="row" ngx-datatable-cell-template>
              <button class="btn btn-sm btn-primary" (click)="_onSetCalendar(row)">Asignar</button>
            </ng-template>
          </ngx-datatable-column>
        </ngx-datatable>
      </div>
    </form>
  </div>
</ng-template>

<ng-template #_loader>
  <div class="row" *ngIf="!_isReady">
    <p class="placeholder-glow" *ngIf="!_loaderError">
      <span class="placeholder placeholder-lg col-12"></span>
      <span class="placeholder col-4"></span>
      <span class="placeholder col-4"></span>
      <span class="placeholder col-6"></span>
    </p>

    <div data-aos="fade-up" *ngIf="_loaderError">
      <div class="row d-flex justify-content-center">
        <div class="col-12">
          <blockquote class="blockquote custom-blockquote blockquote-danger rounded mb-0">
            <p class="text-body mb-2">No se han podido recuperar resultados</p>

          </blockquote>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #modalDetailCita let-modal>
  <div class="modal-header p-3 bg-primary-subtle">
    <h5 class="modal-title text-uppercase fw-bold " id="modal-title">Detalles de la Cita {{_citaSelected.Cita_Id}} </h5>
    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-hidden="true"
      (click)="closemodalDetails()"></button>
  </div>
  <div class="modal-body p-4">
    <fieldset class="mt-4">
      <div class="alert alert-warning alert-border-left alert-dismissible fade show" role="alert">
        <i class="ri-alert-line me-3 align-middle"></i> <strong>Atencion</strong> - Paciente con las siguientes alergias
        <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
      </div>

      <div class="m-4">
        <blockquote class="blockquote custom-blockquote blockquote-outline blockquote-warning rounded mb-0">
          <p class="text-body mb-2">Custom Blockquote Outline Warning Example</p>
        </blockquote>
      </div>

    </fieldset>

    <div class="row g-2">
      <!-- Warning Alert -->


    </div>

    <div class="row g-2 mt-2">
      <!-- Ubicacion -->
      <div class="col-md-6">
        <div class="form-floating">
          <input [value]="_citaSelected.Ubication" class="form-control" readonly>
          <label class="form-label bold text-uppercase">Ubicacion</label>
        </div>
      </div>
      <!-- Unidad -->
      <div class="col-md-6">
        <div class="form-floating">
          <input [value]="_citaSelected.Unidad" class="form-control" readonly>
          <label class="form-label bold text-uppercase">Unidad</label>
        </div>
      </div>

      <!-- Fecha -->
      <div class="col-md-3 col-lg-2">
        <div class="form-floating">
          <input [value]="_citaSelected.Fecha_Inicio | date : 'dd/MM/yyyy' " class="form-control" readonly>
          <label class="form-label bold text-uppercase">Fecha</label>
        </div>
      </div>

      <!-- Horario -->
      <div class="col-md-3 col-lg-2">
        <div class="form-floating">
          <input [value]="_citaSelected.Fecha_Inicio | date : 'HH:mm a' " class="form-control" readonly>
          <label class="form-label bold text-uppercase">Horario</label>
        </div>
      </div>

      <!-- Doctor -->
      <div class="col-md-6 col-lg-8">
        <div class="form-floating">
          <input [value]="_getDoctor(_citaSelected.Empleado_Id)" class="form-control" readonly>
          <label class="form-label bold text-uppercase">Doctor</label>
        </div>
      </div>
    </div>

    <div class="row g-2 mt-2">
      <!-- Paciente -->
      <div class="col-md-3 col-lg-2">
        <div class="form-floating">
          <div class="form-control">
            <a href="javascript:void(0);"
              class="link-secondary  text-decoration-underline  link-underline-opacity-100-hover"
              href="javascript:void(0);" (click)="_gotoForm(_citaSelected)"> {{_citaSelected.N_Expediente}} </a>
          </div>
          <label class="form-label bold text-uppercase">#Paciente</label>
        </div>
      </div>
      <!-- Nombre completo paciente -->
      <div class="col-md-9 col-lg-10">
        <div class="form-floating">
          <input [value]="_citaSelected.Paciente_Nombre_Completo" class="form-control" readonly>
          <label class="form-label bold text-uppercase">Nombre completo Paciente</label>
        </div>
      </div>

      <!-- Servicio -->
      <div class="col-md-4">

        <div class="form-floating">
          <input [value]="_getServicio(_citaSelected.Servicio_Id )" class="form-control" readonly>
          <label class="form-label bold text-uppercase"> Servicio o procedimiento </label>
        </div>

      </div>
      <!-- Tipo evento -->
      <div class="col-md-4">
        <div class="form-floating">
          <input [value]="_citaSelected.Tipo_Evento" class="form-control" readonly>
          <label class="form-label bold text-uppercase">Tipo de Evento </label>
        </div>
      </div>
      <!-- Estatus -->
      <div class="col-md-4">
        <div class="form-floating">
          <input [value]="_citaSelected.Estatus " class="form-control" readonly>
          <label class="form-label bold text-uppercase">Estatus</label>
        </div>
      </div>
    </div>

    <div class="row g-2 mt-2">

      <!-- Anestesia -->
      <div class="col-md-4 col-lg-6">
        <div class="text-center" *ngIf="_isAdmin">
          <div class="form-check form-check-right mb-3" [ngClass]="!_isAdmin ? 'non-events': '' ">
            <input class="form-check-input" type="checkbox" (click)="_onSetAnestecia($event, _citaSelected)"
              [checked]="_citaSelected.Anestesia" id="form-{{_citaSelected.Cita_Id}}">
            <label class="form-check-label">¿Requiere anestesia?</label>
          </div>
        </div>
      </div>

      <!-- Biposia -->
      <div class="col-md-4 col-lg-6">
        <div class="text-center" *ngIf="_isAdmin">
          <div class="form-check form-check-right mb-3" [ngClass]="!_isAdmin ? 'non-events': '' ">
            <input class="form-check-input" (click)="_onSetBiposia($event, _citaSelected)"
              [checked]="_citaSelected.Anestesia" type="checkbox" id="form-{{_citaSelected.Cita_Id}}">
            <label class="form-check-label">Bioposia</label>
          </div>
        </div>
      </div>

      <div class="col-md-4 col-lg-6">
        <div class="form-floating">
          <input type="text" class="form-control" readonly [value]="_citaSelected.Seguro">
          <label class="form-label bold text-uppercase">Seguro</label>
        </div>
      </div>

    </div>

    <div class="row g-2 mt-2">
      <!-- Doctor ayudante -->
      <div class="col-md-12 col-lg-6">
        <div class="input-group">
          <div class="form-floating">
            <input type="text" class="form-control" readonly
              [value]="_citaSelected.Ayudante != null ? _getDoctor(_citaSelected.Ayudante) : 'NO HAY DR AYUDANTE' "
              aria-label="Recipient's username with two button addons">
            <label class="form-label bold text-uppercase">Doctor ayudante </label>
          </div>

          <div *ngIf="!_enfermero">

            <ng-cotainer *ngIf="_citaSelected.Ayudante != null; else btnDoctorTemplate">
              <button *ngIf="_isAdmin" class="btn btn-icon btn-topbar rounded-circle btn-md btn-ghost-info"
                (click)="openModal(_citaSelected,'Editar doctor ayudante','doctor_ayudante')">
                <i class="ri-pencil-line align-bottom text-info"></i>
              </button>

              <div *ngIf="_isAdmin">
                <button class="btn btn-icon btn-topbar rounded-circle btn-md btn-ghost-danger"
                  (click)="deltedAssigned(_citaSelected,'doctor_ayudante')">
                  <i class="ri-delete-bin-7-line align-bottom text-danger"></i>
                </button>
              </div>
            </ng-cotainer>

            <ng-template #btnDoctorTemplate>

              <button *ngIf="_isAdmin" class="btn btn-icon btn-md btn-topbar btn-ghost-primary rounded-circle"
                (click)="openModal(_citaSelected,'Seleccione al doctor ayudante','doctor_ayudante')">
                <i class="ri-user-add-line align-bottom text-primary"></i>
              </button>

            </ng-template>

          </div>
        </div>
      </div>

      <!-- Anestesiólogo -->
      <div class="col-md-12 col-lg-6">
        <div class="input-group">
          <div class="form-floating">
            <input type="text" class="form-control" readonly
              [value]="_citaSelected.Anestesista != null ? _getAnestesiologo(_citaSelected.Anestesista) : 'NO HAY ANESTESIOLOGO' "
              aria-label="Recipient's username with two button addons">
            <label class="form-label bold text-uppercase"> Anestesiólogo </label>
          </div>
          <div *ngIf="_isAdmin">
            <button class="btn btn-icon btn-md btn-topbar btn-ghost-primary rounded-circle"
              (click)="openModal(_citaSelected,'Seleccione al anestesiologo','anestesiologo')">
              <i class="ri-user-add-line align-bottom text-primary"></i>
            </button>
          </div>
          <div *ngIf="_is_anestesiologo && _id_anestesiologo == _citaSelected.Anestesista ">
            <button class="btn btn-icon btn-topbar rounded-circle btn-md btn-ghost-danger"
              (click)="deltedAssigned(_citaSelected,'anestesiologo')">
              <i class="ri-delete-bin-7-line align-bottom text-danger"></i>
            </button>
          </div>
          <div *ngIf="_is_anestesiologo && _citaSelected.Anestesista == null">
            <button type="button" class="btn btn-icon btn-md btn-topbar btn-ghost-primary rounded-circle"
              *ngIf="_is_anestesiologo" (click)="_onSelectedAnestesiologo(_citaSelected)">
              <i class="bx bx-paste align-bottom"></i>
            </button>
          </div>
        </div>



      </div>

      <!--  Enfermeria Quirurgica -->
      <div class="col-md-12 col-lg-6">
        <div class="input-group">
          <div class="form-floating">
            <input type="text" class="form-control" readonly
              [value]="_citaSelected.Enfermeria_Quirurgico != null ? _getEnfermeroQx(_citaSelected.Enfermeria_Quirurgico) : 'PENDIENTE' "
              aria-label="Recipient's username with two button addons">
            <label class="form-label bold text-uppercase"> Enfermeria Quirurgica </label>
          </div>

          <div *ngIf="_isAdmin || _enf_qx">
            <button *ngIf="_isAdmin" class="btn btn-icon btn-md btn-topbar btn-ghost-success rounded-circle"
              (click)="openModal(_citaSelected,'Seleccione al enfermero quirurgico','enfemero_qx')">
              <i class="ri-user-add-line align-bottom text-success"></i>
            </button>
            <button *ngIf="_enf_qx" (click)="_onSetEnfermero('enfemero_qx', _citaSelected)"
              class="btn btn-icon btn-md btn-topbar btn-ghost-primary rounded-circle">
              <i class=" bx bx-paste align-bottom "></i>
            </button>
          </div>
        </div>
      </div>

      <!-- Enfermero General -->
      <div class="col-md-12 col-lg-6">
        <div class="input-group">
          <div class="form-floating">            
            <input type="text" class="form-control" readonly
              [value]="_citaSelected.Enfermeria_General != null ? _getEnfermeroGral(_citaSelected.Enfermeria_General) : 'PENDIENTE' "
              aria-label="Recipient's username with two button addons">
            <label class="form-label bold text-uppercase">Enfermero General </label>
          </div>

          <div *ngIf="_isAdmin || _enfGral">
            <button *ngIf="_isAdmin" class="btn btn-icon btn-md btn-topbar btn-ghost-success rounded-circle"
              (click)="openModal(_citaSelected, 'Seleccione al enfermero general','enfermero_gral')">
              <i class="ri-user-add-line align-bottom text-success"></i>
            </button>

            <button *ngIf="_enfGral" (click)="_onSetEnfermero('enfermero_gral', _citaSelected)"
              class="btn btn-icon btn-md btn-topbar btn-ghost-primary rounded-circle">
              <i class="bx bx-paste align-bottom"></i>
            </button>
          </div>
        </div>
      </div>
    </div>


  </div>
</ng-template>

<ng-template #modalFilters role="" let-modal>

  <div class="modal-content  zoomIn">
    <div class="modal-header p-3 bg-primary-subtle ">
      <h5 class="modal-title" id="modal-title">Filtros de busqueda </h5>
      <button type="button" class="btn-close" data-bs-dismiss="modal" aria-hidden="true" (click)="hideModal()"></button>
    </div>
  </div>

  <div class="modal-body p-4">
    <form [formGroup]="_formFilter">
      <div class="row d-flex justify-content-between ">
        <div class="col-12">
          <div class="mt-3">
            <label class="form-label mb-0">Rango de fechas</label>
            <input class="form-control flatpickr-input" type="text" placeholder="Seleccione un rango de fechas"
              formControlName="_dateRange" mwlFlatpickr [altInput]="true" [convertModelValue]="true" mode="range">
          </div>
        </div>

      </div>

      <div class="row row-cols-1 mt-4">
        <!--  -->
        <div class="col">
          <div class="mb-3">
            <label class="control-label">Por tipos de Cita </label>
            <ng-select [items]="_tipoCitaGrouped" bindLabel="name" [(ngModel)]="_TipoCitaCollect"
              (ngModelChange)="_onChangeEventTipoCita($event)" [ngModelOptions]="{standalone: true}"
              [closeOnSelect]="false" [multiple]="true">
              <ng-template ng-option-tmp let-item="item" let-index="index">
                <div class="d-flex justify-content-between border-bottom border-bottom-dashed py-2">
                  <p class="fw-medium mb-0">
                    <i class="ri-asterisk text-primary fs-14 align-middle me-2"></i> {{item.name}}
                  </p>
                  <div>
                    <span class="text-muted pe-5">{{item.total}} Citas </span>

                  </div>
                </div>
              </ng-template>
            </ng-select>
          </div>
        </div>
        <!--  -->
        <div class="col">
          <div class="mb-3">
            <label class="control-label">Citas por servicios </label>
            <ng-select [items]="_serviciosGrouped" bindLabel="name" [(ngModel)]="_ServiciosCollect"
              (ngModelChange)="_onChangeEventServicios($event)" [ngModelOptions]="{standalone: true}"
              [closeOnSelect]="false" [clearable]="false" [multiple]="true">

              <ng-template ng-option-tmp let-item="item" let-index="index">
                <div class="d-flex justify-content-between border-bottom border-bottom-dashed py-2">
                  <p class="fw-medium mb-0">
                    <i class="ri-asterisk text-primary fs-14 align-middle me-2"></i> {{item.name}}
                  </p>
                  <div>
                    <span class="text-muted pe-5">{{item.total}} Citas </span>

                  </div>
                </div>
              </ng-template>

            </ng-select>
          </div>
        </div>
        <!--  -->
        <div class="col">
          <div class="mb-3">
            <label class="control-label">Citas por Doctores </label>
            <ng-select [items]="_doctoresGrouped" bindLabel="name" [(ngModel)]="_DoctoresCollect"
              (ngModelChange)="_onChangeEvent($event)" [ngModelOptions]="{standalone: true}" [closeOnSelect]="false"
              [clearable]="false" [multiple]="true">

              <ng-template ng-option-tmp let-item="item" let-index="index">
                <div class="d-flex justify-content-between border-bottom border-bottom-dashed py-2">
                  <p class="fw-medium mb-0">
                    <i class="ri-asterisk text-primary fs-14 align-middle me-2"></i> {{item.name}}
                  </p>
                  <div>
                    <span class="text-muted pe-5">{{item.total}} Citas </span>

                  </div>
                </div>
              </ng-template>

            </ng-select>
          </div>
        </div>
        <!--  -->
        <div class="col">
          <div class="mb-3">
            <label class="control-label">Citas por Recursos </label>
            <ng-select [items]="_recursosGrouped" bindLabel="name" [(ngModel)]="_RecursosCollect"
              (ngModelChange)="_onChangeEventRecursos($event)" [ngModelOptions]="{standalone: true}"
              [closeOnSelect]="false" [multiple]="true">
              <ng-template ng-option-tmp let-item="item" let-index="index">
                <div class="d-flex justify-content-between border-bottom border-bottom-dashed py-2">
                  <p class="fw-medium mb-0">
                    <i class="ri-asterisk text-primary fs-14 align-middle me-2"></i> {{item.name}}
                  </p>
                  <div>
                    <span class="text-muted pe-5">{{item.total}} Citas </span>

                  </div>
                </div>
              </ng-template>
            </ng-select>
          </div>
        </div>
      </div>

    </form>

  </div>

  <div class="modal-footer p-1">
    <div class="text-center">
      <button type="button" class="btn btn-primary" (click)="_onSearch()">Buscar</button>
    </div>

  </div>
</ng-template>

<ngx-spinner [name]="_spinner" [fullScreen]="true" type="ball-scale-multiple"></ngx-spinner>

<!-- Canvas -->
<ng-template #canvasDetail let-offcanvas>
  <app-canvas-detalles-citas [_detallesCita]="_detallesCita"></app-canvas-detalles-citas>
</ng-template>