import { Pipe, PipeTransform } from '@angular/core';
import { Patients } from '@app/shared/models/Patients';

@Pipe({
  name: 'buscadorPacientes'
})
export class BuscadorPacientesPipe implements PipeTransform {

  transform(collection: Patients[], searchText: string ) {
    if(typeof searchText === typeof undefined) return collection

        return collection.filter((item) =>  
          item.P00_Nombre__c.toLocaleLowerCase().includes(searchText.toLocaleLowerCase()) ||
          item.P05_Apellidos__c.toLocaleLowerCase().includes(searchText.toLocaleLowerCase()) ||
          item.Nombre_completo__c.toLocaleLowerCase().includes(searchText.toLocaleLowerCase()) ||
          item.Name.toLocaleLowerCase().includes(searchText.toLocaleLowerCase()) ||
          item.P05_Telefono__c.toLocaleLowerCase().includes(searchText.toLocaleLowerCase()) 

      );
  }

}
