
import { CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BreadcrumbsComponent } from './breadcrumbs/breadcrumbs.component';
import { BasicInfoComponent } from './components/basic-info/basic-info.component';
import { NotificationComponent } from './components/notification/notification.component';

import { ClinicalHistoryComponent } from './components/patients/clinical-history/clinical-history.component';
import { DataPatientsComponent } from './components/Forms/data-patients/data-patients.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// ngbBoostrap

import { NgbDropdownModule, NgbToastModule, NgbAccordionModule, NgbDatepickerModule, NgbCollapseModule, NgbNavModule, NgbAlertModule } from '@ng-bootstrap/ng-bootstrap';

// FlatPicker
import { FlatpickrModule } from 'angularx-flatpickr';

// Select Droup down
import { NgSelectModule } from '@ng-select/ng-select';

//simppleBar
import { SimplebarAngularModule } from 'simplebar-angular';

//Swipper


import { FeatherModule } from 'angular-feather'
import { allIcons } from 'angular-feather/icons';


//ngMask
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';

import { InViewportModule } from '@thisissoon/angular-inviewport';
import { ScrollSpyModule } from '@thisissoon/angular-scrollspy';

import { AngularSignaturePadModule } from '@almothafar/angular-signature-pad'


// Ui Switch
import { UiSwitchModule } from 'ngx-ui-switch';
import { NotaIngresoComponent } from './components/patients/nota-ingreso/nota-ingreso.component';
import { FirmaDoctorComponent } from './components/firma-doctor/firma-doctor.component';
import { DetailsDoctorComponent } from './components/infoDetails/details-doctor/details-doctor.component';
import { DetailsRecursoComponent } from './components/infoDetails/details-recurso/details-recurso.component';
import { RightSidebarComponent } from './components/right-sidebar/right-sidebar.component';
import { TopBarFormatosComponent } from './components/top-bar-formatos/top-bar-formatos.component';
import { LayoutFormatosComponent } from './components/Forms/layout-formatos/layout-formatos.component';
import { WebcamModule } from 'ngx-webcam';
import { CanvasDetallesCitasComponent } from './components/Canvas/canvas-detalles-citas/canvas-detalles-citas.component';
import { LoaderFullCalendarComponent } from './components/loader-full-calendar/loader-full-calendar.component';
import { CanvasFirmaDoctorComponent } from './components/Canvas/canvas-firma-doctor/canvas-firma-doctor.component';
import { GalleryImageComponent } from './components/gallery-image/gallery-image.component';
import { FormFilterCalendarComponent } from './components/Forms/form-filter-calendar/form-filter-calendar.component';
import { FilterPipe } from './pipes/filter.pipe';
import { StatComponent } from './widget/stat/stat.component';
import { NotaEvolucionComponent } from './components/patients/nota-evolucion/nota-evolucion.component';
import { NotaPreoperatoriaComponent } from './components/patients/nota-preoperatoria/nota-preoperatoria.component';

import { AnestesiaComponent } from './components/patients/anestesia/anestesia.component';
import { EnfermeriaEndoscopiaComponent } from './components/patients/enfermeria-endoscopia/enfermeria-endoscopia.component';
import { NotaEgresoComponent } from './components/patients/nota-egreso/nota-egreso.component';
import { HojaIndicacionesComponent } from './components/patients/hoja-indicaciones/hoja-indicaciones.component';
import { HojaPostOperatoriaComponent } from './components/patients/hoja-post-operatoria/hoja-post-operatoria.component';
import { DescipcionQuirurgicaComponent } from './components/patients/descipcion-quirurgica/descipcion-quirurgica.component';
import { DoctorAyudanteComponent } from './components/patients/doctor-ayudante/doctor-ayudante.component';
import { FilterDoctorAyudantePipe } from './pipes/filter-doctor-ayudante.pipe';
import { CreatePatientComponent } from './components/Forms/create-patient/create-patient.component';

import { registerLocaleData } from "@angular/common";
import localeEs from "@angular/common/locales/es-MX";
import LocaleEsExtra from "@angular/common/locales/es-MX";
import { CreateCitasComponent } from './components/Forms/create-citas/create-citas.component';
import { FullCalendarModule } from '@fullcalendar/angular';
import { NgxSpinnerModule } from 'ngx-spinner';

import { SwiperModule } from 'swiper/angular'
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { JwtInterceptor } from '../core/helpers/jwt.interceptor';
import { UploadFilesComponent } from './components/upload-files/upload-files.component';

import { AngularPinturaModule } from '@pqina/angular-pintura';

import { FilePondModule,registerPlugin } from 'ngx-filepond'

import { LightboxModule } from 'ngx-lightbox'
// Load Icons
import { defineElement } from 'lord-icon-element';
import lottie from 'lottie-web';

// import and register filepond file type validation plugin
import * as filePondFileValidate from "filepond-plugin-file-validate-type";
//
import { ArchwizardModule } from 'angular-archwizard';

// Ngx Sliders
import { NgxSliderModule } from '@angular-slider/ngx-slider';

//#region  COMPONENTS
  import { CreateCandidateComponent } from './components/create-candidate/create-candidate.component';
  import { EnfermeriaOperatoriosComponent } from './components/patients/enfermeria-operatorios/enfermeria-operatorios.component';
  import { RecomendacionesPacienteComponent } from './components/patients/recomendaciones-paciente/recomendaciones-paciente.component';
  import { ConsentimientoAplicacionSedacionComponent } from './components/patients/consentimiento-aplicacion-sedacion/consentimiento-aplicacion-sedacion.component';
  import { ConsentimientoProcedimientoDiagnosticoComponent } from './components/patients/consentimiento-procedimiento-diagnostico/consentimiento-procedimiento-diagnostico.component';
  import { HistoriaClinicaVaricesComponent } from './components/patients/historia-clinica-varices/historia-clinica-varices.component';
  import { FullCalendarDashboardComponent } from './components/full-calendar-dashboard/full-calendar-dashboard.component';
  import { TablaDeCitasComponent } from './components/tabla-de-citas/tabla-de-citas.component';
  import { CalendarioPlanchaComponent } from './components/calendario-plancha/calendario-plancha.component';
  import { NgxPaginationModule } from 'ngx-pagination';
  import { CalendarioIndexCitasComponent } from './components/calendario-index-citas/calendario-index-citas.component';
  import { CalandarioCitasPorDiaComponent } from './components/calandario-citas-por-dia/calandario-citas-por-dia.component';
  import { FirmaPacienteComponent } from './components/Canvas/firma-paciente/firma-paciente.component';
  import { NgxDatatableModule } from '@swimlane/ngx-datatable';
  
  import { SvgEditorComponent } from './components/patients/svg-editor/svg-editor.component';
  import { CustomUploadFilesComponent } from './components/custom-upload-files/custom-upload-files.component';
  import { NgWebCamComponent } from './components/ng-web-cam/ng-web-cam.component';
  import { FormNotasEvolucionVaricesComponent } from './components/patients/form-notas-evolucion-varices/form-notas-evolucion-varices.component';
  import { FormNotaEvolucionComponent } from './components/patients/form-nota-evolucion/form-nota-evolucion.component';
  import { WizardCalificacionComponent } from './components/wizard-calificacion/wizard-calificacion.component';
  import { TextAreaVoiceRecorderComponent } from './components/text-area-voice-recorder/text-area-voice-recorder.component';
import { CitaSubsecuenteComponent } from './components/Forms/cita-subsecuente/cita-subsecuente.component';
import { RecetaMedicamentoComponent } from './components/Forms/receta-medicamento/receta-medicamento.component';
import { CalificacionVaricesComponent } from './components/calificacion-varices/calificacion-varices.component';
import { RecetasViewComponent } from './components/recetas-view/recetas-view.component';
import { AvisoPrivacidadComponent } from './components/aviso-privacidad/aviso-privacidad.component';
import { CitaPendienteComponent } from './components/cita-pendiente/cita-pendiente.component';
import { CountDownComponent } from './components/count-down/count-down.component';
import { ModalDetailsPatientComponent } from './components/modal-details-patient/modal-details-patient.component';
//#endregion

registerLocaleData(localeEs, "es", LocaleEsExtra);
registerPlugin(filePondFileValidate );

const exports = [
  BreadcrumbsComponent, 
  BasicInfoComponent, 
  NotificationComponent, 
  ClinicalHistoryComponent, 
  DataPatientsComponent, 
  NotaIngresoComponent, 
  DetailsDoctorComponent, 
  RightSidebarComponent,
  DetailsRecursoComponent,
  TopBarFormatosComponent,
  LayoutFormatosComponent,
  CanvasDetallesCitasComponent,
  LoaderFullCalendarComponent,
  CanvasFirmaDoctorComponent,
  GalleryImageComponent,
  FormFilterCalendarComponent,
  StatComponent,
  CreatePatientComponent,
  CreateCitasComponent,
  UploadFilesComponent,
  CreateCandidateComponent,
  
  TablaDeCitasComponent,
  CalendarioPlanchaComponent,
  CalendarioIndexCitasComponent,

  NotaEvolucionComponent,
  NotaPreoperatoriaComponent,
  DoctorAyudanteComponent,
  AnestesiaComponent,
  EnfermeriaEndoscopiaComponent,
  HojaPostOperatoriaComponent,

  DescipcionQuirurgicaComponent,
  NotaEgresoComponent,
  HojaIndicacionesComponent,
  EnfermeriaOperatoriosComponent,

  RecomendacionesPacienteComponent,
  ConsentimientoAplicacionSedacionComponent,
  ConsentimientoProcedimientoDiagnosticoComponent,
  HistoriaClinicaVaricesComponent,
  

  WizardCalificacionComponent,
  TextAreaVoiceRecorderComponent,
  CitaSubsecuenteComponent,
  RecetaMedicamentoComponent,

  CalificacionVaricesComponent,
  RecetasViewComponent,

  AvisoPrivacidadComponent,

  CitaPendienteComponent,
  
  ModalDetailsPatientComponent
  
]
@NgModule({
  declarations: [
    BreadcrumbsComponent,
    BasicInfoComponent,
    NotificationComponent,
    ClinicalHistoryComponent,
    DataPatientsComponent,
    NotaIngresoComponent,
    FirmaDoctorComponent,
    DetailsDoctorComponent,
    DetailsRecursoComponent,
    RightSidebarComponent,
    TopBarFormatosComponent,
    LayoutFormatosComponent,
    CanvasDetallesCitasComponent,
    LoaderFullCalendarComponent,
    CanvasFirmaDoctorComponent,
    GalleryImageComponent,
    FormFilterCalendarComponent,
    FilterPipe,
    FilterDoctorAyudantePipe,
    StatComponent,
    NotaEvolucionComponent,
    NotaPreoperatoriaComponent,
    AnestesiaComponent,
    EnfermeriaEndoscopiaComponent,
    NotaEgresoComponent,
    HojaIndicacionesComponent,
    HojaPostOperatoriaComponent,
    DescipcionQuirurgicaComponent,
    DoctorAyudanteComponent,   
    CreatePatientComponent,
    CreateCitasComponent,
    UploadFilesComponent,
    CreateCandidateComponent,
    EnfermeriaOperatoriosComponent,
    RecomendacionesPacienteComponent,
    ConsentimientoAplicacionSedacionComponent,
    ConsentimientoProcedimientoDiagnosticoComponent,
    HistoriaClinicaVaricesComponent,
    FullCalendarDashboardComponent,
    TablaDeCitasComponent,
    CalendarioPlanchaComponent,
    CalendarioIndexCitasComponent,
    CalandarioCitasPorDiaComponent,
    FirmaPacienteComponent,
    
    SvgEditorComponent,
    CustomUploadFilesComponent,
    NgWebCamComponent,
    FormNotasEvolucionVaricesComponent,
    FormNotaEvolucionComponent,
    WizardCalificacionComponent,
    TextAreaVoiceRecorderComponent,
    CitaSubsecuenteComponent,
    RecetaMedicamentoComponent,
    CalificacionVaricesComponent,
    RecetasViewComponent,
    AvisoPrivacidadComponent,
    CitaPendienteComponent,
    CountDownComponent,
    ModalDetailsPatientComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FlatpickrModule,
    NgSelectModule,
    UiSwitchModule,
    NgbToastModule,
    SimplebarAngularModule,
    NgbDropdownModule,
    NgbAccordionModule,
    WebcamModule,
    NgbCollapseModule,
    NgxPaginationModule,
    InViewportModule,
    NgxMaskDirective,
    NgxDatatableModule,
    NgxMaskPipe,    
    NgbDatepickerModule,
    AngularSignaturePadModule,
    FullCalendarModule,
    NgxSpinnerModule,
    FeatherModule.pick(allIcons),
    ScrollSpyModule.forRoot(),
    SwiperModule,
    FilePondModule,
    LightboxModule,    
    AngularPinturaModule,
    WebcamModule,
    ArchwizardModule,
    NgbNavModule,
    NgxSliderModule,
    NgbAlertModule
  ],
  providers:[
    provideNgxMask(),
    {
      provide: LOCALE_ID,
      useValue: 'es-MX'
    },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
  ],
  exports:[exports],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SharedModule { 
  constructor() {
    defineElement(lottie.loadAnimation);
  }
}
