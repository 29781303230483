
<file-pond
#myPond
[options]="pondOptions"             
(oninit)="pondHandleInit()"
(onaddfile)="pondHandleAddFile($event)"
(onremovefile)="pondRemoveFile($event)"
(onactivatefile)="pondHandleActivateFile($event)">
</file-pond>

<ng-container *ngIf="_showGallery && (_urls && _urls.length)">    
    <div class="row gallery-wrapper" style="cursor: pointer">
        <div class="element-item col-xxl-3 col-xl-4 col-sm-6 project designing development"  data-category="designing development" *ngFor="let data of _urls; let i=index">
            <ng-container *ngIf="data.type != 'application/pdf'">
                <div class="gallery-box card">
                    <div class="gallery-container">
                        <a class="image-popup" >
                            <img class="gallery-img img-fluid mx-auto" src="{{data.src}}" alt=""/>
                            <div class="gallery-overlay" (click)="openBase64(i)">    </div>
                        </a>
                    </div>
                    <div class="box-content">
                        <div class="d-flex align-items-center mt-1">
                            <small class="text-muted"> {{data.filename}} </small>                           
                        </div>
                    </div>
                </div>
            </ng-container>        

            <button class="btn btn-primary btn-sm" (click)="data._showComentario =! data._showComentario" *ngIf="!data._showComentario">Agregar comentario</button>         
            <fieldset class="mt-4" *ngIf="data._showComentario">
                <textarea
                class="form-control"
                [rows]="4"
                [(ngModel)]="data.comentario"
                [ngModelOptions]="{standalone: true}">
                </textarea>
                <button class="mt-4 btn btn-danger btn-sm" (click)="_deleteComentario(data, i)">Quitar comentario</button>
            </fieldset>            
        </div>
    </div>    
    <div class="float-end" *ngIf="showUpload">
        <button class="btn btn-primary text-uppercase" (click)="_onUpload()"> 
            <i class="mdi mdi-file-upload-outline align-middle" ></i> Subir</button>
    </div>
</ng-container>



