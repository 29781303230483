<!-- <button (click)="topFunction()" class="btn btn-danger btn-icon" id="back-to-top">
    <i class="ri-arrow-up-line"></i>
</button> -->

<!--preloader-->
<div id="preloader">
    <div id="status">
        <div class="spinner-border text-primary avatar-sm" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
    </div>
</div>

<div class="customizer-setting">
    <div class="btn-primary rounded-pill shadow-lg btn btn-icon btn-lg p-2" data-bs-toggle="offcanvas"
        data-bs-target="#theme-settings-offcanvas" aria-controls="theme-settings-offcanvas"
        (click)="openEnd(filtetcontent)">
        <i class='mdi mdi-menu  fs-22'></i>
    </div>
</div>

<!-- Theme Settings -->
<ng-template #filtetcontent let-offcanvas>
    <div class="d-flex align-items-center bg-primary bg-gradient p-3 offcanvas-header">
        <h5 class="m-0 me-2 text-white">Opciones</h5>
        <button type="button" class="btn-close btn-close-white ms-auto " data-bs-dismiss="offcanvas" aria-label="Close"
            (click)="offcanvas.dismiss('Cross click')"></button>
    </div>
    <div class="offcanvas-body p-0">
        <ngb-accordion #acc="ngbAccordion"
            class="accordion custom-accordionwithicon custom-accordion-border accordion-border-box accordion-primary p-1 m-1"
            [closeOthers]="true" id="_right_side_bar">
            <!--  -->
            <ngb-panel id="_formatos_pacientes">
                <ng-template ngbPanelTitle>
                    <button class="border-0 bg-transparent" type="button" data-bs-toggle="collapse"
                        data-bs-target="#gen-ques-collapseOne" aria-expanded="false"
                        aria-controls="gen-ques-collapseOne">
                        <span class="text-muted text-uppercase fs-13 bold">Formatos de pacientes</span>
                    </button>
                </ng-template>
                <ng-template ngbPanelContent>
                    <div class="m-4">
                        <li *ngFor="let formato of Formatos">
                            <a data-bs-toggle="collapse" href="javascript:void(0);" role="button" aria-expanded="true"
                                aria-controls="collapseExample" (click)="collapse.toggle()"
                                [attr.aria-expanded]="!isCollapsed">
                                <!--  <i class="ri-folder-2-line align-bottom me-2"></i>  -->
                                <span class="fs-5">{{formato.label}} </span>
                            </a>
                            <div class="collapse show" id="collapseExample" #collapse="ngbCollapse"
                                [(ngbCollapse)]="isCollapsed">
                                <ul class="sub-menu list-unstyled">
                                    <li *ngFor="let _formato of formato.subItems">
                                        <a href="javascript:void(0);" (click)="_onSetIdFormato(_formato)"
                                            class="mt-4 fw-bold fs-5"
                                            [ngClass]="_formato.subItems && _formato.subItems.length ? 'text-muted  ' : ''">{{_formato.label}}</a>
                                        <ul *ngIf="_formato.subItems && _formato.subItems.length"
                                            class="sub-menu list-unstyled">
                                            <li *ngFor="let subitem of _formato.subItems">
                                                <a href="javascript:void(0);" (click)="_onSetIdFormato(subitem)"
                                                    class="mt-4 fs-5">{{subitem.label}} </a>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                        </li>
                    </div>
                </ng-template>
            </ngb-panel>
            <!--  -->
            <ngb-panel id="_citas_subsecuentes">
                <ng-template ngbPanelTitle>
                    <button class="border-0 bg-transparent" type="button" data-bs-toggle="collapse"
                        data-bs-target="#gen-ques-collapseOne" aria-expanded="false"
                        aria-controls="gen-ques-collapseOne">
                        <span class="text-muted text-uppercase fs-13 bold">Citas subsecuentes</span>
                    </button>
                </ng-template>
                <ng-template ngbPanelContent>
                    <div class="p-2" *ngIf="_CitasSubsecuentes && _CitasSubsecuentes.length">
                        <ngx-simplebar style="height: 300px;">
                            <h6 class="fs-11 text-muted text-uppercase mb-3"></h6>
                            <div class="d-flex align-items-center">
                                <div class="flex-grow-1 m-1 ">
                                    <div class="row gap-2 m-2">
                                        <div class="col-12" *ngFor="let item of _CitasSubsecuentes">
                                            <div class="d-grid gap-2">
                                                <button type="button"
                                                    class="btn btn-primary btn-label waves-effect waves-light rounded-pill"
                                                    (click)="_onOpenCita(item)">
                                                    <i
                                                        class="ri-error-warning-line label-icon align-middle rounded-pill fs-16 me-2"></i>
                                                    {{item.Tipo_Cita}}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ngx-simplebar>
                    </div>
                </ng-template>
            </ngb-panel>
            <!--  -->
            <ngb-panel id="_lista_pacientes">
                <ng-template ngbPanelTitle>
                    <button class="border-0 bg-transparent" type="button" data-bs-toggle="collapse"
                        data-bs-target="#gen-ques-collapseOne" aria-expanded="false"
                        aria-controls="gen-ques-collapseOne">
                        <span class="text-muted text-uppercase fs-13 bold">Listado de pacientes</span>
                    </button>
                </ng-template>
                <ng-template ngbPanelContent>
                    <div class="p-2" *ngIf="_clientes_espera && _clientes_espera.length">
                        <ngx-simplebar style="height: 50vh;">
                            <div class="list-group p-2" *ngFor="let item of _clientes_espera let i = index;">
                                <button type="button" (click)="_onSetPatient(item)"
                                    class="list-group-item list-group-item-action">
                                    {{item.data.pacient}}
                                </button>
                            </div>
                        </ngx-simplebar>
                    </div>
                </ng-template>
            </ngb-panel>
            <!--  -->
            <ngb-panel id="_citas_log">
                <ng-template ngbPanelTitle>
                    <button class="border-0 bg-transparent" type="button" data-bs-toggle="collapse"
                        data-bs-target="#gen-ques-collapseOne" aria-expanded="false"
                        aria-controls="gen-ques-collapseOne">
                        <span class="text-muted text-uppercase fs-13 bold">Listado de citas </span>
                    </button>
                </ng-template>
                <ng-template ngbPanelContent>
                    <div class="p-2" *ngIf="_citas_paciente && _citas_paciente.length">
                        <ngx-simplebar style="max-height: calc(100vh - 70px)">
                            <h6 class="fs-11 text-muted text-uppercase mb-3">Citas Log</h6>
                            <div class="d-flex align-items-center ">
                                <div class="flex-grow-1 m-1">
                                    <div class="row gap-2 m-2">
                                        <div class="col-12" *ngFor="let item of _citas_paciente">
                                            <div class="d-grid gap-2">
                                                <button type="button"
                                                    class="btn  btn-label waves-effect waves-light rounded-pill"
                                                    [ngClass]=" item.Name === _cita.Cita_Id ? 'btn-primary' : 'bg-primary-subtle' "
                                                    (click)="_setCita(item)">
                                                    <i
                                                        class="ri-error-warning-line label-icon align-middle rounded-pill fs-16 me-2"></i>
                                                    {{item.Tipo_cita}} - {{item.Name}}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ngx-simplebar>
                    </div>
                </ng-template>
            </ngb-panel>
        </ngb-accordion>
    </div>
</ng-template>

<ng-template #modalPatient let-modal>

    <div class="modal-header p-3 bg-primary-subtle">
        <h5 class="modal-title" id="modal-title">Crear Cita </h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-hidden="true"
            (click)="closeEventModal()"></button>
    </div>

    <div class="modal-body p-4">
        <app-create-citas [_dataCita]="_dataCita" [cita_sub_secuente_id]="_cita_sub_secuente_id"
            (eventClickCancel)="onClickEvent($event)"></app-create-citas>
    </div>

</ng-template>