import { DatePipe } from '@angular/common';
import { Component, ElementRef, HostListener, OnDestroy, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { NgbOffcanvasRef, NgbModal, NgbOffcanvas, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';

import { CredentialsService } from '@core/services/credentials.service';
import { Cita, CalendarObjects, CitasObjects } from '@pages/calendar/models/calendar';
import { DetallesCita } from '@pages/calendar/models/detalles-cita';
import { CalendarService } from '@pages/calendar/services/calendar.service';
import { Filters } from '@pages/models/filters';
import { RedirectService } from '@app/services/redirect.service';
import { DatesSetArg } from '@fullcalendar/core';
import { SelectedFIlters } from '@app/shared/models/selected-filters';
import { FilterCalendar } from '@app/pages/models/filter-calendar';
import { LocalSessionService } from '@app/pages/services/local-session.service';
import { Servicio } from '@app/pages/models/servicios';
import { AlertsService } from '@app/shared/services/alerts.service';
import { delay, map, share, Subscription, timer } from 'rxjs';


//#region Catalogos
const DOCTORES_ = '_cat_doctores'
const RECURSOS_ = '_cat_reursos'
const SERVICIOS_ = '_cat_servicios'

const FILTER_CITAS_DIAS = '_filter_citas_dia_'
//#endregion


const _CITAS_POR_DIA = '_citas_por_dia'

@Component({
  selector: 'app-calandario-citas-por-dia',
  templateUrl: './calandario-citas-por-dia.component.html',
  styleUrls: ['./calandario-citas-por-dia.component.scss']
})
export class CalandarioCitasPorDiaComponent implements OnDestroy {

  @ViewChild('viewContent') viewContent: TemplateRef<any>;
  @ViewChild('offcanvasRight') offcanvasRight: ElementRef | undefined;
  @ViewChild('modalShow') modalShow!: TemplateRef<any>;
  @ViewChild('modalFilters') modalFilters!: TemplateRef<any>;

  _citas: Cita[] = []
  _itsToday: boolean;

  responseData: CalendarObjects;

  _citas_today: Cita[] = []
  _citas_tomorrow: Cita[] = []

  private readonly _today = new Date();
  private readonly _tomorrow = new Date(moment().add('days', 1).toISOString());

  today_: string | null
  tomorrow_: string | null

  _recursoId: string = 'all'
  _doctorId: string = 'true'
  _servicioId: string = 'true'


  _citaSelected: Cita;
  _doctor: string = ''
  _servicio: string;

  _detallesCita: DetallesCita;

  _itsReady: boolean;
  _loaderError: boolean = false
  _canvasRef_: NgbOffcanvasRef;

  _filters: Filters = new Filters()
  _isAdmin: boolean

  _ngbModalRef: NgbModalRef;

  _dateRange: DatesSetArg;

  _selectedDoctors: SelectedFIlters[] = []
  _selectedServicios: SelectedFIlters[] = []
  _selectedRecursos: SelectedFIlters[] = []

  _tipoEvento: SelectedFIlters[] = []

  _filterCalendar: FilterCalendar = new FilterCalendar();


  // public _today = new Date()
  rxTime = new Date();
  $_subscription!: Subscription;
  _hrClose = moment().add(5, 'minutes').toDate()


  @HostListener("window:beforeunload", ["$event"])
  onResize(even: any): void {
    this._beforeReload(event)
  }

  constructor(
    private modalService: NgbModal,
    private date: DatePipe,
    private offcanvasService: NgbOffcanvas,
    private credentialService: CredentialsService,
    private Calendar: CalendarService,
    private toastr: ToastrService,
    private redirect: RedirectService,
    private ngModal: NgbModal,
    private _local: LocalSessionService,
    private alert: AlertsService
  ) {
    this.today_ = this.date.transform(this._today, 'yyyy-MM-dd')
    this.tomorrow_ = this.date.transform(this._tomorrow, 'yyyy-MM-dd')
  }

  async ngOnInit(): Promise<void> {
    console.log({ _update___: this._hrClose });

    this.initTimer()

    await this.getDoctors()
    await this.getRecursos()
    await this.getServicios()
    await this.getTipoEvento()

    if (this.credentialService.credentials?.roles.Doctor) {
      this._doctor = this.credentialService.credentials?.id_salesforce ? this.credentialService.credentials.id_salesforce : '';
    } else if (this.credentialService.credentials?.roles.Administrador) {
      this._isAdmin = true
    }

    this._filters.doctor = this._doctor
    this._filters.inicio = ''
    this._filters.final = ''

    let _filter_val = sessionStorage.getItem(FILTER_CITAS_DIAS)

    if (_filter_val) {
      this._filterCalendar = JSON.parse(_filter_val)
      this.loadCitasByFilter()
      return console.log('b..');
    }



    this._loadCitas()
  }

  ngOnDestroy(): void {
    if (this.$_subscription) {
      this.$_subscription.unsubscribe()
    }
  }


  private _beforeReload(event: any) {
    console.log({ Msg: ' AAAAAA', event });

  }

  private initTimer(): void {
    this.$_subscription = timer(0, 1000)
      .pipe(
        map(() => new Date()),
        delay(500),
        share()
      ).subscribe({
        next: (resp) => {
          this.rxTime = resp
          const _now = moment(resp)
          if (_now.isAfter(this._hrClose)) {
            console.log({ Msg: 'ACTUALIZAR' });
            this._loadCitas()
            this._hrClose = moment(_now).add(5, 'minutes').toDate()

            this.$_subscription.unsubscribe()
          }

        }
      })
  }

  private getDoctors() {
    this._selectedDoctors = []
    if (this._local.getJsonValues(DOCTORES_)) {
      const _DOCTORES = this._local.getJsonValues(DOCTORES_)
      _.map(_DOCTORES, (a) => {
        if (a.tipo_empleado === 'Doctor') {
          this._selectedDoctors.push({
            Id: a.id_salesforce,
            Name: a.name,
            checked: true
          })
        }
      })
    } else {
      this.Calendar._getDoctores(this._doctorId).subscribe({
        next: (response) => {
          if (response.codigo == 200) {
            let _response = response.data as any;
            let _doctores = _response.info;

            this._local.savedJsonValues(DOCTORES_, _doctores)

            //this._doctor = _response.info.salesforce as Doctor;   
            _.map(_response.info, (a) => {
              if (a.tipo_empleado == 'Doctor') {
                this._selectedDoctors.push({
                  Id: a.id_salesforce,
                  Name: a.name,
                  checked: true
                })
              }
            })

          }
        }, error: (error) => {
          this.toastr.error('Ocurri un error al obtener el listado de doctores', 'Error en listado de doctores', {
            timeOut: 3200,
            positionClass: 'toast-top-left'
          })
        }
      })
    }
  }

  private getRecursos() {
    this._selectedRecursos = []
    if (this._local.getJsonValues(RECURSOS_)) {
      const _recurso = this._local.getJsonValues(RECURSOS_)
      _.map(_recurso, (a) => {
        this._selectedRecursos.push({
          Id: a.Id,
          Name: a.Name,
          checked: true
        })
      })
    } else {
      this.Calendar._getRecursos(this._recursoId).subscribe({
        next: (response) => {
          if (response.codigo == 200) {
            let _recurso = response.data
            this._local.savedJsonValues(RECURSOS_, _recurso)

            _.map(response.data, (a) => {
              this._selectedRecursos.push({
                Id: a.Id,
                Name: a.Name,
                checked: true
              })
            })
          }
        },
        error: (error) => {
          console.error({ error });

        }
      })
    }
  }

  private getServicios() {
    this._selectedServicios = []
    if (this._local.getJsonValues(SERVICIOS_)) {
      const _servicios = this._local.getJsonValues(SERVICIOS_)
      _.map(_servicios, (a: Servicio) => {
        this._selectedServicios.push({
          Id: a.Id,
          Name: a.Name,
          checked: true
        })
      })
    } else {
      this.Calendar._getServicio(this._servicioId).subscribe({
        next: (response) => {
          if (response.codigo == 200) {
            this._local.savedJsonValues(SERVICIOS_, response.data)
            _.map(response.data, (a: Servicio) => {

              this._selectedServicios.push({
                Id: a.Id,
                Name: a.Name,
                checked: true
              })
            })
          }
        }
      })
    }
  }

  private getTipoEvento() {
    this.Calendar._getTipoEvento('true').subscribe({
      next: (value: any) => {
        console.log({ value });
        if (value.codigo === 200) {

          let _tipoEvento_ = value.data[0].tipo_evento

          _.map(_tipoEvento_, (a) => {
            this._tipoEvento.push({
              Id: a,
              Name: a,
              checked: true
            })
          })

          // this._tipoEvento = value.data[0].tipo_evento

          // console.log({_tipoEvento__: this._tipoEvento});

        }
      },
    })
  }

  /**
   *
   *
   * @private
   * @memberof CalandarioCitasPorDiaComponent
   */
  private _loadCitas() {
    this._itsReady = false;
    this.Calendar._getCalendarReport(this._filters).subscribe({
      next: (response: any) => {
        if (response.codigo == 200) {
          this.responseData = response.data
          this._itsReady = true;
          sessionStorage.setItem(_CITAS_POR_DIA, JSON.stringify(this.responseData))
          const _v = sessionStorage.getItem(FILTER_CITAS_DIAS)
          if (_v) {
            return this._applyFilter(response.data)
          }
          this._onSetData()


        }
      },
      error: (err: any) => {
        console.error({ err });
        this._loaderError = true;
        this._itsReady = true
        this.toastr.error('Ocurrio un error al obtener los datos del calendario', 'Error', {
          timeOut: 3200
        })
      },
    })

  }

  /**
   *
   *
   * @private
   * @param {CitasObjects} _citasObjects
   * @memberof CalandarioCitasPorDiaComponent
   */
  private _applyFilter(_citasObjects: CitasObjects) {

    if (_citasObjects.Today && _citasObjects.Today.length) {

      let __citasToday = _.filter(_citasObjects.Today, (o) =>
        _.includes(this._filterCalendar.doctores, o.Empleado_Id) ||
        _.includes(this._filterCalendar.tipoEvento, o.Tipo_Cita) ||
        _.includes(this._filterCalendar.recursos, o.Recurso_Id) ||
        _.includes(this._filterCalendar.servicios, o.Servicio_Id))

      console.log({ __citasToday });

      this._citas_today = [...__citasToday]
    }


    if (_citasObjects.Tomorrow && _citasObjects.Tomorrow.length) {

      let _doctoresFilter = _.filter(_citasObjects.Tomorrow, (o) =>
        _.includes(this._filterCalendar.doctores, o.Empleado_Id) ||
        _.includes(this._filterCalendar.tipoEvento, o.Tipo_Cita) ||
        _.includes(this._filterCalendar.recursos, o.Recurso_Id) ||
        _.includes(this._filterCalendar.servicios, o.Servicio_Id))  //_citasObjects.Tomorrow.filter(o => o )
      //  let _filterTipoCita = _.filter(_citasObjects.Tomorrow, (o) => _.includes(this._filterCalendar.tipoEvento, o.Tipo_Cita ) )

      this._citas_tomorrow = [..._doctoresFilter];
    }
  }

  private loadCitasByFilter() {

    let _jsonCitas = sessionStorage.getItem(_CITAS_POR_DIA)

    if (_jsonCitas) {
      this.responseData = JSON.parse(_jsonCitas)
      let _citasObjects = JSON.parse(_jsonCitas).Citas as CitasObjects;

      this._applyFilter(_citasObjects)

      /*       if (_citasObjects.Today && _citasObjects.Today.length) {
            
             let __citasToday = _.filter(_citasObjects.Today, (o) => 
              _.includes(this._filterCalendar.doctores, o.Empleado_Id) || 
              _.includes(this._filterCalendar.tipoEvento, o.Tipo_Cita) ||
              _.includes(this._filterCalendar.recursos, o.Recurso_Id)  ||
              _.includes(this._filterCalendar.servicios, o.Servicio_Id ) )
      
             console.log({__citasToday});
             
             this._citas_today =[...__citasToday]
            }
      
            if (_citasObjects.Tomorrow && _citasObjects.Tomorrow.length) {
      
              let _doctoresFilter = _.filter(_citasObjects.Tomorrow, (o) => 
                _.includes(this._filterCalendar.doctores, o.Empleado_Id) || 
                _.includes(this._filterCalendar.tipoEvento, o.Tipo_Cita) ||
                _.includes(this._filterCalendar.recursos, o.Recurso_Id)  ||
                _.includes(this._filterCalendar.servicios, o.Servicio_Id )  )  //_citasObjects.Tomorrow.filter(o => o )
            //  let _filterTipoCita = _.filter(_citasObjects.Tomorrow, (o) => _.includes(this._filterCalendar.tipoEvento, o.Tipo_Cita ) )
              
              this._citas_tomorrow = [..._doctoresFilter];
            } */

    }
    this.alert._hideLoader()
    this._itsReady = true
  }

  /**
   *
   *
   * @private
   * @memberof CalandarioCitasPorDiaComponent
   */
  private _onSetData() {
    if (this.responseData) {

      this._itsToday = true;
      let _citasObjects = this.responseData.Citas as CitasObjects;
      if (_citasObjects.Today && _citasObjects.Today.length) {
        this._citas_today = [..._citasObjects.Today];
      }

      if (_citasObjects.Tomorrow && _citasObjects.Tomorrow.length) {
        this._citas_tomorrow = [..._citasObjects.Tomorrow];
      }
    }
  }

  /**
   * Abrimos el Canvas
   * @param _id 
   * @param content 
   */
  onClick(_id: number, content: TemplateRef<any>) {
    this._canvasRef_ = this.offcanvasService.open(content, {
      position: 'end',
      scroll: false
    })

  }

  /**
   * Detecteamoslos cambios
   * @param changes 
   */
  ngOnChanges(changes: SimpleChanges): void {


  }

  /**
   * 
   */
  hideCanvas() {

    this.offcanvasService.dismiss('cross click')
  }

  /**
   * 
   */
  hideModal() {
    this.modalService.dismissAll()
  }

  /**
   * 
   * @param itemId 
   * @returns 
   */
  getDoctor(itemId: string): string {
    let _find = _.find(this.responseData.Doctores, { Id: itemId })

    if (_find) {
      return _find.Name
    }

    return 'N/D'
  }

  /**
   * 
   * @param itemId 
   * @returns 
   */
  getServicio(itemId: string): string {

    let _find = _.find(this.responseData.Servicios, { Id: itemId })

    if (_find) {
      return _find.Name
    }
    return 'N/D'

  }

  /**
   * 
   * @param cita 
   */
  _onEventEmmitCita(cita: Cita) {

    let _doctor = _.find(this.responseData.Doctores, { Id: cita.Empleado_Id })
    let _servicio = _.find(this.responseData.Servicios, { Id: cita.Servicio_Id })

    this._citaSelected = cita;

    this._detallesCita = {
      cita,
      doctor: _doctor,
      servicio: _servicio
    }

    let _v = this.offcanvasService.activeInstance;

    this._canvasRef_ = this.offcanvasService.open(this.viewContent, {
      position: 'end',
      scroll: false
    })


  }

  /**
   * 
   * @param event 
   */
  onEventCanvasEmmitter(event: string) {
    this._canvasRef_.close()
    if (event == 'redirect') {
      this.redirect.to('/pacientes_formatos')
    }
  }

  /**
   * 
   * @param status 
   * @returns 
   */
  _getCardClass(status: string) {
    switch (status) {
      case 'Tratamiento':
        return 'card-primary'
      case 'Revisión':
        return 'card-success'
      case 'Bloqueo':
        return 'card-secondary'
      case 'Consulta':
        return 'card-info'
      case 'Cirugía':
        return 'card-dark'
      case 'Primera Cita':
        return 'card-warning'
      case 'Cancelada':
        return 'bg-danger';
      default:
        return 'card-light'
      /*       case 'Programada':
              return 'card-primary'
            case 'Exitosa':
              return 'card-info'
            case 'Confirmada':
              return 'card-success';
            case 'Cancelada':
              return 'card-danger'
            default:
              return 'card-warning' */
    }
  }

  _openModal() {

    this._ngbModalRef = this.ngModal.open(this.modalFilters, {
      animation: true,
      size: 'lg',
      centered: true,
      backdrop: 'static',
      fullscreen: 'lg',
      keyboard: false,
    })
  }

  /**
   * 
   * @param calendarFilter 
   */
  SetEventEmmitFilters(calendarFilter: FilterCalendar) {

    this._filterCalendar = { ...calendarFilter, inicio: '', final: '' }

    this.alert._showLoader('Cargando...')

    sessionStorage.setItem(FILTER_CITAS_DIAS, JSON.stringify(this._filterCalendar))

    this.loadCitasByFilter()

    console.log({ _filterCalendar: this._filterCalendar });


  }

}
