<div class="card" *ngIf="_formato && _itsReady">

    <div class="mt-2" *ngFor="let control of _controls">
        <form [formGroup]="formNotaEvolucion" class="needs-validation createfile-form" autocomplete="off" novalidate>
            <!-- LISTADO NOTAS DE EVOLUCION -->
            <div class="d-flex mt-4" *ngIf="_notasEvolucion && _notasEvolucion.length">
                <div class="col-12">
                    <fieldset>
                        <legend class="text-muted text-uppercase fs-16 text-center ">Notas de evolución </legend>
                        <div class="row">
                            <div class="col-md-auto" *ngFor="let item of _notasEvolucion">
                                <div class="float-start">
                                    <div class="d-grid gap-2">
                                        <button type="button"
                                            class="btn btn-light btn-label waves-effect waves-light rounded-pill text-wrap"
                                            (click)="_set_log_notasEvolucion(item)">
                                            <i
                                                class="ri-error-warning-line label-icon align-middle rounded-pill fs-16 me-2"></i>
                                            {{item.Cita_Id}}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </fieldset>

                    <hr>
                    <fieldset class="mt-4">
                        <legend>
                            <h5 class="text-muted text-uppercase mb-3 text-wrap ">Notas de evolución -
                                {{_cita_selected}} </h5>
                        </legend>
                        <div class="row">
                            <div class="col-md-4 col-xxl-3 col-xl-4">
                                <ngx-simplebar style="max-height: calc(50vh - 70px)">
                                    <div class="d-flex align-items-center ">
                                        <div class="flex-grow-1 ms-3 ">
                                            <div class="row gap-2 m-2">
                                                <div class="col-12" *ngFor="let item of _log_notas_evolucion">
                                                    <div class="d-grid gap-2">
                                                        <button type="button"
                                                            class="btn btn-light btn-label waves-effect waves-light rounded-pill text-wrap "
                                                            (click)="_setNotaEvolucion(item)">
                                                            <i
                                                                class="ri-error-warning-line label-icon align-middle rounded-pill fs-16 me-2"></i>
                                                            Nota # {{item.sesion}}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </ngx-simplebar>
                            </div>
                            <div class="col-md-8 col-xxl-9 col-xl-8" *ngIf="_selected_nota_evolucion">
                                <div class="card card-body">
                                    <div class="row">
                                        <div class="col-xxl-4 col-md-6">
                                            <label class="control-label">Fecha</label>
                                            <input class="form-control" [value]="_selected_nota_evolucion.fecha"
                                                readonly disabled>
                                        </div>
                                        <div class="col-xxl-6 col-md-12">
                                            <label class="control-label">subjetivo</label>
                                            <input class="form-control" [value]="_selected_nota_evolucion.subjetivo"
                                                readonly disabled>
                                        </div>
                                        <div class="col-xxl-6 col-md-6">
                                            <label class="control-label">objetivo</label>
                                            <input class="form-control" [value]="_selected_nota_evolucion.objetivo"
                                                readonly disabled>
                                        </div>
                                        <div class="col-xxl-6 col-md-6">
                                            <label class="control-label">analisis</label>
                                            <input class="form-control" [value]="_selected_nota_evolucion.analisis"
                                                readonly disabled>
                                        </div>
                                        <div class="col-xxl-6 col-md-6">
                                            <label class="control-label">plan</label>
                                            <input class="form-control" [value]="_selected_nota_evolucion.plan" readonly
                                                disabled>
                                        </div>
                                        <div class="col-xxl-6 col-md-6">
                                            <label class="control-label">Medico</label>
                                            <input class="form-control" [value]="_selected_nota_evolucion.nombre_medico"
                                                readonly disabled>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex mt-4"
                            *ngIf="_selected_nota_evolucion && _selected_nota_evolucion.imagenes.length">
                            <fieldset>
                                <div class="mt-4">
                                    <app-gallery-image [base64]="false" [_showDeleteBtn]="true"
                                        [GalleryImage]="_selected_nota_evolucion.imagenes"></app-gallery-image>
                                </div>
                            </fieldset>
                        </div>
                        <hr>
                        <div class="d-flex mt-4"
                            *ngIf="_img_nota_evolucion_historia_clinica && _img_nota_evolucion_historia_clinica.length">
                            <fieldset>
                                <legend class="text-muted fw-bold fs-14">Todos los archivos</legend>
                                <div class="mt-4">
                                    <app-gallery-image [base64]="false"
                                        [GalleryImage]="_img_nota_evolucion_historia_clinica"></app-gallery-image>
                                </div>
                            </fieldset>
                        </div>
                    </fieldset>
                </div>
            </div> <!-- END  LISTADO NOTAS DE EVOLUCION -->

            <div class="card-body border border-1">
                <div class="d-flex justify-content-end">
                    <button class="btn btn-primary" (click)="_AddedNote(control)">
                        <i class="mdi mdi-account-plus align-middle me-1"></i> Agregar nota de evolución
                    </button>
                </div>

                <fieldset class="mb-0 m-2">
                    <legend>Notas de Evolucion</legend>
                    <hr>
                    <div class="row d-flex justify-content-beetwen">
                        <div *ngFor="let item of _notas_evolucion; let i = index" class="col-12">
                            <!--  <ngx-simplebar style="max-height: calc(100vh - 70px)"> -->
                            <div class="card card-body">
                                <fieldset class="mt-4">
                                    <legend class="small text-muted"> {{item.label}} #{{ _log_notas_evolucion.length
                                        +1}}
                                        <div class="float-end"> <button class="btn btn-icon" (click)="_deleteNota(i)">
                                                <i
                                                    class="mdi mdi-trash-can-outline align-middle me-2 text-danger fs-2"></i>
                                            </button>
                                        </div>
                                    </legend>
                                    <app-notas-evolucion-clinical-history [item]="item" [controls]="control"
                                        [isDesktopDevice]="isDesktopDevice" [_cita]="_Cita" [record]="record"
                                        (_eventLoadHistoriaClinica)="_onEventSaveItFormNotasEvolucion($event)">
                                    </app-notas-evolucion-clinical-history>
                                </fieldset>
                            </div>
                            <!--       </ngx-simplebar> -->
                        </div>
                    </div>

                </fieldset>
            </div>
        </form>

        <div class="row">
            <div class="mt-4 mr-2" *ngFor="let obj of control.Objects">
                <div class="col-12" *ngIf="obj.type === 'canvas' ">
                    <div class="row m-2">
                        <div class="col-12">
                            <div class="card card-body"
                                *ngIf="obj.name === 'firma_medico_nota_evolucion_historia_clinica' ">
                                <div class="bg-primary">
                                    <h4 class="fs-4 text-white bold text-uppercase text-center">{{obj.label}} </h4>
                                </div>
                                <ng-container
                                    *ngIf="firma_medico_nota_evolucion_historia_clinica != null; else _firmaTemplate">
                                    <div class="d-flex justify-content-center">
                                        <figure class="figure mb-0">
                                            <img [src]="firma_medico_nota_evolucion_historia_clinica" class="figure-img img-fluid"
                                                max alt="" width="300">
                                        </figure>
                                    </div>
                                </ng-container>
                                <ng-template #_firmaTemplate>
                                    <app-firma-doctor 
                                    class="mt-4"
                                        (eventsignatureImg)="_onSaveSing($event,obj.name)"></app-firma-doctor>
                                </ng-template>


                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>