<app-breadcrumbs title="Listado de candidatos"></app-breadcrumbs>

<div class="mt-4 d-none d-lg-block">
    <div class="card card-body">
        <ng-template [ngTemplateOutlet]="filterSearch"></ng-template>
        <div class="table-responsive">
            <table class="table table-hover table-sm table-nowrap">
                <thead class="text-center bg-light text-uppercase">
                    <th scope="col">#</th>
                    <th scope="col">Nombre</th>
                    <th scope="col">Apellidos</th>
                    <th scope="col">Correo</th>
                    <th scope="col">Teléfono</th>
                    <th scope="col">Información</th>
                    <th scope="col"></th>
                </thead>
                <tbody>
                    <tr *ngFor="let item of _listadoCandidatos | buscadorCandidatos: searchText ; let i = index">
                        <th scope="row"> {{i + 1}} </th>
                        <td>{{item.Datos_Candidato.relaciones.nombre}}</td>
                        <td>{{item.Datos_Candidato.relaciones.apellidos}} </td>
                        <td>{{item.Datos_Candidato.generales.correo}} </td>
                        <td>{{item.Datos_Candidato.generales.telefono | mask:'(000) 000 0000'}} </td>
                        <td>
                            <div class="table-responsive">
                                <table class="table table-hover table-sm table-nowrap">
                                    <thead class="bg-light">
                                        <th scope="col">Origen paciente</th>
                                        <th scope="col">Especialidad</th>
                                        <th scope="col">Método contactó</th>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>{{item.Datos_Candidato.informacion.origen_paciente}} </td>
                                            <td>{{item.Datos_Candidato.informacion.especialidad}} </td>
                                            <td>{{item.Datos_Candidato.informacion.metodo_contacto}} </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </td>
                        <td>
                            <div class="hstack gap-3">
                                <button class="btn btn-primary btn-sm text-uppercase"
                                    (click)="openModal(modalPaciente, item)">Convertir a paciente</button>
                                <button class="btn btn-secondary btn-sm" (click)="_onEdit(item)">Editar
                                    candidato</button>
                                <!-- <button class="btn btn-icon" data-bs-toggle="tooltip" data-bs-placement="top" title="Ver detalles candidato"> <i class="mdi mdi-clipboard-account  align-middle me-1 fw-bold fs-2"></i> </button> -->
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
            <ng-template [ngTemplateOutlet]="filterPage"></ng-template>
        </div>
    </div>
</div>

<div class="mt-4 d-block d-lg-none">
    <ng-template [ngTemplateOutlet]="filterSearch"></ng-template>
    <div class="row row-cols-xxl-5 row-cols-lg-3 row-cols-md-2 row-cols-1">
        <div class="col" *ngFor="let candidato of _listadoCandidatos | buscadorCandidatos: searchText ">
            <div class="card mt-2 shadow-lg p-3 rounded">
                <div class="card-body">
                    <div class="d-md-flex align-items-center">
                        <div class="ms-lg-3 my-3 my-lg-0">
                            <h5 class="fs-16 mb-2">{{candidato.Datos_Candidato.relaciones.nombre}}
                                {{candidato.Datos_Candidato.relaciones.apellidos}} </h5>
                            <p class="fw-bold text-primarymb-0">{{candidato.Datos_Candidato.generales.correo}} </p>
                            <div class="mt-2">
                                <span> {{candidato.Datos_Candidato.generales.telefono | mask:'(000) 000 0000' }} </span>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex gap-4 mt-0 text-muted mx-auto fs-12">
                        <p class="text-wrap fw-bold"> Origen paciente</p>
                        <div class="d-flex mb-0 justify-content-center">
                            <span> {{candidato.Datos_Candidato.informacion.origen_paciente}} </span>
                        </div>
                    </div>
                    <div class="d-flex gap-4 mt-0 text-muted mx-auto fs-12">
                        <p class="text-wrap  fw-bold"> Especialidad </p>
                        <div class="d-flex mb-0 justify-content-center">
                            <span> {{candidato.Datos_Candidato.informacion.especialidad}} </span>
                        </div>
                    </div>
                    <div class="d-flex gap-4 mt-0 text-muted mx-auto fs-12">
                        <p class="text-wrap  fw-bold"> Método contactó </p>
                        <div class="d-flex mb-0 justify-content-center">
                            <span> {{candidato.Datos_Candidato.informacion.metodo_contacto}} </span>
                        </div>
                    </div>
                    <div class="d-flex mt-4 justify-content-center ">
                        <div class="hstack gap-3">
                            <button class="btn btn-primary btn-sm text-uppercase"
                                (click)="openModal(modalPaciente, candidato)">Convertir a paciente</button>
                            <button class="btn btn-secondary btn-sm" (click)="_onEdit(candidato)">Editar
                                candidato</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <ng-template [ngTemplateOutlet]="filterPage"></ng-template>
</div>

<ng-template #filterPage>
    <div class="m-2 p-1">
        <div class="row justify-content-md-between align-items-md-center"
            *ngIf="_listadoCandidatos && _listadoCandidatos.length">
            <div class="col-sm-12 col-md-5">
                <div class="dataTables_info mb-2" id="tickets-table_info" role="status" aria-live="polite">
                    Pagina {{currentPage}} de {{totalPages}} <small class="fw-bold">
                        {{totalCount}} registros </small>
                </div>
            </div>
            <!-- Pagination -->
      <!--       <div class="col-sm-12 col-md-5">
                <div class="text-md-right float-md-end gridjs-pagination">
                    <ngb-pagination [collectionSize]="totalCount" [(page)]="currentPage" [maxSize]="4"
                        [boundaryLinks]="true" [pageSize]="pageSize" (pageChange)="_onPageChange($event)">
                    </ngb-pagination>
                </div>
            </div> -->
            <!-- End Pagination -->
        </div>
    </div>
</ng-template>

<ng-template #filterSearch>
    <div class="row row-cols-1 mt-3">
        <div class="mb-3">
            <label class="form-label">Buscar por nombre</label>
            <input type="text" class="form-control" [(ngModel)]="searchText">
        </div>
    </div>
</ng-template>

<ng-template #modalPaciente let-modal>
    <div class="modal-content">
        <div class="modal-header">
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="btn-close"
                (click)="modal.dismiss('Cross click')"></button>
        </div>
        <div class="modal-body text-center">
            <app-create-patient [_candidato]="_candidato" [_reloadCanidates]="true"
                (eventClickCancel)="onClickEvent($event)"
                (eventConfirmCandidate)="_onEventConfirmCandidate($event)"></app-create-patient>
        </div>
    </div><!-- /.modal-content -->
</ng-template>

<ng-template #modalCandidato let-mmodalCandiato>
    <div class="modal-content">
        <div class="modal-header">
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="btn-close"
                (click)="mmodalCandiato.dismiss('Cross click')"></button>
        </div>
        <div class="modal-body text-center">
            <app-create-candidate [editable]="true" [_dataCandidato]="_candidato"
                (eventCloseModal)="_onEventCloseModal($event)"></app-create-candidate>
        </div>
    </div>
</ng-template>

<ngx-spinner [name]="_spinner" type="ball-scale-multiple" [fullScreen]="false"></ngx-spinner>


<ng-template #successContent let-modal>
    <div class="modal-content">
        <div class="modal-header">
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="btn-close"
                (click)="modal.close('Cross click')"> </button>
        </div>
        <div class="modal-body text-center">
            <lord-icon src="https://cdn.lordicon.com/kvuyljqb.json" trigger="loop"
                colors="primary:#0ab39c,secondary:#405189" style="width:120px;height:120px"></lord-icon>
            <div class="mt-4 pt-2 fs-15">
                <h4>El registro se ha relizado correctamente. ID_PACIENTE: {{_responseData.Paciente_Id}}</h4>
                <h5 class="text-muted mx-4 mb-0">¿Desea generarle una cita, en este momento?</h5>
            </div>
            <!--  -->
            <div class="d-flex gap-2 justify-content-center mt-4 mb-2">
                <button type="button" class="swal2-cancel btn btn-primary w-xs mb-1" aria-label=""
                    style="display: inline-block;" (click)="modal.close('Close click')">Cerrar</button>
                <button type="button" class="swal2-cancel btn btn-success w-xs mb-1" aria-label=""
                    style="display: inline-block;" (click)="_onSetPatient()"> Crear cita</button>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #modalCita let-modalCita>

    <div class="modal-header p-3 bg-primary-subtle">
        <h5 class="modal-title" id="modal-title">Crear Cita </h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-hidden="true"
            (click)="modalCita.close()"></button>
    </div>

    <div class="modal-body p-4">
        <app-create-citas [_id_paciente]="_responseData.Salesforce_Id"
            (eventClickCancel)="onClickEvent($event)"></app-create-citas>
    </div>

</ng-template>