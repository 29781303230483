import { Pipe, PipeTransform } from '@angular/core';
import { CandidatoData } from '../calendar/models/candidatos';

@Pipe({
  name: 'buscadorCandidatos'
})
export class BuscadorCandidatosPipe implements PipeTransform {

  transform(collection: CandidatoData[], searchText: string) {
    if(typeof searchText === typeof undefined) return collection
    return collection.filter((item) =>  item.Name.toLocaleLowerCase().includes(searchText.toLocaleLowerCase()) );
  }

}
