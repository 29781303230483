<div class="card" *ngIf="_formato">
    <div class="card-body">
        <div class="row g-2 m-4" *ngFor="let item of _controls">

                <ng-container *ngIf="item.type=='frame'" >
                    <div class="col-12" >
                        <div class="bg-primary">
                            <h4 class="fs-4 text-white bold text-uppercase text-center">{{item.label}} </h4>     
                        </div>
                    </div>

                    <div class="row g-2" >              
                        <div class="col-sm-12 col-md-6 col-lg-6 col-xl-4 mt-4 mr-2" *ngFor="let obj of item.Objects" > 
    
                            <div *ngIf="obj.type == 'date' ">
                                <div class="form-floating">
                                    <input                        
                                    class="form-control flatpickr-input"                                             
                                    [id]="obj.name"     
                                    [type]="obj.type"     
                                    readonly   
                                    [readonly]="obj.readonly"    
                                    >
                                    <label [for]="obj.name" class="form-label" >{{obj.label}} </label> 
                                </div>
                            </div>
    
                            <div *ngIf="obj.type == 'text' || obj.type == 'number' ">
                                <div class="form-floating">
                                    <input 
                                    class="form-control"
                                    [type]="obj.type"
                                    [name]="obj.name"
                                    [readonly]="obj.readonly"
                                    [value]="_getValue(obj.name)"
                                    [required]="obj.required"
                                    >
                                    <label class="form-label" for="{{obj.name}}" >{{obj.label}} </label>
                                </div>                              
                            </div>
    
                            <div *ngIf="obj.type == 'time' ">
                                <input 
                                type="text"
                                [name]="obj.name"
                                [value]="_getValue(obj.name)"
                                class="form-control" 
                                >
                            </div>
    
    
                        </div>
                    </div>

                </ng-container>
            <form [formGroup]="formNotaEgreso" class="needs-validation createfile-form" autocomplete="off" novalidate>
                <ng-container *ngIf="item.type == 'subtitle' ">                    
                    <div class="col-12">
                        <div class="bg-dark">
                            <h4 class="fs-16 text-white bold text-uppercase text-center">{{item.label}} </h4>     
                        </div>
                        <div class="row g-2">
                            <div *ngFor="let obj of item.Objects" [ngClass]="obj.type == 'textarea' ? 'col-12' : 'col-sm-12 col-md-12 col-lg-6 col-xl-4' " >
                                
                                <div *ngIf="obj.type == 'date' ">
                                    <div class="form-floating">
                                        <input                        
                                        class="form-control flatpickr-input"                                             
                                        [id]="obj.name"     
                                        [type]="obj.type"        
                                        [formControlName]="obj.name"                        
                                        [readonly]="obj.readonly" >
                                        <label [for]="obj.name" class="form-label" >{{obj.label}} </label> 
                                    </div>
                                </div>
    
                                <div *ngIf="obj.type == 'number' ">
                                    <div class="form-floating">
                                        <input 
                                        class="form-control"
                                        type="number"
                                        [name]="obj.name"
                                        [formControlName]="obj.name"
                                        [readonly]="obj.readonly"                                
                                        [required]="obj.required"
                                        >
                                        <label class="form-label" for="{{obj.name}}" >{{obj.label}} </label>
                                    </div>
                                </div>
                                
                                <div *ngIf="obj.type == 'time' ">
                                    <div class="form-floating">
                                        <input 
                                        type="time"
                                        [name]="obj.name"
                                        [formControlName]="obj.name"
                                        [readonly]="obj.readonly"
                                        class="form-control">
                                        <label for="{{obj.name}}" class="form-label">{{obj.label}}</label>
                                    </div>
                            
                                </div>
    
                                <div *ngIf="obj.type =='text' ">
                                    <div class="form-floating">
                                        <input 
                                        class="form-control"
                                        [type]="obj.type"
                                        [name]="obj.name"
                                        [formControlName]="obj.name"
                                        [readonly]="obj.readonly"                                
                                        [required]="obj.required"
                                        >
                                        <label class="form-label" for="{{obj.name}}" >{{obj.label}} </label>
                                    </div>
                                </div>           
    
                                <div *ngIf="obj.type =='textarea' ">
    
                                    <div class="card-body">
                                        <div class="row mt-3">
                                            <div class="col-xl-3">
                                                <label for="{{obj.name}}" class="form-label">{{obj.label}}</label>
                                            </div>
                                            <div class="col-xl-9 col-lg-12">       
                                                <app-text-area-voice-recorder 
                                                [transcript]="f[obj.name].value"
                                                [name]="obj.name"                            
                                                [required]="obj.required"                            
                                                ngDefaultControl 
                                                [formControlName]="obj.name"
                                                [rows]="4"                            
                                                (eventEmmiterValue)="_onEventEmmiterValue($event, obj)">
                                                </app-text-area-voice-recorder>                             
                                        <!--         <textarea
                                                [name]="obj.name"
                                                class="form-control"
                                                [formControlName]="obj.name"
                                                [readonly]="obj.readonly"
                                                [rows]="5"></textarea>      -->                                       
                                            </div>
                                        </div>
                                    </div>
                              
                                </div>

                            </div>
                        </div>
                    </div>
                </ng-container>

                <ng-container *ngIf="item.type == 'label'">
                    <div class="col-12">

                        <hr>
                        <div class="row g-2">
                            <div *ngFor="let obj of item.Objects" [ngClass]="obj.type == 'textarea' || obj.type == 'label' ? 'col-12' : 'col-sm-12 col-md-12 col-lg-6 col-xl-4' ">
                                
                                <div *ngIf="obj.type == 'date' ">
                                    <div class="form-floating">
                                        <input                        
                                        class="form-control flatpickr-input"                                             
                                        [id]="obj.name"     
                                        [type]="obj.type"    
                                        [formControlName]="obj.name"                                    
                                        [readonly]="obj.readonly" >
                                        <label [for]="obj.name" class="form-label" >{{obj.label}} </label> 
                                    </div>
                                </div>
    
                                <div *ngIf="obj.type == 'number' ">
                                    <div class="form-floating">
                                        <input 
                                        class="form-control"
                                        type="number"
                                        [name]="obj.name"
                                        [formControlName]="obj.name"
                                        [readonly]="obj.readonly"                                
                                        [required]="obj.required"
                                        >
                                        <label class="form-label" for="{{obj.name}}" >{{obj.label}} </label>
                                    </div>
                                </div>
                                
                                <div *ngIf="obj.type == 'time' ">
                                    <div class="form-floating">
                                        <input 
                                        type="time"
                                        [name]="obj.name"
                                        [formControlName]="obj.name"
                                        [readonly]="obj.readonly"
                                        class="form-control">
                                        <label for="{{obj.name}}" class="form-label">{{obj.label}}</label>
                                    </div>
                            
                                </div>
    
                                <div *ngIf="obj.type =='text' ">
                                    <div class="form-floating">
                                        <input 
                                        class="form-control"
                                        [type]="obj.type"
                                        [name]="obj.name"
                                        [formControlName]="obj.name"
                                        [readonly]="obj.readonly"                                
                                        [required]="obj.required"
                                        >
                                        <label class="form-label" for="{{obj.name}}" >{{obj.label}} </label>
                                    </div>
                                </div>           
    
                                <div *ngIf="obj.type =='textarea' ">
    
                                    <div class="card-body">
                                        <div class="row mt-3">
                                            <div class="col-xl-3">
                                                <label for="{{obj.name}}" class="form-label">{{obj.label}}</label>
                                            </div>
                                            <div class="col-xl-9 col-lg-12">           
                                                <app-text-area-voice-recorder 
                                                [transcript]="f[obj.name].value"
                                                [name]="obj.name"                            
                                                [required]="obj.required"                            
                                                ngDefaultControl 
                                                [formControlName]="obj.name"
                                                [rows]="4"                            
                                                (eventEmmiterValue)="_onEventEmmiterValue($event, obj)">
                                                </app-text-area-voice-recorder>                         
                                             <!--    <textarea
                                                [name]="obj.name"
                                                [formControlName]="obj.name"
                                                class="form-control"
                                                [readonly]="obj.readonly"
                                                [rows]="5"></textarea>      -->                                       
                                            </div>
                                        </div>
                                    </div>
                              
                                </div>

                                <div *ngIf="obj.type == 'label'">

                                    <fieldset>
                                        <legend> 
                                            <div class="bg-dark">
                                                <h4 class="fs-16 text-white bold text-uppercase text-center">{{obj.label}} </h4>     
                                            </div>  
                                        </legend>
                                        <div class="row g-2">
                                            <div class="col-12" *ngFor="let _objs of obj.Objects">

                                                <fieldset class="mt-4">
                                                    <legend class="fs-14 text-muted"> {{_objs.label}}   </legend>

                                                    <div class="row g-2">
                                                        <div *ngFor="let _obj of _objs.Objects" [ngClass]="_obj.type == 'radio' ? 'col-sm-12 col-md-6 col-lg-6 col-xl-4 mt-4 mr-2' :'col-12'">
                                                            <div class="form-check form-check-right form-radio-primary mb-3">
                                                                <input 
                                                                class="form-check-input" 
                                                                type="radio" 
                                                                [name]="_objs.name"         
                                                                [value]="_obj.name"                                        
                                                                id="{{_obj.name}}" 
                                                                [formControlName]="_objs.name"
                                                                >
                                                                <label class="form-check-label" for="{{_obj.name}}">
                                                                {{_obj.label}}
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </fieldset>

                                                <div *ngIf="_objs.type == 'canvas'">
                                                    <ng-container *ngIf="_Firma && _Firma.length; loadedTemplate">
                                                        <div class="col-12 ">
                                                            <h4 class="fs-4 text-primary bold text-uppercase text-center">Firma digital </h4>     
                                                        </div>
                                                        <fieldset class="mt-4" >
                                                            <div class="card" *ngFor="let item of _Firma" >
                                                             <div class="card-body">
                                                                 <div class="d-flex justify-content-center"  >
                                                                     <figure class="figure mb-0">
                                                                         <img [src]="item.url" class="figure-img img-fluid" max  alt="" width="300">
                                                                     </figure>                                        
                                                                 </div>                                    
                                                             </div>
                                                           
                                                            </div>
                                                         </fieldset>
                                                    </ng-container>
                                                    <ng-template #loadedTemplate>
                                                        <app-firma-doctor></app-firma-doctor>
                                                    </ng-template>
                                                </div>


                                            </div>
                                        </div>
                                    </fieldset>                                   
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </form>

        </div>
    </div>
</div>
