import { AlertsService } from './../../services/alerts.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CredentialsService } from '@app/core/services/credentials.service';
import { Cita } from '@app/pages/calendar/models/calendar';
import { FormatsService } from '@app/shared/services/formats.service';
import { ToastrService } from 'ngx-toastr';


import * as _ from 'lodash'
import { Subject } from 'rxjs';
import { NotaDeEvolucion } from '@app/shared/models/nota-evolucion';
import * as moment from 'moment';

@Component({
  selector: 'app-notas-evolucion-clinical-history',
  templateUrl: './notas-evolucion-clinical-history.component.html',
  styleUrls: ['./notas-evolucion-clinical-history.component.scss']
})
export class NotasEvolucionClinicalHistoryComponent implements OnInit {

  @Input()
  item: any

  @Input()
  controls: any

  @Input()
  isDesktopDevice: boolean

  @Input()
  _cita: Cita;

  @Input()
  record: any

  @Output()
  _eventLoadHistoriaClinica: EventEmitter<boolean> = new EventEmitter<boolean>()

  _formNotasEvolucion: FormGroup = this.formBuilder.group({})
  _showForm: boolean

  _userData: any

  _svgFiles: any[] = []

  eventSubjectClerFiles: Subject<boolean> = new Subject<boolean>();

  
  _assetsSvg = 'assets/images/svg/circle.svg'

  _svgEditors: any[] = [{
    id: 0,
    path: this._assetsSvg,
    _showComments: true,
    comentario: null
  }]

  _svgfilesPng: any[] = []
  _files: any[] = []
  _webcamImage: any[] = []

  constructor(
    private formBuilder: FormBuilder,
    private formatService: FormatsService,
    private toastr: ToastrService,
    private credentials: CredentialsService,
    private alert: AlertsService

  ) {

    const _dataUser = this.credentials._getdataUser()

    if (_dataUser) {
      this._userData = _dataUser
      console.log({ _dataUser });
    }
  }


  ngOnInit(): void {
    if (this.controls) {
      _.map(this.controls.Objects, (a) => {
        this._formNotasEvolucion.addControl(a.name, this.formBuilder.control(a.value))
      })
      this._showForm = true
    }

  }

  _onUploadFiles(_files: any[]) {
    this._files = _files 
  }

  _onSaveItImages(webcamImage: any[]) {
    this._webcamImage = [...webcamImage]
  }


  _onEvenEmmiter(event: boolean) { }

  _onEventEmmiterImg(event: any) { 
    this._svgfilesPng.push(event)    
  }


  _onsave() {

    this.alert._showLoader('Guardando nota de evolucion, espere...')
    let _notaEvolucion: NotaDeEvolucion = new NotaDeEvolucion()
    let _value = this._formNotasEvolucion.value       
    _notaEvolucion = {
      Cita_Id: this._cita.Cita_Id,
      analisis: _value.analisis_nota_evolucion_historia_clinica,
      fecha: moment(_value.fecha_nota_evolucion_historia_clinica).format('YYYY-MM-DD'),
      objetivo: _value.objetivo_nota_evolucion_historia_clinica,
      plan: _value.plan_nota_evolucion_historia_clinica,
      subjetivo : _value.subjetivo_nota_evolucion_historia_clinica,
      usuario: this._userData.usuario
    }

    this.formatService._GuardarNotasEvolucion(_notaEvolucion,this.record.Name,this.controls.name, this._files, this._webcamImage, this._svgfilesPng).then((resp) => {
      this.alert._hideLoader()
      this.eventSubjectClerFiles.next(true)
      this._formNotasEvolucion.reset()
      this._eventLoadHistoriaClinica.next(true)

      if(this._svgfilesPng && this._svgfilesPng.length) {
        this._svgEditors = []
        this._svgEditors.push({
          id: 0,
          path: this._assetsSvg,
          _showComments: true,
          comentario: null
        })
        this._svgfilesPng = []
      }

    }).catch((error) => {
      this.alert._hideLoader()
      console.error({error});
      
    })
  }

}
